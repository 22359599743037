import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Section as DefaultSection } from 'atoms';
import { getUpcomingEvents } from 'core/selectors';
import { getUpcomingEventsRequestAction } from 'core/actions';

import { UpcomingCarousel } from './UpcomingCarousel';

export const UpcomingEvents = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { upcomingEvents } = useSelector(getUpcomingEvents);

  useEffect(() => {
    dispatch(getUpcomingEventsRequestAction());
  }, [dispatch]);

  const itemClickHandler = (eventId: string = '') => {
    history.push(`${eventId}`);
  };

  const seeAllBtnHandler = () => {
    history.push('catalog/all');
  };

  if (!upcomingEvents.length) {
    return null;
  }

  return (
    <Section title="Ближайшие мероприятия">
      <UpcomingCarousel items={upcomingEvents} itemClickHandler={itemClickHandler} />
      <SeeAllButton onClick={seeAllBtnHandler} type="ghost">
        Посмотреть все
      </SeeAllButton>
    </Section>
  );
};

const Section = styled(DefaultSection)`
  background: #f8f8f8;
  margin: 0 auto;

  & > div {
    position: relative;

    & > div:first-child {
      margin-right: 130px;
    }
  }
`;

const SeeAllButton = styled(Button)`
  display: block;
  margin: 56px auto 0;
`;
