import { createGlobalStyle } from 'styled-components';

export interface ITheme {
  theme: {
    [key: string]: string;
  };
}

export const GlobalStyles = createGlobalStyle`
  body {
    min-width: 320px;
  }
  
  *,
  *::after,
  *::before {
    box-sizing: border-box;

    -webkit-font-feature-settings: "lnum";
    -moz-font-feature-settings: "lnum";
    font-feature-settings: "lnum";
  } 
`;
