import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { setSurveyStepAction } from 'core/actions';
import { getSurveyData } from 'core/selectors';
import { getQuestionsQtyString } from 'components/molecules/SurveyCard';

import { StepWrapper } from './StepWrapper';

interface IStartStepProps {
  isEvent?: boolean;
}

export const StartStep = ({ isEvent }: IStartStepProps) => {
  const dispatch = useDispatch();
  const { surveyData } = useSelector(getSurveyData);

  const startSurvey = () => {
    dispatch(setSurveyStepAction());
  };

  if (!surveyData) {
    return null;
  }

  return (
    <StepWrapperStyled $isEvent={isEvent}>
      <TextContentWrapper>
        <QuantityStyled>{getQuestionsQtyString(surveyData.questions.length)}</QuantityStyled>
        <TitleStyled>{surveyData.title}</TitleStyled>
        {surveyData.subtitle && <SubTitleStyled>{surveyData.subtitle}</SubTitleStyled>}
      </TextContentWrapper>
      <ButtonStyled onClick={startSurvey}>Пройти опрос</ButtonStyled>
    </StepWrapperStyled>
  );
};

const StepWrapperStyled = styled(StepWrapper)<{ $isEvent?: boolean }>`
  background: linear-gradient(146deg, #121a7b 0%, #4853ec 100%);

  ${({ $isEvent }) =>
    $isEvent &&
    `
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  `}
`;

const TextContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const QuantityStyled = styled.div`
  color: #ffffff;
  font-family: Open Sans;
  font-size: 16px;
  line-height: 24px;
`;

const TitleStyled = styled.div`
  color: #ffffff;
  text-align: center;
  font-family: Raleway;
  font-size: 18px;
  font-weight: 900;
  line-height: 28px;
  margin-top: 16px;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 40px;
    margin-top: 32px;
  }

  @media (min-width: 1024px) {
    font-size: 48px;
    font-weight: 800;
    line-height: 56px;
  }
`;

const SubTitleStyled = styled.div`
  color: #fff;
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  line-height: 24px;
  margin-top: 16px;

  @media (min-width: 768px) {
    font-size: 16px;
    margin-top: 32px;
  }

  @media (min-width: 1024px) {
    font-size: 18px;
    line-height: 28px;
  }
`;

const ButtonStyled = styled.button`
  border-radius: 8px;
  background-color: #ff8686;
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.4px;
  border: none;
  cursor: pointer;
  margin-top: 32px;

  @media (min-width: 768px) {
    margin-top: 48px;
  }
`;
