import { ActionType, createReducer } from 'typesafe-actions';

import {
  putAreasAliasToStoreAction,
  startInitAppLoadingAction,
  endInitAppLoadingAction,
  clearAreasAliasToStoreAction,
  setAppServerError,
  setVisitorStatusAction,
  putUserDataToStoreAction,
} from 'core/actions';
import { IApiUser, IAreasAlias, ServerErrors } from 'types';

export interface IAppState {
  isInitAppLoading: boolean;
  areasAlias: IAreasAlias[];
  serverError: ServerErrors;
  userInfo: IApiUser | null;
  isDoctor?: boolean;
}

const defaultState: IAppState = {
  isInitAppLoading: true,
  areasAlias: [],
  serverError: ServerErrors.None,
  userInfo: null,
};

const actions = {
  startInitAppLoadingAction,
  endInitAppLoadingAction,
  putAreasAliasToStoreAction,
  setAppServerError,
  clearAreasAliasToStoreAction,
  setVisitorStatusAction,
  putUserDataToStoreAction,
};

export const appReducer = createReducer<IAppState, ActionType<typeof actions>>(defaultState)
  .handleAction(startInitAppLoadingAction, (state) => ({
    ...state,
    isInitAppLoading: true,
  }))
  .handleAction(endInitAppLoadingAction, (state) => ({
    ...state,
    isInitAppLoading: false,
  }))
  .handleAction(setVisitorStatusAction, (state, { payload: { isDoctor } }) => ({
    ...state,
    isDoctor,
  }))

  .handleAction(setAppServerError, (state, { payload: { error } }) => ({
    ...state,
    serverError: error,
  }))

  .handleAction(putAreasAliasToStoreAction, (state, { payload: { areasAlias } }) => ({
    ...state,
    areasAlias,
  }))
  .handleAction(clearAreasAliasToStoreAction, (state) => ({ ...state, areasAlias: [] }))

  .handleAction(putUserDataToStoreAction, (state, { payload }) => ({
    ...state,
    userInfo: payload,
  }));
