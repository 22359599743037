import React, { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { getAllAreas, getIsAppSiderCollapsed, getIsLoggedIn } from 'core/selectors';
import {
  changeLoginPopupVisibleAction,
  changeRegisterPopupVisibleAction,
  getAllAreasRequestAction,
  getCatalogCategoriesRequestAction,
  isAppSiderCollapsedAction,
  logOutAction,
  putUserDataToStoreAction,
} from 'core/actions';

import { AppSider } from './AppSider';

interface IProps {
  children: ReactNode;
}

export const Container = ({ children }: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isAppSiderCollapsed = useSelector(getIsAppSiderCollapsed);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const areas = useSelector(getAllAreas);
  const { area } = useParams<{ area?: string }>();

  useEffect(() => {
    dispatch(getCatalogCategoriesRequestAction({ alias: area }));
  }, [dispatch, area]);

  // TODO move to appsider navmenu and remove areas prop
  useEffect(() => {
    dispatch(getAllAreasRequestAction());
  }, [dispatch]);

  const linkOnClick = () => {
    dispatch(isAppSiderCollapsedAction(true));
  };

  const showLoginModal = () => {
    dispatch(isAppSiderCollapsedAction(true));
    dispatch(changeLoginPopupVisibleAction(true));
  };

  const showRegModal = () => {
    dispatch(isAppSiderCollapsedAction(true));
    dispatch(changeRegisterPopupVisibleAction(true));
  };

  const logoutHandler = () => {
    dispatch(logOutAction());
    dispatch(putUserDataToStoreAction(null));
    history.push('/');
  };

  return (
    <AppSider
      linkOnClick={linkOnClick}
      isAppSiderCollapsed={isAppSiderCollapsed}
      isLoggedIn={isLoggedIn}
      showLoginModal={showLoginModal}
      showRegModal={showRegModal}
      logoutHandler={logoutHandler}
      area={area}
      catalogCategoriesItems={areas}
    >
      {children}
    </AppSider>
  );
};
