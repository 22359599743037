import { ActionType, createReducer } from 'typesafe-actions';

import {
  putAuthorToStoreAction,
  getAuthorRequestAction,
  getAuthorSuccessAction,
  getAuthorFailureAction,
  putAuthorEventsToStoreAction,
  getAuthorEventsRequestAction,
  getAuthorEventsSuccessAction,
  getAuthorEventsFailureAction,
  clearAuthorToStoreAction,
} from 'core/actions';
import { IAuthorExtended, IEventListItem } from 'types';

export interface IAuthorState {
  author: IAuthorExtended;
  isAuthorLoading: boolean;

  authorEvents: IEventListItem[];
}

const defaultState: IAuthorState = {
  author: createDefaultAuthor(),
  isAuthorLoading: true,

  authorEvents: [],
};

const actions = {
  putAuthorToStoreAction,
  getAuthorRequestAction,
  getAuthorSuccessAction,
  getAuthorFailureAction,
  putAuthorEventsToStoreAction,
  getAuthorEventsRequestAction,
  getAuthorEventsSuccessAction,
  getAuthorEventsFailureAction,

  clearAuthorToStoreAction,
};

export const authorReducer = createReducer<IAuthorState, ActionType<typeof actions>>(defaultState)
  .handleAction(putAuthorToStoreAction, (state, { payload: { author } }) => ({
    ...state,
    author,
  }))
  .handleAction(getAuthorRequestAction, (state) => ({
    ...state,
    isAuthorLoading: true,
  }))
  .handleAction(getAuthorSuccessAction, (state) => ({
    ...state,
    isAuthorLoading: false,
  }))
  .handleAction(getAuthorFailureAction, (state) => ({
    ...state,
    isAuthorLoading: false,
  }))

  .handleAction(putAuthorEventsToStoreAction, (state, { payload: { events } }) => ({
    ...state,
    authorEvents: events,
  }))

  .handleAction(clearAuthorToStoreAction, () => ({
    ...defaultState,
  }));

function createDefaultAuthor(): IAuthorExtended {
  return {
    id: 0,
    position: '',
    fullName: '',
    photoUrl: '',
    description: '',
    biography: '',
    additionalInfo: '',
    totalEvents: 0,
    publications: [],
  };
}
