import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { Row, Col } from 'antd';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';

import { degreeList, specifiesList } from 'mock';
import { IFormErrors, IRegisterFormSecondStepValues, RegisterSteps } from 'types';
import { validationService } from 'services';
import { putRegisterStepToStore, putSecondStepRegisterToStore } from 'core/actions';
import { store } from 'core';
import { IState } from 'core/types/reduxTypes';

import { TextField, SelectField, FormFooter } from '..';

export const Form = ({
  handleSubmit,
  handleStepDown,
}: InjectedFormProps<IRegisterFormSecondStepValues, { handleStepDown?: () => void }> & {
  handleStepDown?: () => void;
}) => {
  return (
    <form>
      <FormContent>
        <Row gutter={[64, 0]}>
          <Col xs={24} xl={12}>
            <Margin xs="0" xl="0 0 27px 0">
              <Field
                name="specify"
                caption="Специальность"
                required
                options={specifiesList}
                defaultValue="Выберите специальность"
                component={SelectField}
              />
            </Margin>
          </Col>
          <Col xs={24} xl={12}>
            <Margin xs="0" xl="11px 0 18px 0">
              <Field name="workStudyPlace" caption="Место работы/учебы" component={TextField} required />
            </Margin>
          </Col>
        </Row>
        <Row gutter={[64, 0]}>
          <Col xs={24} xl={12}>
            <Margin xs="0" xl="11px 0 18px 0">
              <Field name="position" caption="Должность" component={TextField} />
            </Margin>
          </Col>
          <Col xs={24} xl={12}>
            <Margin>
              <Field
                name="degree"
                caption="Ученая степень"
                options={degreeList}
                defaultValue=""
                component={SelectField}
              />
            </Margin>
          </Col>
        </Row>
      </FormContent>
      <FormFooter step={RegisterSteps.Second} handleSubmit={handleSubmit} handleStepDown={handleStepDown} />
    </form>
  );
};

const validateSecondStep = ({ specify, position, workStudyPlace, degree }: IRegisterFormSecondStepValues) => {
  return {
    specify: validationService.validateRequired(specify),
    position: position ? validationService.validateLength(position, 60) : '',
    workStudyPlace: validationService.validateRequired(workStudyPlace),
    degree: degree ? validationService.validateLength(degree, 60) : '',
  } as IFormErrors;
};

const onSecondStepFormSubmit = (formData: IRegisterFormSecondStepValues) => {
  store.dispatch(putSecondStepRegisterToStore(formData));
  store.dispatch(putRegisterStepToStore(RegisterSteps.Third));
};

const mapStateToProps = (state: IState) => ({
  initialValues: state.auth.register.secondStep,
});

export const SecondStepForm = connect(mapStateToProps)(
  reduxForm<IRegisterFormSecondStepValues, { handleStepDown?: () => void }>({
    form: 'RegisterFormSecondStep',
    validate: validateSecondStep,
    onSubmit: onSecondStepFormSubmit,
    enableReinitialize: true,
    touchOnBlur: false,
  })(Form),
);

const Margin = styled.div<{ xs?: string; md?: string; xl?: string }>`
  ${({ xs, md, xl }) => css`
    margin: ${xs ?? 0};

    ${md &&
    css`
      @media (min-width: 768px) {
        margin: ${md};
      }
    `}

    ${xl &&
    css`
      @media (min-width: 1200px) {
        margin: ${xl};
      }
    `}
  `};
`;

const FormContent = styled.div`
  margin-bottom: 2px;
  padding-top: 10px;

  @media (min-width: 768px) {
    margin-bottom: 64px;
  }
`;
