import { IApiAuthor, IAuthor, IApiAuthorExtended, IAuthorExtended } from 'types';

import { convertPublicationsToAppSchema } from './publications';

export const convertAuthorsToAppSchema = (authors: IApiAuthor[]): IAuthor[] => {
  return authors.map((item) => ({
    position: item.position,
    fullName: item.full_name,
    imageUrl: item.photo_url,
    id: item.id,
    description: item.description,
    totalArticles: item.total_articles || 0,
    totalEvents: item.total_events || 0,
  }));
};

export const convertAuthorExtendedToAppSchema = (author: IApiAuthorExtended): IAuthorExtended => {
  return {
    id: author.id,
    position: author.position,
    fullName: author.full_name,
    photoUrl: author.photo_url,
    description: author.description,
    biography: author.biography,
    additionalInfo: author.additional_info,
    totalEvents: author.total_events,
    publications: convertPublicationsToAppSchema(author.articles),
  };
};
