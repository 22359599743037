import React from 'react';
import styled, { css } from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

interface IProps {
  onClick: () => void;
  imageUrl: string;
  position: string;
  fullName: string;
  description: string;
  color?: string;
}

export const AuthorCard = ({ onClick, imageUrl, position, fullName, description, color }: IProps) => {
  return (
    <Container onClick={onClick}>
      <Image url={imageUrl} />
      <Position>{position}</Position>
      <FullName>{fullName}</FullName>
      <Description $color={color}>
        <Position>{position}</Position>
        <FullName>{fullName}</FullName>
        {ReactHtmlParser(description.replace(/\r\n/g, ' <br> '))}
      </Description>
    </Container>
  );
};

const Position = styled.div`
  font-family: 'Raleway', sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #a7a8ab;

  padding: 16px 16px 4px 16px;

  @media (min-width: 768px) {
    padding: 24px 24px 8px 24px;
  }
`;

const FullName = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #000000;

  padding: 0px 16px 32px 16px;

  @media (min-width: 768px) {
    padding: 0px 24px 32px 24px;
  }
`;

const Description = styled.div<{ $color?: string }>`
  display: none;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;

  padding: 0px 24px;

  background-color: ${({ $color }) => $color || '#4853ec'};

  ${Position} {
    color: #ffffff;
    padding: 32px 0px 8px 0;
  }

  ${FullName} {
    color: #ffffff;
    padding: 0px 0px 40px 0px;
  }
`;

const Container = styled.div`
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  width: 100%;
  cursor: pointer;

  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgba(143, 150, 236, 0.1);

  &:hover {
    ${Description} {
      display: block;
    }
  }

  @media (min-width: 768px) {
    width: 370px;
  }
`;

const Image = styled.div<{ url: string }>`
  ${({ url }) => css`
    height: 340px;
    background-image: url('${url}');
    background-repeat: no-repeat;
    background-position: 50% 0px;
    background-size: cover;

    @media (min-width: 768px) {
      height: 432px;
    }
  `}
`;
