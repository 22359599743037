import { ActionType, createReducer } from 'typesafe-actions';

import {
  clearPublicationToStoreAction,
  getPublicationFailureAction,
  getPublicationRequestAction,
  getPublicationSuccessAction,
  putPublicationToStoreAction,
} from 'core/actions';
import { IPublicationExtended } from 'types';

export interface IPublicationState {
  publication: IPublicationExtended;
  isPublicationLoading: boolean;
}

const defaultState: IPublicationState = {
  publication: createDefaultPublication(),
  isPublicationLoading: true,
};

const actions = {
  clearPublicationToStoreAction,
  getPublicationFailureAction,
  getPublicationRequestAction,
  getPublicationSuccessAction,
  putPublicationToStoreAction,
};

export const publicationReducer = createReducer<IPublicationState, ActionType<typeof actions>>(defaultState)
  .handleAction(putPublicationToStoreAction, (state, { payload: { publication } }) => ({
    ...state,
    publication,
  }))
  .handleAction(getPublicationRequestAction, (state) => ({
    ...state,
    isPublicationLoading: true,
  }))
  .handleAction(getPublicationSuccessAction, (state) => ({
    ...state,
    isPublicationLoading: false,
  }))
  .handleAction(getPublicationFailureAction, (state) => ({
    ...state,
    isPublicationLoading: false,
  }))
  .handleAction(clearPublicationToStoreAction, () => ({
    ...defaultState,
  }));

function createDefaultPublication(): IPublicationExtended {
  return {
    id: 0,
    title: '',
    date: '2000-12-12',
    viewCount: 0,
    imageUrl: '',
    shortDescription: '',
    quote: {
      duty: '',
      surname: '',
      text: '',
    },
    description: '',
    sourceName: '',
    sourceLink: '',
    pdfLink: '',
    area: {
      alias: '',
      title: '',
      shortTitle: '',
    },
    authors: [],
    category: {
      id: 0,
      title: '',
      shortTitle: '',
      alias: '',
      areaTitle: '',
    },
    recommended_articles: [],
  };
}
