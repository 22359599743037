import React from 'react';
import styled from 'styled-components';
import Carousel from 'react-multi-carousel';

import { IEventListItem } from 'types';

import { ProgramsCarouselItem } from '../ProgramsCarousel/ProgramsCarouselItem';
import { ButtonGroup } from '../ProgramsCarousel/ButtonGroup';

import 'react-multi-carousel/lib/styles.css';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 992 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 992, min: 768 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 768, min: 576 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 576, min: 0 },
    items: 1,
  },
};

interface IProps {
  items?: IEventListItem[];
  itemClickHandler(url: string): void;
}

export const UpcomingCarousel = ({
  items = [],
  itemClickHandler,
}: IProps) => {
  return (
    <Wrapper>
      <CarouselWrapper
        ssr={false}
        arrows={false}
        removeArrowOnDeviceType={['tablet', 'mobile']}
        responsive={responsive}
        renderDotsOutside
        renderButtonGroupOutside
        customButtonGroup={items.length > 0 ? <ButtonGroupStyled /> : null}
      >
        {items.map((item) => (
          <ProgramsCarouselItem itemClickHandler={itemClickHandler} item={item} key={item.id} />
        ))}
      </CarouselWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 0;
`;

const ButtonGroupStyled = styled(ButtonGroup)`
  top: 0;
  right: 10px;
  bottom: unset;
`

const CarouselWrapper = styled(Carousel)`
  box-shadow: 0 4px 10px rgba(72, 83, 236, 0.03);
  border-radius: 12px;
  overflow: hidden;
  margin: 0 0 34px 0;
  border: 1px solid #eeeeee;
`;
