import * as React from 'react';
import { memo, useCallback, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';

export interface IInputDigit extends IInputDigitStyle {
  value: string;
  name?: string;
  focus?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  afterOnChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onPaste?: (event: React.ClipboardEvent) => void;
}

export interface IInputDigitStyle {
  isLoading?: boolean;
  hasError?: boolean;
}

export const InputDigit = memo(
  ({ value, name, isLoading, onChange, afterOnChange, onKeyDown, onFocus, focus, ...rest }: IInputDigit) => {
    const ref = useRef<HTMLInputElement>(null);
    useEffect(() => {
      if (ref.current && focus) ref.current.focus();
    }, [focus]);

    const validate = useCallback((valueToValidate: string) => {
      if (valueToValidate === '') return true;
      if (!new RegExp('^[0-9]+$').test(valueToValidate)) return false;
      if (valueToValidate.length > 1) return false;
      return true;
    }, []);

    const onChangeHandler = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const targetValue = event.target.value;
        if (validate(targetValue.trim())) {
          if (onChange) onChange(event);
        }
        if (afterOnChange) afterOnChange(event);
      },
      [onChange, afterOnChange, validate],
    );

    return (
      <InputStyled
        type="tel"
        ref={ref}
        onChange={onChangeHandler}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        isLoading={isLoading}
        value={value}
        name={name}
        {...rest}
      />
    );
  },
);

const InputStyled = styled.input.attrs({
  autocomplete: 'none',
})<IInputDigitStyle>`
  ${({ isLoading, hasError, disabled }) => css`
    position: relative;
    width: 90px;
    padding: 30px 0;
    text-align: center;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: white;
    border: 1px solid #dcdcdd;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: ${getColor(hasError)};
    cursor: ${disabled ? 'initial' : 'pointer'};
    outline: none;
    opacity: ${disabled ? '0.8' : '1'};
    user-select: ${disabled ? 'none' : 'initial'};
    transition: opacity 0.2s;

    ::placeholder,
    ::-webkit-input-placeholder {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      color: 'black';
      opacity: 0.4;
    }
    :-ms-input-placeholder {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      color: black;
      opacity: 0.4;
    }

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }

    ${isLoading &&
    css`
      opacity: 0.5;
      user-select: none;
      pointer-events: none;
      transition: opacity 0.2s;
    `}

    @media (max-width: 768px) {
      width: 66px;
    }
  `};
`;

const getColor = (hasError?: boolean) => {
  if (hasError) {
    return 'red';
  }

  return 'black';
};
