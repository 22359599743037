import React from 'react';
import styled from 'styled-components';
import { last } from 'ramda';

interface ISurveyCardProps {
  title: string;
  questionsQty: number;
  onClick: () => void;
}

export const getQuestionsQtyString = (quantity: number) => {
  if (last(String(quantity)) === '0' || (quantity >= 5 && quantity <= 19)) return `${quantity} вопросов`;
  if (last(String(quantity)) === '1') return `${quantity} вопрос`;
  return `${quantity} вопроса`;
};

export const SurveyCard = ({ title, questionsQty, onClick }: ISurveyCardProps) => {
  return (
    <SurveyCardWrapper onClick={onClick}>
      <SurveyTitle>{title}</SurveyTitle>
      <QuestionsQuantity>{getQuestionsQtyString(questionsQty)}</QuestionsQuantity>
    </SurveyCardWrapper>
  );
};

const SurveyCardWrapper = styled.div`
  width: 100%;
  border-radius: 12px;
  border: 1px solid #eee;
  padding: 24px;
  background: #fff;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const SurveyTitle = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  max-width: 70%;
  font-weight: 600;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const QuestionsQuantity = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 24px;
  color: #4f4f4f;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
