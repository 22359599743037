import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import { TextInput } from 'atoms';

interface IProps extends WrappedFieldProps {
  caption: string;
  required?: boolean;
  onlyLowerCase?: boolean;
  className?: string;
}

export const TextField = ({ input, meta: { touched, error }, required, caption, onlyLowerCase, className }: IProps) => {
  const errorText = touched && error ? error : '';

  return (
    <TextInput
      {...input}
      type="primary"
      caption={caption}
      errorMsg={errorText}
      required={required}
      onlyLowerCase={onlyLowerCase}
      className={className}
    />
  );
};
