import { IApiPartner, IPartner } from './partners';
import { IApiBanner, IBanner } from './banners';
import { IApiEventListItem, IEventListItem, IApiEventCategory, IEventCategory } from './events';

export interface IApiArea {
  id: number;
  title: string;
  alias: string;
  description: string;
  background_url: string;
  categories: IApiEventCategory[];
  partners: IApiPartner[];
  upcoming_events: IApiEventListItem[];
  interesting_events: IApiEventListItem[];
  social_networks: IApiAreaSocials[];
  banners: IApiBanner[];
  color: string | null;
}

export interface IArea {
  id: number | null;
  title: string;
  alias: string;
  description: string;
  backgroundUrl: string;
  categories: IEventCategory[];
  partners: IPartner[];
  upcomingEvents: IEventListItem[];
  interestingEvents: IEventListItem[];
  socialNetworks: IAreaSocials[];
  banners: IBanner[];
  color: string | null;
}

export interface IApiPopularArea {
  title: string;
  alias: string;
  short_title: string;
}

export interface IPopularArea {
  title: string;
  alias: string;
  shortTitle: string;
}

export interface IApiAreasAlias {
  alias: string;
  short_title: string;
  title: string;
}

export interface IAreasAlias {
  areasAlias: string;
  title: string;
  shortTitle: string;
}

export interface IApiAreaCropped {
  alias: string;
  description: string;
  thumbnail_url: string;
  title: string;
  total_events: number;
  total_viewings: number;
}
export interface IAreaCropped {
  alias: string;
  logoUrl: string;
  title: string;
  description: string;
  viewsCount: number;
  videosCount: number;
  authorsCount: number;
}

export interface IAreaShortened {
  alias: string;
  title: string;
}

export enum Socials {
  vk = 'vk',
  facebook = 'facebook',
  instagram = 'instagram',
  youtube = 'youtube',
  twitter = 'twitter',
  telegram = 'telegram',
}

export interface IApiAreaSocials {
  kind: 'facebook' | 'youtube' | 'vk' | 'instagram' | 'twitter' | 'telegram';
  link: string;
}

export interface IAreaSocials {
  type: Socials;
  link: string;
}
