import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import { TextInput } from 'atoms';

interface IProps extends WrappedFieldProps {
  caption: string;
  required?: boolean;
}

export const PasswordField = ({ input, meta: { touched, error }, required, caption }: IProps) => {
  const errorText = touched && error ? error : '';

  return <TextInput type="primary" caption={caption} errorMsg={errorText} required={required} isPassword {...input} />;
};
