import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CSSProperties } from 'styled-components';
import { Select as AntdSelect } from 'antd';

import { Select } from 'atoms';
import { getHomeCalendarEvents, getAreasAlias } from 'core/selectors';
import { getHomeEventsRequestAction, putCurrentHomeEventsMonthToStoreAction } from 'core/actions';

import { Calendar } from './Calendar';

export const Container = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentMonthIndex, items } = useSelector(getHomeCalendarEvents);
  const areasAlias = useSelector(getAreasAlias);

  const [choosedAlias, setChoosedAlias] = useState('');

  const monthChangeHandler = (apiIndex: number) => {
    dispatch(putCurrentHomeEventsMonthToStoreAction(apiIndex));
  };

  const itemClickHandler = (url: string = '') => {
    history.push(url);
  };

  const seeAllBtnHandler = () => {
    history.push(`/catalog/all`);
  };

  useEffect(() => {
    dispatch(
      getHomeEventsRequestAction({
        monthIndex: currentMonthIndex,
        alias: choosedAlias || undefined,
      }),
    );
  }, [dispatch, currentMonthIndex, choosedAlias]);

  return (
    <Calendar
      events={items}
      currentMonthIndex={currentMonthIndex}
      monthChangeHandler={monthChangeHandler}
      itemClickHandler={itemClickHandler}
      seeAllBtnHandler={seeAllBtnHandler}
      selectFilter={
        <Select arrowColor="#4853EC" value={choosedAlias} onChange={setChoosedAlias} style={SelectStyles}>
          <AntdSelect.Option key="all" style={SelectOptionStyles} value="">
            Все направления
          </AntdSelect.Option>
          {areasAlias.map((item) => (
            <AntdSelect.Option key={item.areasAlias} style={SelectOptionStyles} value={item.areasAlias}>
              {item.shortTitle || item.title}
            </AntdSelect.Option>
          ))}
        </Select>
      }
    />
  );
};

const SelectStyles: CSSProperties = {
  width: '100%',
  height: '100%',
  border: '1px solid #4853EC',
  borderRadius: 8,
  fontSize: 16,
  display: 'flex',
  alignItems: 'center',
};

const SelectOptionStyles: CSSProperties = {
  borderBottom: '1px solid #EBEFF2',
  fontFamily: 'Open Sans, sans-serif',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
  padding: '7px 16px',
};
