import React from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from 'redux-form';
import { Layout, Button as AntdButton } from 'antd';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';

import logoImg from 'assets/icons/logo.svg';
import { Button } from 'atoms';
import { getAuthPopupsVisible, getIsAppSiderCollapsed, getIsLoggedIn, getUserData } from 'core/selectors';
import {
  changeCreatePasswordPopupVisibleAction,
  changeForgotPasswordPopupVisibleAction,
  changeLoginPopupVisibleAction,
  changeRegisterPopupVisibleAction,
  isAppSiderCollapsedAction,
  changeSentMessagePopupVisibleAction,
  clearCreatePasswordToStoreAction,
  clearAuthServerErrorToStoreAction,
  clearRegisterToStoreAction,
} from 'core/actions';
import { UserInfoDropdown } from 'components/molecules';

import { LoginModal } from '../LoginModal';
import { ForgotPasswordModal } from '../ForgotPasswordModal';
import { RegisterModal } from '../RegisterModal';
import { SentMessageModal } from '../SentMessageModal';
import { CreatePasswordModal } from '../CreatePasswordModal';

import { MainMenu } from './MainMenu';

const { Header } = Layout;

interface IProps {
  isMenuButtonVisible?: boolean;
}

export const AppHeader = ({ isMenuButtonVisible = false }: IProps) => {
  const dispatch = useDispatch();
  const { login, register, forgotPassword, sentMessage, createPassword } = useSelector(getAuthPopupsVisible);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const history = useHistory();
  const isAppSiderCollapsed = useSelector(getIsAppSiderCollapsed);
  const siderDtnIcon = isAppSiderCollapsed ? <MenuOutlined /> : <CloseOutlined />;
  const userInfo = useSelector(getUserData);

  const showLoginModal = () => {
    dispatch(clearAuthServerErrorToStoreAction());
    dispatch(changeLoginPopupVisibleAction(true));
  };

  const handleLoginOk = () => {
    dispatch(changeLoginPopupVisibleAction(false));
  };

  const handleLoginCancel = () => {
    dispatch(changeLoginPopupVisibleAction(false));
    dispatch(reset('LogInForm'));
    dispatch(clearAuthServerErrorToStoreAction());
  };

  const handleFPOk = () => {
    dispatch(changeForgotPasswordPopupVisibleAction(false));
  };

  const handleFPCancel = () => {
    dispatch(changeForgotPasswordPopupVisibleAction(false));
    dispatch(reset('ForgotPasswordForm'));
    dispatch(clearAuthServerErrorToStoreAction());
  };

  const showRegModal = () => {
    dispatch(clearAuthServerErrorToStoreAction());
    dispatch(changeRegisterPopupVisibleAction(true));
  };

  const handleRegOk = () => {
    dispatch(changeRegisterPopupVisibleAction(false));
  };

  const handleRegCancel = () => {
    dispatch(changeRegisterPopupVisibleAction(false));
    dispatch(reset('RegisterFormFirstStep'));
    dispatch(reset('RegisterFormSecondStep'));
    dispatch(reset('RegisterFormThirdStep'));
    dispatch(clearRegisterToStoreAction());
    dispatch(clearAuthServerErrorToStoreAction());
  };

  const handleSentMessageOk = () => {
    dispatch(changeLoginPopupVisibleAction(true));
  };

  const handleSentMessageCancel = () => {
    dispatch(changeSentMessagePopupVisibleAction(false));
    dispatch(clearAuthServerErrorToStoreAction());
  };

  const handleCreatePasswordCancel = () => {
    dispatch(changeCreatePasswordPopupVisibleAction(false));
    dispatch(clearCreatePasswordToStoreAction());
    dispatch(reset('CreatePasswordForm'));
    dispatch(clearAuthServerErrorToStoreAction());
    history.push('/');
  };

  const appSiderHandler = () => {
    dispatch(isAppSiderCollapsedAction(!isAppSiderCollapsed));
  };

  return (
    <HeaderContainer>
      <Link to="/">
        <Logo src={logoImg} alt="Logotype" />
      </Link>

      <MainMenu />

      <AuthMenu>
        {isLoggedIn && (
          <MenuItem>
            <UserInfoDropdown name={userInfo?.full_name || ''} />
          </MenuItem>
        )}

        {!isLoggedIn && (
          <>
            <MenuItem>
              <Button size="small" type="default" onClick={showLoginModal}>
                Войти
              </Button>
            </MenuItem>

            <MenuItem>
              <Button size="small" onClick={showRegModal}>
                Зарегистрироваться
              </Button>
            </MenuItem>
          </>
        )}
      </AuthMenu>

      {isMenuButtonVisible ? (
        <MenuButton
          className={isAppSiderCollapsed ? 'app-sider-collapsed' : ''}
          onClick={() => appSiderHandler()}
          type="text"
          icon={siderDtnIcon}
        />
      ) : null}

      <ForgotPasswordModal
        title="Забыли пароль?"
        visible={forgotPassword}
        onOk={handleFPOk}
        onCancel={handleFPCancel}
        showLoginModal={showLoginModal}
        footer={null}
        width={624}
        destroyOnClose
      />

      <LoginModal
        showRegModal={showRegModal}
        title="Вход по номеру"
        visible={login}
        onOk={handleLoginOk}
        onCancel={handleLoginCancel}
        footer={null}
        width={624}
        destroyOnClose
      />

      <RegisterModal
        visible={register}
        onOk={handleRegOk}
        onCancel={handleRegCancel}
        footer={null}
        width={1172}
        destroyOnClose
      />

      <SentMessageModal
        visible={sentMessage}
        onOk={handleSentMessageOk}
        onCancel={handleSentMessageCancel}
        footer={null}
        width={1172}
        destroyOnClose
      />

      <CreatePasswordModal
        visible={createPassword}
        title="Создать пароль"
        onCancel={handleCreatePasswordCancel}
        footer={null}
        width={624}
        destroyOnClose
      />
    </HeaderContainer>
  );
};

const HeaderContainer = styled(Header)`
  & {
    background: #ffffff;
    height: 72px;
    line-height: 72px;
    position: fixed;
    top: 0;
    z-index: 10;
    margin: 0;
    padding: 0 12px;
    width: 100%;
    box-shadow: 0 4px 10px rgba(72, 83, 236, 0.08);

    @media (min-width: 768px) {
      padding: 0 22px;
      height: 80px;
      line-height: 80px;
    }

    @media (min-width: 1200px) {
      padding: 0 120px;
      height: 120px;
      line-height: 120px;
    }
  }
`;

const MenuButton = styled(AntdButton)`
  &.app-sider-collapsed {
    color: #4853ec;
    float: right;

    &:hover,
    &:focus {
      color: #4853ec;
    }
  }

  & {
    color: #9da0aa;
    float: right;
    margin: 19px 0 0 0;

    &:hover,
    &:focus {
      color: #9da0aa;
      background: none;
    }

    svg {
      font-size: 26px;
    }

    @media (min-width: 768px) {
      margin: 25px 0 0 0;
    }

    @media (min-width: 1200px) {
      margin: 45px 0 0 0;
    }

    @media (min-width: 1350px) {
      display: none;
    }
  }
`;

const Menu = styled.ul`
  display: inline-flex;
  height: 100%;
  list-style: none;
`;

const AuthMenu = styled(Menu)`
  float: right;
  display: none;

  @media (min-width: 1350px) {
    display: inline-flex;

    & > li:first-of-type {
      margin-right: 16px;
    }
  }
`;

const Logo = styled.img`
  margin: -6px 0 0 0;
  width: 104px;

  @media (min-width: 768px) {
    width: 156px;
  }

  @media (min-width: 1200px) {
    width: auto;
  }
`;

const MenuItem = styled.li`
  flex: 0 1 auto;
  align-self: center;

  & > a {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    text-transform: uppercase;
  }
`;
