import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { format, differenceInMinutes } from 'date-fns';
import { ru } from 'date-fns/locale';

import { LogoWhite } from 'assets/icons';
import { changeLoginPopupVisibleAction, setViewFlagRequestAction, setRegisteredFlagRequestAction } from 'core/actions';
import { getEvent, getIsLoggedIn } from 'core/selectors';
import { Button as ButtonDefault } from 'atoms';
import { useWindowSize } from 'hooks';
import { getTodayDate, glueDate } from 'helpers';

interface IOverlayProps {
  color?: string;
}

export const Overlay = ({ color }: IOverlayProps) => {
  const [width] = useWindowSize();
  const { id, startDate, startTime, isViewed, isRegistered } = useSelector(getEvent);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const dispatch = useDispatch();

  const startViewHandler = () => {
    if (!isLoggedIn) {
      dispatch(changeLoginPopupVisibleAction(true));
      return;
    }

    if (id !== null) {
      dispatch(setViewFlagRequestAction(id));
    }
  };

  const registerHandler = () => {
    if (!isLoggedIn) {
      dispatch(changeLoginPopupVisibleAction(true));
      return;
    }

    if (id !== null) {
      dispatch(setRegisteredFlagRequestAction(id));
    }
  };

  if (isViewed) return null;

  return (
    <Container color={color}>
      <Content>
        <Logo />
        {getContent(startDate, startTime, isRegistered, startViewHandler, registerHandler, width, isLoggedIn)}
      </Content>
    </Container>
  );
};

function getContent(
  startDate: string,
  startTime: string,
  isRegistered: boolean,
  startViewHandler: any,
  registerHandler: any,
  width: number,
  isLoggedIn: boolean,
) {
  const eventDate = glueDate(startDate, startTime);
  const today = getTodayDate();

  if (differenceInMinutes(eventDate, today) > 15) {
    return (
      <>
        <Text>
          Уважаемый коллега, мероприятие начнется{' '}
          <span>
            {format(new Date(startDate), 'dd MMMM', { locale: ru })} в {startTime}
          </span>{' '}
          по МСК.{' '}
          {width > 768 &&
            'Чтобы не пропустить, зарегистрируйтесь и нажмите на колокольчик. Ниже вы можете ознакомиться с программой и общей информацией по мероприятию'}
        </Text>
        {isRegistered ? (
          <RegisteredText>
            {width > 768 ? 'Спасибо, вы зарегистрированы на мероприятие!' : 'Вы зарегистрированы'}
          </RegisteredText>
        ) : (
          <RegisterButton onClick={registerHandler}>
            {isLoggedIn ? 'Присоединиться к просмотру' : 'Принять участие'}
          </RegisterButton>
        )}
      </>
    );
  }

  return (
    <>
      <Text>Для просмотра видео трансляции нажмите кнопку ниже</Text>
      <JoinButton onClick={startViewHandler}>Присоединиться к просмотру</JoinButton>
    </>
  );
}

const Container = styled.div<{ color?: string }>`
  border-radius: 12px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ color }) => color || 'rgba(18, 26, 123)'};
  opacity: 0.9;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  flex: 0 1 auto;

  display: flex;
  flex-direction: column;
`;

const Logo = styled(LogoWhite)`
  display: none;

  @media (min-width: 768px) {
    display: block;
    margin: 0 auto;
  }
`;

const Text = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;

  margin: 0 auto;
  width: 80%;

  span {
    font-weight: 700;
  }

  @media (min-width: 768px) {
    width: 50%;
    margin: 32px auto 0 auto;
  }
`;

const JoinButton = styled(ButtonDefault)`
  border: 2px solid #ffffff;
  background-color: transparent;
  font-size: 14px;
  line-height: 24px;

  margin: 0 auto;

  @media (min-width: 768px) {
    font-size: 16px;
    margin: 56px auto 0 auto;
  }
`;

const RegisterButton = styled(ButtonDefault)`
  && {
    margin: 15px auto 0 auto;
    font-size: 16px;
    border: 2px solid #ff8686;
    background-color: #ff8686;
    line-height: 24px;

    @media (min-width: 768px) {
      margin: 56px auto 0 auto;
    }
  }
`;

const RegisteredText = styled.p`
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-align: center;
  color: #ffffff;

  margin: 15px auto;

  @media (min-width: 768px) {
    margin: 50px auto;
    width: 50%;
  }
`;
