import React from 'react';
import styled, { css } from 'styled-components';
import { Col, Row } from 'antd';
import ReactHtmlParser from 'react-html-parser';

import { Bag, Camera } from 'assets/icons';

interface IProps {
  publicationOnClick?: () => void;
  eventsOnClick?: () => void;
  imageUrl: string;
  position: string;
  description: string;
  eventsTotal: number;
  publicationsTotal: number;
  name?: string;
  onAuthorClick?: () => void;
}

export const AuthorCard = ({
  publicationOnClick,
  eventsOnClick,
  imageUrl,
  position,
  description,
  eventsTotal,
  publicationsTotal,
  name,
  onAuthorClick,
}: IProps) => {
  const onEventsClickHandler = () => {
    if (eventsOnClick) {
      eventsOnClick();
    }
  };

  const onPublicationsClickHandler = () => {
    if (publicationOnClick) {
      publicationOnClick();
    }
  };

  const onAuthorClickHandler = () => {
    if (onAuthorClick) {
      onAuthorClick();
    }
  };

  return (
    <Container onClick={onAuthorClickHandler} $isClickable={Boolean(onAuthorClick)}>
      <Row>
        <Col xs={24} md={8} xl={9}>
          <Image url={imageUrl} />
        </Col>

        <Col xs={24} md={16} xl={15}>
          <Info>
            <Position>
              {name ? `${name}, ` : null}
              {position}
            </Position>
            <Description>{ReactHtmlParser(description.replace(/\r\n/g, ' <br> '))}</Description>
            <Buttons>
              <Button $isClickable={Boolean(publicationOnClick)} onClick={onPublicationsClickHandler}>
                <Bag />
                <IconText>
                  {publicationsTotal} {getPublicationText(publicationsTotal)}
                </IconText>
              </Button>
              <Button $isClickable={Boolean(eventsOnClick)} onClick={onEventsClickHandler}>
                <Camera />
                <IconText>
                  {eventsTotal} {getEventText(eventsTotal)}
                </IconText>
              </Button>
            </Buttons>
          </Info>
        </Col>
      </Row>
    </Container>
  );
};

const Container = styled.div<{ $isClickable?: boolean }>`
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
  ${({ $isClickable }) =>
    $isClickable &&
    css`
      cursor: pointer;
    `}

  margin: 24px 0 0 0;

  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgba(72, 83, 236, 0.03);

  @media (min-width: 768px) {
    margin: 48px 0 0 0;
  }
`;

const Image = styled.div<{ url: string }>`
  ${({ url }) => css`
    height: 340px;
    background-image: url('${url}');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 0;

    @media (min-width: 768px) {
      background-size: cover;
      height: 100%;
    }
  `}
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;

  padding: 24px 16px 32px 16px;

  @media (min-width: 768px) {
    padding: 32px 32px 35px 32px;
  }

  @media (min-width: 1200px) {
    padding: 64px 64px 72px 64px;
  }
`;

const Position = styled.div`
  width: 100%;

  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #000000;

  margin-bottom: 8px;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
    font-weight: 900;

    margin-bottom: 24px;
  }
`;

const Description = styled.div`
  width: 100%;

  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #4f4f4f;

  margin-bottom: 32px;

  @media (min-width: 768px) {
    height: 216px;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
  }

  @media (min-width: 992px) {
    height: 172px;
    margin-bottom: 44px;
  }
`;

const Buttons = styled.div`
  display: flex;
`;

const Button = styled.div<{ $isClickable?: boolean }>`
  display: flex;
  align-items: center;

  cursor: ${({ $isClickable }) => ($isClickable ? 'pointer' : 'default')};
  outline: none;

  &:not(:first-child) {
    margin-left: 16px;
  }

  @media (min-width: 768px) {
    &:not(:first-child) {
      margin-left: 26px;
    }
  }

  @media (min-width: 992px) {
    &:not(:first-child) {
      margin-left: 56px;
    }
  }
`;

const IconText = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: block;

    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #4853ec;

    margin-left: 8px;
  }
`;

function getEventText(count: number) {
  switch (count) {
    case 1:
      return 'мероприятие';
    case 2:
      return 'мероприятия';
    case 3:
      return 'мероприятия';
    case 4:
      return 'мероприятия';
    default:
      return 'мероприятий';
  }
}

function getPublicationText(count: number) {
  switch (count) {
    case 1:
      return 'публикация';
    default:
      return 'публикаций';
  }
}
