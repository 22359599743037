import React, { memo, useEffect, useRef, useState } from 'react';

import { loadScript, removeScript } from 'helpers';
import { PlayerState } from 'types';
import { useWindowSize } from 'hooks';

declare global {
  interface Window {
    fc_load_iframe?: (iframe: HTMLIFrameElement, param?: string) => void;
  }
}

interface IProps {
  videoId: string;
  setIsLoaded?: (isLoaded: boolean) => void;
  onStateChange?: (data: { data: PlayerState }) => void;
}

// type EventType = 'get';

// interface IMessage {
//   event: EventType;
//   event_id: number;
//   isPlaying: boolean;
//   multitrack: any[];
//   origin: string;
//   server: string;
//   source_frame: string;
//   src: string;
//   use_dvr: number;
// }

export const ReactFacecast = memo(({ videoId, setIsLoaded, onStateChange }: IProps) => {
  const [width] = useWindowSize();
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const ref = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    loadScript('facecast-script', 'https://facecast.net/w/js/iframe.js', () => {
      setScriptLoaded(true);
      setIsLoaded?.(true);
    });

    return () => removeScript('facecast-script');
  }, [setIsLoaded]);

  useEffect(() => {
    if (ref.current) {
      window.fc_load_iframe?.(ref.current, width < 576 ? 'force_mobile_layout' : undefined);

      // const msg_receive = (msg: any) => {
      //   if (msg && msg.data && typeof msg.data === 'string' && msg.data.includes('Facecast_player')) {
      //     const data = JSON.parse(msg.data) as IMessage;
      //     onStateChange?.({ data: data.isPlaying ? PlayerState.PLAYING : PlayerState.PAUSED });
      //   }
      // };

      // window.addEventListener('message', msg_receive);
      // return () => window.removeEventListener('message', msg_receive);
    }

    return undefined;
  }, [scriptLoaded, width, onStateChange]);

  return (
    <>
      {scriptLoaded && (
        <iframe id={videoId} ref={ref} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen title="video" />
      )}
    </>
  );
});
