export interface IApiSurveysRequest {
  id__in?: number[]; // survey id
  event_id?: number;
  no_event?: boolean;
  page?: number;
}

export interface ISurveysRequest {
  surveyIds?: number[];
  eventId?: number;
  isEvent?: boolean;
  page?: number;
}

export type SurveyAnswerDtoType = {
  question: number;
  answers: number[];
  customInput?: string;
};

export interface ISurveyAnswersDto {
  surveyAnswers: SurveyAnswerDtoType[];
}

export interface ISurveyCustomAnswerDto {
  question: number;
  answer: string;
}

export type ApiSurveyAnswerType = {
  id: number;
  answer: string;
  image_url: string;
};

export type SurveyAnswerType = {
  id: number;
  answer: string;
  imageUrl: string;
};

export type SurveyApiAnswerOptionType = 'radio_button' | 'checkbox' | 'custom_input';
export enum SurveyAnswerOption {
  RADIOBUTTON = 'radiobutton',
  CHECKBOX = 'checkbox',
  CUSTOM_INPUT = 'customInput',
}
export type SurveyApiQuestionContentType = 'text + photo' | 'photo' | 'text';
export type SurveyQuestionContentType = 'photo' | 'text' | 'full';

interface IApiQuestion {
  id: number;
  question: string;
  image_url: string;
  ans_type: SurveyApiAnswerOptionType;
  question_type: SurveyApiQuestionContentType;
  many_answers: boolean;
  answers: ApiSurveyAnswerType[];
}

export interface IApiSurvey {
  id: number;
  title: string;
  subtitle: string;
  event: number;
  // image_url: string;
  questions: IApiQuestion[];
}

export interface IApiSurveys {
  count: number;
  next: string;
  previous: string;
  results: IApiSurvey[];
}

export interface IQuestion {
  id: number;
  question: string;
  answerType: SurveyAnswerOption;
  questionType: 'photo' | 'text' | 'full';
  isManyAnswers: boolean;
  imageUrl: string;
  answers: SurveyAnswerType[];
}

export interface ISurvey {
  id: number;
  title: string;
  subtitle: string;
  // subtitleImage: string;
  event: number;
  questions: IQuestion[];
}

export interface ISurveys {
  count: number;
  next: string;
  previous: string;
  results: ISurvey[];
}
