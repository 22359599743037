import { IApiAreaSocials, IAreaSocials } from 'types';

import { CatalogSearchASC } from './catalog';
import { IApiAuthor, IAuthor } from './author';
import { IApiTag, ITag } from './tags';
import { IApiBanner, IBanner } from './banners';
import { IApiPartner, IPartner } from './partners';

export interface IApiEventCategory {
  id: number;
  alias: string;
  title: string;
  area_title: string;
  description: string;
  short_title: string;
  count_events: number;
}

export interface IEventCategory {
  id: number;
  alias: string;
  title: string;
  areaTitle: string;
  description: string;
  eventsCount: number;
  shortTitle: string;
}

export interface IApiEventCategoryRequest {
  area__alias?: string;
}

export interface IApiEventListItem {
  id: number;
  title: string;
  description: string;
  start_date: string;
  thumbnail_url: string;
  viewing_count: number;
  subscribed: boolean;
  authors: IApiAuthor[];
  is_interesting: boolean;
  area: {
    alias: string;
    title: string;
    short_title: string;
  };
  category: {
    id: number;
    title: string;
    short_title: string;
  };
  tags: IApiTag[];
  marks: IApiEventMark[];
  average_mark: number;
  checkpoints: string;
  user_code: string;
  questions_ids: number[];
}

export interface IEventListItem {
  id: number;
  title: string;
  description: string;
  startDate: string;
  thumbnailUrl: string;
  viewingCount: number;
  subscribed: boolean;
  authors: IAuthor[];
  isInteresting: boolean;
  area: {
    alias: string;
    title: string;
    shortTitle: string;
  };
  category: {
    id: number;
    title: string;
    shortTitle: string;
  };
  tags: ITag[];
  marks: IApiEventMark[];
  averageMark: number;
  checkpoints: string;
  userCode: string;
  questionsIds: number[];
}

export interface IApiEvent {
  id: number;
  title: string;
  description: string;
  start_date: string;
  start_time: string;
  thumbnail_url: string;
  background_url: string;
  vimeo_url: string;
  viewing_count: number;
  is_viewed: boolean;
  is_registered: boolean;
  subscribed: boolean;
  authors: IApiAuthor[];
  area: {
    alias: string;
    title: string;
    short_title: string;
  };
  category: {
    id: number;
    alias: string;
    title: string;
  };
  sections: IApiEventSection[];
  recommended_events: IApiEventListItem[];
  social_networks: IApiAreaSocials[];
  banners: IApiBanner[];
  partners: IApiPartner[];
  marks: IApiEventMark[];
  average_mark: number;
}

export interface IEvent {
  id: number | null;
  title: string;
  description: string;
  startDate: string;
  startTime: string;
  thumbnailUrl: string;
  backgroundUrl: string;
  videoUrl: string;
  viewingCount: number;
  isViewed: boolean;
  isRegistered: boolean;
  subscribed: boolean;
  authors: IAuthor[];
  area: {
    alias: string;
    title: string;
    shortTitle: string;
  };
  category: {
    id: number | null;
    alias: string;
    title: string;
  };
  sections?: IEventSection[];
  recommendedEvents: IEventListItem[];
  socialNetworks: IAreaSocials[];
  banners: IBanner[];
  partners: IPartner[];
  marks: IEventMark[];
  averageMark: number;
}

export interface IApiEventMark {
  author: number | null;
  mark: number;
}

interface ICalculatedEventMark {
  authorName?: string;
  mark: number;
}

export type IEventMarkType = Record<string, ICalculatedEventMark>;

export interface IEventMark {
  authorName?: string;
  authorId: string;
  mark: number;
}

export interface IEventMarkDto {
  authorId?: string;
  mark: number;
  eventId: number;
}

export interface IApiEventSection {
  title: string;
  start_time: string;
  end_time: string;
  description: string;
  authors: IApiAuthor[];
}

export interface IEventSection {
  title: string;
  startTime: string;
  endTime: string;
  description: string;
  authors: IAuthor[];
}

export interface IEventsRequest {
  alias?: string;
  dateStart?: Date | null;
  dateEnd?: Date | null;
  tags?: string[];
  searchString?: string | null;
  searchASC: CatalogSearchASC;
  offset?: number;
  limit?: number;
  category?: number;
  authorId?: number[];
  upcomingEvents?: boolean;
  userEvents?: boolean;
}

export interface IApiEventsRequest {
  area__alias?: string;
  start_date_after?: string;
  start_date_before?: string;
  tag__in?: string;
  title?: string;
  category__in?: number;
  ordering: IApiEventOrderingRequest;
  offset: number;
  limit: number;
  author__in?: number[];
  upcoming_events?: boolean;
  user_events?: boolean;
}

export type IApiEventOrderingRequest =
  | 'start_date'
  | '-start_date'
  | 'created'
  | '-subscription_count'
  | '-viewing_count';

export enum PlayerState {
  UNSTARTED = -1,
  ENDED = 0,
  PLAYING = 1,
  PAUSED = 2,
  BUFFERING = 3,
  CUED = 5,
}
