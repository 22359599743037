import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import { FormItem, AreasSelect } from 'atoms';
import { IAreasAlias } from 'types';

interface IProps extends WrappedFieldProps {
  options: IAreasAlias[];
}

export const AreaSelect = ({ input: { value, onChange }, options }: IProps) => {
  return (
    <FormItem label="По направлениям" $labelTop>
      <AreasSelect areas={options} onChange={onChange} value={value} />
    </FormItem>
  );
};
