import React from 'react';
import { ButtonGroupProps } from 'react-multi-carousel/lib/types';
import styled, { css } from 'styled-components';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

export const ButtonGroup = ({ next, previous, carouselState }: ButtonGroupProps) => {
  const lastSlide = carouselState!.totalItems - carouselState!.slidesToShow;

  return (
    <Wrapper>
      <Button className={carouselState?.currentSlide === 0 ? 'disable' : ''} onClick={previous}>
        <LeftOutlined />
      </Button>
      <Button className={carouselState?.currentSlide === lastSlide ? 'disable' : ''} onClick={next}>
        <RightOutlined />
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  right: 67px;
  bottom: 70px;
`;

const Button = styled.button`
  ${({ theme: { primaryBtnActiveBgColor } }) => css`
    background: #ffffff;
    border-radius: 150px;
    border: 1px solid #eeeeee;
    border-color: #eeeeee;
    outline: none;
    cursor: pointer;
    height: 50px;
    width: 50px;
    transition: color, background-color, 0.1s linear;
    margin: 0 0 0 15px;
    display: none;
    box-sizing: border-box;

    span {
      transition: color 0.1s linear;
      color: ${primaryBtnActiveBgColor};
    }

    @media (min-width: 768px) {
      display: inline-block;
    }

    &:hover {
      background-color: ${primaryBtnActiveBgColor};
      border-color: ${primaryBtnActiveBgColor};
      span {
        color: white;
      }
    }

    &.disable:hover {
      background-color: #6972ec;
      border-color: #6972ec;
      span {
        color: white;
      }
    }
  `}
`;
