import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { getEventCertificateRequestAction } from 'core/actions';
import { getUserCertificateRequestState } from 'core/selectors';

interface ICertificateButtonProps {
  eventId: number;
}

export const CertificateButton = ({ eventId }: ICertificateButtonProps) => {
  const dispatch = useDispatch();
  const {
    personalData: { isCertificateRequestLoading },
  } = useSelector(getUserCertificateRequestState);

  const onButtonClickHandler = () => {
    dispatch(getEventCertificateRequestAction(eventId));
  };

  return !isCertificateRequestLoading ? (
    <ButtonStyled onClick={onButtonClickHandler}>Сгенерировать сертификат</ButtonStyled>
  ) : (
    <ButtonStyled disabled>Генерируем сертификат...</ButtonStyled>
  );
};

const ButtonStyled = styled.button`
  border-radius: 8px;
  background-color: #ff8686;
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.4px;
  border: none;
  cursor: pointer;
  margin-top: 24px;

  &:disabled {
    background-color: grey;
    cursor: default;
  }
`;
