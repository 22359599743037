import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { change, Field, InjectedFormProps, reduxForm } from 'redux-form';
import styled from 'styled-components';

import { IFormErrors, IRegisterFormFirstStepValues, RegisterSteps } from 'types';
import { PlaceService, validationService } from 'services';
import { store } from 'core';
import { putFirstStepRegisterToStore, putRegisterStepToStore } from 'core/actions';
import { IState } from 'core/types/reduxTypes';
import { countriesList, tagsSelectList } from 'mock';

import { AsyncSelectField, TextField, FormFooter, TagSelectField, DateField, SelectField } from '..';

const Form = ({
  handleSubmit,
}: InjectedFormProps<
  IRegisterFormFirstStepValues,
  { handleStepDown?: () => void; country?: string; region?: string }
>) => {
  const dispatch = useDispatch();
  const [co, setCo] = useState('Россия');
  const [re, setRe] = useState('');

  return (
    <form>
      <FormContent>
        <FieldsWrapper>
          <Field name="name" caption="Фамилия, имя, отчество" component={TextField} required />
          <Field name="birthdayDate" caption="Дата рождения" component={DateField} required />
        </FieldsWrapper>
        <FieldsWrapper>
          <Field name="sex" caption="Пол" component={TagSelectField} tags={tagsSelectList} />
          <Field name="email" caption="E-mail" component={TextField} required onlyLowerCase />
        </FieldsWrapper>
        <FieldsWrapperColumn>
          <Field
            name="country"
            caption="Страна"
            onChange={(value: any) => {
              dispatch(change('RegisterFormThirdStep', 'region', ''));
              dispatch(change('RegisterFormThirdStep', 'city', ''));
              setCo(value);
            }}
            component={AsyncSelectField}
            fetchOptions={requestCountries}
            required
          />
          <Field
            name="region"
            caption="Регион"
            onChange={(value: any) => {
              dispatch(change('RegisterFormThirdStep', 'city', ''));
              setRe(value);
            }}
            component={SelectField}
            options={getRegions('Россия')}
            required={co === 'Россия'}
            disabled={co !== 'Россия'}
          />
          {co !== 'Россия' ? (
            <Field
              name="city"
              caption="Город"
              component={AsyncSelectField}
              fetchOptions={requestCities}
              fetchArgument={co}
              required={Boolean(true)}
            />
          ) : (
            <Field
              name="city"
              caption="Город"
              component={SelectField}
              required={Boolean(true)}
              options={getCities('Россия', re)}
            />
          )}
        </FieldsWrapperColumn>
      </FormContent>
      <FormFooter step={RegisterSteps.First} handleSubmit={handleSubmit} />
    </form>
  );
};

function requestCountries(value: string) {
  return PlaceService.getCountries(value);
}

function getCities(countryName?: string, regionName?: string) {
  if (!regionName) {
    return [];
  }

  const country = countriesList.find((c) => c.value === countryName);

  if (!country) {
    return [];
  }

  const region = country.regions.find((r) => r.value === regionName);

  if (!region) {
    return [];
  }

  return region.cities;
}

function requestCities(value: string, country: string) {
  return PlaceService.getCities(country, value);
}

function getRegions(countryName?: string) {
  if (!countryName) {
    return [];
  }

  return countriesList.find((c) => c.value === countryName)?.regions || [];
}

const validateFirstStep = ({ name, email, birthdayDate, phoneNumber, country, city }: IRegisterFormFirstStepValues) => {
  return {
    name: validationService.validateName(name),
    sex: '',
    email: validationService.validateEmail(email),
    birthdayDate: validationService.validateDate(birthdayDate),
    phoneNumber: phoneNumber ? validationService.validatePhoneNumber(phoneNumber) : '',
    country: validationService.validateCountry(country),
    city: validationService.validateCountry(city),
  } as IFormErrors;
};

const onFirstStepFormSubmit = (formData: IRegisterFormFirstStepValues) => {
  store.dispatch(
    putFirstStepRegisterToStore({
      ...formData,
      email: formData.email.trim().toLowerCase(),
      country: formData.country,
      city: formData.city,
    }),
  );
  store.dispatch(putRegisterStepToStore(RegisterSteps.Second));
};

const mapStateToProps = (state: IState) => ({
  initialValues: state.auth.register.firstStep,
});

export const FirstStepForm = connect(mapStateToProps)(
  reduxForm<IRegisterFormFirstStepValues, {}>({
    form: 'RegisterFormFirstStep',
    validate: validateFirstStep,
    onSubmit: onFirstStepFormSubmit,
    enableReinitialize: true,
    touchOnBlur: false,
  })(Form),
);

const FormContent = styled.div`
  margin-bottom: 2px;
  padding-top: 19px;

  @media (min-width: 768px) {
    margin-bottom: 64px;
  }
`;

const FieldsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    width: 45%;
  }

  @media (max-width: 1100px) {
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }
`;

const FieldsWrapperColumn = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    width: 45%;
  }

  @media (max-width: 1100px) {
    & > div {
      width: 100%;
    }
  }
`;
