import { createAction } from 'typesafe-actions';

import { IArea, IAreaCropped, IAreaShortened } from 'types';

import { ACTIONS } from './constants';

export const getAllAreasRequestAction = createAction(ACTIONS.GET_ALL_AREAS_REQUEST)();
export const getAllAreasSuccessAction = createAction(ACTIONS.GET_ALL_AREAS_SUCCESS)();
export const getAllAreasFailureAction = createAction(ACTIONS.GET_ALL_AREAS_FAILURE)();
export const putAllAreasToStoreAction = createAction(ACTIONS.PUT_ALL_AREAS_TO_STORE)<{
  areas: IAreaShortened[];
}>();

export const getAreasCroppedRequestAction = createAction(ACTIONS.GET_AREAS_CROPPED_REQUEST)();
export const getMoreAreasCroppedRequestAction = createAction(ACTIONS.GET_MORE_AREAS_CROPPED_REQUEST)();
export const getAreasCroppedSuccessAction = createAction(ACTIONS.GET_AREAS_CROPPED_SUCCESS)();
export const getAreasCroppedFailureAction = createAction(ACTIONS.GET_AREAS_CROPPED_FAILURE)();
export const putAreasCroppedToStoreAction = createAction(ACTIONS.PUT_AREAS_CROPPED_TO_STORE)<{
  areas: IAreaCropped[];
  total: number;
}>();
export const clearAreasCroppedToStoreAction = createAction(ACTIONS.CLEAR_AREAS_CROPPED_TO_STORE)();

export const getAreaRequestAction = createAction(ACTIONS.GET_AREA_REQUEST)<{ alias: string }>();
export const getAreaSuccessAction = createAction(ACTIONS.GET_AREA_SUCCESS)();
export const getAreaFailureAction = createAction(ACTIONS.GET_AREA_FAILURE)();
export const putAreaToStoreAction = createAction(ACTIONS.PUT_AREA_TO_STORE)<{
  area: IArea;
}>();
export const clearAreaToStoreAction = createAction(ACTIONS.CLEAR_AREA_TO_STORE)();
