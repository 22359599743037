import { notificationSound } from 'assets/sounds';

class SoundsService {
  playSound(fileName: string) {
    const audio = new Audio(fileName);
    audio.loop = false;
    audio.play().catch((e) => {
      // eslint-disable-next-line no-console
      console.log(e);
    });
  }

  playNotificationSound() {
    this.playSound(notificationSound);
  }
}

export const soundsService = new SoundsService();
