import {
  IApiSurveys,
  ISurveys,
  SurveyAnswerOption,
  SurveyApiAnswerOptionType,
  SurveyApiQuestionContentType,
  SurveyQuestionContentType,
} from 'types';

const convertQuestionType = (type: SurveyApiQuestionContentType): SurveyQuestionContentType => {
  if (type === 'text + photo') return 'full';
  return type;
};

const convertAnswerType = (type: SurveyApiAnswerOptionType): SurveyAnswerOption => {
  if (type === 'checkbox') return SurveyAnswerOption.CHECKBOX;
  if (type === 'radio_button') return SurveyAnswerOption.RADIOBUTTON;
  return SurveyAnswerOption.CUSTOM_INPUT;
};

export const convertApiSurveysToAppSchema = (questions: IApiSurveys): ISurveys => {
  return {
    count: questions.count,
    next: questions.next,
    previous: questions.previous,
    results: questions.results.map((result) => ({
      ...result,
      questions: result.questions.reverse().map((item) => ({
        id: item.id,
        question: item.question,
        isManyAnswers: item.many_answers,
        imageUrl: item.image_url,
        questionType: convertQuestionType(item.question_type),
        answers: item.answers.map(({ id, answer, image_url }) => ({
          id,
          answer,
          imageUrl: image_url,
        })),
        answerType: convertAnswerType(item.ans_type),
      })),
    })),
  };
};
