import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { changeLoginPopupVisibleAction } from 'core/actions';
import { getIsLoggedIn } from 'core/selectors';

import { SurveyContent } from './SurveyContent';

interface IAuthSurveyInterceptorProps {
  eventSurveyId?: number;
  finalStepCallback?: () => void;
}

export const AuthSurveyInterceptor = (props: IAuthSurveyInterceptorProps) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn);

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(changeLoginPopupVisibleAction(true));
    }
  }, [dispatch, isLoggedIn]);

  return isLoggedIn ? (
    <SurveyContent {...props} />
  ) : (
    <AuthNote>Доступ к контенту доступен только авторизованным пользователям</AuthNote>
  );
};

export const AuthNote = styled.p`
  margin: auto;
  width: 300px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
`;
