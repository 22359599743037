import { IState } from 'core/types';

export const getHome = ({ home: { home } }: IState) => home;
export const getIsHomeLoading = ({ home: { isHomeLoading } }: IState) => isHomeLoading;

export const getHomeCalendarEvents = ({ home: { calendarEvents } }: IState) => calendarEvents;

export const getHomeOriginals = ({
  home: {
    home: { originalsDescription, originalsTitle, originalsVideoUrl },
  },
}: IState) => ({
  originalsDescription,
  originalsTitle,
  originalsVideoUrl,
});

export const getHomeEnterestingEvents = ({ home: { enterestingEvents, countEnterestingEvens } }: IState) => ({
  enterestingEvents,
  countEnterestingEvens,
});

export const getUpcomingEvents = ({ home: { upcomingEvents } }: IState) => ({
  upcomingEvents,
});
