import React from 'react';
import styled from 'styled-components';

import { IAreaSocials } from 'types';

import { SocialItem } from './SocialItem';

interface IProps {
  socials: IAreaSocials[];
  className?: string;
  color?: string;
}

export const Socials = ({ socials, className, color }: IProps) => (
  <Content className={className} $color={color}>
    <Title>Присоединяйся к нам в социальных сетях</Title>
    <Wrapper>
      {socials.map((item) => (
        <SocialItem key={item.link} {...item} />
      ))}
    </Wrapper>
  </Content>
);

const Content = styled.div<{ $color?: string }>`
  width: 100%;
  height: 80px;
  background-color: ${({ $color }) => $color || '#4853ec'};
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 576px) {
    height: 90px;
  }
`;

const Title = styled.p`
  display: none;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;
  color: #ffffff;
  margin: 0 32px 0 0;

  @media (min-width: 860px) {
    display: block;
  }
`;

const Wrapper = styled.div`
  height: 40px;

  display: flex;

  & > a {
    &:not(:last-child) {
      margin-right: 10px;
    }

    svg {
      width: 40px;
      height: 40px;
    }
  }

  @media (min-width: 576px) {
    height: 48px;

    & > a {
      &:not(:last-child) {
        margin-right: 16px;
      }

      svg {
        width: initial;
        height: initial;
      }
    }
  }
`;
