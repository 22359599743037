import React from 'react';
import { Select as AntdSelect, SelectProps } from 'antd';
import styled, { css } from 'styled-components';

const { Option: AntOption, OptGroup: AntOptGroup } = AntdSelect;

export interface IOption {
  id: string | number;
  value: string;
  label?: string;
  children?: IOption[];
}

interface ISelectStyles {}

interface IProps extends ISelectStyles, SelectProps<string | any> {
  optionItems: IOption[];
  caption?: string;
  required?: boolean;
  errorMsg?: string;
  defaultLabel?: string;
}

export const RegisterSelect = ({
  optionItems,
  caption,
  required = false,
  errorMsg = '',
  defaultLabel = 'Не выбрано',
  disabled,
  ...props
}: IProps) => {
  return (
    <SelectContainer isError={!!errorMsg} disabled={disabled}>
      {caption && <Caption required={required}>{caption}</Caption>}
      <AntdSelect {...props} value={!props.value ? defaultLabel : props.value} disabled={disabled}>
        {createMenu(optionItems)}
      </AntdSelect>
      {errorMsg && <ErrorContainer>{errorMsg}</ErrorContainer>}
    </SelectContainer>
  );
};

const createMenu = (optionList: IOption[]) => {
  return optionList.map((item) =>
    item.children ? (
      <OptionGroup key={item.id} label={item.label || item.value}>
        {createMenu(item.children)}
      </OptionGroup>
    ) : (
      <Option key={item.id} value={item.value}>
        {item.label || item.value}
      </Option>
    ),
  );
};

const SelectContainer = styled.div<{ isError: boolean; disabled?: boolean }>`
  ${({ theme: { registerSelectErrorColor, registerSelectBorderActiveColor }, isError, disabled }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    outline: none;
    line-height: 56px;
    box-sizing: border-box;
    padding: 0 0 17px 0;
    margin: 0;
    opacity: ${disabled ? 0.5 : 1};

    .ant-select-focused .ant-select-selector,
    .ant-select-selector:focus,
    .ant-select-selector:active,
    .ant-select-open .ant-select-selector {
      border-color: #red !important;
      box-shadow: none !important;
    }

    .ant-select {
      width: 100%;

      .ant-select-selector {
        padding: 0;
        height: 37px;
        border: none;
        border-bottom: 1px solid #dcdcdc;
        border-radius: 0px;

        ${isError &&
        css`
          border-bottom: 1px solid ${registerSelectErrorColor};
        `}
      }

      .ant-select-selection-item {
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.8px;
      }

      .ant-select-selection-search {
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.8px;
        left: 0;
        right: 0;
        top: -5px;
      }

      .ant-select-arrow {
        transform: scale(1.4, 1.2) translate(5px, -7px);
      }

      &.ant-select-open {
        .ant-select-selector {
          border-bottom: 1px solid ${registerSelectBorderActiveColor};
        }
        .ant-select-arrow {
          color: ${registerSelectBorderActiveColor};
        }
      }

      &.ant-select-disabled {
        .ant-select-selector {
          background: transparent;
        }
      }

      ${!disabled &&
      css`
        &:hover {
          .ant-select-selector {
            border-bottom: 1px solid ${registerSelectBorderActiveColor};
          }
          .ant-select-arrow {
            color: ${registerSelectBorderActiveColor};
          }
        }
      `}
    }

    @media (min-width: 576px) {
      .ant-select {
        .ant-select-selection-item {
          font-family: 'Raleway', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.8px;
        }
      }
    }

    @media (min-width: 768px) {
      .ant-select {
        .ant-select-selection-item {
          font-size: 16px;
          line-height: 20px;
        }
        .ant-select-arrow {
          transform: scale(1.7, 1.4) translate(3px, -7px);
        }
      }
    }
  `}
`;

const OptionGroup = styled(AntOptGroup)`
  ${({ theme: { registerSelectMenuBgColor, registerSelectMenuTextColor } }) => css`
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.8px;
    color: ${registerSelectMenuTextColor};
    width: 100%;
    background-color: ${registerSelectMenuBgColor};
  `}
`;

const Option = styled(AntOption)`
  ${({ theme: { registerSelectMenuTextColor, registerSelectActiveTextColor, registerSelectMenuBgActiveColor } }) => css`
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: ${registerSelectMenuTextColor};
    padding: 8px 30px 8px 16px;

    &-option-active {
      color: ${registerSelectActiveTextColor};
      background-color: ${registerSelectMenuBgActiveColor};
    }
  `}
`;

const Caption = styled.div<{ required: boolean }>`
  ${({ theme: { registerSelectMenuCaptionColor, registerSelectErrorColor }, required }) => css`
    & {
      width: 100%;
      font-family: 'Raleway', sans-serif;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: ${registerSelectMenuCaptionColor};
      padding: 0;
      display: flex;
      align-items: flex-end;

      ${required &&
      `
        &:after {
          display: inline;
          content: '*';
          color: ${registerSelectErrorColor};
          font-size: 20px;
          vertical-align: top;
          line-height: 20px;
          padding: 0 0 0 3px;
        }
      `}

      @media (min-width: 768px) {
        padding: 0 0 1px 0;
        font-size: 14px;
        line-height: 26px;
      }
    }
  `}
`;

const ErrorContainer = styled.div`
  ${({ theme: { registerSelectErrorColor } }) => css`
    & {
      font-family: 'Raleway', sans-serif;
      color: ${registerSelectErrorColor};
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 10px;
      position: absolute;
      left: 0;
      bottom: 0;

      @media (min-width: 768px) {
        font-size: 12px;
        line-height: 12px;
      }
    }
  `}
`;
