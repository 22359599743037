import { all } from 'redux-saga/effects';

import {
  areaRootSaga,
  areaEventsRootSaga,
  eventRootSaga,
  catalogRootSaga,
  authRootSaga,
  appRootSaga,
  homeRootSaga,
  homeEventsRootSaga,
  authorsRootSaga,
  publicationsRootSaga,
  personalAreaRootSaga,
  surveysRootSaga,
} from './sagas';

export function* rootSaga() {
  try {
    yield all([
      appRootSaga(),
      homeRootSaga(),
      homeEventsRootSaga(),
      areaRootSaga(),
      areaEventsRootSaga(),
      eventRootSaga(),
      catalogRootSaga(),
      authRootSaga(),
      authorsRootSaga(),
      publicationsRootSaga(),
      personalAreaRootSaga(),
      surveysRootSaga(),
    ]);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
}
