import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

import {
  homeReducer,
  areaReducer,
  eventReducer,
  catalogReducer,
  authReducer,
  successReducer,
  appReducer,
  authorsReducer,
  authorReducer,
  publicationsReducer,
  publicationReducer,
  personalAreaReducer,
  surveysReducer,
} from 'core/reducers';

export const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  home: homeReducer,
  area: areaReducer,
  event: eventReducer,
  catalog: catalogReducer,
  form: reduxFormReducer,
  success: successReducer,
  authors: authorsReducer,
  author: authorReducer,
  publications: publicationsReducer,
  publication: publicationReducer,
  personalArea: personalAreaReducer,
  surveys: surveysReducer,
});
