import { useEffect, useState } from 'react';

interface IEventSourceHookOptions {
  isNeedToConnect?: boolean;
}

export const useEventSource = (url: string, { isNeedToConnect = true }: IEventSourceHookOptions) => {
  const [sse, setSse] = useState<EventSource | null>(null);

  const [lastMessage, setLastMessage] = useState<string | null>(null);

  const [isConnectionOpen, setIsConnectionOpen] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setLastMessage(null);
    setIsConnectionOpen(false);
    setIsError(false);

    if (isNeedToConnect) {
      if (url) {
        setSse(new EventSource(url));
      }
    } else {
      setSse(null);
    }
  }, [url, isNeedToConnect]);

  useEffect(() => {
    if (sse) {
      sse.onopen = () => {
        setIsConnectionOpen(true);
      };

      sse.onerror = () => {
        setIsConnectionOpen(false);
        setIsError(false);
      };

      sse.onmessage = (e) => {
        setLastMessage(e.data);
      };

      return () => {
        sse.close();
      };
    }

    return undefined;
  }, [sse]);

  return {
    lastMessage,
    isConnectionOpen,
    isError,
  };
};
