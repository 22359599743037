import { ActionType, createReducer } from 'typesafe-actions';
import update from 'immutability-helper';

import {
  putHomeToStoreAction,
  clearHomeToStoreAction,
  putCurrentHomeEventsMonthToStoreAction,
  putHomeEventsToStoreAction,
  putHomeEnterestingEventsToStoreAction,
  getHomeRequestAction,
  getHomeSuccessAction,
  getHomeFailureAction,
  putUpcomingEventsToStoreAction,
} from 'core/actions';
import { IHome, IEventListItem, IHomeEnterestingEvent } from 'types';

export interface IHomeState {
  home: IHome;
  isHomeLoading: boolean;

  calendarEvents: {
    items: IEventListItem[];
    currentMonthIndex: number;
  };
  upcomingEvents: IEventListItem[];
  enterestingEvents: IHomeEnterestingEvent[];
  countEnterestingEvens: number;
}

const defaultState: IHomeState = {
  home: createDefaultHome(),
  isHomeLoading: true,

  calendarEvents: {
    items: [],
    currentMonthIndex: 0,
  },
  upcomingEvents: [],
  enterestingEvents: [],
  countEnterestingEvens: 0,
};

const actions = {
  putHomeToStoreAction,
  clearHomeToStoreAction,
  putHomeEventsToStoreAction,
  putUpcomingEventsToStoreAction,
  putCurrentHomeEventsMonthToStoreAction,
  putHomeEnterestingEventsToStoreAction,
  getHomeRequestAction,
  getHomeSuccessAction,
  getHomeFailureAction,
};

export const homeReducer = createReducer<IHomeState, ActionType<typeof actions>>(defaultState)
  .handleAction(getHomeRequestAction, (state) => ({
    ...state,
    isHomeLoading: true,
  }))
  .handleAction(getHomeSuccessAction, (state) => ({
    ...state,
    isHomeLoading: false,
  }))
  .handleAction(getHomeFailureAction, (state) => ({
    ...state,
    isHomeLoading: false,
  }))
  .handleAction(putHomeToStoreAction, (state, { payload: { home } }) => ({ ...state, home }))
  .handleAction(clearHomeToStoreAction, () => ({ ...defaultState }))

  .handleAction(putHomeEventsToStoreAction, (state, { payload: { events } }) => {
    return update(state, { calendarEvents: { items: { $set: events } } });
  })
  .handleAction(putUpcomingEventsToStoreAction, (state, { payload: { upcomingEvents } }) => ({
    ...state,
    upcomingEvents,
  }))

  .handleAction(
    putHomeEnterestingEventsToStoreAction,
    (state, { payload: { enterestingEvents, countEnterestingEvens } }) => ({
      ...state,
      enterestingEvents,
      countEnterestingEvens,
    }),
  )
  .handleAction(putCurrentHomeEventsMonthToStoreAction, (state, { payload: apiIndex }) => {
    return update(state, { calendarEvents: { currentMonthIndex: { $set: apiIndex } } });
  });

function createDefaultHome(): IHome {
  return {
    title: '',
    description: '',
    backgroundUrl: '',
    popularAreas: [],
    partners: [],
    banners: [],
    originalsTitle: '',
    originalsDescription: '',
    originalsVideoUrl: '',
  };
}
