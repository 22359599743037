interface ICrossBrowserFullScreenDocument extends Document {
  mozFullScreen?: boolean;
  webkitIsFullScreen?: boolean;

  webkitExitFullscreen?: () => void;
  mozCancelFullScreen?: () => void;
  msExitFullscreen?: () => void;
}

export const checkFullScreen = (): boolean => {
  const docElement = document as ICrossBrowserFullScreenDocument;
  const isFullscreen = docElement.fullscreenElement || docElement.mozFullScreen || docElement.webkitIsFullScreen;

  return Boolean(isFullscreen);
};

export const closeFullScreen = () => {
  const documentElem = document as ICrossBrowserFullScreenDocument;

  if (documentElem.exitFullscreen) {
    documentElem.exitFullscreen();
    return;
  }
  if (documentElem.webkitExitFullscreen) {
    /* Safari */
    documentElem.webkitExitFullscreen();
    return;
  }
  if (documentElem.mozCancelFullScreen) {
    /* Mozilla */
    documentElem.mozCancelFullScreen();
    return;
  }
  if (documentElem.msExitFullscreen) {
    /* IE11 */
    documentElem.msExitFullscreen();
  }
};
