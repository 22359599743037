import React from 'react';
import styled from 'styled-components';
import { Radio, RadioChangeEvent } from 'antd';
import { useDispatch } from 'react-redux';

import { SurveyAnswerType, SurveyQuestionContentType } from 'types';
import { saveSurveyAnswersToStoreAction } from 'core/actions';
import ImageStub from 'assets/images/no-image-provided.png';

interface IRadioButtonGroupProps {
  questionId: number;
  questionType: SurveyQuestionContentType;
  options: SurveyAnswerType[];
}

export const RadioButtonGroup = ({ options, questionType, questionId }: IRadioButtonGroupProps) => {
  const dispatch = useDispatch();

  const onChange = (e: RadioChangeEvent) => {
    dispatch(saveSurveyAnswersToStoreAction({ answers: { question: questionId, answers: [e.target.value] } }));
  };

  const isImage = (imageUrl: string) => {
    return (imageUrl && questionType !== 'text') || questionType === 'full';
  };

  const isText = (answer: string) => {
    return (answer && questionType !== 'photo') || questionType === 'full';
  };

  return options.length ? (
    <RadioStyled onChange={onChange}>
      {options.map(({ id, answer, imageUrl }) => (
        <Radio key={id} value={id} style={{ alignItems: 'center' }}>
          {isImage(imageUrl) && (
            <ImageWrapper>
              <AnswerImage
                src={imageUrl}
                alt="answer-image"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = ImageStub;
                }}
              />
            </ImageWrapper>
          )}
          {isText(answer) && <p className="radio-styled-answer">{answer}</p>}
        </Radio>
      ))}
    </RadioStyled>
  ) : null;
};

const RadioStyled = styled(Radio.Group)`
  width: 100%;
  border-top: 1px solid #d2d7de;
  border-bottom: 1px solid #d2d7de;

  .radio-styled-answer {
    margin-bottom: 0;
  }

  .ant-radio-group {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .ant-radio-wrapper {
    padding: 32px 0;
    width: 100%;
    color: #000000;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    align-items: center;

    &:not(:last-of-type) {
      border-bottom: 1px solid #d2d7de;
    }

    & > span:last-of-type {
      padding-left: 16px;
      display: flex;
      align-items: center;
    }
  }

  .ant-radio {
    top: 0;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 12px;
  margin-right: 16px;
  min-width: 30%;
  max-width: 30%;

  @media (min-width: 768px) {
    max-width: 260px;
    max-height: 180px;
    margin-right: 24px;
  }
`;

const AnswerImage = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 12px;
  margin: 0 auto;
`;
