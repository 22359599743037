import { SagaIterator } from 'redux-saga';
import { takeEvery, call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { convertEventToAppSchema } from 'services/apiMappers';
import { eventsService } from 'services';
import {
  getEventRequestAction,
  getEventSuccessAction,
  getEventFailureAction,
  putEventToStoreAction,
  setViewFlagRequestAction,
  setViewFlagSuccessAction,
  setViewFlagFailureAction,
  subscribeRequestAction,
  subscribeSuccessAction,
  subscribeFailureAction,
  unSubscribeRequestAction,
  unSubscribeSuccessAction,
  unSubscribeFailureAction,
  setRegisteredFlagRequestAction,
  setRegisteredFlagSuccessAction,
  setRegisteredFlagFailureAction,
  setViewedMinuteRequestAction,
  setViewedMinuteSuccessAction,
  setViewedMinuteFailureAction,
  getEventSilentRequestAction,
  getEventSilentSuccessAction,
  getEventSilentFailureAction,
  setEventRatingRequestAction,
  setEventRatingSuccessAction,
  setEventRatingFailureAction,
} from 'core/actions';

function* getEventSaga({ payload: eventId }: ActionType<typeof getEventRequestAction>): SagaIterator {
  try {
    const { data } = yield call(() => eventsService.getEvent(eventId));
    const event = convertEventToAppSchema(data);
    yield put(getEventSuccessAction());
    yield put(putEventToStoreAction(event));
  } catch (error) {
    yield put(getEventFailureAction());
  }
}

function* getEventSilentSaga({ payload: eventId }: ActionType<typeof getEventSilentRequestAction>): SagaIterator {
  try {
    const { data } = yield call(() => eventsService.getEvent(eventId));
    const event = convertEventToAppSchema(data);
    yield put(getEventSilentSuccessAction());
    yield put(putEventToStoreAction(event));
  } catch (error) {
    yield put(getEventSilentFailureAction());
  }
}

function* setViewFlagSaga({ payload: eventId }: ActionType<typeof setViewFlagRequestAction>): SagaIterator {
  try {
    yield call(() => eventsService.eventViewings(eventId));

    yield put(setViewFlagSuccessAction());
    yield put(getEventSilentRequestAction(eventId));
  } catch (error) {
    yield put(setViewFlagFailureAction());
  }
}

function* setEventRatingSaga({ payload: ratingDto }: ActionType<typeof setEventRatingRequestAction>): SagaIterator {
  try {
    yield call(() => eventsService.setEventRating(ratingDto));

    yield put(setEventRatingSuccessAction());
    yield put(getEventSilentRequestAction(ratingDto.eventId));
  } catch (error) {
    yield put(setEventRatingFailureAction());
  }
}

function* setRegisteredFlagSaga({ payload: eventId }: ActionType<typeof setRegisteredFlagRequestAction>): SagaIterator {
  try {
    yield call(() => eventsService.eventRegister(eventId));

    yield put(setRegisteredFlagSuccessAction());
    yield put(getEventSilentRequestAction(eventId));
  } catch (error) {
    yield put(setRegisteredFlagFailureAction());
  }
}

function* subscribeSaga({ payload: eventId }: ActionType<typeof subscribeRequestAction>): SagaIterator {
  try {
    eventsService.subscribe(String(eventId));

    yield put(subscribeSuccessAction(eventId));
  } catch (error) {
    yield put(subscribeFailureAction());
  }
}

function* unSubscribeSaga({ payload: eventId }: ActionType<typeof unSubscribeRequestAction>): SagaIterator {
  try {
    eventsService.unsubscribe(String(eventId));

    yield put(unSubscribeSuccessAction(eventId));
  } catch (error) {
    yield put(unSubscribeFailureAction());
  }
}

function* setViewedSecondsWorker({ payload: eventId }: ActionType<typeof setViewedMinuteRequestAction>): SagaIterator {
  try {
    yield call(() => eventsService.setEventViewTime(eventId));

    yield put(setViewedMinuteSuccessAction());
  } catch (error) {
    yield put(setViewedMinuteFailureAction());
  }
}

export function* eventRootSaga(): SagaIterator {
  yield takeEvery(getEventRequestAction, getEventSaga);
  yield takeEvery(getEventSilentRequestAction, getEventSilentSaga);
  yield takeEvery(setViewFlagRequestAction, setViewFlagSaga);
  yield takeEvery(setRegisteredFlagRequestAction, setRegisteredFlagSaga);
  yield takeEvery(subscribeRequestAction, subscribeSaga);
  yield takeEvery(unSubscribeRequestAction, unSubscribeSaga);
  yield takeEvery(setViewedMinuteRequestAction, setViewedSecondsWorker);
  yield takeEvery(setEventRatingRequestAction, setEventRatingSaga);
}
