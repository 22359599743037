/* eslint-disable @typescript-eslint/no-unused-vars */
import { SagaIterator } from 'redux-saga';
import { takeLatest, call, put, select, SagaReturnType, takeEvery } from 'redux-saga/effects';
import { getFormValues } from 'redux-form';
import { ActionType } from 'typesafe-actions';

import {
  convertPublicationsCategoriesToAppSchema,
  convertPublicationsToAppSchema,
  convertPublicationToAppSchema,
} from 'services/apiMappers';
import { PublicationService } from 'services';
import {
  getPublicationsRequestAction,
  getPublicationsSuccessAction,
  putPublicationsToStoreAction,
  getPublicationsFailureAction,
  getPublicationsCategoriesRequestAction,
  getPublicationsCategoriesSuccessAction,
  putPublicationsCategoriesToStoreAction,
  getPublicationsCategoriesFailureAction,
  getPublicationSuccessAction,
  putPublicationToStoreAction,
  getPublicationFailureAction,
  getPublicationRequestAction,
  getMorePublicationsRequestAction,
} from 'core/actions';
import { CurrentPublicationsCategoryType, PublicationsPageType, PublicationsType } from 'core/types/selectorsTypes';
import { getPublicationsPage, getCurrentPublicationsCategoryFromStore, getPublications } from 'core/selectors';

type PublicationsResult = SagaReturnType<typeof PublicationService.getPublications>;
function* getPublicationsWorker({ payload: { alias } }: ActionType<typeof getPublicationsRequestAction>): SagaIterator {
  try {
    const formValues = yield select(getFormValues('publicationsSearchForm'));
    const { searchString, areaSelect } = formValues || {};
    const page: PublicationsPageType = yield select(getPublicationsPage);
    const category: CurrentPublicationsCategoryType = yield select(getCurrentPublicationsCategoryFromStore);

    const { results, total }: PublicationsResult = yield call(() =>
      PublicationService.getPublications({
        alias: alias || areaSelect,
        searchString,
        offset: page,
        category: category ? [category] : undefined,
      }),
    );
    const publicationsResults = convertPublicationsToAppSchema(results);

    yield put(getPublicationsSuccessAction());
    yield put(putPublicationsToStoreAction({ publications: publicationsResults, total }));
  } catch (error) {
    yield put(getPublicationsFailureAction());
  }
}

function* getMorePublicationsWorker({
  payload: { alias },
}: ActionType<typeof getMorePublicationsRequestAction>): SagaIterator {
  try {
    const formValues = yield select(getFormValues('publicationsSearchForm'));
    const { searchString, areaSelect } = formValues || {};
    const category: CurrentPublicationsCategoryType = yield select(getCurrentPublicationsCategoryFromStore);
    const { publications: oldPublications }: PublicationsType = yield select(getPublications);

    const { results, total }: PublicationsResult = yield call(() =>
      PublicationService.getPublications({
        alias: alias || areaSelect,
        searchString,
        offset: Math.ceil(oldPublications.length / PublicationService.defaultPublicationsLimit) + 1,
        category: category ? [category] : undefined,
      }),
    );
    const publicationsResults = convertPublicationsToAppSchema(results);

    yield put(getPublicationsSuccessAction());
    yield put(putPublicationsToStoreAction({ publications: [...oldPublications, ...publicationsResults], total }));
  } catch (error) {
    yield put(getPublicationsFailureAction());
  }
}

type Categories = SagaReturnType<typeof PublicationService.getCategories>;
function* getCategoriesWorker({
  payload: { alias },
}: ActionType<typeof getPublicationsCategoriesRequestAction>): SagaIterator {
  try {
    const data: Categories = yield call(() => PublicationService.getCategories(alias));
    const categories = convertPublicationsCategoriesToAppSchema(data);

    yield put(getPublicationsCategoriesSuccessAction());
    yield put(putPublicationsCategoriesToStoreAction({ categories }));
  } catch (error) {
    yield put(getPublicationsCategoriesFailureAction());
  }
}

type Publication = SagaReturnType<typeof PublicationService.getPublication>;
function* getPublicationWorker({ payload: { id } }: ActionType<typeof getPublicationRequestAction>): SagaIterator {
  try {
    const data: Publication = yield call(() => PublicationService.getPublication(id));
    const publication = convertPublicationToAppSchema(data);

    yield put(getPublicationSuccessAction());
    yield put(putPublicationToStoreAction({ publication }));
  } catch (error) {
    yield put(getPublicationFailureAction());
  }
}

export function* publicationsRootSaga(): SagaIterator {
  yield takeLatest(getPublicationsRequestAction, getPublicationsWorker);
  yield takeLatest(getMorePublicationsRequestAction, getMorePublicationsWorker);
  yield takeEvery(getPublicationRequestAction, getPublicationWorker);
  yield takeEvery(getPublicationsCategoriesRequestAction, getCategoriesWorker);
}
