import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { Link } from 'react-router-dom';

import { Tag } from 'atoms';
import { IEventListItem } from 'types';

interface IProps {
  event: IEventListItem;
  area?: string;
}

export const OtherEventsItem = ({ event: { id, category, startDate, title, authors, description }, area }: IProps) => {
  return (
    <Wrapper>
      <ItemTag size="middle" colorType={4}>
        {category.shortTitle || category.title}
      </ItemTag>
      <StartDate>{format(new Date(startDate), 'd MMMM', { locale: ru })}</StartDate>
      <Title>{title}</Title>
      {authors[0] && (
        <>
          <Post>{authors[0].position}</Post>
          <Teacher>{authors[0].fullName}</Teacher>
        </>
      )}
      {authors[1] && (
        <>
          <Post>{authors[1].position}</Post>
          <Teacher>{authors[1].fullName}</Teacher>
        </>
      )}
      <Description to={area ? `/area/${area}/event/${id}` : `/event/${id}`} className="description">
        {description.length > 200 ? `${description.substr(0, 200)} ...` : description}
      </Description>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: #ffffff;
  padding: 32px 25px 55px 25px;
  cursor: pointer;
  position: relative;
  border: 1px solid #eeeeee;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(72, 83, 236, 0.08);
  margin: 0 0 8px 0;

  &:nth-child(3),
  &:nth-child(4) {
    display: none;
  }

  &:hover {
    background: #4853ec;
    border-color: #4853ec;

    .description {
      display: block;
    }
  }

  @media (min-width: 768px) {
    margin: 0;
    box-shadow: none;
  }

  @media (min-width: 1200px) {
    border-radius: 0;
    border-right: 0;

    &:last-child {
      border-radius: 0 12px 12px 0;
      border-right: 1px solid #eeeeee;
    }

    &:first-child {
      border-radius: 12px 0 0 12px;
    }

    &:nth-child(3),
    &:nth-child(4) {
      display: block;
    }
  }
`;

const StartDate = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #4853ec;
  margin: 0 0 15px 0;
`;

const Title = styled.div`
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
  margin: 0 0 24px 0;

  @media (min-width: 1200px) {
    font-size: 24px;
  }
`;

const Teacher = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin: 0 0 10px 0;
`;

const Post = styled.div`
  font-family: 'Raleway', sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #a7a8ab;
  margin: 0 0 5px 0;
`;

const Description = styled(Link)`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  background: #4853ec;
  display: none;
  position: absolute;
  top: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 25px 62px 25px;

  &:hover {
    color: #ffffff;
  }
`;

const ItemTag = styled(Tag)`
  margin: 0 0 40px 0;
`;
