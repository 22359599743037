import React, { FunctionComponent, memo, useEffect } from 'react';
import { BrowserRouter, Route, Redirect, Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';

import { getIsInitAppLoading, getServerError, getVisitorStatus } from 'core/selectors';
import { initAppRequestAction } from 'core/actions';
import {
  Area,
  Event,
  Catalog,
  Home,
  Authors,
  Author,
  Publication,
  Publications,
  NotFound,
  ServerError,
  NetworkError,
  RedirectToMobile,
  PersonalArea,
  Survey,
  Surveys,
} from 'pages';
import { ServerErrors } from 'types';
import { CookiesPopup } from 'components/molecules';
import { AccessInterceptor } from 'components/organisms';

export const Router = memo(() => {
  const dispatch = useDispatch();
  const isInitAppLoading = useSelector(getIsInitAppLoading);
  const isDoctor = useSelector(getVisitorStatus);

  useEffect(() => {
    dispatch(initAppRequestAction());
  }, [dispatch]);

  if (isInitAppLoading) return <Spin style={{ position: 'absolute', left: '48%', top: '48%' }} size="large" />;

  if (!isDoctor) return <AccessInterceptor />;

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route exact path="/" render={linkWithGuard(Home)} />
        <Route exact path="/personal-area" component={linkWithGuard(PersonalArea)} />
        <Route exact path="/create-password/:token" render={linkWithGuard(Home)} />
        <Route exact path="/event/:id" render={linkWithGuard(Event)} />
        <Route exact path="/catalog/:category" render={linkWithGuard(Catalog)} />
        <Route exact path="/authors" render={linkWithGuard(Authors)} />
        <Route exact path="/publication/:id" render={linkWithGuard(Publication)} />
        <Route exact path="/authors/:id" render={linkWithGuard(Author)} />
        <Route exact path="/publications/:category" render={linkWithGuard(Publications)} />
        <Route exact path="/surveys" render={linkWithGuard(Surveys)} />
        <Route exact path="/surveys/:surveyId" render={linkWithGuard(Survey)} />

        <Route exact path="/area/:area" render={linkWithGuard(Area)} />
        <Route exact path="/area/:area/event/:id" render={linkWithGuard(Event)} />
        <Route exact path="/area/:area/catalog/:category" render={linkWithGuard(Catalog)} />
        <Route exact path="/area/:area/authors" render={linkWithGuard(Authors)} />
        <Route exact path="/area/:area/authors/:id" render={linkWithGuard(Author)} />
        <Route exact path="/area/:area/publications/:category" render={linkWithGuard(Publications)} />
        <Route exact path="/area/:area/publication/:id" render={linkWithGuard(Publication)} />

        <Route exact path="/server-error" component={ServerError} />

        <Route exact path="/network-error" component={NetworkError} />
        <Route exact path="/redirect" render={linkWithGuard(RedirectToMobile)} />
        <Route exact path="/error" component={ServerError} />

        <Route exact path="/404" component={NotFound} />

        <Redirect from="*" to="/404" />
      </Switch>
    </BrowserRouter>
  );
});

const Guard = memo(({ children }: { children: React.JSX.Element }) => {
  const serverError = useSelector(getServerError);
  const { pathname } = useLocation();

  if (
    serverError === ServerErrors.BadGateway ||
    serverError === ServerErrors.TimeoutGateway ||
    serverError === ServerErrors.Econnaborted
  ) {
    return <Redirect to={`/server-error?backUrl=${encodeURIComponent(pathname)}`} push />;
  }

  if (serverError === ServerErrors.Network) {
    return <Redirect to={`/network-error?backUrl=${encodeURIComponent(pathname)}`} push />;
  }

  if (serverError === ServerErrors.NotFound) {
    return <Redirect to="/404" />;
  }

  return children;
});

const linkWithGuard = (Component: FunctionComponent) => {
  return () => (
    <Guard>
      <>
        <Component />
        <CookiesPopup />
      </>
    </Guard>
  );
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
