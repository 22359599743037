import React from 'react';
import styled, { css } from 'styled-components';

interface IProps {
  onClick: () => void;
  children: number;
  isActive: boolean;
}

export const PaginatorItem = ({ onClick, children, isActive }: IProps) => {
  return (
    <Container $isActive={isActive} onClick={onClick}>
      0{children}
    </Container>
  );
};

const Container = styled.button<{ $isActive: boolean }>`
  ${({ $isActive, theme: { primaryBtnBgColor } }) => css`
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;

    background: transparent;
    color: #a7a8ab;
    cursor: pointer;
    border: none;
    transition: border 0.1s linear;

    padding: 0 0 19px 0;
    margin: 0 33px -2px 0;
    border-bottom: 3px solid transparent;

    outline: none;
    display: inline-block;

    &:last-child {
      margin-right: 0;
    }

    ${$isActive &&
    css`
      color: #000000;
      border-color: ${primaryBtnBgColor};
    `}
  `}
`;
