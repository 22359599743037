import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getAreasCropped } from 'core/selectors';
import { getAreasCroppedRequestAction, getMoreAreasCroppedRequestAction } from 'core/actions';

import { Areas } from './Areas';

export const Container = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { areas, total } = useSelector(getAreasCropped);

  const loadMoreOnClickHandler = () => {
    dispatch(getMoreAreasCroppedRequestAction());
  };

  const areaOnClickHandler = (alias: string) => {
    push(`/area/${alias}`);
  };

  useEffect(() => {
    dispatch(getAreasCroppedRequestAction());
  }, [dispatch]);

  if (!areas.length) {
    return null;
  }

  return (
    <Areas total={total} areas={areas} areaOnClick={areaOnClickHandler} loadMoreOnClick={loadMoreOnClickHandler} />
  );
};
