import { createAction } from 'typesafe-actions';

import { IEvent, IEventMarkDto } from 'types';

import { ACTIONS } from './constants';

export const getEventRequestAction = createAction(ACTIONS.GET_EVENT_REQUEST)<number>();
export const getEventSuccessAction = createAction(ACTIONS.GET_EVENT_SUCCESS)();
export const getEventFailureAction = createAction(ACTIONS.GET_EVENT_FAILURE)();
export const getEventSilentRequestAction = createAction(ACTIONS.GET_EVENT_SILENT_REQUEST)<number>();
export const getEventSilentSuccessAction = createAction(ACTIONS.GET_EVENT_SILENT_SUCCESS)();
export const getEventSilentFailureAction = createAction(ACTIONS.GET_EVENT_SILENT_FAILURE)();
export const putEventToStoreAction = createAction(ACTIONS.PUT_EVENT_TO_STORE)<IEvent>();
export const clearEventToStoreAction = createAction(ACTIONS.CLEAR_EVENT_TO_STORE)();

export const setViewFlagRequestAction = createAction(ACTIONS.SET_VIEW_FLAG_REQUEST)<number>();
export const setViewFlagSuccessAction = createAction(ACTIONS.SET_VIEW_FLAG_SUCCESS)();
export const setViewFlagFailureAction = createAction(ACTIONS.SET_VIEW_FLAG_FAILURE)();

export const setEventRatingRequestAction = createAction(ACTIONS.SET_EVENT_RATING_REQUEST)<IEventMarkDto>();
export const setEventRatingSuccessAction = createAction(ACTIONS.SET_EVENT_RATING_SUCCESS)();
export const setEventRatingFailureAction = createAction(ACTIONS.SET_EVENT_RATING_FAILURE)();

export const setRegisteredFlagRequestAction = createAction(ACTIONS.SET_REGISTERED_FLAG_REQUEST)<number>();
export const setRegisteredFlagSuccessAction = createAction(ACTIONS.SET_REGISTERED_FLAG_SUCCESS)();
export const setRegisteredFlagFailureAction = createAction(ACTIONS.SET_REGISTERED_FLAG_FAILURE)();

export const subscribeRequestAction = createAction(ACTIONS.SUBSCRIBE_REQUEST)<number>();
export const subscribeSuccessAction = createAction(ACTIONS.SUBSCRIBE_SUCCESS)<number>();
export const subscribeFailureAction = createAction(ACTIONS.SUBSCRIBE_FAILURE)();

export const unSubscribeRequestAction = createAction(ACTIONS.UNSUBSCRIBE_REQUEST)<number>();
export const unSubscribeSuccessAction = createAction(ACTIONS.UNSUBSCRIBE_SUCCESS)<number>();
export const unSubscribeFailureAction = createAction(ACTIONS.UNSUBSCRIBE_FAILURE)();

export const setViewedMinuteRequestAction = createAction(ACTIONS.SET_VIEWED_MINUTE_REQUEST)<number>();
export const setViewedMinuteSuccessAction = createAction(ACTIONS.SET_VIEWED_MINUTE_SUCCESS)();
export const setViewedMinuteFailureAction = createAction(ACTIONS.SET_VIEWED_MINUTE_FAILURE)();
