import React from 'react';
import styled from 'styled-components';
import Carousel from 'react-multi-carousel';

import { IBanner } from 'types';
import { Container } from 'atoms';

import { BannersCarouselItem } from './BannersCarouselItem';
import { ButtonGroup } from './ButtonGroup';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 992 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 992, min: 768 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 768, min: 576 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 576, min: 0 },
    items: 1,
  },
};

interface IProps {
  items?: IBanner[];
  className?: string;
}

export const BannersCarousel = ({ items = [], className }: IProps) => {
  return (
    <SectionContainer className={className}>
      <Container>
        <Wrapper>
          <CarouselWrapper
            ssr={false}
            infinite
            autoPlay
            autoPlaySpeed={10000}
            showDots={false}
            arrows={false}
            removeArrowOnDeviceType={['tablet', 'mobile']}
            responsive={responsive}
            renderDotsOutside
            renderButtonGroupOutside
            customButtonGroup={<ButtonGroup />}
          >
            {items.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <BannersCarouselItem item={item} key={index} />
            ))}
          </CarouselWrapper>
        </Wrapper>
      </Container>
    </SectionContainer>
  );
};

const SectionContainer = styled.div`
  background: #ffffff;
  padding: 64px 0 64px;
`;

const Wrapper = styled.div`
  background: #ffffff;
  margin: 0;
  position: relative;

  .react-multi-carousel-dot-list {
    position: static;
    display: inline-block;
    text-align: left;
    border-bottom: 1px solid #f6f8fb;
    margin: 0 0 0 25px;
  }
`;

const CarouselWrapper = styled(Carousel)`
  box-shadow: 0 4px 10px rgba(72, 83, 236, 0.03);
  border-radius: 12px;
  overflow: hidden;
`;
