import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';

import { store } from 'core';
import { Router } from 'components/pages';
import { GlobalStyles, blueGrayTheme } from 'constants/theme';
import 'antd/dist/antd.css';
import 'react-phone-number-input/style.css'

export const App = () => {
  return (
    <ThemeProvider theme={blueGrayTheme}>
      <GlobalStyles />
      <Provider store={store}>
        <Router />
      </Provider>
    </ThemeProvider>
  );
};
