import React from 'react';
import styled, { css } from 'styled-components';
import { Tag as AntdTag } from 'antd';

interface ITag {
  value: string | number;
  label?: string | number;
}

interface IProps {
  tags: ITag[];
  caption: string;
  activeTag?: string | number;
  required?: boolean;
  onChange?: (value: string | number) => void;
  className?: string;
}

export const TagSelect = ({ tags, caption, activeTag, onChange, required, className }: IProps) => {
  return (
    <TagSelectContainer className={className}>
      <Caption required={required}>{caption}</Caption>
      {tags.map((tag) => (
        <Tag key={tag.value} isActive={tag.value === activeTag} onClick={() => onChange?.(tag.value)}>
          {tag.label ?? tag.value}
        </Tag>
      ))}
    </TagSelectContainer>
  );
};

const TagSelectContainer = styled.div`
  padding-bottom: 7px;
`;

const Caption = styled.p<{ required?: boolean }>`
  ${({ theme: { TagSelectCaptionColor, TextInputErrorTextColor }, required }) => css`
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    margin: 0;
    padding: 0;
    padding-bottom: 4px;
    color: ${TagSelectCaptionColor};

    ${required &&
    css`
      &:after {
        display: inline;
        content: '*';
        color: ${TextInputErrorTextColor};
        font-size: 20px;
        vertical-align: top;
        line-height: 20px;
        padding: 0 0 0 3px;
      }
    `}

    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 24px;
    }
  `};
`;

const Tag = styled(AntdTag)<{ isActive?: boolean }>`
  ${({
    theme: { TagSelectBackGroundColor, TagSelectBackGroundActiveColor, TagSelectTextColor, TagSelectTextActiveColor },
    isActive,
  }) => css`
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.8px;
    border: none;
    border-radius: 4px;
    padding: 8px 24px;
    margin: 0;
    margin-right: 16px;
    cursor: pointer;
    color: ${isActive ? TagSelectTextActiveColor : TagSelectTextColor};
    background-color: ${isActive ? TagSelectBackGroundActiveColor : TagSelectBackGroundColor};
  `};
`;
