import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { getAuthServerError } from 'core/selectors';

export const FormServerError = () => {
  const error = useSelector(getAuthServerError);

  return error ? (
    <Container>
      <Text>{error}</Text>
    </Container>
  ) : null;
};

const Container = styled.div`
  text-align: left;
  padding: 10px 0;
`;

const Text = styled.p`
  ${({ theme: { formErrorTextColor } }) => css`
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: ${formErrorTextColor};
  `};
`;
