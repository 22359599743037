import React from 'react';
import styled, { css } from 'styled-components';
import { Col, Row } from 'antd';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { useHistory, useParams } from 'react-router-dom';

import { IEventListItem } from 'types';
import { SubscriptionBtn, Tag, InterestingTag } from 'atoms';
import { cropEventTitle } from 'helpers';
import { useWindowSize } from 'hooks';

interface IEventProps {
  screen: Partial<Record<Breakpoint, boolean>>;
  event: IEventListItem;
}

export const Event = ({
  screen,
  event: { thumbnailUrl, tags, category, viewingCount, subscribed, title, authors, id, isInteresting, area: eventArea },
}: IEventProps) => {
  const [width] = useWindowSize();
  const history = useHistory();
  const { area } = useParams<{ area?: string }>();

  const eventClickHandler = () => {
    history.push(area ? `/area/${area}/event/${id}` : `/event/${id}`);
  };

  return (
    <EventStyled onClick={eventClickHandler}>
      <Row>
        <Col span={24} md={8} lg={6}>
          <ImageStyled url={thumbnailUrl}>
            {tags.map((item) => (
              <Tag size="small" key={item.name} bgColor={item.color}>
                {item.name}
              </Tag>
            ))}
          </ImageStyled>
        </Col>
        <Col span={24} md={16} lg={18}>
          <ContentStyled>
            <Row gutter={screen.lg ? 30 : 0}>
              <Col xs={24} sm={16}>
                <Row>
                  {isInteresting && <InterestingTag size="small" />}
                  <Tag colorType={5} size="small">
                    {category.title}
                  </Tag>
                  <Tag colorType={5} size="small">
                    {eventArea.shortTitle}
                  </Tag>
                </Row>
              </Col>
              <Col xs={24} sm={8} style={{ marginTop: screen.sm ? 0 : 8 }}>
                <Row wrap={false}>
                  <Tag colorType={9} size="small">
                    {viewingCount} просмотров
                  </Tag>
                  <SubscribeStyled>
                    <SubscriptionBtn eventId={id!} isSubscribed={subscribed} size="small" forListItem />
                  </SubscribeStyled>
                </Row>
              </Col>
            </Row>
            <Row gutter={[30, 30]}>
              <Col sm={24} md={24} lg={16}>
                <TitleStyled>{cropEventTitle(width, title)}</TitleStyled>
              </Col>
              <Col sm={24} md={24} lg={8}>
                {authors[0] && (
                  <>
                    <AuthotPositionStyled>{authors[0].position}</AuthotPositionStyled>
                    <AuthorFullNameStyled>{authors[0].fullName}</AuthorFullNameStyled>
                  </>
                )}
                {authors[1] && (
                  <>
                    <AuthotPositionStyled>{authors[1].position}</AuthotPositionStyled>
                    <AuthorFullNameStyled>{authors[1].fullName}</AuthorFullNameStyled>
                  </>
                )}
              </Col>
            </Row>
          </ContentStyled>
        </Col>
      </Row>
    </EventStyled>
  );
};

const EventStyled = styled.div`
  display: block;
  margin-top: 10px;
  border: 1px solid #eeeeee;
  border-radius: 12px;
  overflow: hidden;
  background-color: #ffffff;
  cursor: pointer;

  &:hover {
    box-shadow: 0 4px 10px rgb(72 83 236 / 8%);
  }
`;

const ContentStyled = styled.div`
  padding: 24px 24px 32px;
  display: grid;
  grid-gap: 10px;

  @media (min-width: 768px) {
    padding: 32px;
  }
`;

const ImageStyled = styled.div<{ url: string }>`
  ${({ url }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-wrap: wrap;

    padding: 16px;
    height: 100%;
    min-height: 190px;

    background-image: url('${url}');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    span {
      margin-top: 3px;
    }
  `}
`;

const SubscribeStyled = styled.div`
  cursor: pointer;
`;

const AuthotPositionStyled = styled.div`
  color: #a7a8ab;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
`;

const AuthorFullNameStyled = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: #000000;
`;

const TitleStyled = styled.div`
  color: #000000;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0;
  text-align: left;
  overflow: hidden;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
    height: 95px;
  }
`;
