import styled from 'styled-components';
import { Breadcrumb as AntdBreadcrumb } from 'antd';

export const Breadcrumb = styled(AntdBreadcrumb)`
  margin: 0 0 48px 0;

  .ant-breadcrumb-link,
  .ant-breadcrumb-separator {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    cursor: pointer;
  }

  .ant-breadcrumb-separator {
    margin: 0 20px;
  }

  @media (min-width: 768px) {
    margin: 0 0 56px 0;
  }

  @media (min-width: 1200px) {
    margin: 0 0 64px 0;
  }
`;
