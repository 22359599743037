import React from 'react';
import styled from 'styled-components';

import { Container } from 'atoms';
import { IEventSection } from 'types';

import { EventProgramItem } from './EventProgramItem';

interface IProps {
  data: IEventSection[];
  color?: string;
}

export const EventProgram = ({ data, color }: IProps) => (
  <Wrapper>
    <Container>
      <Title>Программа</Title>
    </Container>
    <Container>
      {data.map(({ title, description, startTime, endTime, authors }) => (
        <EventProgramItem
          key={String(title)}
          title={title}
          time={`${startTime} - ${endTime}`}
          description={description}
          authors={authors}
          color={color}
        />
      ))}
    </Container>
  </Wrapper>
);

const Wrapper = styled.div`
  background: #f8f8f8;
  padding: 48px 0 96px 0;
`;

const Title = styled.div`
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  margin: 0 0 32px 0;

  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 40px;
    margin: 0 0 48px 0;
  }

  @media (min-width: 1200px) {
    font-size: 48px;
    line-height: 56px;
    margin: 0 0 70px 0;
  }
`;
