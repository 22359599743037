const baseColors = {
  primaryColor: '#4853ec',
  secondaryColor: '#e2e3fc',
  tertiaryColor: '#e6eaf1',

  primaryTextColor: '#000000',
  secondaryTextColor: '#ffffff',

  errorTextColor: '#eb5757',

  primaryInputColor: '#4f4f4f',
  secondaryInputColor: '#f6f8fb',
};

export const blueGrayTheme = {
  topBackgroundColor: baseColors.primaryColor,

  primaryBtnBgColor: baseColors.primaryColor,
  primaryBtnTextColor: baseColors.secondaryTextColor,
  primaryBtnHoverBgColor: '#6972ec',
  primaryBtnActiveBgColor: '#2630ae',
  primaryBtnDisabledBgColor: '#9da1d3',
  primaryBtnDisabledTextColor: baseColors.secondaryTextColor,

  defaultBtnBgColor: baseColors.secondaryColor,
  defaultBtnTextColor: baseColors.primaryColor,
  defaultBtnHoverBgColor: '#ffffff',
  defaultBtnHoverBorderColor: baseColors.primaryColor,
  defaultBtnActiveBgColor: '#f0f1fd',
  defaultBtnDisabledBgColor: '#c4c8f9',
  defaultBtnDisabledTextColor: '#8289ee',

  ghostBtnBgColor: '#e6eaf1',
  ghostBtnTextColor: '#9da0aa',
  ghostBtnHoverBgColor: '#eff1f4',
  ghostBtnActiveBgColor: '#dce0e7',
  ghostBtnDisabledBgColor: '#e7eaf0',
  ghostBtnDisabledTextColor: '#c9ccd4',

  TagPrimaryTextColor: baseColors.primaryColor,
  TagSecondaryTextColor: baseColors.secondaryTextColor,

  TagSelectBackGroundColor: baseColors.secondaryColor,
  TagSelectBackGroundActiveColor: baseColors.primaryColor,
  TagSelectTextColor: baseColors.primaryColor,
  TagSelectTextActiveColor: baseColors.secondaryTextColor,
  TagSelectCaptionColor: '#9da0aa',

  TextInputTextColor: baseColors.primaryTextColor,
  TextInputPlaceHolderColor: '#9da0aa',
  TextInputCaptionColor: '#4f4f4f',
  TextInputCaptionActiveColor: '#9da0aa',
  TextInputCaptionAltColor: '#9da0aa',
  TextInputDisabledTextColor: '#808080',
  TextInputIconColor: baseColors.primaryColor,
  TextInputBorderActiveColor: baseColors.primaryColor,
  TextInputErrorTextColor: baseColors.errorTextColor,

  MonthsSelectorTextColor: '#4f4f4f',
  MonthsSelectorTextHoverColor: baseColors.primaryTextColor,
  MonthsSelectorTextActiveColor: baseColors.primaryTextColor,
  MonthsSelectorActiveBorderColor: baseColors.primaryColor,

  switchBgColor: baseColors.secondaryInputColor,
  switchHeadBgColor: '#a7a8ab',
  switchActiveBgColor: 'rgba(72, 83, 236, 0.1)',
  switchActiveHeadBgColor: baseColors.primaryColor,
  switchBorderColor: 'transparent',
  switchActiveBorderColor: 'transparent',

  radioGroupItemBorderColor: '#a7a8ab',
  radioGroupItemActiveBorderColor: baseColors.primaryColor,
  radioGroupItemActiveBgColor: baseColors.primaryColor,
  radioGroupItemActivePointerColor: baseColors.secondaryInputColor,
  radioGroupItemTitleColor: baseColors.primaryTextColor,

  checkboxActiveBgColor: baseColors.primaryColor,
  checkboxErrBorderColor: baseColors.errorTextColor,

  dropDownMenuBgColor: '#ffffff',
  dropDownMenuTextColor: baseColors.primaryTextColor,
  dropDownMenuCaptionColor: '#9da0aa',
  dropDownMenuActiveBgColor: baseColors.secondaryColor,
  dropDownMenuActiveTextColor: baseColors.primaryColor,

  registerFormTermsLinkColor: baseColors.primaryColor,
  registerFormTermsColor: '#9da0aa',

  registerSelectErrorColor: baseColors.errorTextColor,
  registerSelectBorderActiveColor: baseColors.primaryColor,
  registerSelectMenuBgColor: '#ffffff',
  registerSelectMenuTextColor: baseColors.primaryTextColor,
  registerSelectActiveTextColor: baseColors.primaryColor,
  registerSelectMenuBgActiveColor: baseColors.secondaryColor,
  registerSelectMenuCaptionColor: '#9da0aa',

  formErrorTextColor: baseColors.errorTextColor,
};
