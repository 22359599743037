import React from 'react';
import styled from 'styled-components';

import { Tag } from 'components/atoms';
import { CloseIcon, Star, Tick as TickIcon } from 'assets/icons';
import { IUserEventInfo } from 'core';

import { CertificateButton } from './CertificateButton';

interface IUserHistoryItemProps {
  event: IUserEventInfo;
}

export const UserHistoryItem = ({ event }: IUserHistoryItemProps) => {
  const [passedControls, totalControls] = event.checkpoints.split('/');
  const isPassedControls = passedControls === totalControls && passedControls !== '0';

  const marks = event.marks.find((mark) => mark.author === null);

  return (
    <UserHistoryItemWrapper>
      <LeftSideContainer>
        <EventTags>
          {event.userCode && (
            <Tag colorType={8} size="small">
              {event.userCode}
            </Tag>
          )}
          {event.tags.map(({ name }) => (
            <Tag key={name} colorType={5} size="small">
              {name}
            </Tag>
          ))}
        </EventTags>
        <EventName>{event.title}</EventName>
        {event.isCertificate && <CertificateButton eventId={event.id} />}
      </LeftSideContainer>
      <RightSideContainer>
        <StarRatingWrapper>
          <StarsWrapper>
            {Array.from({ length: 5 }).map((_, index) => {
              const ratingIndex = index + 1;
              return <StarStyled key={ratingIndex} $isFilled={index < (marks?.mark || 0)} />;
            })}
          </StarsWrapper>
        </StarRatingWrapper>
        <AverageRatingStyled>
          Ср.оценка мероприятия - <span>{event.averageMark}</span>
        </AverageRatingStyled>
        <ControlText>Контроль</ControlText>
        <ControlInfo>
          <ControlQuantity>{event.checkpoints}</ControlQuantity>
          {isPassedControls ? <TickIcon /> : <CloseIconStyled />}
        </ControlInfo>
      </RightSideContainer>
      {event.isCertificate && <CertificateButton eventId={event.id} />}
    </UserHistoryItemWrapper>
  );
};

const StarRatingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const StarsWrapper = styled.div`
  display: flex;
`;

const StarStyled = styled(Star)<{ $isFilled: boolean }>`
  width: 24px;
  height: 24px;

  & > path {
    fill-opacity: ${({ $isFilled }) => ($isFilled ? '1' : '0.24')};
  }

  &:not(:first-of-type) {
    margin-left: 4px;
  }
`;

const AverageRatingStyled = styled.div`
  font-style: italic;
  margin-bottom: 12px;
  font-size: 13px;

  & > span {
    font-weight: 600;
  }
`;

const LeftSideContainer = styled.div`
  @media (max-width: 767px) {
    & > button {
      display: none;
    }
  }

  @media (min-width: 768px) {
    width: 63%;
  }

  @media (min-width: 992px) {
    width: 74%;
  }
`;

const RightSideContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ControlText = styled.span`
  color: #a7a8ab;
  font-family: Raleway;
  font-size: 14px;
  font-style: italic;
  line-height: 24px;
`;

const ControlInfo = styled.div`
  display: flex;
  align-items: center;
`;

const ControlQuantity = styled.span`
  color: #000000;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

const UserHistoryItemWrapper = styled.div`
  border-radius: 12px;
  border: 1px solid #eee;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  @media (min-width: 768px) {
    padding: 40px;
    flex-direction: row;

    & > button {
      display: none;
    }
  }
`;

const EventTags = styled.div`
  display: none;
  margin-bottom: 16px;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    display: flex;
  }
`;

const EventName = styled.div`
  color: #000000;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 24px;

  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 28px;
    margin-bott0m: 0px;
  }
`;

const CloseIconStyled = styled(CloseIcon)`
  width: 24px;
  height: 12px;

  & > path {
    fill: #eb5757;
  }
`;
