import { IState } from 'core/types';

// Publications
export const getCurrentPublicationsCategoryFromStore = ({ publications: { currentCategoryId } }: IState) =>
  currentCategoryId;
export const getPublicationsCategoriesFromStore = ({ publications: { categories } }: IState) => categories;
export const getPublicationsCategoriesLoadingFromStore = ({ publications: { categoriesLoading } }: IState) =>
  categoriesLoading;
export const getPublicationsCategoriesErrorFromStore = ({ publications: { categoriesError } }: IState) =>
  categoriesError;

export const getPublications = ({ publications }: IState) => ({
  publications: publications.publications,
  total: publications.total,
});
export const getPublicationsPage = ({ publications }: IState) => publications.page;
export const getIsPublicationsLoading = ({ publications }: IState) => publications.publicationsLoading;
export const getPublicationsError = ({ publications }: IState) => publications.publicationsError;

export const getLastPublicationsSearch = ({ publications }: IState) => publications.lastSearch;

// Publication
export const getPublication = ({ publication: { publication } }: IState) => publication;
export const getIsPublicationLoading = ({ publication: { isPublicationLoading } }: IState) => isPublicationLoading;
