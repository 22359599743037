import { SagaIterator } from 'redux-saga';
import { takeEvery, select, call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { format } from 'date-fns';
import { reset } from 'redux-form';

import {
  registerRequestAction,
  loginRequstAction,
  loginSuccessAction,
  loginFailureAction,
  logOutAction,
  logOutSuccessAction,
  logOutFailureAction,
  changeLoginPopupVisibleAction,
  forgotPasswordRequestAction,
  changeSentMessagePopupVisibleAction,
  createPasswordRequestAction,
  registerSuccessAction,
  registerFailureAction,
  forgotPasswordSuccessAction,
  forgotPasswordFailureAction,
  createPasswordSuccessAction,
  createPasswordFailureAction,
  changeCreatePasswordPopupVisibleAction,
  putAuthServerErrorToStoreAction,
  clearAuthServerErrorToStoreAction,
  clearRegisterToStoreAction,
  clearCreatePasswordToStoreAction,
  clearForgotPasswordToStoreAction,
  getCodeRequestAction,
  activatePhoneRequestAction,
  getVerificationCodeRequestAction,
  putCodeErrorToStoreAction,
  checkEmailRequestAction,
  putEmailExistsAction,
  putUserDataToStoreAction,
  userDataFailureAction,
  userDataSuccessAction,
} from 'core/actions';
import { getCreatePasswordToken, getPhone, getRegister } from 'core/selectors';
import { AuthService } from 'services';
import { IApiRegisterValues } from 'types';

export function* loginSaga({ payload: { code, phone, email } }: ActionType<typeof loginRequstAction>): SagaIterator {
  try {
    yield put(clearAuthServerErrorToStoreAction());

    const {
      data: { access, refresh },
    } = yield call(() => AuthService.logIn(code, phone, email));

    localStorage.setItem('access_token', access);
    localStorage.setItem('refresh_token', refresh);

    yield put(loginSuccessAction());

    const userData = yield call(() => AuthService.getUser());
    yield put(putUserDataToStoreAction(userData));
    yield put(userDataSuccessAction());

    yield put(changeLoginPopupVisibleAction(false));
    yield put(reset('LogInForm'));
  } catch (error) {
    if ((error as any).response?.data?.detail?.includes('No active account found with the given credentials')) {
      yield put(putAuthServerErrorToStoreAction('Неправильный Email или пароль'));
    }

    if ((error as any).response?.data?.details?.includes('Неверный код. Введите код из смс-сообщения')) {
      yield put(putCodeErrorToStoreAction('Неверный код. Введите код из смс-сообщения'));
    }
    yield put(userDataFailureAction());

    yield put(loginFailureAction());
  }
}

export function* logOutSaga(): SagaIterator {
  try {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');

    yield put(logOutSuccessAction());
  } catch (error) {
    yield put(logOutFailureAction());
  }
}

export function* registerSaga(): SagaIterator {
  try {
    yield put(clearAuthServerErrorToStoreAction());
    const values = yield select(getRegister);
    const phone = yield select(getPhone);
    const birthdayDateArr = values.firstStep.birthdayDate.split('.');
    const data: IApiRegisterValues = {
      email: values.firstStep.email,
      full_name: values.firstStep.name,
      country: values.firstStep.country,
      region: values.firstStep.region,
      city: values.firstStep.city,
      specialty: values.secondStep.specify, // Специальность
      additional_specialty: '', // Просто поле не для чего
      phone_number: phone,
      gender: values.firstStep.sex,
      date_of_birth: format(
        new Date(Number(birthdayDateArr[2]), Number(birthdayDateArr[1] - 1), Number(birthdayDateArr[0])),
        'yyyy-MM-dd',
      ),
      working_place: values.secondStep.workStudyPlace,
      position: values.secondStep.position, // Должность
      academic_degree: values.secondStep.degree, // Степень
    };
    const {
      data: { access, refresh },
    } = yield call(() => AuthService.register(data));
    yield put(registerSuccessAction());
    yield put(changeSentMessagePopupVisibleAction(true));
    yield put(clearRegisterToStoreAction());
    localStorage.setItem('access_token', access);
    localStorage.setItem('refresh_token', refresh);

    const userData = yield call(() => AuthService.getUser());
    yield put(putUserDataToStoreAction(userData));
    yield put(userDataSuccessAction());

    yield put(loginSuccessAction());
    yield put(changeLoginPopupVisibleAction(false));
  } catch (error) {
    if ((error as any).response?.data?.details?.includes('The email already exists.')) {
      yield put(putAuthServerErrorToStoreAction('Пользователь с такой почтой уже существует'));
    }

    if ((error as any).response?.data?.details?.includes('Неверный код. Введите код из смс-сообщения')) {
      yield put(putCodeErrorToStoreAction('Неверный код. Введите код из смс-сообщения'));
    }

    yield put(registerFailureAction());
  }
}

export function* forgotPasswordSaga({
  payload: { email },
}: ActionType<typeof forgotPasswordRequestAction>): SagaIterator {
  try {
    yield put(clearAuthServerErrorToStoreAction());
    yield call(() => AuthService.forgotPassword(email));

    yield put(forgotPasswordSuccessAction());
    yield put(changeSentMessagePopupVisibleAction(true));
    yield put(clearForgotPasswordToStoreAction());
  } catch (error) {
    if ((error as any).response?.data?.details?.includes('The email is not registered. Please try again.')) {
      yield put(putAuthServerErrorToStoreAction('Такого email не существует, пройдите регистрацию'));
    }

    yield put(forgotPasswordFailureAction());
  }
}

export function* createPasswordSaga({
  payload: { password },
}: ActionType<typeof createPasswordRequestAction>): SagaIterator {
  try {
    const token = yield select(getCreatePasswordToken);

    if (!token) throw new Error('Token is not exist');

    yield call(() => AuthService.createPassword(token, password));
    yield put(createPasswordSuccessAction());
    yield put(changeCreatePasswordPopupVisibleAction(false));
    yield put(clearCreatePasswordToStoreAction());
  } catch (error) {
    yield put(createPasswordFailureAction());
  }
}

export function* getCodeSaga({ payload }: ActionType<typeof getCodeRequestAction>): SagaIterator {
  try {
    yield call(() => AuthService.getCode(payload));
  } catch (error) {
    if ((error as any).response?.data?.details?.includes('Phone number is already confirmed')) {
      yield put(putCodeErrorToStoreAction('Номер телефона уже используется, введите другой'));
    }
  }
}

export function* checkEmailSaga({ payload }: ActionType<typeof checkEmailRequestAction>): SagaIterator {
  try {
    const { exists } = yield call(() => AuthService.checkEmail(payload));
    yield put(putEmailExistsAction(exists));
  } catch (error) {
    // yield put(createPasswordFailureAction());
  }
}

export function* getVerificationCodeSaga({
  payload: { email, phone },
}: ActionType<typeof getVerificationCodeRequestAction>): SagaIterator {
  try {
    const { exists } = yield call(() => AuthService.checkEmail(email));
    yield put(putEmailExistsAction(exists));

    if (exists) {
      yield call(() => AuthService.getVerificationCode(phone));
    }
  } catch (error) {
    // yield put(createPasswordFailureAction());
  }
}

export function* activatePhoneSaga({
  payload: { code, phone },
}: ActionType<typeof activatePhoneRequestAction>): SagaIterator {
  try {
    const { confirmed } = yield call(() => AuthService.activatePhone(code, phone));

    if (confirmed) {
      yield put(registerRequestAction());
    }
  } catch (error) {
    if ((error as any).response?.data?.details?.includes('Неверный код. Введите код из смс-сообщения')) {
      yield put(putCodeErrorToStoreAction('Неверный код. Введите код из смс-сообщения'));
    }
  }
}

export function* authRootSaga(): SagaIterator {
  yield takeEvery(registerRequestAction, registerSaga);
  yield takeEvery(loginRequstAction, loginSaga);
  yield takeEvery(logOutAction, logOutSaga);
  yield takeEvery(forgotPasswordRequestAction, forgotPasswordSaga);
  yield takeEvery(createPasswordRequestAction, createPasswordSaga);
  yield takeEvery(getCodeRequestAction, getCodeSaga);
  yield takeEvery(getVerificationCodeRequestAction, getVerificationCodeSaga);
  yield takeEvery(checkEmailRequestAction, checkEmailSaga);
  yield takeEvery(activatePhoneRequestAction, activatePhoneSaga);
}
