import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { getArea, getAreaCalendarEvents } from 'core/selectors';
import { getAreaEventsRequestAction, putCurrentAreaEventsMonthToStoreAction } from 'core/actions';

import { Calendar } from './Calendar';

export const Container = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentMonthIndex, items } = useSelector(getAreaCalendarEvents);
  const { color } = useSelector(getArea);
  const { area } = useParams<{ area: string }>();

  const monthChangeHandler = (apiIndex: number) => {
    dispatch(putCurrentAreaEventsMonthToStoreAction(apiIndex));
  };

  const itemClickHandler = (url: string = '') => {
    history.push(`/area/${area}${url}`);
  };

  const seeAllBtnHandler = () => {
    history.push(`/area/${area}/catalog/all`);
  };

  useEffect(() => {
    dispatch(getAreaEventsRequestAction({ monthIndex: currentMonthIndex, alias: area }));
  }, [dispatch, currentMonthIndex, area]);

  return (
    <Calendar
      events={items}
      currentMonthIndex={currentMonthIndex}
      monthChangeHandler={monthChangeHandler}
      itemClickHandler={itemClickHandler}
      seeAllBtnHandler={seeAllBtnHandler}
      color={color || undefined}
    />
  );
};
