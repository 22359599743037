import React from 'react';
import { useSelector } from 'react-redux';

import { getEvent } from 'core/selectors';

import { Partners } from './Partners';

export const Container = () => {
  const { partners } = useSelector(getEvent);

  if (!partners.length) {
    return null;
  }

  return <Partners data={partners} title="Партнеры и виртуальная экспозиция" isItemModalNeed />;
};
