import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Modal, Row, Spin } from 'antd';
import ReactHtmlParser from 'react-html-parser';

import { IPartner, IPartnerExtended } from 'types';
import { eventsService } from 'services';
import { convertPartnerExtendedToAppSchema } from 'services/apiMappers';

declare module 'html-react-parser';

interface IProps {
  partner: null | IPartner;
  onClose: () => void;
}

export const PartnerModal = ({ partner, onClose }: IProps) => {
  const [partnerExtended, setPartnerExtended] = useState<null | IPartnerExtended>(null);

  useEffect(() => {
    if (partner) {
      setPartnerExtended(null);
      eventsService
        .getEventPartner(partner.id)
        .then((apiPartner) => setPartnerExtended(convertPartnerExtendedToAppSchema(apiPartner)))
        .catch(() => setPartnerExtended(null));
    }
  }, [partner]);

  return (
    <ModalContainer
      visible={Boolean(partner)}
      title="Партнер"
      onCancel={onClose}
      footer={null}
      width={1172}
      destroyOnClose
    >
      {partnerExtended ? (
        <>
          <Row gutter={[8, 8]}>
            <Col xs={24} md={8}>
              <ItemContainer>
                <img
                  src={partnerExtended.thumbnailUrl}
                  alt={partnerExtended.name}
                  style={{ filter: ' grayscale(0%)' }}
                />
              </ItemContainer>
            </Col>
          </Row>

          {partnerExtended.description && <Discription>{ReactHtmlParser(partnerExtended.description)}</Discription>}

          {partnerExtended.contacts && (
            <Contacts>{ReactHtmlParser(partnerExtended.contacts.replace(/\r\n/g, ' <br> '))}</Contacts>
          )}
        </>
      ) : (
        <Spin style={{ width: '100%', padding: '50px 0' }} size="large" />
      )}
    </ModalContainer>
  );
};

const ItemContainer = styled.div`
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  height: 240px;
  text-align: center;
  line-height: 230px;

  img {
    max-width: 50%;
    filter: grayscale(100%);
    transition: all 0.5s ease;
  }

  &:hover {
    img {
      filter: grayscale(0%);
    }
  }
`;

const ModalContainer = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
    overflow: hidden;

    .ant-modal-close {
      top: 24px;
      right: 32px;

      .ant-modal-close-x {
        font-size: 20px;
        width: 26px;
        height: 26px;
        line-height: 26px;
      }

      @media (min-width: 768px) {
        top: 32px;
        right: 40px;

        .ant-modal-close-x {
          font-size: 25px;
          width: 36px;
          height: 36px;
          line-height: 36px;
        }
      }

      @media (min-width: 1200px) {
        top: 64px;
        right: 70px;

        .ant-modal-close-x {
          font-size: 30px;
          width: 56px;
          height: 56px;
          line-height: 56px;
        }
      }
    }

    .ant-modal-header {
      border: none;
      padding: 0;

      .ant-modal-title {
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 18px;
        line-height: 28px;
        color: #000000;
        display: none;
      }

      @media (min-width: 768px) {
        .ant-modal-title {
          font-size: 22px;
          line-height: 40px;
        }
      }

      @media (min-width: 1200px) {
        .ant-modal-title {
          font-size: 32px;
          line-height: 48px;
        }
      }
    }

    .ant-modal-body {
      padding: 20px;

      @media (min-width: 768px) {
        padding: 30px;
      }

      @media (min-width: 1200px) {
        padding: 64px 80px 64px 80px;
      }
    }
  }
`;

const Discription = styled.div`
  padding: 50px 0 0 0;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #000000;

  p {
    display: flex;
    flex-direction: column;

    br {
      display: none;
    }
  }

  img {
    width: 100% !important;
  }

  iframe {
    width: 100%;
    height: 287px;
    margin-bottom: 20px;

    @media (min-width: 576px) {
      height: 395px;
    }

    @media (min-width: 768px) {
      height: 611px;
    }

    @media (min-width: 1200px) {
      height: 579px;
    }
  }
`;

const Contacts = styled.div`
  padding: 50px 0 0 0;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #000000;
`;
