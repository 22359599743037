import React, { memo } from 'react';
import styled, { css, CSSProperties } from 'styled-components';
import { Row, Col, Select as AntdSelect } from 'antd';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';

import { Select, Button } from 'atoms';
import { CatalogSearchASC, IEventListItem } from 'types';
import { Event } from 'organisms';

interface IProps {
  result: IEventListItem[];
  total: number;
  searchASC?: CatalogSearchASC;
  loading: boolean;
  screen: Partial<Record<Breakpoint, boolean>>;
  searchAscOnChange: (searchASC: CatalogSearchASC) => void;
  loadMoreOnClick: () => void;
}

export const Results = memo(
  ({ total, searchASC, result, loading, screen, searchAscOnChange, loadMoreOnClick }: IProps) => {
    return (
      <Wrapper isLoading={loading}>
        <Row justify="space-between">
          <Col xs={24} md={16}>
            <StyledGrey>Сначала показывать:</StyledGrey>
            <Select onChange={searchAscOnChange} value={searchASC}>
              <AntdSelect.Option style={SelectOptionStyles} value={CatalogSearchASC.start_date}>
                {CatalogSearchASC.start_date}
              </AntdSelect.Option>
              <AntdSelect.Option style={SelectOptionStyles} value={CatalogSearchASC.created}>
                {CatalogSearchASC.created}
              </AntdSelect.Option>
              <AntdSelect.Option style={SelectOptionStyles} value={CatalogSearchASC.subscriptions}>
                {CatalogSearchASC.subscriptions}
              </AntdSelect.Option>
              <AntdSelect.Option style={SelectOptionStylesLast} value={CatalogSearchASC.viewings}>
                {CatalogSearchASC.viewings}
              </AntdSelect.Option>
            </Select>
          </Col>
          <Col xs={0} md={8}>
            <AlignRight>
              <StyledGrey>Найдено:</StyledGrey> <StyledTotal>{total}</StyledTotal>
            </AlignRight>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {result.length === 0 && <span>Нет результатов</span>}
            {result.map((item) => (
              <Event screen={screen} key={item.id} event={item} />
            ))}
          </Col>
        </Row>
        {total > result.length && (
          <Row justify="center">
            <Col>
              <ButtonStyled onClick={loadMoreOnClick}>Загрузить еще</ButtonStyled>
            </Col>
          </Row>
        )}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div<{ isLoading: boolean }>`
  ${({ isLoading }) => css`
    margin: 0;
    transition: all 0.2s;
    ${isLoading && WrapperLoadingStyles}
  `}
`;

const WrapperLoadingStyles = css`
  opacity: 0.5;
  pointer-events: none;
`;

const StyledGrey = styled.span`
  color: #9da0aa;
`;

const StyledTotal = styled.span`
  color: #000000;
  font-weight: 600;
`;

const AlignRight = styled.div`
  text-align: right;
`;

const ButtonStyled = styled(Button)`
  margin-top: 50px;
  margin-bottom: 40px;
  box-shadow: none;

  &.ant-btn-primary {
    border: 2px solid #f8f8f8;
    background-color: #e6eaf1;
    color: #9da0aa;
    box-shadow: none;

    &:focus {
      border: 2px solid #f8f8f8;
      background-color: #e6eaf1;
    }

    &:hover {
      border: 2px solid #f8f8f8;
      background-color: #e6eaf1;
      color: #9da0aa;
      box-shadow: none;
    }
  }
`;

const SelectOptionStyles: CSSProperties = {
  borderBottom: '1px solid #EBEFF2',
  fontFamily: 'Open Sans, sans-serif',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '20px',
  padding: '7px 16px',
};

const SelectOptionStylesLast: CSSProperties = {
  ...SelectOptionStyles,
  borderBottom: 'none',
};
