import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { differenceInMilliseconds } from 'date-fns';
import styled from 'styled-components';

import { getEvent, getIsLoggedIn } from 'core/selectors';
import { useEventSource } from 'hooks';
import { Button as ButtonDefault } from 'atoms';
import { LogoWhite } from 'assets/icons';
import { eventsService, soundsService } from 'services';
import { getIsTodayDate, getTodayDate, glueDate } from 'helpers';

import { checkFullScreen, closeFullScreen } from './utils';

interface IMessage {
  id: number;
  created: string;
}

export const ActivityControl = () => {
  const dispatch = useDispatch();
  const { startDate, startTime, id, isViewed } = useSelector(getEvent);
  const isLoggedIn = useSelector(getIsLoggedIn);

  const { lastMessage } = useEventSource(id ? eventsService.getActivityControlUrl(id) : '', {
    isNeedToConnect: (startDate ? getIsTodayDate(glueDate(startDate, startTime)) : false) && isLoggedIn && isViewed,
  });

  const lastMessageParsed: IMessage | null = useMemo(
    () => (lastMessage ? JSON.parse(lastMessage) : null),
    [lastMessage],
  );

  const [isOpen, setIsOpen] = useState(false);

  const confirmActivity = useCallback(() => {
    if (lastMessageParsed) {
      eventsService.confirmActivity(lastMessageParsed.id).then(() => {
        setIsOpen(false);
      });
    }
  }, [lastMessageParsed]);

  useEffect(() => {
    if (!isViewed) {
      return undefined;
    }

    if (lastMessageParsed && isActualMessage(lastMessageParsed.created)) {
      eventsService.getActivityStatus(lastMessageParsed.id).then(({ is_approved }) => {
        if (!is_approved) {
          setIsOpen(true);
          soundsService.playNotificationSound();
          const timeout = setTimeout(() => {
            setIsOpen(false);
          }, getCloseTime(lastMessageParsed.created));

          return () => {
            clearTimeout(timeout);
          };
        }

        return undefined;
      });
    }

    return undefined;
  }, [dispatch, lastMessageParsed, isViewed]);

  useEffect(() => {
    const isFullscreen = checkFullScreen();
    if (isViewed && isOpen && isFullscreen) {
      closeFullScreen();
    }
  }, [isOpen, isViewed]);

  if (!isViewed) {
    return null;
  }

  if (!isOpen) {
    return null;
  }

  return (
    <Container>
      <Content>
        <Logo />
        <Text>Для просмотра видео трансляции нажмите кнопку ниже</Text>
        <ConfirmButton onClick={confirmActivity}>Подтвердить присутствие</ConfirmButton>
      </Content>
    </Container>
  );
};

const CONTROL_VIEW_TIME = 300000;

const isActualMessage = (serverDate: string) => {
  const diff = differenceInMilliseconds(getTodayDate(), new Date(serverDate.replace(/-/g, '/')));

  return diff <= CONTROL_VIEW_TIME;
};

const getCloseTime = (serverDate: string) => {
  const diff = differenceInMilliseconds(getTodayDate(), new Date(serverDate.replace(/-/g, '/')));

  return CONTROL_VIEW_TIME - diff < 0 ? 0 : CONTROL_VIEW_TIME - diff;
};

const Container = styled.div`
  border-radius: 12px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(18, 26, 123, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  flex: 0 1 auto;

  display: flex;
  flex-direction: column;
`;

const Logo = styled(LogoWhite)`
  display: none;

  @media (min-width: 768px) {
    display: block;
    margin: 0 auto;
  }
`;

const Text = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;

  margin: 0 auto;
  width: 80%;

  span {
    font-weight: 700;
  }

  @media (min-width: 768px) {
    width: 50%;
    margin: 32px auto 0 auto;
  }
`;

const ConfirmButton = styled(ButtonDefault)`
  border: 2px solid #ffffff;
  background-color: transparent;
  font-size: 14px;
  line-height: 24px;

  margin: 0 auto;

  @media (min-width: 768px) {
    font-size: 16px;
    margin: 56px auto 0 auto;
  }
`;
