import { SagaIterator } from 'redux-saga';
import { takeEvery, call, put, select } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { AuthService, eventsService } from 'services';
import {
  getUserEventsRequestAction,
  getUserEventsSuccessAction,
  getUserEventsFailureAction,
  putUserEventsToStoreAction,
  getUserInfoRequestAction,
  putPersonalUserDataToStore,
  getUserInfoSuccessAction,
  getUserInfoFailureAction,
  saveUserInfoRequestAction,
  saveUserInfoSuccessAction,
  saveUserInfoFailureAction,
  getEventCertificateRequestAction,
  getEventCertificateFailureAction,
  getEventCertificateSuccessAction,
  putUserDataToStoreAction,
} from 'core/actions';
import { CatalogSearchASC, IApiUser } from 'types';
import { convertAppUserDataToDto, convertEventsToAppSchema, convertUserDataToAppSchema } from 'services/apiMappers';
import { getUserPersonalData } from 'core/selectors';

export function* getUserEventsSaga({ payload }: ActionType<typeof getUserEventsRequestAction>): SagaIterator {
  try {
    const { results, total } = yield call(() =>
      eventsService.getEvents({
        searchASC: CatalogSearchASC.end_date,
        userEvents: true,
        offset: payload.offset,
        limit: 10,
      }),
    );
    const userEvents = convertEventsToAppSchema(results);

    yield put(getUserEventsSuccessAction());
    yield put(putUserEventsToStoreAction({ events: userEvents, total }));
  } catch (error) {
    yield put(getUserEventsFailureAction());
  }
}

export function* getPersonalUserInfoSaga(): SagaIterator {
  try {
    const userData: IApiUser = yield call(() => AuthService.getUser());
    const userConverted = convertUserDataToAppSchema(userData);
    yield put(putPersonalUserDataToStore(userConverted));
    yield put(getUserInfoSuccessAction());
  } catch (error) {
    yield put(getUserInfoFailureAction());
  }
}

export function* savePersonalUserInfoSaga(): SagaIterator {
  try {
    const { personalData } = yield select(getUserPersonalData);
    const userDataDto: IApiUser = convertAppUserDataToDto(personalData);
    const userData: IApiUser = yield call(() => AuthService.updateUser(personalData.id, userDataDto));

    yield put(putUserDataToStoreAction(userData));

    const userConverted = convertUserDataToAppSchema(userData);
    yield put(putPersonalUserDataToStore(userConverted));
    yield put(saveUserInfoSuccessAction());
  } catch (error) {
    yield put(saveUserInfoFailureAction());
  }
}

export function* getEventCertificateSaga({
  payload: eventId,
}: ActionType<typeof getEventCertificateRequestAction>): SagaIterator {
  try {
    yield call(() => eventsService.getEventCertificate(eventId));

    yield put(getEventCertificateSuccessAction());
  } catch (error) {
    yield put(getEventCertificateFailureAction());
  }
}

export function* personalAreaRootSaga(): SagaIterator {
  yield takeEvery(getUserEventsRequestAction, getUserEventsSaga);
  yield takeEvery(getUserInfoRequestAction, getPersonalUserInfoSaga);
  yield takeEvery(saveUserInfoRequestAction, savePersonalUserInfoSaga);
  yield takeEvery(getEventCertificateRequestAction, getEventCertificateSaga);
}
