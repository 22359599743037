import React from 'react';
import styled from 'styled-components';

import { AccessDenied } from 'assets/icons';

export const AccessDeniedStub = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <AccessDeniedImage>
          <AccessDenied />
        </AccessDeniedImage>
        <AccessDeniedTitle>Доступ запрещён!</AccessDeniedTitle>
        <AccessDeniedText>
          Данный сайт предназначен только для действующих специалистов в области здравохранения.
        </AccessDeniedText>
      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 260px;

  @media (min-width: 768px) {
    max-width: 531px;
  }
`;

const AccessDeniedImage = styled.div`
  width: 88px;
  height: 88px;

  & svg {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 768px) {
    width: 188px;
    height: 188px;
  }
`;

const AccessDeniedTitle = styled.div`
  color: #000000;
  font-family: Raleway;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  margin-top: 40px;

  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 40px;
    margin-top: 48px;
  }

  @media (min-width: 1200px) {
    color: #231f20;
    font-weight: 900;
    font-size: 40px;
    line-height: 56px;
  }
`;

const AccessDeniedText = styled.div`
  color: #000;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 24px;
  margin-top: 16px;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;
  }

  @media (min-width: 1200px) {
    margin-top: 16px;
  }
`;
