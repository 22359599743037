import React from 'react';
import { formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { Row } from 'antd';
import styled, { css } from 'styled-components';
import { connect, useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-number-input';
import { RadioChangeEvent } from 'antd/lib/radio';

import { IFormErrors, IRegisterFormThirdStepValues, RegisterSteps } from 'types';
import { validationService } from 'services';
import { getCodeRequestAction, putIsPersonalDataApproveErrorAction, putIsPersonalDataApproveValueAction, putRegisterStepToStore, setPhoneAction } from 'core/actions';
import { store } from 'core';
import { IState } from 'core/types/reduxTypes';
import { getIsRegisterLoading, getPhone, getRegister } from 'core/selectors';
import { Button, Checkbox, FormServerError } from 'components/atoms';

export const Form = ({
  handleStepDown,
}: InjectedFormProps<
  IRegisterFormThirdStepValues,
  { handleStepDown?: () => void; country?: string; region?: string }
> & {
  handleStepDown?: () => void;
}) => {
  const dispatch = useDispatch();
  const phone = useSelector(getPhone);
  const register = useSelector(getRegister);
  const isRegisterLoading = useSelector(getIsRegisterLoading);

  const onChangePhone = (value: string) => {
      dispatch(setPhoneAction(value));
  }

  const TermsCheckboxChangeHandler = (e: RadioChangeEvent) => {
    dispatch(putIsPersonalDataApproveValueAction(e.target.checked));
  };

  const onThirdStepFormSubmit = () => {
    if (store.getState().auth.register.isPersonalDataApprove.value && phone) {
      store.dispatch(putRegisterStepToStore(RegisterSteps.Fourth));
      store.dispatch(getCodeRequestAction(phone));
      // store.dispatch(registerRequestAction());
      return;
    }
  
    store.dispatch(putIsPersonalDataApproveErrorAction(true));
  };

  return (
    <form>
      <FormContent>
        <Phone
          international
          defaultCountry="RU"
          value={phone}
          onChange={onChangePhone}/>
      </FormContent>
      <>
          <Terms>
            <TermsCheckbox
              onChange={TermsCheckboxChangeHandler}
              isError={register.isPersonalDataApprove.isError}
              checked={register.isPersonalDataApprove.value}
            />
            <TermsContent>
              Я даю согласие на обработку моих{' '}
              <a
                href="https://drive.google.com/file/d/1jALGESCWl04bUyUvU_2ZgAfthGWPp309/view?usp=sharing"
                target="_blank"
                rel="noreferrer"
              >
                персональных данных
              </a>{' '}
              и соглашаетесь c{' '}
              <a
                target="_blank"
                href="https://drive.google.com/file/d/1CGjp8LKF3nVqOKgKedE4h_mVamjmfpWy/view?usp=sharing"
                rel="noreferrer"
              >
                политикой конфиденциальности.
              </a>
            </TermsContent>
          </Terms>
          <ButtonWrapper>
              <ButtonBackRestyled type="default" onClick={handleStepDown}>
                Вернуться назад
              </ButtonBackRestyled>
              <ButtonNextRestyled type="primary" onClick={onThirdStepFormSubmit} loading={isRegisterLoading}>
                Получить код
              </ButtonNextRestyled>
          </ButtonWrapper>
        <SupportInfo>
          Служба технической поддержки +7 985 143-77-51
        </SupportInfo>
      <Row>
        <FormServerError />
      </Row>
    </>
    </form>
  );
};

const validateThirdStep = ({ country, city }: IRegisterFormThirdStepValues) => {
  return {
    country: validationService.validateRequired(country),
    city: validationService.validateRequired(city),
    region: '',
  } as IFormErrors;
};

const selector = formValueSelector('RegisterFormThirdStep');
const mapStateToProps = (state: IState) => ({
  initialValues: state.auth.register.thirdStep,
  country: selector(state, 'country'),
  region: selector(state, 'region'),
});

export const ThirdStepForm = connect(mapStateToProps)(
  reduxForm<IRegisterFormThirdStepValues, { handleStepDown?: () => void }>({
    form: 'RegisterFormThirdStep',
    validate: validateThirdStep,
    onSubmit: () => {},
    enableReinitialize: true,
    touchOnBlur: false,
  })(Form),
);

const FormContent = styled.div`
  margin-bottom: 2px;
  padding-top: 10px;

  @media (min-width: 768px) {
    margin-bottom: 45px;
  }
`;

const Phone = styled(PhoneInput)`
  display: flex;
  margin: 0 auto 32px;

  & .PhoneInputInput {
    padding-bottom: 8px;
    border: none;
    border-bottom: 1px solid #DCDCDD;
    outline: none;
  }
`;

const Terms = styled.div`
  ${({ theme: { registerFormTermsLinkColor, registerFormTermsColor } }) => css`
    max-width: 484px;
    font-family: 'Open Sans', sans-serif;
    color: ${registerFormTermsColor};
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin: 0 auto 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      color: ${registerFormTermsLinkColor};
      text-decoration: underline;
    }

    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 18px;
    }
  `}
`;

const ButtonBackRestyled = styled(Button)`
  width: 100%;
  margin-right: 16px;

  @media (min-width: 576px) {
    width: auto;
  }
`;

const ButtonNextRestyled = styled(ButtonBackRestyled)`
  margin-bottom: 0px;
  margin-right: 0px;

  @media (min-width: 576px) {
    margin-bottom: 0px;
  }
`;

const TermsCheckbox = styled(Checkbox)`
  float: left;
  margin: 6px 0 0 0;
`;

const TermsContent = styled.div`
  /* padding: 0 0 0 40px; */
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
`;

const SupportInfo = styled.p`
  text-align: center;
  color: #9DA0AA;
`;