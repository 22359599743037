import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Menu } from 'antd';

import { IAreaShortened } from 'types';
import { DownIcon } from 'assets/icons';
import { getIsLoggedIn } from 'core/selectors';

interface INavMenuProps {
  linkOnClick(): void;
  area?: string;
  catalogCategoriesItems: IAreaShortened[];
}

export const NavMenu = ({ linkOnClick, area, catalogCategoriesItems }: INavMenuProps) => {
  const [isCatalogMenuList, setCatalogMenuList] = useState<boolean>(false);
  const isLoggedIn = useSelector(getIsLoggedIn);

  const toggleCatalogMenuItem = () => {
    setCatalogMenuList((visible) => !visible);
  };

  return (
    <MainMenu>
      <MenuItem>
        <ExpandedNavLinkWrapper>
          <NavLink
            onClick={linkOnClick}
            to={area ? `/area/${area}/catalog/all` : `/catalog/all`}
            activeClassName="selected"
          >
            Образовательные программы
          </NavLink>
          <DownIconStyled onClick={toggleCatalogMenuItem} />
        </ExpandedNavLinkWrapper>
        {isCatalogMenuList && (
          <ChildMenu>
            {catalogCategoriesItems.map((item, i) =>
              i < 15 ? (
                <ChildMenuItem key={item.alias} onClick={linkOnClick}>
                  <NavLink to={`/area/${item.alias}`}>{item.title}</NavLink>
                </ChildMenuItem>
              ) : null,
            )}
          </ChildMenu>
        )}
      </MenuItem>

      <MenuItem>
        <NavLink
          onClick={linkOnClick}
          to={area ? `/area/${area}/publications/all` : `/publications/all`}
          activeClassName="selected"
        >
          Библиотека
        </NavLink>
      </MenuItem>

      <MenuItem>
        <NavLink onClick={linkOnClick} to={area ? `/area/${area}/authors` : `/authors`} activeClassName="selected">
          Лекторы
        </NavLink>
      </MenuItem>
      {isLoggedIn && (
        <MenuItem>
          <NavLink onClick={linkOnClick} to="/surveys" activeClassName="selected">
            Опросы
          </NavLink>
        </MenuItem>
      )}
    </MainMenu>
  );
};

const MainMenu = styled(Menu)`
  display: block;
  border: none;
`;

export const baseLinkStyles = css`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: #000000;
  font-style: normal;
`;

const MenuItem = styled.li`
  margin-bottom: 16px;
  padding: 0;

  & > div > a,
  & > a {
    ${baseLinkStyles}
    font-weight: bold;
    line-height: 24px;
    text-transform: uppercase;
  }

  & > svg {
    margin-left: 8px;
  }
`;

const ChildMenu = styled.ul`
  margin-top: 16px !important;
  padding-bottom: 8px !important;
  border-bottom: 1px solid #d2d7de;
`;

const ChildMenuItem = styled.li`
  margin-bottom: 16px;

  & > a {
    ${baseLinkStyles}
    font-weight: 600;
    line-height: 20px;
    text-transform: capitalize;
  }
`;

const ExpandedNavLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DownIconStyled = styled(DownIcon)`
  cursor: pointer;
`;
