import React from 'react';
import styled, { css } from 'styled-components';

import { BellLogo } from 'assets/icons';

interface IProps {
  size?: 'medium' | 'small';
  isSubscribed?: boolean;
  onClickHandler?(e: React.MouseEvent): void;
  forListItem?: boolean;
}

export const SubscriptionBtn = ({
  size = 'medium',
  isSubscribed = false,
  onClickHandler,
  forListItem = false,
}: IProps) => (
  <ComponentContainer
    className="subscription-btn"
    title="Подписаться"
    size={size}
    isSubscribed={isSubscribed}
    onClick={onClickHandler}
    forListItem={forListItem}
  >
    <BellLogo />
  </ComponentContainer>
);

const ComponentContainer = styled.div<{ isSubscribed: boolean; size: 'medium' | 'small'; forListItem: boolean }>`
  ${({ isSubscribed, size, forListItem }) => css`
    width: 56px;
    height: 56px;
    padding: 16px 16px;
    background: rgba(255, 255, 255, 0.24);
    border-radius: 40px;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;

    svg {
      color: #ffffff;
    }

    ${isSubscribed &&
    `
      background-color: #ffffff;
      svg {
        color: #4853EC;
      }
    `}

    ${size === 'small' &&
    `         
      width: 28px;
      height: 28px;
      padding: 3px 8px;
      svg {
        width: 12px;
      }
    `}
    
    ${forListItem &&
    ` 
      background: #E6EAF1;
      svg {
        color: #9DA0AA;
      }
    `}
    
    ${forListItem &&
    isSubscribed &&
    ` 
      background: #4853EC;
      svg {
        color: #ffffff;
      }
    `}
  `}
`;
