import React, { useEffect } from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

export const RedirectToMobile = () => {
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const url = searchParams.get('url');

  useEffect(() => {
    if (url) {
      window.open(url, '_self');
    }
  }, [url]);

  return (
    <Container>
      <Spin />
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  left: 50%;
`;
