import React, { memo, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { Spin } from 'antd';
import ReactHtmlParser from 'react-html-parser';

import { MainTemplate } from 'templates';
import {
  clearAreaToStoreAction,
  clearAuthorToStoreAction,
  getAreaRequestAction,
  getAuthorRequestAction,
  resetLastAuthorsSearchAction,
} from 'core/actions';
import { Breadcrumb, Container, Section } from 'atoms';
import { getArea, getAreasAlias, getAuthor, getAuthorEvents, getIsAuthorLoading } from 'core/selectors';
import { ProgramsAuthorCarousel, PublicationsLectorCarousel } from 'components/organisms';

import { AuthorCard } from './Components';

export const Author = memo(() => {
  const dispatch = useDispatch();
  const { area, id } = useParams<{ area?: string; id: string }>();
  const history = useHistory();

  const areaAliases = useSelector(getAreasAlias);
  const author = useSelector(getAuthor);
  const authorEvents = useSelector(getAuthorEvents);
  const isAuthorLoading = useSelector(getIsAuthorLoading);

  const publicationRef = useRef<HTMLDivElement>(null);
  const eventsRef = useRef<HTMLDivElement>(null);

  const currentArea = useMemo(() => {
    return areaAliases.find((alias) => alias.areasAlias === area);
  }, [area, areaAliases]);

  const { color } = useSelector(getArea);

  useEffect(() => {
    if (area) {
      dispatch(getAreaRequestAction({ alias: area }));
    }

    return () => {
      dispatch(clearAreaToStoreAction());
    };
  }, [dispatch, area]);

  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (!location.pathname.includes('/author')) {
        dispatch(resetLastAuthorsSearchAction());
      }
    });

    return () => {
      unlisten();
    };
  }, [history, dispatch]);

  const publicationOnClick = () => {
    if (publicationRef.current) {
      publicationRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  };

  const eventsOnClick = () => {
    if (eventsRef.current && authorEvents.length > 0) {
      eventsRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  };

  useEffect(() => {
    dispatch(getAuthorRequestAction({ id }));

    return () => {
      dispatch(clearAuthorToStoreAction());
    };
  }, [dispatch, id]);

  if (isAuthorLoading) {
    return <Spin style={{ position: 'absolute', left: '48%', top: '48%' }} size="large" />;
  }

  const goToArea = () => {
    if (currentArea && currentArea.areasAlias) {
      history.push(`/area/${currentArea.areasAlias}`);
    }
  };

  const goToAuthors = () => {
    if (currentArea && currentArea.areasAlias) {
      history.push(`/area/${currentArea.areasAlias}/authors`);
      return;
    }
    history.push('/authors');
  };

  return (
    <MainTemplate isMenuButtonVisible>
      <TopBackground bgColor={color || undefined}>
        <Container>
          <Breadcrumb>
            {currentArea && <Breadcrumb.Item onClick={goToArea}>{currentArea.title}</Breadcrumb.Item>}
            <Breadcrumb.Item onClick={goToAuthors}>Лекторы</Breadcrumb.Item>
            {author?.fullName && <Breadcrumb.Item>{author?.fullName}</Breadcrumb.Item>}
          </Breadcrumb>
          {author?.fullName && <Title>{author?.fullName}</Title>}
        </Container>
      </TopBackground>
      {author && (
        <>
          <Container>
            <AuthorCard
              publicationOnClick={publicationOnClick}
              eventsOnClick={eventsOnClick}
              imageUrl={author.photoUrl}
              position={author.position}
              description={author.description}
              eventsTotal={authorEvents.length}
              publicationsTotal={author.publications.length}
            />
          </Container>

          {author.biography && (
            <Biography title="Биография">{ReactHtmlParser(author.biography.replace(/\r\n/g, ' <br> '))}</Biography>
          )}

          {author.additionalInfo && (
            <AdditionalInfo title="Дополнительная информация">
              {ReactHtmlParser(author.additionalInfo.replace(/\r\n/g, ' <br> '))}
            </AdditionalInfo>
          )}

          <div ref={eventsRef}>
            <AuthorEvents title="Мероприятия">
              <ProgramsAuthorCarousel color={color || undefined} />
            </AuthorEvents>
          </div>

          {author.publications.length > 0 && (
            <div ref={publicationRef}>
              <Publication title="Публикации">
                <PublicationsLectorCarousel />
              </Publication>
            </div>
          )}
        </>
      )}
    </MainTemplate>
  );
});

const TopBackground = styled.div<{ backgroundUrl?: string; bgColor?: string }>`
  ${({ backgroundUrl = '', theme: { topBackgroundColor }, bgColor }) => css`
    padding: 80px 0 15px 0;
    background-color: ${topBackgroundColor};
    background-image: url(${backgroundUrl});
    background-repeat: no-repeat;
    background-position: 50% 0;
    position: relative;
    z-index: 0;
    background-size: cover;

    &:before {
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0.8;
      background: ${bgColor ?? 'linear-gradient(275.17deg, #525ded 24.3%, #121a7b 102.71%)'};
    }

    @media (min-width: 768px) {
      padding: 104px 0 60px 0;
    }

    @media (min-width: 1200px) {
      padding: 100px 0 60px 0;
    }
  `}
`;

const Title = styled.h1`
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;

  @media (min-width: 768px) {
    font-size: 56px;
    line-height: 48px;
  }

  @media (min-width: 1200px) {
    font-size: 64px;
    line-height: 72px;
  }
`;

const Biography = styled(Section)`
  padding: 24px 0 0 0;

  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 24px;
  color: #000000;

  & > div:first-child {
    & > div:first-child {
      margin-bottom: 24px;
    }
  }

  @media (min-width: 768px) {
    padding: 48px 0 0 0;
    font-size: 16px;
    line-height: 28px;
  }
`;

const AdditionalInfo = styled(Section)`
  padding: 24px 0 0 0;

  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 24px;
  color: #000000;

  & > div:first-child {
    & > div:first-child {
      margin-bottom: 24px;
    }
  }

  p {
    display: flex;
    flex-direction: column;

    br {
      display: none;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    width: 100% !important;
  }

  iframe {
    width: 100%;
    height: 287px;
    margin-bottom: 20px;
  }

  @media (min-width: 576px) {
    iframe {
      height: 395px;
    }
  }

  @media (min-width: 768px) {
    padding: 48px 0 0 0;
    font-size: 16px;
    line-height: 28px;

    iframe {
      height: 611px;
    }
  }

  @media (min-width: 1200px) {
    iframe {
      height: 579px;
    }
  }
`;

const AuthorEvents = styled(Section)<{ $color?: string }>`
  padding: 24px 0 0 0;
  background-color: #ffffff;

  & > div:first-child {
    & > div:first-child {
      margin-bottom: 24px;
    }
  }

  @media (min-width: 768px) {
    padding: 48px 0 65px 0;
  }
`;

const Publication = styled(Section)`
  padding: 24px 0 0 0;

  @media (min-width: 768px) {
    padding: 48px 0 65px 0;
  }
`;
