import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface IProps {
  children: ReactNode;
  className?: string;
}

// Emulate bootstrap .container
export const Container = ({ children, className }: IProps) => <Content className={className}>{children}</Content>;

const Content = styled.div`
  width: 100%;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    max-width: 540px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    max-width: 720px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    max-width: 960px;
  }

  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) {
    max-width: 1172px;
  }
`;
