import React from 'react';
import styled, { css } from 'styled-components';

const months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь'];

interface EventInit {
  bubbles?: boolean;
  cancelable?: boolean;
  composed?: boolean;
}

interface IProps {
  onClick?(event: EventInit): void;
}

export const MonthsSelector = ({ onClick }: IProps) => (
  <MonthsSelectorContainer>
    <MonthsSelectorItem onClick={onClick}>Все</MonthsSelectorItem>
    {months.map((item) => (
      <MonthsSelectorItem key={item} onClick={onClick}>
        {item}
      </MonthsSelectorItem>
    ))}
  </MonthsSelectorContainer>
);

const MonthsSelectorContainer = styled.div`
  border-bottom: 1px solid #d2d7de;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MonthsSelectorItem = styled.div`
  ${({
    theme: {
      MonthsSelectorTextColor,
      MonthsSelectorTextHoverColor,
      MonthsSelectorTextActiveColor,
      MonthsSelectorActiveBorderColor,
    },
  }) => css`
    color: ${MonthsSelectorTextColor};
    border-bottom: 1px solid transparent;
    display: inline-block;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    padding: 6px;
    flex: 1 1 auto;
    cursor: pointer;

    &:hover {
      color: ${MonthsSelectorTextHoverColor};
    }

    &.active {
      color: ${MonthsSelectorTextActiveColor};
      border-color: ${MonthsSelectorActiveBorderColor};
    }
  `}
`;
