import React, { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';

import { MainTemplate } from 'templates';
import {
  clearAreaToStoreAction,
  clearPublicationsToStoreAction,
  getAreaRequestAction,
  resetLastPublicationsSearchAction,
} from 'core/actions';
import { Breadcrumb, Container } from 'atoms';
import { getArea, getAreasAlias, getPublicationsCategoriesFromStore } from 'core/selectors';
import { PublicationsCategories } from 'components/molecules';

import { Form, Results } from './Components';

export const Publications = memo(() => {
  const dispatch = useDispatch();
  const { area, category } = useParams<{ area?: string; category: string }>();
  const history = useHistory();
  const areaAliases = useSelector(getAreasAlias);
  const categories = useSelector(getPublicationsCategoriesFromStore);

  const { color } = useSelector(getArea);

  useEffect(() => {
    if (area) {
      dispatch(getAreaRequestAction({ alias: area }));
    }

    return () => {
      dispatch(clearAreaToStoreAction());
    };
  }, [dispatch, area]);

  const currentArea = useMemo(() => {
    return areaAliases.find((alias) => alias.areasAlias === area);
  }, [area, areaAliases]);

  const categoryTitle = useMemo(() => {
    return categories.find((categoryItem) => categoryItem.alias === category)?.title;
  }, [category, categories]);

  useEffect(() => {
    const historyListener = history.listen((location) => {
      if (!location.pathname.includes('/publication')) {
        dispatch(resetLastPublicationsSearchAction());
      }
    });

    return () => {
      historyListener();
    };
  }, [history, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearPublicationsToStoreAction());
    };
  }, [dispatch, area]);

  const goToArea = () => {
    if (currentArea && currentArea.areasAlias) {
      history.push(`/area/${currentArea.areasAlias}`);
    }
  };

  const goToLibrary = () => {
    if (currentArea && currentArea.areasAlias) {
      history.push(`/area/${currentArea.areasAlias}/publications/all`);
      return;
    }
    history.push('/publications/all');
  };

  return (
    <MainTemplate isMenuButtonVisible>
      <TopBackground bgColor={color || undefined}>
        <Container>
          <Breadcrumb>
            {currentArea && currentArea.title && (
              <Breadcrumb.Item onClick={goToArea}>{currentArea.title}</Breadcrumb.Item>
            )}
            <Breadcrumb.Item onClick={goToLibrary}>Библиотека</Breadcrumb.Item>
            {categoryTitle && <Breadcrumb.Item>{categoryTitle}</Breadcrumb.Item>}
          </Breadcrumb>
          <Title>Библиотека</Title>
          <PublicationsCategories />
        </Container>
      </TopBackground>
      <Container>
        <Form color={color || undefined} />
        <Results />
      </Container>
    </MainTemplate>
  );
});

const TopBackground = styled.div<{ backgroundUrl?: string; bgColor?: string }>`
  ${({ backgroundUrl = '', theme: { topBackgroundColor }, bgColor }) => css`
    padding: 80px 0 15px 0;
    background-color: ${topBackgroundColor};
    background-image: url(${backgroundUrl});
    background-repeat: no-repeat;
    background-position: 50% 0;
    position: relative;
    z-index: 0;
    background-size: cover;

    &:before {
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0.8;
      background: ${bgColor ?? 'linear-gradient(275.17deg, #525ded 24.3%, #121a7b 102.71%)'};
    }

    @media (min-width: 768px) {
      padding: 104px 0 60px 0;
    }

    @media (min-width: 1200px) {
      padding: 100px 0 60px 0;
    }
  `}
`;

const Title = styled.h1`
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;

  @media (min-width: 768px) {
    font-size: 56px;
    line-height: 48px;
  }

  @media (min-width: 1200px) {
    font-size: 64px;
    line-height: 72px;
  }
`;
