import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { getIsLoggedIn } from 'core/selectors';

export const useLoginGuard = (callback: () => void) => {
  const isLoggedIn = useSelector(getIsLoggedIn);
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      callback();
    }
  }, [callback, isLoggedIn]);
};
