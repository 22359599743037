import axios from 'axios';

import { store } from 'core';
import { logOutAction, setAppServerError } from 'core/actions';
import { AuthService } from 'services';
import { ServerErrors } from 'types';

export const checkTokenInterceptor = () => {
  axios.interceptors.response.use(
    (res) => res,
    (error) => {
      const {
        message,
        code,
        response: { status = undefined, config = undefined, data: { detail = undefined } = {} } = {},
      } = error;

      if (
        status === ServerErrors.NotFound ||
        status === ServerErrors.BadGateway ||
        status === ServerErrors.TimeoutGateway ||
        message === ServerErrors.Network ||
        code === ServerErrors.Econnaborted
      ) {
        store.dispatch(setAppServerError({ error: status ?? code ?? message }));
      }

      if (detail?.includes('Token is invalid or expired')) {
        store.dispatch(logOutAction());
      }

      if (status === 401 && detail?.includes('Given token not valid for any token type')) {
        return AuthService.refreshToken().then((token) => {
          config.headers.Authorization = `Bearer ${token}`;
          return axios.request(config);
        });
      }

      return Promise.reject(error);
    },
  );
};
