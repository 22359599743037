import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { NavLink as NavLinkReactRouter, useLocation, useParams } from 'react-router-dom';

import {
  getAllAreasRequestAction,
  getCatalogCategoriesRequestAction,
  getPublicationsCategoriesRequestAction,
} from 'core/actions';
import {
  getAllAreas,
  getCatalogCategoriesFromStore,
  getIsLoggedIn,
  getPublicationsCategoriesFromStore,
} from 'core/selectors';
import { Container } from 'components/atoms';

export const MainMenu = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { area } = useParams<{ area?: string }>();
  const areas = useSelector(getAllAreas);
  const isLoggedIn = useSelector(getIsLoggedIn);

  const categories = useSelector(getCatalogCategoriesFromStore);
  const publications = useSelector(getPublicationsCategoriesFromStore);

  useEffect(() => {
    dispatch(getCatalogCategoriesRequestAction({ alias: area }));
    dispatch(getPublicationsCategoriesRequestAction({ alias: area }));
  }, [dispatch, area]);

  useEffect(() => {
    dispatch(getAllAreasRequestAction());
  }, [dispatch]);

  return (
    <MainMenuStyled>
      <MenuItem>
        <NavLink $isActive={pathname.includes('catalog')} to={area ? `/area/${area}/catalog/all` : `/catalog/all`}>
          Образовательные программы
        </NavLink>
        {categories.length ? (
          <DropdownContent>
            <Container>
              <ChildMenuNav>
                {areas.map(({ alias, title }, i) =>
                  i < 15 ? (
                    <ChildMenuItem key={alias}>
                      <NavLink to={`/area/${alias}`}>{title}</NavLink>
                    </ChildMenuItem>
                  ) : null,
                )}
              </ChildMenuNav>
            </Container>
          </DropdownContent>
        ) : null}
      </MenuItem>
      <MenuItem>
        <NavLink
          $isActive={pathname.includes('publications')}
          to={area ? `/area/${area}/publications/all` : `/publications/all`}
        >
          Библиотека
        </NavLink>
        {publications.length ? (
          <DropdownContent>
            <Container>
              <ChildMenuNav>
                {publications.map(({ alias, shortTitle }, i) =>
                  i < 15 ? (
                    <ChildMenuItem key={alias}>
                      <NavLink to={`/publications/${alias}`}>{shortTitle}</NavLink>
                    </ChildMenuItem>
                  ) : null,
                )}
              </ChildMenuNav>
            </Container>
          </DropdownContent>
        ) : null}
      </MenuItem>
      <MenuItem>
        <NavLink $isActive={pathname.includes('authors')} to={area ? `/area/${area}/authors` : `/authors`}>
          Лекторы
        </NavLink>
      </MenuItem>
      {isLoggedIn && (
        <MenuItem>
          <NavLink $isActive={pathname.includes('surveys')} to="/surveys">
            Опросы
          </NavLink>
        </MenuItem>
      )}
    </MainMenuStyled>
  );
};

const MainMenuStyled = styled.ul`
  display: none;

  @media (min-width: 1350px) {
    display: inline-flex;
    height: 100%;
    list-style: none;
  }
`;

const NavLink = styled(NavLinkReactRouter)<{ $isActive?: boolean }>`
  ${({ $isActive }) => css`
    && {
      ${$isActive &&
      css`
        color: #4853ec;
      `};
    }
  `}
`;

const DropdownContent = styled.div`
  position: absolute;
  transform: scaleY(0);
  transform-origin: top;
  height: auto;
  padding-top: 32px;
  padding-bottom: 56px;
  top: 120px;
  left: 0;
  right: 0;
  width: 100vw;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(72, 83, 236, 0.08);
  border-top: 1px solid #eeeeee;
  overflow: hidden;
  line-height: normal;
`;

const MenuItem = styled.li`
  flex: 0 1 auto;
  align-self: center;

  &:hover {
    ${DropdownContent} {
      display: block;
      transform: scaleY(1);
      transition: all 0.4s ease;
    }
  }

  &:has(${DropdownContent}:hover) {
    & > a {
      border-top: 4px solid #4853ec;
    }
  }

  & > a {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    text-transform: uppercase;
    padding: 48px 0;
    box-sizing: border-box;
    margin-right: 40px;

    :hover {
      border-top: 4px solid #4853ec;
    }
  }
`;

const ChildMenuNav = styled.ul`
  list-style: none;
  column-count: 3;
`;

const ChildMenuItem = styled.li`
  margin-bottom: 16px;
  flex: 0 1 auto;

  & > a {
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    color: #000000;
    font-size: 14px;
    font-family: 'Raleway';
    font-weight: 700;
    line-height: 24px;
    text-transform: capitalize;
    padding: 8px 0;

    &:hover {
      color: #4853ec;
    }
  }
`;
