import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import { RegisterSelect } from 'atoms';

interface IProps extends WrappedFieldProps {
  caption: string;
  options: { id: number; value: string }[];
  required?: boolean;
  disabled?: boolean;
  className?: string;
}

export const SelectField = ({
  input,
  meta: { touched, error },
  required,
  caption,
  options,
  disabled,
  className,
}: IProps) => {
  const errorText = touched && error ? error : '';

  return (
    <RegisterSelect
      optionItems={options}
      caption={caption}
      required={required}
      errorMsg={errorText}
      disabled={disabled}
      showSearch
      className={className}
      {...input}
      onBlur={(e) => e.preventDefault()}
    />
  );
};
