export enum ACTIONS {
  INIT_APP_REQUEST = 'INIT_APP_REQUEST',
  INIT_APP_SUCCESS = 'INIT_APP_SUCCESS',
  INIT_APP_FAILURE = 'INIT_APP_FAILURE',
  START_INIT_APP_LOADING = 'START_INIT_APP_LOADING',
  END_INIT_APP_LOADING = 'END_INIT_APP_LOADING',

  USERDATA_REQUEST = 'USERDATA_REQUEST',
  USERDATA_SUCCESS = 'USERDATA_SUCCESS',
  USERDATA_FAILURE = 'USERDATA_FAILURE',
  PUT_USERDATA_TO_STORE = 'PUT_USERDATA_TO_STORE',
  SAVE_USERDATA_REQUEST = 'SAVE_USERDATA_REQUEST',
  SAVE_USERDATA_SUCCESS = 'SAVE_USERDATA_SUCCESS',
  SAVE_USERDATA_FAILURE = 'SAVE_USERDATA_FAILURE',
  RESET_USERDATA_UPDATE_STATUS = 'RESET_USERDATA_UPDATE_STATUS',

  GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST',
  GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS',
  GET_USER_INFO_FAILURE = 'GET_USER_INFO_FAILURE',
  PUT_PERSONAL_USER_DATA_TO_STORE = 'PUT_PERSONAL_USER_DATA_TO_STORE',

  GET_EVENT_CERTIFICATE_REQUEST = 'GET_EVENT_CERTIFICATE_REQUEST',
  GET_EVENT_CERTIFICATE_SUCCESS = 'GET_EVENT_CERTIFICATE_SUCCESS',
  GET_EVENT_CERTIFICATE_FAILURE = 'GET_EVENT_CERTIFICATE_FAILURE',

  SET_VISITOR_STATUS_ACTION = 'SET_VISITOR_STATUS_ACTION',

  SET_APP_SERVER_ERROR = 'SET_APP_SERVER_ERROR',

  CLEAR_SUCCESS_BY_ACTION_TYPE = 'CLEAR_SUCCESS_BY_ACTION_TYPE',

  START_AUTH_LOADING = 'START_AUTH_LOADING',
  END_AUTH_LOADING = 'END_AUTH_LOADING',

  REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE',

  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILURE = 'LOGIN_FAILURE',

  LOG_OUT = 'LOG_OUT',
  LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS',
  LOG_OUT_FAILURE = 'LOG_OUT_FAILURE',

  REGISTER_REQUEST = 'REGISTER_REQUEST',
  REGISTER_SUCCESS = 'REGISTER_SUCCESS',
  REGISTER_FAILURE = 'REGISTER_FAILURE',
  PUT_REGISTER_STEP_TO_STORE = 'PUT_REGISTER_STEP_TO_STORE',
  PUT_FIRST_STEP_REGISTER_TO_STORE = 'PUT_FIRST_STEP_REGISTER_TO_STORE',
  PUT_SECOND_STEP_REGISTER_TO_STORE = 'PUT_SECOND_STEP_REGISTER_TO_STORE',
  PUT_THIRD_STEP_REGISTER_TO_STORE = 'PUT_THIRD_STEP_REGISTER_TO_STORE',
  PUT_FOURTH_STEP_REGISTER_TO_STORE = 'PUT_FOURTH_STEP_REGISTER_TO_STORE',
  CLEAR_REGISTER_TO_STORE = 'CLEAR_REGISTER_TO_STORE',

  FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE',
  CLEAR_FORGOT_PASSWORD_TO_STORE = 'CLEAR_FORGOT_PASSWORD_TO_STORE',

  CREATE_PASSWORD_REQUEST = 'CREATE_PASSWORD_REQUEST',
  CREATE_PASSWORD_SUCCESS = 'CREATE_PASSWORD_SUCCESS',
  CREATE_PASSWORD_FAILURE = 'CREATE_PASSWORD_FAILURE',
  PUT_CREATE_PASSWORD_TOKEN_TO_STORE = 'PUT_CREATE_PASSWORD_TOKEN_TO_STORE',
  CLEAR_CREATE_PASSWORD_REQUEST = 'CLEAR_CREATE_PASSWORD_REQUEST',

  PUT_AUTH_SERVER_ERROR_TO_STORE = 'PUT_AUTH_SERVER_ERROR_TO_STORE',
  PUT_CODE_ERROR_TO_STORE = 'PUT_CODE_ERROR_TO_STORE',
  CLEAR_AUTH_SERVER_ERROR_TO_STORE = 'CLEAR_AUTH_SERVER_ERROR_TO_STORE',

  CHANGE_LOGIN_POPUP_VISIBLE = 'CHANGE_LOGIN_POPUP_VISIBLE',
  CHANGE_REGISTER_POPUP_VISIBLE = 'CHANGE_REGISTER_POPUP_VISIBLE',
  CHANGE_FORGOT_PASSWORD_POPUP_VISIBLE = 'CHANGE_FORGOT_PASSWORD_POPUP_VISIBLE',
  CHANGE_CREATE_PASSWORD_POPUP_VISIBLE = 'CHANGE_CREATE_PASSWORD_POPUP_VISIBLE',
  CHANGE_MAIL_CHECK_POPUP_VISIBLE = 'CHANGE_MAIL_CHECK_POPUP_VISIBLE',
  CHANGE_SENT_MESSAGE_POPUP_VISIBLE = 'CHANGE_SENT_MESSAGE_POPUP_VISIBLE',

  START_AREAS_ALIAS_LOADING = 'START_AREAS_ALIAS_LOADING',
  END_AREAS_ALIAS_LOADING = 'END_AREAS_ALIAS_LOADING',
  GET_AREAS_ALIAS_SUCCESS = 'GET_AREAS_ALIAS_SUCCESS',
  GET_AREAS_ALIAS_FAILURE = 'GET_AREAS_ALIAS_FAILURE',
  PUT_AREAS_ALIAS_TO_STORE = 'PUT_AREAS_ALIAS_TO_STORE',
  CLEAR_AREAS_ALIAS_TO_STORE = 'CLEAR_AREAS_ALIAS_TO_STORE',

  GET_ALL_AREAS_REQUEST = 'GET_ALL_AREAS_REQUEST',
  GET_ALL_AREAS_SUCCESS = 'GET_ALL_AREAS_SUCCESS',
  GET_ALL_AREAS_FAILURE = 'GET_ALL_AREAS_FAILURE',
  PUT_ALL_AREAS_TO_STORE = 'PUT_ALL_AREAS_TO_STORE',
  GET_AREAS_CROPPED_REQUEST = 'GET_AREAS_CROPPED_REQUEST',
  GET_MORE_AREAS_CROPPED_REQUEST = 'GET_MORE_AREAS_CROPPED_REQUEST',
  GET_AREAS_CROPPED_SUCCESS = 'GET_AREAS_CROPPED_SUCCESS',
  GET_AREAS_CROPPED_FAILURE = 'GET_AREAS_CROPPED_FAILURE',
  PUT_AREAS_CROPPED_TO_STORE = 'PUT_AREAS_CROPPED_TO_STORE',
  CLEAR_AREAS_CROPPED_TO_STORE = 'CLEAR_AREAS_CROPPED_TO_STORE',

  GET_HOME_REQUEST = 'GET_HOME_REQUEST',
  GET_HOME_SUCCESS = 'GET_HOME_SUCCESS',
  GET_HOME_FAILURE = 'GET_HOME_FAILURE',
  PUT_HOME_TO_STORE = 'PUT_HOME_TO_STORE',
  CLEAR_HOME_TO_STORE = 'CLEAR_HOME_TO_STORE',

  GET_HOME_ENTERESTING_EVENTS_REQUEST = 'GET_HOME_ENTERESTING_EVENTS_REQUEST',
  GET_HOME_ENTERESTING_EVENTS_SUCCESS = 'GET_HOME_ENTERESTING_EVENTS_SUCCESS',
  GET_HOME_ENTERESTING_EVENTS_FAILURE = 'GET_HOME_ENTERESTING_EVENTS_FAILURE',
  PUT_HOME_ENTERESTING_EVENTS_TO_STORE = 'PUT_HOME_ENTERESTING_EVENTS_TO_STORE',

  GET_HOME_EVENTS_REQUEST = 'GET_HOME_EVENTS_REQUEST',
  GET_HOME_EVENTS_SUCCESS = 'GET_HOME_EVENTS_SUCCESS',
  GET_HOME_EVENTS_FAILURE = 'GET_HOME_EVENTS_FAILURE',
  PUT_HOME_EVENTS_TO_STORE = 'PUT_HOME_EVENTS_TO_STORE',
  CLEAR_HOME_EVENTS_TO_STORE = 'CLEAR_HOME_EVENTS_TO_STORE',
  PUT_CURRENT_HOME_EVENTS_MONTH_TO_STORE = 'PUT_CURRENT_HOME_EVENTS_MONTH_TO_STORE',

  GET_AREA_REQUEST = 'GET_AREA_REQUEST',
  GET_AREA_SUCCESS = 'GET_AREA_SUCCESS',
  GET_AREA_FAILURE = 'GET_AREA_FAILURE',
  PUT_AREA_TO_STORE = 'PUT_AREA_TO_STORE',
  CLEAR_AREA_TO_STORE = 'CLEAR_AREA_TO_STORE',

  GET_AREA_EVENTS_REQUEST = 'GET_AREA_EVENTS_REQUEST',
  GET_AREA_EVENTS_SUCCESS = 'GET_AREA_EVENTS_SUCCESS',
  GET_AREA_EVENTS_FAILURE = 'GET_AREA_EVENTS_FAILURE',
  PUT_AREA_EVENTS_TO_STORE = 'PUT_AREA_EVENTS_TO_STORE',
  CLEAR_AREA_EVENTS_TO_STORE = 'CLEAR_AREA_EVENTS_TO_STORE',
  PUT_CURRENT_AREA_EVENTS_MONTH_TO_STORE = 'PUT_CURRENT_AREA_EVENTS_MONTH_TO_STORE',

  GET_EVENT_REQUEST = 'GET_EVENT_REQUEST',
  GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS',
  GET_EVENT_FAILURE = 'GET_EVENT_FAILURE',
  GET_EVENT_SILENT_REQUEST = 'GET_EVENT_SILENT_REQUEST',
  GET_EVENT_SILENT_SUCCESS = 'GET_EVENT_SILENT_SUCCESS',
  GET_EVENT_SILENT_FAILURE = 'GET_EVENT_SILENT_FAILURE',
  PUT_EVENT_TO_STORE = 'PUT_EVENT_TO_STORE',
  CLEAR_EVENT_TO_STORE = 'CLEAR_EVENT_TO_STORE',

  GET_SURVEYS_REQUEST = 'GET_SURVEYS_REQUEST',
  GET_ALL_SURVEYS_REQUEST = 'GET_ALL_SURVEYS_REQUEST',
  GET_SURVEYS_SUCCESS = 'GET_SURVEYS_SUCCESS',
  GET_SURVEYS_FAILURE = 'GET_SURVEYS_FAILURE',
  SAVE_SURVEY_TO_STORE = 'SAVE_SURVEY_TO_STORE',
  SAVE_All_SURVEYS_TO_STORE = 'SAVE_All_SURVEYS_TO_STORE',
  SAVE_SURVEYS_QTY_TO_STORE = 'SAVE_SURVEYS_QTY_TO_STORE',
  RESET_SURVEY = 'RESET_SURVEY',

  SET_SURVEY_STEP = 'SET_SURVEY_STEP',

  SAVE_SURVEY_ANSWERS_TO_STORE = 'SAVE_SURVEY_ANSWERS_TO_STORE',
  SET_SURVEY_ANSWERS_REQUEST = 'SET_SURVEY_ANSWERS_REQUEST',
  SET_SURVEY_ANSWERS_SUCCESS = 'SET_SURVEY_ANSWERS_SUCCESS',
  SET_SURVEY_ANSWERS_FAILURE = 'SET_SURVEY_ANSWERS_FAILURE',
  RESET_SURVEY_ANSWERS_STATUS = 'RESET_SURVEY_ANSWERS_STATUS',

  SET_SURVEY_CUSTOM_ANSWER_REQUEST = 'SET_SURVEY_CUSTOM_ANSWER_REQUEST',
  SET_SURVEY_CUSTOM_ANSWER_SUCCESS = 'SET_SURVEY_CUSTOM_ANSWER_SUCCESS',
  SET_SURVEY_CUSTOM_ANSWER_FAILURE = 'SET_SURVEY_CUSTOM_ANSWER_FAILURE',

  GET_UPCOMING_EVENTS_REQUEST = 'GET_UPCOMING_EVENTS_REQUEST',
  GET_UPCOMING_EVENTS_SUCCESS = 'GET_UPCOMING_EVENTS_SUCCESS',
  GET_UPCOMING_EVENTS_FAILURE = 'GET_UPCOMING_EVENTS_FAILURE',
  PUT_UPCOMING_EVENTS_TO_STORE = 'PUT_UPCOMING_EVENTS_TO_STORE',

  SUBSCRIBE_REQUEST = 'SUBSCRIBE_REQUEST',
  SUBSCRIBE_SUCCESS = 'SUBSCRIBE_SUCCESS',
  SUBSCRIBE_FAILURE = 'SUBSCRIBE_FAILURE',

  UNSUBSCRIBE_REQUEST = 'UNSUBSCRIBE_REQUEST',
  UNSUBSCRIBE_SUCCESS = 'UNSUBSCRIBE_SUCCESS',
  UNSUBSCRIBE_FAILURE = 'UNSUBSCRIBE_FAILURE',

  SET_EVENT_RATING_REQUEST = 'SET_EVENT_RATING_REQUEST',
  SET_EVENT_RATING_SUCCESS = 'SET_EVENT_RATING_SUCCESS',
  SET_EVENT_RATING_FAILURE = 'SET_EVENT_RATING_FAILURE',

  SET_VIEW_FLAG_REQUEST = 'SET_VIEW_FLAG_REQUEST',
  SET_VIEW_FLAG_SUCCESS = 'SET_VIEW_FLAG_SUCCESS',
  SET_VIEW_FLAG_FAILURE = 'SET_VIEW_FLAG_FAILURE',

  SET_REGISTERED_FLAG_REQUEST = 'SET_REGISTERED_FLAG_REQUEST',
  SET_REGISTERED_FLAG_SUCCESS = 'SET_REGISTERED_FLAG_SUCCESS',
  SET_REGISTERED_FLAG_FAILURE = 'SET_REGISTERED_FLAG_FAILURE',

  IS_APP_SIDER_COLLAPSED = 'IS_APP_SIDER_COLLAPSED',

  PUT_IS_PERSONAL_DATA_APPROVE_VALUE = 'PUT_IS_PERSONAL_DATA_APPROVE_VALUE',
  PUT_IS_PERSONAL_DATA_APPROVE_ERROR = 'PUT_IS_PERSONAL_DATA_APPROVE_ERROR',

  // Catalog
  PUT_CURRENT_CATALOG_CATEGORY_TO_STORE = 'PUT_CURRENT_CATALOG_CATEGORY_TO_STORE',
  PUT_SEARCH_ASC_TO_STORE = 'PUT_SEARCH_ASC_TO_STORE',
  GET_CATALOG_CATEGORIES_REQUEST = 'GET_CATALOG_CATEGORIES_REQUEST',
  GET_CATALOG_CATEGORIES_SUCCESS = 'GET_CATALOG_CATEGORIES_SUCCESS',
  GET_CATALOG_CATEGORIES_FAILURE = 'GET_CATALOG_CATEGORIES_FAILURE',
  PUT_CATALOG_CATEGORIES_TO_STORE = 'PUT_CATALOG_CATEGORIES_TO_STORE',
  GET_TAGS_REQUEST = 'GET_TAGS_REQUEST',
  GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS',
  GET_TAGS_FAILURE = 'GET_TAGS_FAILURE',
  PUT_TAGS_TO_STORE = 'PUT_TAGS_TO_STORE',
  GET_CATALOG_EVENTS_REQUEST = 'GET_EVENTS_REQUEST',
  GET_CATALOG_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS',
  GET_CATALOG_EVENTS_FAILURE = 'GET_EVENTS_FAILURE',
  PUT_CATALOG_EVENTS_TO_STORE = 'PUT_EVENTS_TO_STORE',
  GET_MORE_CATALOG_EVENTS_REQUEST = 'GET_MORE_EVENTS_REQUEST',
  GET_MORE_CATALOG_EVENTS_SUCCESS = 'GET_MORE_EVENTS_SUCCESS',
  GET_MORE_CATALOG_EVENTS_FAILURE = 'GET_MORE_EVENTS_FAILURE',
  RESET_CATALOG_STATE = 'RESET_CATALOG_STATE',
  SET_PHONE = 'SET_PHONE',

  GET_AUTHORS_REQUEST = 'GET_AUTHORS_REQUEST',
  GET_MORE_AUTHORS_REQUEST = 'GET_MORE_AUTHORS_REQUEST',
  GET_AUTHORS_SUCCESS = 'GET_AUTHORS_SUCCESS',
  GET_AUTHORS_FAILURE = 'GET_AUTHORS_FAILURE',
  PUT_AUTHORS_TO_STORE = 'PUT_AUTHORS_TO_STORE',
  PUT_AUTHORS_PAGE_TO_STORE = 'PUT_AUTHORS_PAGE_TO_STORE',
  CLEAR_AUTHORS_TO_STORE = 'CLEAR_AUTHORS_TO_STORE',

  GET_AUTHOR_REQUEST = 'GET_AUTHOR_REQUEST',
  GET_AUTHOR_SUCCESS = 'GET_AUTHOR_SUCCESS',
  GET_AUTHOR_FAILURE = 'GET_AUTHOR_FAILURE',
  PUT_AUTHOR_TO_STORE = 'PUT_AUTHOR_TO_STORE',
  CLEAR_AUTHOR_TO_STORE = 'CLEAR_AUTHOR_TO_STORE',

  GET_AUTHOR_EVENTS_REQUEST = 'GET_AUTHOR_EVENTS_REQUEST',
  GET_AUTHOR_EVENTS_SUCCESS = 'GET_AUTHOR_EVENTS_SUCCESS',
  GET_AUTHOR_EVENTS_FAILURE = 'GET_AUTHOR_EVENTS_FAILURE',
  PUT_AUTHOR_EVENTS_TO_STORE = 'PUT_AUTHOR_EVENTS_TO_STORE',

  GET_PUBLICATIONS_REQUEST = 'GET_PUBLICATIONS_REQUEST',
  GET_MORE_PUBLICATIONS_REQUEST = 'GET_MORE_PUBLICATIONS_REQUEST',
  GET_PUBLICATIONS_SUCCESS = 'GET_PUBLICATIONS_SUCCESS',
  GET_PUBLICATIONS_FAILURE = 'GET_PUBLICATIONS_FAILURE',
  PUT_PUBLICATIONS_TO_STORE = 'PUT_PUBLICATIONS_TO_STORE',

  GET_PUBLICATION_REQUEST = 'GET_PUBLICATION_REQUEST',
  GET_PUBLICATION_SUCCESS = 'GET_PUBLICATION_SUCCESS',
  GET_PUBLICATION_FAILURE = 'GET_PUBLICATION_FAILURE',
  PUT_PUBLICATION_TO_STORE = 'PUT_PUBLICATION_TO_STORE',
  CLEAR_PUBLICATION_TO_STORE = 'CLEAR_PUBLICATION_TO_STORE',

  PUT_PUBLICATIONS_PAGE_TO_STORE = 'PUT_PUBLICATIONS_PAGE_TO_STORE',
  CLEAR_PUBLICATIONS_TO_STORE = 'CLEAR_PUBLICATIONS_TO_STORE',
  PUT_CURRENT_PUBLICATIONS_CATEGORY_TO_STORE = 'PUT_CURRENT_PUBLICATIONS_CATEGORY_TO_STORE',
  GET_PUBLICATIONS_CATEGORIES_REQUEST = 'GET_PUBLICATIONS_CATEGORIES_REQUEST',
  GET_PUBLICATIONS_CATEGORIES_SUCCESS = 'GET_PUBLICATIONS_CATEGORIES_SUCCESS',
  GET_PUBLICATIONS_CATEGORIES_FAILURE = 'GET_PUBLICATIONS_CATEGORIES_FAILURE',
  PUT_PUBLICATIONS_CATEGORIES_TO_STORE = 'PUT_PUBLICATIONS_CATEGORIES_TO_STORE',

  SET_VIEWED_MINUTE_REQUEST = 'SET_VIEWED_MINUTE_REQUEST',
  SET_VIEWED_MINUTE_SUCCESS = 'SET_VIEWED_MINUTE_SUCCESS',
  SET_VIEWED_MINUTE_FAILURE = 'SET_VIEWED_MINUTE_FAILURE',

  POST_GET_CODE_REQUEST = 'POST_GET_CODE_REQUEST',
  POST_GET_VERIFICATION_CODE_REQUEST = 'POST_GET_VERIFICATION_CODE_REQUEST',
  CHECK_EMAIL_REQUEST = 'CHECK_EMAIL_REQUEST',
  PUT_EMAIL_EXISTS = 'PUT_EMAIL_EXISTS',

  ACTIVATE_PHONE_REQUEST = 'ACTIVATE_PHONE_REQUEST',

  GET_USER_EVENTS_REQUEST = 'GET_USER_EVENTS_REQUEST',
  GET_USER_EVENTS_SUCCESS = 'GET_USER_EVENTS_SUCCESS',
  GET_USER_EVENTS_FAILURE = 'GET_USER_EVENTS_FAILURE',
  PUT_USER_EVENTS_TO_STORE = 'PUT_USER_EVENTS_TO_STORE',

  SET_LAST_AUTHORS_SEARCH = 'SET_LAST_AUTHORS_SEARCH',
  RESET_LAST_AUTHORS_SEARCH = 'RESET_LAST_AUTHORS_SEARCH',
  SET_LAST_CATALOG_SEARCH = 'SET_LAST_CATALOG_SEARCH',
  RESET_LAST_CATALOG_SEARCH = 'RESET_LAST_CATALOG_SEARCH',
  SET_LAST_PUBLICATIONS_SEARCH = 'SET_LAST_PUBLICATIONS_SEARCH',
  RESET_LAST_PUBLICATIONS_SEARCH = 'RESET_LAST_PUBLICATIONS_SEARCH',
}
