import { createAction } from 'typesafe-actions';

import { IEventListItem } from 'types';

import { ACTIONS } from './constants';

export const getAreaEventsRequestAction = createAction(ACTIONS.GET_AREA_EVENTS_REQUEST)<{
  monthIndex: number;
  alias?: string;
}>();
export const getAreaEventsSuccessAction = createAction(ACTIONS.GET_AREA_EVENTS_SUCCESS)();
export const getAreaEventsFailureAction = createAction(ACTIONS.GET_AREA_EVENTS_FAILURE)();
export const putAreaEventsToStoreAction = createAction(ACTIONS.PUT_AREA_EVENTS_TO_STORE)<{
  events: IEventListItem[];
}>();
export const clearAreaEventsToStoreAction = createAction(ACTIONS.CLEAR_AREA_EVENTS_TO_STORE)();

export const putCurrentAreaEventsMonthToStoreAction = createAction(
  ACTIONS.PUT_CURRENT_AREA_EVENTS_MONTH_TO_STORE,
)<number>();
