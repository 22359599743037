import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import { useParams, useHistory } from 'react-router-dom';

import { MainTemplate } from 'templates';
import {
  changeRegisterPopupVisibleAction,
  getTagsRequestAction,
  resetCatalogState,
  resetLastCatalogSearchAction,
} from 'core/actions';
import { Breadcrumb, Button, Container } from 'atoms';
import { CatalogCategories } from 'molecules';
import { WhiteLogo } from 'assets/icons';
import { getCatalogCategoriesFromStore, getIsLoggedIn } from 'core/selectors';

import { Form, Results } from './Components';

export const Catalog = () => {
  const dispatch = useDispatch();
  const { area, category } = useParams<{ area?: string; category: string }>();
  const history = useHistory();
  const screen = useBreakpoint();
  const categories = useSelector(getCatalogCategoriesFromStore);
  const isLoggedIn = useSelector(getIsLoggedIn);

  const categoryTitle = useMemo(() => {
    return categories.find((categoryItem) => categoryItem.alias === category)?.title;
  }, [category, categories]);

  const registerHandler = () => {
    dispatch(changeRegisterPopupVisibleAction(true));
  };

  useEffect(() => {
    const unlisten = history.listen((location) => {
      const isCurrentAppSection = location.pathname.includes('/catalog') || location.pathname.includes('/event');

      if (!isCurrentAppSection) {
        dispatch(resetLastCatalogSearchAction());
      }
    });

    return () => {
      unlisten();
    };
  }, [history, dispatch]);

  useEffect(() => {
    dispatch(getTagsRequestAction());

    return () => {
      dispatch(resetCatalogState());
    };
  }, [dispatch, area]);

  const goToCategory = () => {
    if (area) {
      history.push(`/area/${area}`);
    }
  };

  const goToPrograms = () => {
    if (area && categories[0]?.areaTitle) {
      history.push(`/area/${area}/catalog/all`);
      return;
    }
    history.push('/catalog/all');
  };

  return (
    <MainTemplate isMenuButtonVisible>
      <TopBackground>
        <Container>
          <Breadcrumb>
            {area && categories[0]?.areaTitle && (
              <Breadcrumb.Item onClick={goToCategory}>{categories[0].areaTitle}</Breadcrumb.Item>
            )}
            <Breadcrumb.Item onClick={goToPrograms}>Образовательные программы</Breadcrumb.Item>
            {categoryTitle && <Breadcrumb.Item>{categoryTitle}</Breadcrumb.Item>}
          </Breadcrumb>
          <Title>Образовательные программы</Title>
          <CatalogCategories />
        </Container>
      </TopBackground>
      <Container>
        <Form />
        <Results />
      </Container>
      <BottomRegistration>
        <ContainerStyled>
          {screen.md && (
            <Row wrap={false} gutter={22} align="middle" justify="space-between">
              <Col>
                <WhiteLogo />
              </Col>
              <Col flex={1}>Образовательные программы для медицинского сообщества</Col>
              {!isLoggedIn && (
                <Col>
                  <RegisterButton onClick={registerHandler}>Зарегистрироваться</RegisterButton>
                </Col>
              )}
            </Row>
          )}
        </ContainerStyled>
      </BottomRegistration>
    </MainTemplate>
  );
};

const TopBackground = styled.div`
  background: #4853ec;
  padding: 32px 0 56px 0;

  @media (min-width: 768px) {
    padding: 32px 0 72px 0;
  }

  @media (min-width: 1200px) {
    padding: 48px 0 72px 0;
  }
`;

const Title = styled.h1`
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  margin-bottom: 32px;

  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 72px;
  }

  @media (min-width: 1200px) {
    font-size: 48px;
    line-height: 56px;
  }
`;

const BottomRegistration = styled.div`
  color: #ffffff;
  min-height: 24px;
  margin-top: 20px;
  background: #4853ec;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
`;

const ContainerStyled = styled(Container)`
  padding-top: 15px;
  padding-bottom: 15px;
`;

const RegisterButton = styled(Button)`
  &.ant-btn-primary {
    background-color: #ff8686;
    &:hover {
      background-color: #ff8686;
    }
  }
`;
