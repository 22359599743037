import { createAction } from 'typesafe-actions';

import { IEventListItem } from 'types';

import { ACTIONS } from './constants';

export const getHomeEventsRequestAction = createAction(ACTIONS.GET_HOME_EVENTS_REQUEST)<{
  monthIndex: number;
  alias?: string;
}>();
export const getHomeEventsSuccessAction = createAction(ACTIONS.GET_HOME_EVENTS_SUCCESS)();
export const getHomeEventsFailureAction = createAction(ACTIONS.GET_HOME_EVENTS_FAILURE)();
export const putHomeEventsToStoreAction = createAction(ACTIONS.PUT_HOME_EVENTS_TO_STORE)<{
  events: IEventListItem[];
}>();
export const clearHomeEventsToStoreAction = createAction(ACTIONS.CLEAR_HOME_EVENTS_TO_STORE)();

export const putCurrentHomeEventsMonthToStoreAction = createAction(
  ACTIONS.PUT_CURRENT_HOME_EVENTS_MONTH_TO_STORE,
)<number>();
