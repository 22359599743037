import React from 'react';
import styled from 'styled-components';

import { PageSwitchers } from './PageSwitchers';
import { PaginatorItem } from './PaginatorItem';

interface IProps {
  pagesCount: number;
  activePage: number;
  setActivePage: (page: number) => void;
}

export const Paginator = ({ pagesCount, activePage, setActivePage }: IProps) => {
  const nextPageOnClick = () => {
    if (activePage < pagesCount) {
      setActivePage(activePage + 1);
    }
  };

  const prevPageOnClick = () => {
    if (activePage > 1) {
      setActivePage(activePage + -1);
    }
  };

  const pageOnClick = (pageNumber: number) => {
    setActivePage(pageNumber);
  };

  if (pagesCount < 2) return null;

  return (
    <Container>
      <Paginators>{getPagination(pagesCount, activePage, pageOnClick)}</Paginators>
      <Switchers next={nextPageOnClick} prev={prevPageOnClick} activePage={activePage} pagesCount={pagesCount} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;

const Paginators = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    width: 70%;
  }
`;

const Switchers = styled(PageSwitchers)`
  margin-left: auto;
`;

function getPagination(pagesCount: number, activePage: number, onClick: (pageNumber: number) => void) {
  const pagination = [];

  for (let i = 0; i < pagesCount; i += 1) {
    pagination.push(
      <PaginatorItem isActive={i + 1 === activePage} onClick={() => onClick(i + 1)} key={i + 1}>
        {i + 1}
      </PaginatorItem>,
    );
  }

  return pagination;
}
