import React from 'react';
import { Checkbox as AntdCheckbox, CheckboxProps } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import styled, { css } from 'styled-components';

interface IProps extends CheckboxProps {
  caption?: string;
  onChange?(e: RadioChangeEvent): void;
  checked?: boolean;
  disabled?: boolean;
  isError?: boolean;
}

export const Checkbox = ({
  caption = '',
  onChange,
  checked = false,
  disabled = false,
  className,
  isError = false,
}: IProps) => (
  <CheckboxContainer isError={isError} className={className}>
    <AntdCheckbox disabled={disabled} checked={checked} onChange={onChange}>
      {caption}
    </AntdCheckbox>
  </CheckboxContainer>
);

const CheckboxContainer = styled.div<{ isError: boolean }>`
  ${({ theme: { checkboxActiveBgColor, checkboxErrBorderColor }, isError }) => css`
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: 14px;

    .ant-checkbox-wrapper {
      align-items: center;

      .ant-checkbox {
        top: 0;
      }
    }

    .ant-checkbox:hover::after,
    .ant-checkbox-wrapper:hover .ant-checkbox::after {
      visibility: hidden;
    }

    .ant-checkbox-inner {
      height: 24px;
      width: 24px;
      border-radius: 4px;

      &:after {
        left: 34%;
      }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${checkboxActiveBgColor};
      border-color: ${checkboxActiveBgColor};
    }

    ${isError &&
    `
    .ant-checkbox-inner {
      border-color: ${checkboxErrBorderColor};
    } 
    `}
  `}
`;
