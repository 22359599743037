import React from 'react';
import styled from 'styled-components';
import { WrappedFieldProps } from 'redux-form';

import { FormItem, TextInput } from 'atoms';

export const SearchString = ({ input: { value, onChange } }: WrappedFieldProps) => {
  return (
    <FormItem label="Поиск" $labelTop>
      <StyledTextInput value={value} onChange={onChange} type="secondary" placeholder="Ключевое слово" />
    </FormItem>
  );
};

const StyledTextInput = styled(TextInput)`
  padding: 0px;
`;
