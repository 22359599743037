import React from 'react';
import { Modal, ModalProps } from 'antd';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';

import { getIsLoginLoading } from 'core/selectors';

import { LoginForm } from './LoginForm';

interface IProps extends ModalProps {
  showRegModal?(): void;
}

export const LoginModal = ({showRegModal, ...props }: IProps) => {
  const isLoginLoading = useSelector(getIsLoginLoading);

  return (
    <ModalContainer {...props} maskClosable={!isLoginLoading}>
      <Loading isLoading={isLoginLoading} />
      <LoginForm showRegModal={showRegModal} />
    </ModalContainer>
  );
};

const ModalContainer = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
    overflow: hidden;

    .ant-modal-close {
      top: 24px;
      right: 32px;

      .ant-modal-close-x {
        font-size: 20px;
        width: 26px;
        height: 26px;
        line-height: 26px;
      }

      @media (min-width: 768px) {
        top: 32px;
        right: 40px;

        .ant-modal-close-x {
          font-size: 25px;
          width: 36px;
          height: 36px;
          line-height: 36px;
        }
      }

      @media (min-width: 1200px) {
        top: 64px;
        right: 70px;

        .ant-modal-close-x {
          font-size: 30px;
          width: 56px;
          height: 56px;
          line-height: 56px;
        }
      }
    }

    .ant-modal-header {
      border: none;
      padding: 24px 32px;

      .ant-modal-title {
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 18px;
        line-height: 28px;
        color: #000000;
      }

      @media (min-width: 768px) {
        padding: 32px 40px;

        .ant-modal-title {
          font-size: 22px;
          line-height: 40px;
        }
      }

      @media (min-width: 1200px) {
        padding: 64px 70px;

        .ant-modal-title {
          font-size: 32px;
          line-height: 48px;
        }
      }
    }

    .ant-modal-body {
      padding: 0 32px 24px 32px;

      @media (min-width: 768px) {
        padding: 0 40px 32px 40px;
      }

      @media (min-width: 1200px) {
        padding: 0 70px 64px 70px;
      }
    }
  }
`;

const Loading = styled.div<{ isLoading: boolean }>`
  ${({ isLoading }) =>
    css`
      transition: opacity 0.2s;

      background-color: white;
      border-radius: 12px;
      z-index: 10;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      opacity: 0;
      visibility: hidden;

      ${isLoading &&
      css`
        opacity: 0.5;
        visibility: visible;
      `};
    `}
`;
