import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getAreasAlias, getIsPublicationsLoading } from 'core/selectors';
import { getPublicationsRequestAction } from 'core/actions';

import { PublicationsForm } from './Form';

interface IFormContainerProps {
  color?: string;
}

export const FormContainer = ({ color }: IFormContainerProps) => {
  const dispatch = useDispatch();
  const { area } = useParams<{ area?: string }>();
  const areasAlias = useSelector(getAreasAlias);

  const searchResultLoading = useSelector(getIsPublicationsLoading);

  const formOnSubmit = useCallback(() => {
    dispatch(getPublicationsRequestAction({ alias: area }));
  }, [dispatch, area]);

  return (
    <PublicationsForm
      handleSubmit={formOnSubmit}
      handleOnChange={formOnSubmit}
      loading={searchResultLoading}
      area={area}
      areasAlias={areasAlias}
      color={color}
    />
  );
};
