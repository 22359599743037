import React from 'react';
import { Row, Col } from 'antd';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';

import { Button } from 'atoms';
import { createPasswordRequestAction } from 'core/actions';
import { store } from 'core';
import { validationService } from 'services';
import { ICreatePasswordFormValues, IFormErrors } from 'types';
import { getIsCreatePasswordLoading } from 'core/selectors';

import { PasswordField } from './Components/PasswordField';

const Form = ({ handleSubmit }: InjectedFormProps<ICreatePasswordFormValues>) => {
  const isLoading = useSelector(getIsCreatePasswordLoading);

  return (
    <>
      <Row gutter={[0, 30]}>
        <Col xs={24}>
          <Field name="password" caption="Новый пароль" format={null} component={PasswordField} required isPassword />
        </Col>
        <Col xs={24}>
          <Field
            name="repeatedPassword"
            caption="Повторите пароль"
            format={null}
            component={PasswordField}
            required
            isPassword
          />
        </Col>
      </Row>
      <Row style={{ marginTop: '60px' }}>
        <Col xs={24}>
          <Button type="primary" block onClick={handleSubmit} loading={isLoading}>
            Задать пароль
          </Button>
        </Col>
      </Row>
    </>
  );
};

const validate = ({ password, repeatedPassword }: ICreatePasswordFormValues) => {
  return {
    password: validationService.validatePassword(password),
    repeatedPassword: validationService.validateRepeatedPassword(password, repeatedPassword),
  } as IFormErrors;
};

const onSubmit = (formData: ICreatePasswordFormValues) => {
  store.dispatch(createPasswordRequestAction(formData));
};

export const CreatePasswordForm = reduxForm<ICreatePasswordFormValues>({
  form: 'CreatePasswordForm',
  validate,
  onSubmit,
  touchOnBlur: false,
})(Form);
