import React from 'react';
import { Row, Col } from 'antd';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { Button, FormServerError } from 'atoms';
import { forgotPasswordRequestAction } from 'core/actions';
import { store } from 'core';
import { validationService } from 'services';
import { IForgotPasswordFormValues, IFormErrors } from 'types';
import { getIsForgotPasswordLoading } from 'core/selectors';

import { TextField } from './Components/TextField';

interface IProps {
  showLoginModal?: () => void;
}

const Form = ({ showLoginModal, handleSubmit }: InjectedFormProps<IForgotPasswordFormValues, IProps> & IProps) => {
  const isLoading = useSelector(getIsForgotPasswordLoading);

  return (
    <>
      <Field name="email" caption="E-mail" format={null} component={TextField} required onlyLowerCase />
      <Note>
        На указанный вами адрес будет отправлено письмо. Пожалуйста, проверьте вашу почту и откройте данное письмо.
      </Note>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={0}>
          <Button type="primary" block onClick={handleSubmit} loading={isLoading}>
            Восстановить пароль
          </Button>
        </Col>
        <Col xs={24} md={isLoading ? 10 : 12}>
          <Button type="default" block onClick={showLoginModal}>
            Вернуться назад
          </Button>
        </Col>
        <Col xs={0} md={isLoading ? 14 : 12}>
          <Button type="primary" block onClick={handleSubmit} loading={isLoading}>
            Восстановить пароль
          </Button>
        </Col>
      </Row>
      <FormServerError />
    </>
  );
};

const validate = ({ email }: IForgotPasswordFormValues) => {
  return {
    email: validationService.validateEmail(email),
  } as IFormErrors;
};

const onSubmit = (formData: IForgotPasswordFormValues) => {
  store.dispatch(forgotPasswordRequestAction({ ...formData, email: formData.email.trim().toLowerCase() }));
};

export const ForgotPasswordForm = reduxForm<IForgotPasswordFormValues, IProps>({
  form: 'ForgotPasswordForm',
  validate,
  onSubmit,
  touchOnBlur: false,
})(Form);

const Note = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #9da0aa;
  margin: 32px 0 40px 0;
  display: inline-block;
`;
