import { createAction } from 'typesafe-actions';

import { IPublicationCategory, IPublication, IPublicationExtended } from 'types';

import { ACTIONS } from './constants';

// Categories
export const putCurrentPublicationsCategoryToStoreAction = createAction(
  ACTIONS.PUT_CURRENT_PUBLICATIONS_CATEGORY_TO_STORE,
)<{
  id?: number;
}>();
export const getPublicationsCategoriesRequestAction = createAction(ACTIONS.GET_PUBLICATIONS_CATEGORIES_REQUEST)<{
  alias?: string;
}>();
export const getPublicationsCategoriesSuccessAction = createAction(ACTIONS.GET_PUBLICATIONS_CATEGORIES_SUCCESS)();
export const getPublicationsCategoriesFailureAction = createAction(ACTIONS.GET_PUBLICATIONS_CATEGORIES_FAILURE)();
export const putPublicationsCategoriesToStoreAction = createAction(ACTIONS.PUT_PUBLICATIONS_CATEGORIES_TO_STORE)<{
  categories: IPublicationCategory[];
}>();

// Publications
export const getPublicationsRequestAction = createAction(ACTIONS.GET_PUBLICATIONS_REQUEST)<{ alias?: string }>();
export const getMorePublicationsRequestAction = createAction(ACTIONS.GET_MORE_PUBLICATIONS_REQUEST)<{
  alias?: string;
}>();
export const getPublicationsSuccessAction = createAction(ACTIONS.GET_PUBLICATIONS_SUCCESS)();
export const getPublicationsFailureAction = createAction(ACTIONS.GET_PUBLICATIONS_FAILURE)();
export const putPublicationsToStoreAction = createAction(ACTIONS.PUT_PUBLICATIONS_TO_STORE)<{
  publications: IPublication[];
  total: number;
}>();
export const clearPublicationsToStoreAction = createAction(ACTIONS.CLEAR_PUBLICATIONS_TO_STORE)();

export const putPublicationsPageToStoreAction = createAction(ACTIONS.PUT_PUBLICATIONS_PAGE_TO_STORE)<{
  page: number;
}>();

// Publication
export const getPublicationRequestAction = createAction(ACTIONS.GET_PUBLICATION_REQUEST)<{ id: string }>();
export const getPublicationSuccessAction = createAction(ACTIONS.GET_PUBLICATION_SUCCESS)();
export const getPublicationFailureAction = createAction(ACTIONS.GET_PUBLICATION_FAILURE)();
export const putPublicationToStoreAction = createAction(ACTIONS.PUT_PUBLICATION_TO_STORE)<{
  publication: IPublicationExtended;
}>();
export const clearPublicationToStoreAction = createAction(ACTIONS.CLEAR_PUBLICATION_TO_STORE)();

export interface IPublicationsSearchFormValues {
  searchString: string;
  areaSelect: string;
}

// Publications Search
export const setLastPublicationsSearchAction = createAction(ACTIONS.SET_LAST_PUBLICATIONS_SEARCH)<
  Partial<IPublicationsSearchFormValues>
>();
export const resetLastPublicationsSearchAction = createAction(ACTIONS.RESET_LAST_PUBLICATIONS_SEARCH)();
