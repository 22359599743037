import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { Spin } from 'antd';
import ReactHtmlParser from 'react-html-parser';

import { getHome, getIsHomeLoading } from 'core/selectors';
import { MainTemplate } from 'templates';
import {
  changeCreatePasswordPopupVisibleAction,
  putCreatePasswordTokenToStoreAction,
  createPasswordRequestAction,
  changeLoginPopupVisibleAction,
  getHomeRequestAction,
  clearHomeToStoreAction,
} from 'core/actions';
import { useOnRequestSuccess } from 'hooks';
import { CalendarHome, PartnersHome, BannersHomeCarousel, Areas, EMediQOriginals, UpcomingEvents } from 'organisms';
import { Container } from 'atoms';
import { AreaSwitcher } from 'components/molecules';

export const Home = memo(() => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { token } = useParams<{ token?: string }>();

  const { title, description, backgroundUrl } = useSelector(getHome);
  const isHomeLoading = useSelector(getIsHomeLoading);

  useOnRequestSuccess(createPasswordRequestAction, () => {
    push(`/`);
    dispatch(changeLoginPopupVisibleAction(true));
  });

  useEffect(() => {
    dispatch(getHomeRequestAction());

    return () => {
      dispatch(clearHomeToStoreAction());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isHomeLoading) {
      return undefined;
    }

    if (token) {
      dispatch(putCreatePasswordTokenToStoreAction(token));
      dispatch(changeCreatePasswordPopupVisibleAction(true));
    }

    return undefined;
  }, [dispatch, token, isHomeLoading]);

  if (isHomeLoading) return <Spin style={{ position: 'absolute', left: '48%', top: '48%' }} size="large" />;

  return (
    <MainTemplate isMenuButtonVisible>
      <TopBackground backgroundUrl={backgroundUrl}>
        <Container>
          {title && <Title>{title}</Title>}
          {description && <SubTitle>{ReactHtmlParser(description.replace(/\r\n/g, ' <br> '))}</SubTitle>}
          <AreaSwitcher />
        </Container>
      </TopBackground>
      <UpcomingEvents />
      <Areas />
      <BannersHomeCarousel />
      <EMediQOriginals />
      <CalendarHome />
      <PartnersHome />
    </MainTemplate>
  );
});

const TopBackground = styled.div<{ backgroundUrl?: string }>`
  ${({ backgroundUrl = '', theme: { topBackgroundColor } }) => css`
    padding: 80px 0 15px 0;
    background-color: ${topBackgroundColor};
    background-image: url(${backgroundUrl});
    background-repeat: no-repeat;
    background-position: 50% 0;
    position: relative;
    z-index: 0;
    background-size: cover;

    &:before {
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0.8;
      background: linear-gradient(275.17deg, #525ded 24.3%, #121a7b 102.71%);
    }

    @media (min-width: 768px) {
      padding: 100px 0 60px 0;
    }
  `}
`;

const Title = styled.h1`
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;

  @media (min-width: 768px) {
    font-size: 56px;
    line-height: 48px;
  }

  @media (min-width: 1200px) {
    font-size: 64px;
    line-height: 72px;
  }
`;

const SubTitle = styled.h2`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: #ffffff;
  margin: 0 0 72px 0;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
    font-weight: normal;
    margin: 0 0 96px 0;
  }

  @media (min-width: 1200px) {
    margin: 0 0 104px 0;
    width: 75%;
  }
`;
