import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { DownOutlined } from '@ant-design/icons';
import ReactHtmlParser from 'react-html-parser';

import { IAuthor } from 'types';

interface IProps {
  time?: string;
  title?: string;
  description?: string;
  authors?: IAuthor[];
  color?: string;
}

export const EventProgramItem = ({ time = '', title = '', description = '', authors, color }: IProps) => {
  const [isOpened, setIsOpened] = useState(false);

  const triggerClickhandler = () => {
    setIsOpened(!isOpened);
  };

  const onBlur = () => {
    setIsOpened(false);
  };

  return (
    <BorderContainer isOpened={isOpened} color={color}>
      <Container tabIndex={0} onBlur={onBlur}>
        <Trigger isOpened={isOpened} color={color} onClick={triggerClickhandler}>
          <DownOutlined />
        </Trigger>
        <Caption>
          <Time color={color}>{time}</Time>
          <Title>{title}</Title>
        </Caption>
        <Content isOpened={isOpened}>
          <Description>{ReactHtmlParser(description.replace(/\r\n/g, ' <br> '))}</Description>

          {authors && authors.length > 0 && (
            <>
              <AuthorsTitle>Лекторы</AuthorsTitle>
              <Authors>{authors.map((author) => author.fullName).join(', ')}</Authors>
            </>
          )}
        </Content>
      </Container>
    </BorderContainer>
  );
};

interface IBorderContainerProps {
  isOpened?: boolean;
  color?: string;
}

const BorderContainer = styled.div<IBorderContainerProps>`
  ${({ isOpened, color }) => css`
    position: relative;
    padding: 0 0 16px 0;

    @media (min-width: 768px) {
      padding: 0 0 27px 64px;
    }

    &:before {
      content: '';
      display: none;
      position: absolute;
      background: ${color || '#4853ec'};
      width: 4px;
      left: 0;
      top: 0;
      bottom: 0;

      @media (min-width: 768px) {
        display: block;
      }
    }

    &:first-child {
      &:before {
        top: 60px;
      }
    }

    &:last-child {
      &:before {
        top: 0;
        height: 60px;
        bottom: auto;
      }
    }

    &:after {
      content: '';
      display: none;
      position: absolute;
      background: ${color || '#4853ec'};
      width: 32px;
      height: 32px;
      border-radius: 30px;
      left: -26px;
      top: 40px;
      transition: border-color 0.3s ease-in-out;
      border: 12px solid rgba(72, 83, 236, 0.24);
      border-color: ${isOpened ? 'rgba(72, 83, 236, 0.24)' : 'transparent'};
      background-clip: content-box;
      box-sizing: content-box;

      @media (min-width: 768px) {
        display: block;
      }
    }
  `}
`;

const Container = styled.div`
  background: #ffffff;
  position: relative;
  border-radius: 12px;
  box-shadow: 0 4px 10px 0 rgba(72, 83, 236, 0.08);
  padding: 24px 24px 24px 16px;
  outline: none;

  @media (min-width: 768px) {
    padding: 48px 94px 48px 32px;
  }
`;

interface ITriggerProps {
  isOpened?: boolean;
  color?: string;
}

const Trigger = styled.div<ITriggerProps>`
  ${({ isOpened, color }) => css`
    position: absolute;
    right: 23px;
    top: 23px;

    font-size: 20px;
    color: ${color || '#4853ec'};
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    transform: ${isOpened ? 'rotate(180deg)' : 'rotate(0)'};

    @media (min-width: 768px) {
      font-size: 40px;
      right: 50px;
      top: 40px;
    }
  `}
`;

const Title = styled.div`
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #01050f;
  margin: 0 0 8px 0;

  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 32px;
  }
`;

const Time = styled.div<{ color?: string }>`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.32px;
  color: ${({ color }) => color || '#4853ec'};
  margin: 0 0 5px 0;

  @media (min-width: 768px) {
    font-size: 40px;
    line-height: 48px;
  }
`;

const Caption = styled.div`
  width: 100%;
`;

interface IContentProps {
  isOpened?: boolean;
}

const Content = styled.div<IContentProps>`
  ${({ isOpened }) => css`
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    max-height: ${isOpened ? '1500px' : '0px'};
  `}
`;

const Description = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.17px;
  color: #01050f;
`;

const AuthorsTitle = styled.div`
  font-family: 'Raleway', sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #9da0aa;
  margin-top: 32px;
`;

const Authors = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
`;
