import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { Star } from 'assets/icons';
import { setEventRatingRequestAction } from 'core/actions';
import { IEventMarkDto } from 'types';

interface IStartRatingProps {
  eventId: number;
  authorId: string | null;
  rating: number;
  averageMark: number;
  authorName?: string;
}

export const StarRating = ({ authorId, rating, authorName, averageMark, eventId }: IStartRatingProps) => {
  const dispatch = useDispatch();

  const rateEvent = (mark: number) => {
    const dto: IEventMarkDto = {
      eventId,
      mark,
      ...(authorId && authorId !== '0' && { authorId }),
    };
    dispatch(setEventRatingRequestAction(dto));
  };

  return (
    <StarRatingWrapper>
      <LeftSideWrapper>
        <StarRatingText>{authorName || 'Ваша реакция'}</StarRatingText>
        {authorName === 'Ваша реакция' ? (
          <AverageRatingStyled>
            Средняя оценка мероприятия - <span>{averageMark}</span>
          </AverageRatingStyled>
        ) : null}
      </LeftSideWrapper>
      <StarsWrapper>
        {Array.from({ length: 5 }).map((_, index) => {
          const ratingIndex = index + 1;
          return <StarStyled key={ratingIndex} $isFilled={index < rating} onClick={() => rateEvent(ratingIndex)} />;
        })}
      </StarsWrapper>
    </StarRatingWrapper>
  );
};

const StarRatingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LeftSideWrapper = styled.div``;

const AverageRatingStyled = styled.div`
  font-style: italic;
  margin-top: 4px;
  font-size: 13px;

  & > span {
    font-weight: 500;
  }

  @media (min-width: 768px) {
    font-size: 15px;

    & > span {
      font-weight: 600;
    }
  }
`;

const StarsWrapper = styled.div`
  display: flex;
`;

const StarRatingText = styled.div`
  color: #01050f;
  font-size: 18px;
  font-family: 'Raleway';
  font-weight: 700;
  line-height: 32px;
`;

const StarStyled = styled(Star)<{ $isFilled: boolean }>`
  cursor: pointer;
  width: 32px;
  height: 32px;

  & > path {
    fill-opacity: ${({ $isFilled }) => ($isFilled ? '1' : '0.24')};
  }

  &:not(:first-of-type) {
    margin-left: 4px;
  }
`;
