import { createAction } from 'typesafe-actions';

import {
  ISurveysRequest,
  ISurveyAnswersDto,
  SurveyAnswerDtoType,
  ISurvey,
  ISurveys,
  ISurveyCustomAnswerDto,
} from 'types';

import { ACTIONS } from './constants';

export const getSurveysRequestAction = createAction(ACTIONS.GET_SURVEYS_REQUEST)<ISurveysRequest>();
export const getSurveysSuccessAction = createAction(ACTIONS.GET_SURVEYS_SUCCESS)();
export const getSurveysFailureAction = createAction(ACTIONS.GET_SURVEYS_FAILURE)();
export const saveSurveyToStoreAction = createAction(ACTIONS.SAVE_SURVEY_TO_STORE)<ISurvey>();

export const getAllSurveysRequestAction = createAction(ACTIONS.GET_ALL_SURVEYS_REQUEST)<{ page?: number }>();
export const saveSurveysQtyToStoreAction = createAction(ACTIONS.SAVE_SURVEYS_QTY_TO_STORE)<number>();
export const saveAllSurveysToStoreAction = createAction(ACTIONS.SAVE_All_SURVEYS_TO_STORE)<ISurveys>();

export const resetSurveyAction = createAction(ACTIONS.RESET_SURVEY)();

export const saveSurveyAnswersToStoreAction = createAction(ACTIONS.SAVE_SURVEY_ANSWERS_TO_STORE)<{
  surveyId?: number;
  answers: SurveyAnswerDtoType;
}>();
export const setSurveyAnswersRequestAction = createAction(ACTIONS.SET_SURVEY_ANSWERS_REQUEST)<{
  surveyId: number;
  surveyDto: ISurveyAnswersDto;
}>();
export const setSurveyAnswersSuccessAction = createAction(ACTIONS.SET_SURVEY_ANSWERS_SUCCESS)();
export const setSurveyAnswersFailureAction = createAction(ACTIONS.SET_SURVEY_ANSWERS_FAILURE)<string>();
export const resetSurveyAnswersStatusAction = createAction(ACTIONS.RESET_SURVEY_ANSWERS_STATUS)();

export const setSurveyCustomAnswerRequestAction = createAction(ACTIONS.SET_SURVEY_CUSTOM_ANSWER_REQUEST)<{
  customAnswerDto: ISurveyCustomAnswerDto;
}>();
export const setSurveyCustomAnswerSuccessAction = createAction(ACTIONS.SET_SURVEY_CUSTOM_ANSWER_SUCCESS)();
export const setSurveyCustomAnswerFailureAction = createAction(ACTIONS.SET_SURVEY_CUSTOM_ANSWER_FAILURE)<string>();

export const setSurveyStepAction = createAction(ACTIONS.SET_SURVEY_STEP)();
