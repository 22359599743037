import {
  IApiArea,
  IApiEventListItem,
  IArea,
  IEventListItem,
  IAreasAlias,
  IAreaCropped,
  IApiAreaCropped,
  IApiAreasAlias,
  IApiPopularArea,
  IPopularArea,
  IApiAreaSocials,
  IAreaSocials,
  Socials,
  IAreaShortened,
} from 'types';

import { convertAuthorsToAppSchema } from './authors';
import { convertEventsCategoriesToAppSchema } from './events';
import { convertPartnersToAppSchema } from './partners';

export const convertAreaToAppSchema = (area: IApiArea): IArea => {
  return {
    id: area.id,
    title: area.title,
    alias: area.alias,
    description: area.description,
    backgroundUrl: area.background_url,
    categories: convertEventsCategoriesToAppSchema(area.categories),
    partners: convertPartnersToAppSchema(area.partners),
    upcomingEvents: area.upcoming_events.map((eventItem) => transformEvent(eventItem)),
    interestingEvents: area.interesting_events.map((eventItem) => transformEvent(eventItem)),
    socialNetworks: convertAreaSocialsToAppSchema(area.social_networks),
    color: area.color,
    banners: area.banners.map((banner) => ({
      name: banner.name,
      desktopUrl: banner.desktop_url,
      mobileUrl: banner.mobile_url,
      url: banner.url,
    })),
  };
};

export const convertAreasAliasToAppSchema = (areasAlias: IApiAreasAlias[]): IAreasAlias[] => {
  return areasAlias.map((alias) => ({ areasAlias: alias.alias, title: alias.title, shortTitle: alias.short_title }));
};

function transformEvent(eventItem: IApiEventListItem): IEventListItem {
  return {
    id: eventItem.id,
    title: eventItem.title,
    description: eventItem.description,
    startDate: eventItem.start_date,
    thumbnailUrl: eventItem.thumbnail_url,
    viewingCount: eventItem.viewing_count,
    subscribed: eventItem.subscribed,
    isInteresting: eventItem.is_interesting,
    authors: convertAuthorsToAppSchema(eventItem.authors),
    area: {
      alias: eventItem.area.alias,
      title: eventItem.area.title,
      shortTitle: eventItem.area.short_title,
    },
    category: {
      id: eventItem.category.id,
      title: eventItem.category.title,
      shortTitle: eventItem.category.short_title,
    },
    tags: eventItem.tags.map((tag) => ({
      name: tag.name,
      color: tag.color,
    })),
    marks: eventItem.marks,
    averageMark: eventItem.average_mark,
    checkpoints: eventItem.checkpoints,
    userCode: eventItem.user_code,
    questionsIds: eventItem.questions_ids,
  };
}

export const convertAreasCroppedToAppSchema = (apiAreasCropped: IApiAreaCropped[]): IAreaCropped[] => {
  return apiAreasCropped.map((area) => ({
    alias: area.alias,
    logoUrl: area.thumbnail_url,
    title: area.title,
    description: area.description,
    viewsCount: area.total_viewings,
    videosCount: area.total_events,
    authorsCount: 0,
  }));
};

export const convertAllAreasToAppSchema = (apiAreas: IApiAreaCropped[]): IAreaShortened[] => {
  return apiAreas.map((area) => ({
    alias: area.alias,
    title: area.title,
  }));
};

export const convertPopularAreasToAppSchema = (popularAreas: IApiPopularArea[]): IPopularArea[] => {
  return popularAreas.map((area) => ({
    title: area.title,
    alias: area.alias,
    shortTitle: area.short_title,
  }));
};

export const convertAreaSocialsToAppSchema = (socials: IApiAreaSocials[]): IAreaSocials[] => {
  return socials.map((social) => ({
    type: Socials[social.kind],
    link: social.link,
  }));
};
