import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import { getEventsLoadingFromStore, getTagsFromStore } from 'core/selectors';
import { getCatalogEventsRequestAction } from 'core/actions';

import { CatalogForm } from './Form';

export const FormContainer = () => {
  const dispatch = useDispatch();
  const screen = useBreakpoint();
  const { area } = useParams<{ area?: string }>();

  const tags = useSelector(getTagsFromStore);
  const searchResultLoading = useSelector(getEventsLoadingFromStore);

  const formOnSubmit = useCallback(() => {
    dispatch(getCatalogEventsRequestAction({ alias: area }));
  }, [dispatch, area]);

  return (
    <CatalogForm
      handleSubmit={formOnSubmit}
      handleOnChange={formOnSubmit}
      tags={tags}
      loading={searchResultLoading}
      screen={screen}
    />
  );
};
