import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { Row, Col } from 'antd';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';

import { IAuthor } from 'types';
import { AuthorCard, PaginatorAuthors } from 'components/molecules';
import { Button } from 'components/atoms';
import { AuthorService } from 'services';

interface IProps {
  result: IAuthor[];
  total: number;
  loading: boolean;
  cardOnClick: (id: number) => void;
  loadMoreOnClick: () => void;
  screen: Partial<Record<Breakpoint, boolean>>;
  color?: string;
}

export const Results = memo(({ cardOnClick, total, result, loading, screen, color, loadMoreOnClick }: IProps) => {
  return (
    <Wrapper isLoading={loading}>
      <RowStyled gutter={[15, 15]} justify="space-between">
        {result.map((item) => (
          <Col xs={screen.md ? undefined : 24} key={item.id}>
            <AuthorCard
              color={color}
              onClick={() => cardOnClick(item.id)}
              description={item.description}
              imageUrl={item.imageUrl}
              fullName={item.fullName}
              position={item.position}
            />
          </Col>
        ))}
      </RowStyled>

      <RowStyled2 gutter={[15, 40]} justify={screen.md ? undefined : 'center'}>
        <Col xs={0} md={24}>
          <PaginatorAuthors />
        </Col>
        {total - result.length > 0 && (
          <Col xs={24} md={0}>
            <SeeAllButton onClick={loadMoreOnClick} type="ghost">
              Загрузить еще{' '}
              {total - result.length > AuthorService.defaultAuthorsLimit
                ? AuthorService.defaultAuthorsLimit
                : total - result.length}{' '}
              публикаций
            </SeeAllButton>
          </Col>
        )}
      </RowStyled2>
    </Wrapper>
  );
});

const Wrapper = styled.div<{ isLoading: boolean }>`
  ${({ isLoading }) => css`
    margin: 0;
    transition: all 0.2s;
    ${isLoading && WrapperLoadingStyles}
  `}
`;

const RowStyled = styled(Row)`
  &::after {
    content: '';
    width: 384px;
  }
`;

const RowStyled2 = styled(Row)`
  margin-top: 25px;

  @media (min-width: 768px) {
    margin-top: 56px;
  }
`;

const WrapperLoadingStyles = css`
  opacity: 0.5;
  pointer-events: none;
`;

const SeeAllButton = styled(Button)`
  width: 100%;
`;
