import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import {
  getPublications,
  getPublicationsPage,
  getIsPublicationsLoading,
  getPublicationsCategoriesFromStore,
} from 'core/selectors';
import { getMorePublicationsRequestAction, getPublicationsRequestAction } from 'core/actions';

import { Results } from './Results';

export const ResultsContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { area, category } = useParams<{ area?: string; category: string }>();
  const screen = useBreakpoint();

  const categories = useSelector(getPublicationsCategoriesFromStore);
  const { publications, total } = useSelector(getPublications);
  const page = useSelector(getPublicationsPage);
  const authorsLoading = useSelector(getIsPublicationsLoading);

  const cardOnClick = useCallback(
    (id: number) => {
      history.push(area ? `/area/${area}/publication/${id}` : `/publication/${id}`);
    },
    [history, area],
  );

  const loadMorePublications = useCallback(() => {
    dispatch(getMorePublicationsRequestAction({ alias: area }));
  }, [dispatch, area]);

  useEffect(() => {
    if (categories.length) dispatch(getPublicationsRequestAction({ alias: area }));
  }, [dispatch, area, page, categories, category]);

  return (
    <Results
      total={total}
      cardOnClick={cardOnClick}
      result={publications}
      loading={authorsLoading}
      screen={screen}
      loadMoreOnClick={loadMorePublications}
    />
  );
};
