import qs from 'querystring';

import { BaseService } from 'services';
import { ICitiesApi, ICountriesApi } from 'types';

class PlaceApiService extends BaseService {
  public async getCountries(country: string) {
    const body = { name__icontains: country };

    const { data } = await this.get<ICountriesApi[]>(`counties?${qs.stringify(body)}`);

    return data;
  }

  public async getCities(country: string, city: string) {
    const body = { country_name: country, name__icontains: city };

    const { data } = await this.get<ICitiesApi[]>(`cities?${qs.stringify(body)}`);

    return data;
  }
}

export const PlaceService = new PlaceApiService();
