import React from 'react';
import styled from 'styled-components';

import { Container } from 'components/atoms';

import { PrivateUserData } from './PrivateUserData';

export const UserProfile = () => {
  return (
    <ProfileContainer>
      <PrivateUserData />
    </ProfileContainer>
  );
};

const ProfileContainer = styled(Container)`
  background-color: #ffffff;
  margin-top: -16px;
`;
