import React, { memo, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Button as DefaultButton } from 'atoms';
import { NotFound as NotFoundIcon } from 'assets/icons';
import { MainTemplate } from 'templates';
import { setAppServerError } from 'core/actions';
import { ServerErrors } from 'types';

export const NotFound = memo(() => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const onClickHandler = () => {
    push('/');
  };

  useEffect(() => {
    dispatch(setAppServerError({ error: ServerErrors.None }));
  }, [dispatch]);

  return (
    <MainTemplate isMenuButtonVisible noFooter>
      <Container>
        <FillerTop />

        <Content>
          <NotFoundIcon />

          <Text>Страница не найдена</Text>

          <Description>
            По запрошенному вами адресу, ничего не было найдено. Возможно страница была удалена или ваша ссылка
            некорректна. Попробуйте воспользоваться одним из разделов:
          </Description>

          <Button type="primary" block onClick={onClickHandler}>
            На главную
          </Button>
        </Content>

        <FillerBottom />
      </Container>
    </MainTemplate>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-height: calc(100vh - 72px);

  @media (min-width: 768px) {
    min-height: calc(100vh - 80px);
  }

  @media (min-width: 1200px) {
    min-height: calc(100vh - 120px);
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    margin: 0 0 0 0px;
    max-width: 500px;
    width: 100%;
    padding: 0 20px;
  }

  @media (min-width: 576px) {
    svg {
      margin: 0 0 20px 0;
    }
  }

  @media (min-width: 768px) {
    svg {
      margin: 0 0 0 0;
    }
  }
`;

const Text = styled.p`
  font-family: 'Raleway', sans-serif;
  font-weight: 900;
  font-size: 35px;
  line-height: 40px;
  text-align: center;
  color: #231f20;

  margin: 10px 0 0 0;

  @media (min-width: 768px) {
    margin: 40px 0 0 0;
    font-size: 40px;
    line-height: 56px;
  }
`;

const Description = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  text-align: center;
  max-width: 960px;

  margin: 16px 0 36px 0;

  @media (min-width: 768px) {
    margin: 16px 0 56px 0;
  }
`;

const Button = styled(DefaultButton)`
  max-width: 240px;
  margin: 0 0 50px 0;
`;

const FillerTop = styled.div`
  height: 60px;
  width: 100%;

  @media (min-width: 768px) {
    height: 90px;
  }
`;

const FillerBottom = styled.div`
  background-color: #4853ec;
  height: 60px;
  width: 100%;

  @media (min-width: 768px) {
    height: 90px;
  }
`;
