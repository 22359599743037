import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getPublications, getPublicationsPage } from 'core/selectors';
import { PublicationService } from 'services';
import { putPublicationsPageToStoreAction } from 'core/actions';

import { Paginator } from './Paginator';

export const Container = () => {
  const dispatch = useDispatch();
  const { total } = useSelector(getPublications);
  const page = useSelector(getPublicationsPage);

  const setActivePage = (newPage: number) => {
    dispatch(putPublicationsPageToStoreAction({ page: newPage }));
  };

  return (
    <Paginator
      pagesCount={Math.ceil(total / PublicationService.defaultPublicationsLimit)}
      activePage={page}
      setActivePage={setActivePage}
    />
  );
};
