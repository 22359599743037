import { SagaIterator } from 'redux-saga';
import { takeEvery, put, call } from 'redux-saga/effects';

import {
  startInitAppLoadingAction,
  endInitAppLoadingAction,
  initAppRequestAction,
  initAppSuccessAction,
  initAppFailureAction,
  refreshTokenSuccessAction,
  refreshTokenFailureAction,
  putAreasAliasToStoreAction,
  getAreasAliasSuccessAction,
  getAreasAliasFailureAction,
  putUserDataToStoreAction,
  userDataSuccessAction,
  userDataFailureAction,
} from 'core/actions';
import { areaService, AuthService } from 'services';
import { convertAreasAliasToAppSchema } from 'services/apiMappers';
import { IApiUser } from 'types';

function* initAppSaga() {
  try {
    yield put(startInitAppLoadingAction());

    try {
      yield call(() => AuthService.refreshToken());
      yield put(refreshTokenSuccessAction());
    } catch {
      yield put(refreshTokenFailureAction());
    }

    try {
      const { data } = yield call(() => areaService.getAreasAlias());
      const areasAlias = convertAreasAliasToAppSchema(data);
      yield put(putAreasAliasToStoreAction({ areasAlias }));
      yield put(getAreasAliasSuccessAction());
    } catch {
      yield put(getAreasAliasFailureAction());
    }

    try {
      const userData: IApiUser = yield call(() => AuthService.getUser());
      yield put(putUserDataToStoreAction(userData));
      yield put(userDataSuccessAction());
    } catch {
      yield put(userDataFailureAction());
    }

    yield put(initAppSuccessAction());
  } catch {
    yield put(initAppFailureAction());
  } finally {
    yield put(endInitAppLoadingAction());
  }
}

export function* appRootSaga(): SagaIterator {
  yield takeEvery(initAppRequestAction, initAppSaga);
}
