import { ActionType, createReducer } from 'typesafe-actions';

import {
  resetSurveyAction,
  saveSurveyAnswersToStoreAction,
  saveSurveyToStoreAction,
  saveAllSurveysToStoreAction,
  saveSurveysQtyToStoreAction,
  setSurveyStepAction,
  setSurveyAnswersSuccessAction,
  setSurveyAnswersFailureAction,
  resetSurveyAnswersStatusAction,
} from 'core/actions';
import { ISurvey, ISurveys, SurveyAnswerDtoType } from 'types';

type AnswerStatusType = {
  result: 'success' | 'failure';
  message: string;
};

export interface ISurveysState {
  allSurveys: ISurveys | null;
  surveysQty: number;
  currentSurvey: ISurvey | null;
  currentStep: number;
  surveyAnswers: SurveyAnswerDtoType[];
  answersStatus: AnswerStatusType | null;
}

const defaultState: ISurveysState = {
  allSurveys: null,
  surveysQty: 0,
  currentSurvey: null,
  currentStep: 0,
  surveyAnswers: [],
  answersStatus: null,
};

const actions = {
  saveSurveyToStoreAction,
  saveSurveysQtyToStoreAction,
  resetSurveyAction,
  saveSurveyAnswersToStoreAction,
  setSurveyStepAction,
  setSurveyAnswersSuccessAction,
  setSurveyAnswersFailureAction,
  resetSurveyAnswersStatusAction,
  saveAllSurveysToStoreAction,
};

export const surveysReducer = createReducer<ISurveysState, ActionType<typeof actions>>(defaultState)
  .handleAction(saveAllSurveysToStoreAction, (state, { payload }) => ({
    ...state,
    allSurveys: payload,
  }))
  .handleAction(saveSurveysQtyToStoreAction, (state, { payload }) => ({
    ...state,
    surveysQty: payload,
  }))
  .handleAction(setSurveyStepAction, (state) => ({
    ...state,
    currentStep: state.currentStep + 1,
  }))
  .handleAction(setSurveyAnswersSuccessAction, (state) => ({
    ...state,
    answersStatus: {
      result: 'success',
      message: 'Ответы успешно отправлены',
    },
  }))
  .handleAction(setSurveyAnswersFailureAction, (state, { payload }) => ({
    ...state,
    answersStatus: {
      result: 'failure',
      message: payload,
    },
  }))
  .handleAction(resetSurveyAnswersStatusAction, (state) => ({
    ...state,
    answersStatus: null,
  }))

  .handleAction(saveSurveyToStoreAction, (state, { payload }) => ({
    ...state,
    currentStep: 0,
    surveyAnswers: [],
    currentSurvey: payload,
  }))
  .handleAction(resetSurveyAction, (state) => ({
    ...state,
    currentSurvey: null,
    currentStep: 0,
    surveyAnswers: [],
  }))
  .handleAction(saveSurveyAnswersToStoreAction, (state, { payload: { answers } }) => {
    const prevSurveyAnswers = state.surveyAnswers;
    const isTheSameLastQuestion =
      prevSurveyAnswers.length && prevSurveyAnswers[prevSurveyAnswers.length - 1].question === answers.question;
    const updatedAnswers = isTheSameLastQuestion
      ? [...prevSurveyAnswers.slice(0, prevSurveyAnswers.length - 1), answers]
      : [...prevSurveyAnswers, answers];

    return {
      ...state,
      surveyAnswers: updatedAnswers,
    };
  });
