import { createAction } from 'typesafe-actions';

import { IAuthor, IAuthorExtended, IEventListItem } from 'types';

import { ACTIONS } from './constants';

// Authors
export const getAuthorsRequestAction = createAction(ACTIONS.GET_AUTHORS_REQUEST)<{ alias?: string }>();
export const getMoreAuthorsRequestAction = createAction(ACTIONS.GET_MORE_AUTHORS_REQUEST)<{ alias?: string }>();
export const getAuthorsSuccessAction = createAction(ACTIONS.GET_AUTHORS_SUCCESS)();
export const getAuthorsFailureAction = createAction(ACTIONS.GET_AUTHORS_FAILURE)();
export const putAuthorsToStoreAction = createAction(ACTIONS.PUT_AUTHORS_TO_STORE)<{
  authors: IAuthor[];
  total: number;
}>();
export const clearAuthorsToStoreAction = createAction(ACTIONS.CLEAR_AUTHORS_TO_STORE)();

export const putAuthorsPageToStoreAction = createAction(ACTIONS.PUT_AUTHORS_PAGE_TO_STORE)<{
  page: number;
}>();

// Author
export const getAuthorRequestAction = createAction(ACTIONS.GET_AUTHOR_REQUEST)<{ id: string }>();
export const getAuthorSuccessAction = createAction(ACTIONS.GET_AUTHOR_SUCCESS)();
export const getAuthorFailureAction = createAction(ACTIONS.GET_AUTHOR_FAILURE)();
export const putAuthorToStoreAction = createAction(ACTIONS.PUT_AUTHOR_TO_STORE)<{
  author: IAuthorExtended;
}>();
export const clearAuthorToStoreAction = createAction(ACTIONS.CLEAR_AUTHOR_TO_STORE)();

// Author Events
export const getAuthorEventsRequestAction = createAction(ACTIONS.GET_AUTHOR_EVENTS_REQUEST)<{
  id: string;
  area?: string;
}>();
export const getAuthorEventsSuccessAction = createAction(ACTIONS.GET_AUTHOR_EVENTS_SUCCESS)();
export const getAuthorEventsFailureAction = createAction(ACTIONS.GET_AUTHOR_EVENTS_FAILURE)();
export const putAuthorEventsToStoreAction = createAction(ACTIONS.PUT_AUTHOR_EVENTS_TO_STORE)<{
  events: IEventListItem[];
}>();

export interface IAuthorSearchFormValues {
  searchString: string;
  areaSelect: string;
}

// Author Search
export const setLastAuthorsSearchAction = createAction(ACTIONS.SET_LAST_AUTHORS_SEARCH)<
  Partial<IAuthorSearchFormValues>
>();
export const resetLastAuthorsSearchAction = createAction(ACTIONS.RESET_LAST_AUTHORS_SEARCH)();
