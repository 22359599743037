import { ActionType, createReducer } from 'typesafe-actions';
import update from 'immutability-helper';

import {
  putEventToStoreAction,
  clearEventToStoreAction,
  subscribeSuccessAction,
  unSubscribeSuccessAction,
  getEventRequestAction,
  getEventSuccessAction,
  getEventFailureAction,
} from 'core/actions';
import { IEvent } from 'types';

export interface IEventState {
  event: IEvent;
  isEventLoading: boolean;
}

const defaultState: IEventState = {
  event: createDefaultEvent(),
  isEventLoading: true,
};

const actions = {
  putEventToStoreAction,
  clearEventToStoreAction,
  subscribeSuccessAction,
  unSubscribeSuccessAction,

  getEventRequestAction,
  getEventSuccessAction,
  getEventFailureAction,
};

export const eventReducer = createReducer<IEventState, ActionType<typeof actions>>(defaultState)
  .handleAction(getEventRequestAction, (state) => ({
    ...state,
    isEventLoading: true,
  }))
  .handleAction(getEventSuccessAction, (state) => ({
    ...state,
    isEventLoading: false,
  }))
  .handleAction(getEventFailureAction, (state) => ({
    ...state,
    isEventLoading: false,
  }))

  .handleAction(putEventToStoreAction, (state, { payload: event }) => ({
    ...state,
    event,
  }))
  .handleAction(clearEventToStoreAction, () => ({ ...defaultState }))
  .handleAction(subscribeSuccessAction, (state) => {
    return update(state, { event: { subscribed: { $set: true } } });
  })
  .handleAction(unSubscribeSuccessAction, (state) => {
    return update(state, { event: { subscribed: { $set: false } } });
  });

function createDefaultEvent(): IEvent {
  return {
    id: null,
    title: '',
    description: '',
    startDate: '2000-12-12',
    startTime: '00:00',
    thumbnailUrl: '',
    backgroundUrl: '',
    videoUrl: '',
    viewingCount: 0,
    isViewed: false,
    isRegistered: false,
    subscribed: false,
    authors: [],
    area: {
      alias: '',
      title: '',
      shortTitle: '',
    },
    category: {
      id: null,
      alias: '',
      title: '',
    },
    sections: [],
    recommendedEvents: [],
    socialNetworks: [],
    banners: [],
    partners: [],
    marks: [],
    averageMark: 0,
  };
}
