import React from 'react';
import { Checkbox } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import ImageStub from 'assets/images/no-image-provided.png';
import { SurveyAnswerType, SurveyQuestionContentType } from 'types';
import { saveSurveyAnswersToStoreAction } from 'core/actions';

interface ICheckboxGroupProps {
  questionId: number;
  options: SurveyAnswerType[];
  questionType: SurveyQuestionContentType;
}

export const CheckboxGroup = ({ options, questionId, questionType }: ICheckboxGroupProps) => {
  const dispatch = useDispatch();

  const onChange = (checkedValues: CheckboxValueType[]) => {
    dispatch(
      saveSurveyAnswersToStoreAction({
        answers: { question: questionId, answers: checkedValues.map((value) => Number(value)) },
      }),
    );
  };

  const isImage = (imageUrl: string) => {
    return (imageUrl && questionType !== 'text') || questionType === 'full';
  };

  const isText = (answer: string) => {
    return (answer && questionType !== 'photo') || questionType === 'full';
  };

  return (
    <CheckboxStyled
      options={options.map(({ id, answer, imageUrl }) => ({
        label: (
          <>
            {isImage(imageUrl) && (
              <ImageWrapper>
                <AnswerImage
                  src={imageUrl}
                  alt="answer-image"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = ImageStub;
                  }}
                />
              </ImageWrapper>
            )}
            {isText(answer) && <>{answer}</>}
          </>
        ),
        value: id,
      }))}
      onChange={onChange}
    />
  );
};

const CheckboxStyled = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #d2d7de;
  border-bottom: 1px solid #d2d7de;
  width: 100%;

  .ant-checkbox-wrapper {
    padding: 32px 0;
    width: 100%;
    color: #000000;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    align-items: center;

    & > span:last-of-type {
      padding-left: 16px;
      display: flex;
      align-items: center;
    }
  }

  .ant-checkbox-wrapper:not(:last-of-type) {
    border-bottom: 1px solid #d2d7de;
  }

  .ant-checkbox {
    top: 0;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 12px;
  margin-right: 16px;
  min-width: 30%;
  max-width: 30%;

  @media (min-width: 768px) {
    max-width: 260px;
    max-height: 180px;
    margin-right: 24px;
  }
`;

const AnswerImage = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 12px;
  margin: 0 auto;
`;
