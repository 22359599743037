import React from 'react';
import { useSelector } from 'react-redux';

import { getEvent } from 'core/selectors';

import { BannersCarousel } from './BannersCarousel';

export const Container = ({ className }: { className?: string }) => {
  const { banners } = useSelector(getEvent);

  if (!banners.length) {
    return null;
  }

  return <BannersCarousel items={banners} className={className} />;
};
