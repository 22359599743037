import React, { useEffect, useState } from 'react';

import { ReactFacecast } from 'atoms';

interface IProps {
  videoUrl: string;
}

export const Facecast = ({ videoUrl }: IProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (isLoaded) {
      const visibilitychange = () => {
        if (document.hidden) {
          window.frames[0].postMessage(`{"frame":"${getVideoId(videoUrl)}","exec":"pause"}`, '*');
        }
      };

      window.frames[0].postMessage(`{"frame":"${getVideoId(videoUrl)}","exec":"pause"}`, '*');

      document.addEventListener('visibilitychange', visibilitychange);
      return () => document.removeEventListener('visibilitychange', visibilitychange);
    }

    return undefined;
  }, [isLoaded, videoUrl]);

  return <ReactFacecast videoId={getVideoId(videoUrl)} setIsLoaded={setIsLoaded} />;
};

const getVideoId = (url: string) => {
  const separator = url.includes('/w/') ? '/w/' : url.includes('/v/') ? '/v/' : '/';

  const parsed = url.split(separator);

  if (parsed && parsed[parsed.length - 1]) {
    if (parsed[parsed.length - 1].includes('/')) {
      const parsed2 = parsed[parsed.length - 1].split('/');

      if (parsed2 && parsed2[0]) {
        return parsed2[0];
      }
    } else {
      return parsed[parsed.length - 1];
    }
  }

  return '';
};
