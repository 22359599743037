const directors = [
  'главный врач',
  'директор медорганизации',
  'заместитель руководителя медорганизации',
  'заведующий структурного подразделения медорганизации - специалист',
  'главная медицинская сестра (главная акушерка, главный фельдшер)',
];

const doctors = [
  'аллерголог-иммунолог',
  'анестезиолог-реаниматолог',
  'бактериолог',
  'вирусолог',
  'гастроэнтеролог',
  'гематолог',
  'генетик',
  'гериатр',
  'дезинфектолог',
  'дерматовенеролог',
  'детский кардиолог',
  'детский онколог',
  'детский онколог-гематолог',
  'детский уролог-андролог',
  'детский хирург',
  'детский эндокринолог',
  'диабетолог',
  'диетолог',
  'врач',
  'инфекционист',
  'кардиолог',
  'врач клинической лабораторной диагностики',
  'клинический миколог',
  'клинический фармаколог',
  'колопроктолог',
  'косметолог',
  'лаборант',
  'лабораторный генетик',
  'лабораторный миколог',
  'врач мануальной терапии',
  'медицинский микробиолог',
  'методист',
  'невролог',
  'нейрохирург',
  'неонатолог',
  'нефролог',
  'врач общей практики (семейный врач)',
  'онколог',
  'ортодонт',
  'остеопат',
  'оториноларинголог',
  'офтальмолог',
  'офтальмолог-протезист',
  'паразитолог',
  'патологоанатом',
  'педиатр',
  'педиатр участковый',
  'пластический хирург',
  'врач по авиационной и космической медицине',
  'врач по водолазной медицине',
  'врач по гигиене детей и подростков',
  'врач по гигиене питания',
  'врач по гигиене труда',
  'врач по гигиеническому воспитанию',
  'врач по коммунальной гигиене',
  'врач по лечебной физкультуре',
  'врач по медико-социальной экспертизе',
  'врач по медицинской профилактике',
  'врач по медицинской реабилитации',
  'врач по общей гигиене',
  'врач по паллиативной медицинской помощи',
  'врач по радиационной гигиене',
  'врач по рентгенэндоваскулярным диагностике и лечению',
  'врач по санитарно-гигиеническим лабораторным исследованиям',
  'врач по спортивной медицине',
  'профпатолог',
  'психиатр',
  'психиатр детский',
  'психиатр подростковый',
  'психиатр-нарколог',
  'психотерапевт',
  'пульмонолог',
  'радиолог',
  'радиотерапевт',
  'ревматолог',
  'рентгенолог',
  'рефлексотерапевт',
  'сексолог',
  'сердечно-сосудистый хирург',
  'врач скорой медицинской помощи',
  'статистик',
  'стоматолог',
  'стоматолог детский',
  'стоматолог-ортопед',
  'стоматолог-терапевт',
  'стоматолог-хирург',
  'судебно-медицинский эксперт',
  'судебно-психиатрический эксперт',
  'сурдолог-оториноларинголог',
  'сурдолог-протезист',
  'терапевт',
  'терапевт подростковый',
  'терапевт участковый',
  'токсиколог',
  'торакальный хирург',
  'травматолог-ортопед',
  'трансфузиолог',
  'врач ультразвуковой диагностики',
  'уролог',
  'физиотерапевт',
  'врач физической и реабилитационной медицины',
  'фтизиатр',
  'врач функциональной диагностики',
  'хирург',
  'челюстно-лицевой хирург',
  'эндокринолог',
  'эндоскопист',
  'эпидемиолог',
];

const personal = [
  'акушер',
  'заведующий здравпунктом - фельдшер (медицинская сестра)',
  'заведующий фельдшерско-акушерским пунктом - фельдшер (акушер, медицинская сестра)',
  'зубной врач',
  'зубной техник',
  'лаборант',
  'медицинская сестра',
  'медицинская сестра-анестезист',
  'медицинская сестра врача общей практики (семейного врача)',
  'медицинская сестра диетическая',
  'медицинская сестра медико-социальной помощи',
  'медицинская сестра палатная (постовая)',
  'медицинская сестра патронажная',
  'медицинская сестра перевязочной',
  'медицинская сестра по косметологии',
  'медицинская сестра по массажу',
  'медицинская сестра (фельдшер) по приему вызовов скорой медицинской помощи и передаче их выездным бригадам скорой медицинской помощи',
  'медицинская сестра приемного отделения',
  'медицинская сестра процедурной',
  'медицинская сестра по реабилитации',
  'медицинская сестра стерилизационной',
  'медицинская сестра участковая',
  'медицинская сестра по физиотерапии',
  'медицинский лабораторный техник (фельдшер-лаборант)',
  'медицинский оптик-оптометрист',
  'медицинский технолог',
  'операционная медицинская сестра',
  'рентгенолаборант',
  'старшая медицинская сестра (акушер, фельдшер, операционная медицинская сестра, зубной техник)',
  'фельдшер',
  'фельдшер скорой медицинской помощи',
  'фельдшер-нарколог',
];

const createSpecifies = (parentId: string, children: string[]) => {
  return children.map((child, index) => ({ id: `${parentId}-${index}`, value: child }));
};

export const degreeList = [
  {
    id: '0',
    value: '',
    label: 'Не выбрано',
  },
  {
    id: '1',
    value: 'md',
    label: 'д.м.н.',
  },
  {
    id: '2',
    value: 'pdm',
    label: 'к.м.н.',
  },
];

export const specifiesList = [
  {
    id: '1',
    value: 'Руководители',
    children: createSpecifies('1', directors),
  },
  {
    id: '2',
    value: 'Врачи',
    children: createSpecifies('2', doctors),
  },
  {
    id: '2',
    value: 'Средний медицинский персонал',
    children: createSpecifies('3', personal),
  },
];

const regions = [
  {
    cities: [
      {
        id: '1',
        value: 'Апрелевка',
      },
      {
        id: '2',
        value: 'Балашиха',
      },
      {
        id: '3',
        value: 'Бронницы',
      },
      {
        id: '4',
        value: 'Верея',
      },
      {
        id: '5',
        value: 'Видное',
      },
      {
        id: '6',
        value: 'Волоколамск',
      },
      {
        id: '7',
        value: 'Воскресенск',
      },
      {
        id: '8',
        value: 'Высоковск',
      },
      {
        id: '9',
        value: 'Голицыно',
      },
      {
        id: '10',
        value: 'Дзержинский',
      },
      {
        id: '11',
        value: 'Дмитров',
      },
      {
        id: '12',
        value: 'Долгопрудный',
      },
      {
        id: '13',
        value: 'Домодедово',
      },
      {
        id: '14',
        value: 'Дрезна',
      },
      {
        id: '15',
        value: 'Дубна',
      },
      {
        id: '16',
        value: 'Егорьевск',
      },
      {
        id: '17',
        value: 'Жуковский',
      },
      {
        id: '18',
        value: 'Зарайск',
      },
      {
        id: '19',
        value: 'Звенигород',
      },
      {
        id: '20',
        value: 'Ивантеевка',
      },
      {
        id: '21',
        value: 'Истра',
      },
      {
        id: '22',
        value: 'Кашира',
      },
      {
        id: '23',
        value: 'Клин',
      },
      {
        id: '24',
        value: 'Коломна',
      },
      {
        id: '25',
        value: 'Королев',
      },
      {
        id: '26',
        value: 'Котельники',
      },
      {
        id: '27',
        value: 'Красноармейск',
      },
      {
        id: '28',
        value: 'Красногорск',
      },
      {
        id: '29',
        value: 'Краснознаменск',
      },
      {
        id: '30',
        value: 'Кубинка',
      },
      {
        id: '31',
        value: 'Ликино-Дулёво',
      },
      {
        id: '32',
        value: 'Лобня',
      },
      {
        id: '33',
        value: 'Лосино-Петровский',
      },
      {
        id: '34',
        value: 'Луховицы',
      },
      {
        id: '35',
        value: 'Лыткарино',
      },
      {
        id: '36',
        value: 'Люберцы',
      },
      {
        id: '37',
        value: 'Можайск',
      },
      {
        id: '38',
        value: 'Мытищи',
      },
      {
        id: '39',
        value: 'Наро-Фоминск',
      },
      {
        id: '40',
        value: 'Ногинск',
      },
      {
        id: '41',
        value: 'Одинцово',
      },
      {
        id: '42',
        value: 'Озёры',
      },
      {
        id: '43',
        value: 'Орехово-Зуево',
      },
      {
        id: '44',
        value: 'Павловский Посад',
      },
      {
        id: '45',
        value: 'Подольск',
      },
      {
        id: '46',
        value: 'Пушкино',
      },
      {
        id: '47',
        value: 'Пущино',
      },
      {
        id: '48',
        value: 'Раменское',
      },
      {
        id: '49',
        value: 'Реутов',
      },
      {
        id: '50',
        value: 'Рошаль',
      },
      {
        id: '51',
        value: 'Руза',
      },
      {
        id: '52',
        value: 'Сергиев Посад',
      },
      {
        id: '53',
        value: 'Серпухов',
      },
      {
        id: '54',
        value: 'Солнечногорск',
      },
      {
        id: '55',
        value: 'Старая Купавна',
      },
      {
        id: '56',
        value: 'Ступино',
      },
      {
        id: '57',
        value: 'Талдом',
      },
      {
        id: '58',
        value: 'Фрязино',
      },
      {
        id: '59',
        value: 'Химки',
      },
      {
        id: '60',
        value: 'Хотьково',
      },
      {
        id: '61',
        value: 'Черноголовка',
      },
      {
        id: '62',
        value: 'Чехов',
      },
      {
        id: '63',
        value: 'Шатура',
      },
      {
        id: '64',
        value: 'Щелково',
      },
      {
        id: '65',
        value: 'Электрогорск',
      },
      {
        id: '66',
        value: 'Электросталь',
      },
      {
        id: '67',
        value: 'Электроугли',
      },
      {
        id: '68',
        value: 'Яхрома',
      },
    ],
    id: '10',
    value: 'Московская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Выборг',
      },
      {
        id: '2',
        value: 'Гатчина',
      },
      {
        id: '3',
        value: 'Кингисепп',
      },
      {
        id: '4',
        value: 'Пикалёво',
      },
    ],
    id: '38',
    value: 'Ленинградская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Москва',
      },
    ],
    id: '64',
    value: 'Москва',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Санкт-Петербург',
      },
    ],
    id: '77',
    value: 'Cанкт-Петербург',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Барнаул',
      },
      {
        id: '2',
        value: 'Бийск',
      },
      {
        id: '3',
        value: 'Рубцовск',
      },
    ],
    id: '18',
    value: 'Алтайский край',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Благовещенск',
      },
    ],
    id: '25',
    value: 'Амурская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Архангельск',
      },
      {
        id: '2',
        value: 'Северодвинск',
      },
    ],
    id: '13',
    value: 'Архангельская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Астрахань',
      },
      {
        id: '2',
        value: 'Ахтубинск',
      },
      {
        id: '3',
        value: 'Харабали',
      },
    ],
    id: '14',
    value: 'Астраханская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Белгород',
      },
      {
        id: '2',
        value: 'Новый Оскол',
      },
      {
        id: '3',
        value: 'Старый Оскол',
      },
    ],
    id: '19',
    value: 'Белгородская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Брянск',
      },
      {
        id: '2',
        value: 'Клинцы',
      },
    ],
    id: '28',
    value: 'Брянская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Владимир',
      },
      {
        id: '2',
        value: 'Гусь-Хрустальный',
      },
      {
        id: '3',
        value: 'Ковров',
      },
      {
        id: '4',
        value: 'Муром',
      },
      {
        id: '5',
        value: 'Покров',
      },
      {
        id: '6',
        value: 'Суздаль',
      },
    ],
    id: '34',
    value: 'Владимирская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Волгоград',
      },
      {
        id: '2',
        value: 'Волжский',
      },
      {
        id: '3',
        value: 'Камышин',
      },
      {
        id: '4',
        value: 'Михайловка',
      },
      {
        id: '5',
        value: 'Урюпинск',
      },
    ],
    id: '35',
    value: 'Волгоградская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Вологда',
      },
      {
        id: '2',
        value: 'Череповец',
      },
    ],
    id: '36',
    value: 'Вологодская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Борисоглебск',
      },
      {
        id: '2',
        value: 'Воронеж',
      },
    ],
    id: '27',
    value: 'Воронежская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Биробиджан',
      },
    ],
    id: '24',
    value: 'Еврейская автономная область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Чита',
      },
    ],
    id: '84',
    value: 'Забайкальский край',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Иваново',
      },
    ],
    id: '47',
    value: 'Ивановская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Ангарск',
      },
      {
        id: '2',
        value: 'Братск',
      },
      {
        id: '3',
        value: 'Иркутск',
      },
      {
        id: '4',
        value: 'Усолье-Сибирское',
      },
    ],
    id: '7',
    value: 'Иркутская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Баксан',
      },
      {
        id: '2',
        value: 'Нальчик',
      },
    ],
    id: '16',
    value: 'Кабардино-Балкарская Республика',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Зеленоградск',
      },
      {
        id: '2',
        value: 'Калининград',
      },
      {
        id: '3',
        value: 'Светлогорск',
      },
    ],
    id: '45',
    value: 'Калининградская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Калуга',
      },
      {
        id: '2',
        value: 'Малоярославец',
      },
      {
        id: '3',
        value: 'Обнинск',
      },
    ],
    id: '50',
    value: 'Калужская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Вилючинск',
      },
      {
        id: '2',
        value: 'Петропавловск-Камчатский',
      },
    ],
    id: '33',
    value: 'Камчатский край',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Карачаевск',
      },
      {
        id: '2',
        value: 'Черкесск',
      },
    ],
    id: '51',
    value: 'Карачаево-Черкесская Республика',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Анжеро-Судженск',
      },
      {
        id: '2',
        value: 'Кемерово',
      },
      {
        id: '3',
        value: 'Междуреченск',
      },
      {
        id: '4',
        value: 'Новокузнецк',
      },
      {
        id: '5',
        value: 'Прокопьевск',
      },
    ],
    id: '8',
    value: 'Кемеровская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Киров',
      },
      {
        id: '2',
        value: 'Кирово-Чепецк',
      },
    ],
    id: '53',
    value: 'Кировская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Кострома',
      },
    ],
    id: '57',
    value: 'Костромская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Анапа',
      },
      {
        id: '2',
        value: 'Армавир',
      },
      {
        id: '3',
        value: 'Геленджик',
      },
      {
        id: '4',
        value: 'Ейск',
      },
      {
        id: '5',
        value: 'Краснодар',
      },
      {
        id: '6',
        value: 'Новороссийск',
      },
      {
        id: '7',
        value: 'Сочи',
      },
      {
        id: '8',
        value: 'Темрюк',
      },
      {
        id: '9',
        value: 'Туапсе',
      },
    ],
    id: '6',
    value: 'Краснодарский край',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Ачинск',
      },
      {
        id: '2',
        value: 'Железногорск',
      },
      {
        id: '3',
        value: 'Красноярск',
      },
      {
        id: '4',
        value: 'Норильск',
      },
    ],
    id: '15',
    value: 'Красноярский край',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Курган',
      },
    ],
    id: '58',
    value: 'Курганская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Курск',
      },
    ],
    id: '59',
    value: 'Курская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Елец',
      },
      {
        id: '2',
        value: 'Липецк',
      },
    ],
    id: '43',
    value: 'Липецкая область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Магадан',
      },
    ],
    id: '61',
    value: 'Магаданская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Апатиты',
      },
      {
        id: '2',
        value: 'Мурманск',
      },
    ],
    id: '9',
    value: 'Мурманская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Нарьян-Мар',
      },
    ],
    id: '67',
    value: 'Ненецкий автономный округ',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Арзамас',
      },
      {
        id: '2',
        value: 'Дзержинск',
      },
      {
        id: '3',
        value: 'Нижний Новгород',
      },
      {
        id: '4',
        value: 'Саров',
      },
    ],
    id: '11',
    value: 'Нижегородская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Валдай',
      },
      {
        id: '2',
        value: 'Великий Новгород',
      },
    ],
    id: '30',
    value: 'Новгородская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Бердск',
      },
      {
        id: '2',
        value: 'Новосибирск',
      },
    ],
    id: '21',
    value: 'Новосибирская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Омск',
      },
    ],
    id: '72',
    value: 'Омская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Оренбург',
      },
      {
        id: '2',
        value: 'Орск',
      },
    ],
    id: '73',
    value: 'Оренбургская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Мценск',
      },
      {
        id: '2',
        value: 'Орел',
      },
    ],
    id: '65',
    value: 'Орловская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Пенза',
      },
    ],
    id: '74',
    value: 'Пензенская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Березники',
      },
      {
        id: '2',
        value: 'Пермь',
      },
      {
        id: '3',
        value: 'Соликамск',
      },
    ],
    id: '22',
    value: 'Пермский край',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Артем',
      },
      {
        id: '2',
        value: 'Владивосток',
      },
      {
        id: '3',
        value: 'Находка',
      },
      {
        id: '4',
        value: 'Уссурийск',
      },
    ],
    id: '12',
    value: 'Приморский край',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Великие Луки',
      },
      {
        id: '2',
        value: 'Псков',
      },
    ],
    id: '31',
    value: 'Псковская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Адыгейск',
      },
      {
        id: '2',
        value: 'Майкоп',
      },
    ],
    id: '2',
    value: 'Республика Адыгея',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Горно-Алтайск',
      },
    ],
    id: '39',
    value: 'Республика Алтай',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Нефтекамск',
      },
      {
        id: '2',
        value: 'Октябрьский',
      },
      {
        id: '3',
        value: 'Салават',
      },
      {
        id: '4',
        value: 'Стерлитамак',
      },
      {
        id: '5',
        value: 'Уфа',
      },
    ],
    id: '68',
    value: 'Республика Башкортостан',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Улан-Удэ',
      },
    ],
    id: '82',
    value: 'Республика Бурятия',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Буйнакск',
      },
      {
        id: '2',
        value: 'Дербент',
      },
      {
        id: '3',
        value: 'Каспийск',
      },
      {
        id: '4',
        value: 'Кизляр',
      },
      {
        id: '5',
        value: 'Махачкала',
      },
      {
        id: '6',
        value: 'Хасавюрт',
      },
    ],
    id: '29',
    value: 'Республика Дагестан',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Магас',
      },
      {
        id: '2',
        value: 'Назрань',
      },
    ],
    id: '62',
    value: 'Республика Ингушетия',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Элиста',
      },
    ],
    id: '85',
    value: 'Республика Калмыкия',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Кондопога',
      },
      {
        id: '2',
        value: 'Петрозаводск',
      },
    ],
    id: '56',
    value: 'Республика Карелия',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Воркута',
      },
      {
        id: '2',
        value: 'Сыктывкар',
      },
      {
        id: '3',
        value: 'Ухта',
      },
    ],
    id: '37',
    value: 'Республика Коми',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Алупка',
      },
      {
        id: '2',
        value: 'Алушта',
      },
      {
        id: '3',
        value: 'Армянск',
      },
      {
        id: '4',
        value: 'Бахчисарай',
      },
      {
        id: '5',
        value: 'Джанкой',
      },
      {
        id: '6',
        value: 'Евпатория',
      },
      {
        id: '7',
        value: 'Керчь',
      },
      {
        id: '8',
        value: 'Красноперекопск',
      },
      {
        id: '9',
        value: 'Саки',
      },
      {
        id: '10',
        value: 'Симферополь',
      },
      {
        id: '11',
        value: 'Судак',
      },
      {
        id: '12',
        value: 'Феодосия',
      },
      {
        id: '13',
        value: 'Ялта',
      },
    ],
    id: '4',
    value: 'Республика Крым',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Йошкар-Ола',
      },
    ],
    id: '49',
    value: 'Республика Марий Эл',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Саранск',
      },
    ],
    id: '78',
    value: 'Республика Мордовия',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Якутск',
      },
    ],
    id: '87',
    value: 'Республика Саха (Якутия)',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Беслан',
      },
      {
        id: '2',
        value: 'Владикавказ',
      },
      {
        id: '3',
        value: 'Моздок',
      },
    ],
    id: '23',
    value: 'Республика Северная Осетия Алания',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Альметьевск',
      },
      {
        id: '2',
        value: 'Бугульма',
      },
      {
        id: '3',
        value: 'Елабуга',
      },
      {
        id: '4',
        value: 'Зеленодольск',
      },
      {
        id: '5',
        value: 'Казань',
      },
      {
        id: '6',
        value: 'Набережные Челны',
      },
      {
        id: '7',
        value: 'Нижнекамск',
      },
    ],
    id: '5',
    value: 'Республика Татарстан',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Кызыл',
      },
    ],
    id: '60',
    value: 'Республика Тыва',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Абаза',
      },
      {
        id: '2',
        value: 'Абакан',
      },
    ],
    id: '1',
    value: 'Республика Хакасия',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Азов',
      },
      {
        id: '2',
        value: 'Батайск',
      },
      {
        id: '3',
        value: 'Волгодонск',
      },
      {
        id: '4',
        value: 'Каменск-Шахтинский',
      },
      {
        id: '5',
        value: 'Новочеркасск',
      },
      {
        id: '6',
        value: 'Новошахтинск',
      },
      {
        id: '7',
        value: 'Ростов-на-Дону',
      },
      {
        id: '8',
        value: 'Таганрог',
      },
      {
        id: '9',
        value: 'Шахты',
      },
    ],
    id: '3',
    value: 'Ростовская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Касимов',
      },
      {
        id: '2',
        value: 'Рязань',
      },
    ],
    id: '52',
    value: 'Рязанская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Новокуйбышевск',
      },
      {
        id: '2',
        value: 'Самара',
      },
      {
        id: '3',
        value: 'Сызрань',
      },
      {
        id: '4',
        value: 'Тольятти',
      },
    ],
    id: '70',
    value: 'Самарская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Балаково',
      },
      {
        id: '2',
        value: 'Саратов',
      },
      {
        id: '3',
        value: 'Энгельс',
      },
    ],
    id: '17',
    value: 'Саратовская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Южно-Сахалинск',
      },
    ],
    id: '86',
    value: 'Сахалинская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Верхний Тагил',
      },
      {
        id: '2',
        value: 'Екатеринбург',
      },
      {
        id: '3',
        value: 'Каменск-Уральский',
      },
      {
        id: '4',
        value: 'Нижний Тагил',
      },
      {
        id: '5',
        value: 'Первоуральск',
      },
      {
        id: '6',
        value: 'Полевской',
      },
      {
        id: '7',
        value: 'Серов',
      },
    ],
    id: '32',
    value: 'Свердловская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Севастополь',
      },
    ],
    id: '79',
    value: 'Севастополь',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Рославль',
      },
      {
        id: '2',
        value: 'Смоленск',
      },
    ],
    id: '76',
    value: 'Смоленская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Ессентуки',
      },
      {
        id: '2',
        value: 'Железноводск',
      },
      {
        id: '3',
        value: 'Кисловодск',
      },
      {
        id: '4',
        value: 'Минеральные Воды',
      },
      {
        id: '5',
        value: 'Михайловск',
      },
      {
        id: '6',
        value: 'Невинномысск',
      },
      {
        id: '7',
        value: 'Пятигорск',
      },
      {
        id: '8',
        value: 'Ставрополь',
      },
    ],
    id: '44',
    value: 'Ставропольский край',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Мичуринск',
      },
      {
        id: '2',
        value: 'Моршанск',
      },
      {
        id: '3',
        value: 'Тамбов',
      },
    ],
    id: '63',
    value: 'Тамбовская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Бологое',
      },
      {
        id: '2',
        value: 'Вышний Волочёк',
      },
      {
        id: '3',
        value: 'Кимры',
      },
      {
        id: '4',
        value: 'Конаково',
      },
      {
        id: '5',
        value: 'Ржев',
      },
      {
        id: '6',
        value: 'Тверь',
      },
      {
        id: '7',
        value: 'Торжок',
      },
    ],
    id: '26',
    value: 'Тверская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Северск',
      },
      {
        id: '2',
        value: 'Томск',
      },
    ],
    id: '80',
    value: 'Томская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Белёв',
      },
      {
        id: '2',
        value: 'Новомосковск',
      },
      {
        id: '3',
        value: 'Тула',
      },
    ],
    id: '20',
    value: 'Тульская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Тобольск',
      },
      {
        id: '2',
        value: 'Тюмень',
      },
    ],
    id: '81',
    value: 'Тюменская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Ижевск',
      },
    ],
    id: '48',
    value: 'Удмуртская Республика',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Димитровград',
      },
      {
        id: '2',
        value: 'Ульяновск',
      },
    ],
    id: '42',
    value: 'Ульяновская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Комсомольск-на-Амуре',
      },
      {
        id: '2',
        value: 'Хабаровск',
      },
    ],
    id: '55',
    value: 'Хабаровский край',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Когалым',
      },
      {
        id: '2',
        value: 'Нижневартовск',
      },
      {
        id: '3',
        value: 'Нягань',
      },
      {
        id: '4',
        value: 'Сургут',
      },
      {
        id: '5',
        value: 'Ханты-Мансийск',
      },
    ],
    id: '54',
    value: 'Ханты-Мансийский автономный округ Югра',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Нефтеюганск',
      },
    ],
    id: '69',
    value: 'Ханты-Мансийский автономный округ-Югра',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Златоуст',
      },
      {
        id: '2',
        value: 'Копейск',
      },
      {
        id: '3',
        value: 'Магнитогорск',
      },
      {
        id: '4',
        value: 'Миасс',
      },
      {
        id: '5',
        value: 'Чебаркуль',
      },
      {
        id: '6',
        value: 'Челябинск',
      },
    ],
    id: '46',
    value: 'Челябинская область',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Грозный',
      },
    ],
    id: '40',
    value: 'Чеченская Республика',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Чебоксары',
      },
    ],
    id: '83',
    value: 'Чувашская Республика',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Новочебоксарск',
      },
    ],
    id: '71',
    value: 'Чувашская республика Чувашия',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Надым',
      },
      {
        id: '2',
        value: 'Новый Уренгой',
      },
      {
        id: '3',
        value: 'Ноябрьск',
      },
      {
        id: '4',
        value: 'Салехард',
      },
    ],
    id: '66',
    value: 'Ямало-Ненецкий автономный округ',
  },
  {
    cities: [
      {
        id: '1',
        value: 'Переславль-Залесский',
      },
      {
        id: '2',
        value: 'Ростов',
      },
      {
        id: '3',
        value: 'Рыбинск',
      },
      {
        id: '4',
        value: 'Углич',
      },
      {
        id: '5',
        value: 'Ярославль',
      },
    ],
    id: '75',
    value: 'Ярославская область',
  },
];

const createCities = (
  parentId: string,
  citiesArr: {
    id: string;
    value: string;
  }[],
) => citiesArr.map((city) => ({ id: `${parentId}-${city.id}`, value: city.value }));

const createRegions = (
  parentId: string,
  regionsArr: {
    id: string;
    value: string;
    cities: {
      id: string;
      value: string;
    }[];
  }[],
) =>
  regionsArr.map((region) => ({
    id: `${parentId}-${region.id}`,
    value: region.value,
    cities: createCities(`${parentId}-${region.id}`, region.cities),
  }));

export const countriesList = [
  { id: '1', value: 'Россия', regions: createRegions('1', regions) },
  { id: '2', value: 'Азербайджан', regions: [] },
  { id: '3', value: 'Армения', regions: [] },
  { id: '4', value: 'Беларусь', regions: [] },
  { id: '5', value: 'Грузия', regions: [] },
  { id: '6', value: 'Казахстан', regions: [] },
  { id: '7', value: 'Киргизия', regions: [] },
  { id: '8', value: 'Молдова', regions: [] },
  { id: '9', value: 'Таджикистан', regions: [] },
  { id: '10', value: 'Туркменистан', regions: [] },
  { id: '11', value: 'Узбекистан', regions: [] },
  { id: '12', value: 'Украина', regions: [] },
];
