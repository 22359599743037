import React from 'react';
import styled from 'styled-components';

import { RegisterSteps } from 'types';

interface IProps {
  step: RegisterSteps;
}

export const ModalTitle = ({ step }: IProps) => {
  return (
    <Title>
      {step !== RegisterSteps.Fourth ? <>Регистрация <Step>Шаг {step}</Step></> : <>Введите код</>}
    </Title>
  );
};

const Title = styled.div`
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 28px;
  color: #000000;

  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 40px;
  }

  @media (min-width: 1200px) {
    font-size: 32px;
    line-height: 48px;
  }
`;

const Step = styled.span`
  margin-left: 5px;
  color: #4853ec;
`;
