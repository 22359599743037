import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { getArea } from 'core/selectors';

import { ProgramsCarousel } from './ProgramsCarousel';

export const Container = () => {
  const { upcomingEvents } = useSelector(getArea);
  const history = useHistory();
  const { area } = useParams<{ area: string }>();

  const itemClickHandler = (url: string = '') => {
    history.push(`/area/${area}${url}`);
  };

  const seeAllBtnHandler = () => {
    history.push(`/area/${area}/catalog/all`);
  };

  if (!upcomingEvents.length) {
    return null;
  }

  return (
    <ProgramsCarousel items={upcomingEvents} itemClickHandler={itemClickHandler} seeAllBtnHandler={seeAllBtnHandler} />
  );
};
