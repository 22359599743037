import qs from 'querystring';

import {
  IPublicationsRequest,
  IApiPublicationsRequest,
  IApiPublication,
  IApiPublicationCategoryRequest,
  IApiPublicationCategory,
  IApiPublicationExtended,
} from 'types';

import { BaseService } from './BaseService';

class PublicationAPIService extends BaseService {
  public defaultPublicationsLimit = 9;

  public async getCategories(alias?: string) {
    const body: IApiPublicationCategoryRequest = {};
    if (alias) body.area__alias = alias;

    const { data } = await this.get<IApiPublicationCategory[]>(`article-categories?${qs.stringify({ ...body })}`);
    return data;
  }

  public async getPublications({
    alias,
    searchString,
    offset = 1,
    limit = this.defaultPublicationsLimit,
    category,
  }: IPublicationsRequest) {
    const search = searchString ?? undefined;

    const body: IApiPublicationsRequest = { offset, limit };
    if (search) body.title = search;
    if (alias) body.area__alias = alias;
    if (category) body.category__in = category;

    const { data } = await this.get<{ results: IApiPublication[]; total: number }>(
      `articles?${qs.stringify({ ...body })}`,
    );

    return data;
  }

  public async getPublication(id: string) {
    const { data } = await this.get<IApiPublicationExtended>(`articles/${id}`);

    return data;
  }
}

export const PublicationService = new PublicationAPIService();
