import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';

import { SurveyAnswerOption, SurveyAnswerType } from 'types';
import ImageStub from 'assets/images/no-image-provided.png';
import { saveSurveyAnswersToStoreAction } from 'core/actions';

interface IAnswerImageSelectorProps {
  questionId: number;
  options: SurveyAnswerType[];
  answerType: SurveyAnswerOption;
}

export const AnswerImageSelector = ({ options, answerType, questionId }: IAnswerImageSelectorProps) => {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const dispatch = useDispatch();

  const getMultipleAnswers = (id: number) => {
    if (answerType === SurveyAnswerOption.RADIOBUTTON) return [id];
    return selectedIds.includes(id) ? selectedIds.filter((answerId) => answerId !== id) : [...selectedIds, id];
  };

  const onChange = (id: number) => {
    const answers = getMultipleAnswers(id);

    setSelectedIds(answers);
    dispatch(saveSurveyAnswersToStoreAction({ answers: { question: questionId, answers } }));
  };

  return (
    <>
      <ImageAnswersWrapper>
        {options.map(({ id, imageUrl }) => (
          <ImageWrapper
            $isSelected={selectedIds.includes(id)}
            $isMulti={answerType === SurveyAnswerOption.CHECKBOX}
            key={id}
          >
            <AnswerImage
              $isSelected={selectedIds.includes(id)}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = ImageStub;
                currentTarget.alt = 'image-stub';
              }}
              src={imageUrl}
              alt="answer-image"
              onClick={() => onChange(id)}
            />
          </ImageWrapper>
        ))}
      </ImageAnswersWrapper>
      {answerType === SurveyAnswerOption.CHECKBOX && (
        <MutipleHint>* Допускается выбор нескольких вариантов ответа</MutipleHint>
      )}
    </>
  );
};

const ImageAnswersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding-bottom: -48px;
`;

const ImageWrapper = styled.div<{ $isSelected: boolean; $isMulti: boolean }>`
  width: calc(50% - 12px);
  border-radius: 12px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;

  ${({ $isSelected, $isMulti }) => {
    if ($isSelected) {
      return css`
        border: 2px solid #e2e3fc;
        box-shadow: 0 4px 10px 0 rgba(72, 83, 236, 0.16);

        &::before {
          content: '✓';
          color: #4853ec;
          font-size: 12px;
          line-height: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          border-radius: ${$isMulti ? 'unset' : '50%'};
          border: 1px solid rgba(72, 83, 236, 0.16);
          background: #ffffff;
          position: absolute;
          top: -10px;
          left: -10px;
          z-index: 2;
          padding-right: 1px;
        }
      `;
    }
    return css`
      box-shadow: 0 4px 10px 0 rgba(72, 83, 236, 0.08);
    `;
  }}
`;

const AnswerImage = styled.img<{ $isSelected: boolean }>`
  width: 100%;
  object-fit: contain;
  border-radius: 12px;
`;

export const MutipleHint = styled.span`
  font-style: italic;
  font-size: 14px;
  margin-top: 24px;
`;
