import React, { useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import ReactHtmlParser from 'react-html-parser';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { useHistory, useParams } from 'react-router-dom';

import { getAllSurveys, getArea, getEvent, getIsEventLoading } from 'core/selectors';
import {
  getEventRequestAction,
  clearEventToStoreAction,
  resetLastCatalogSearchAction,
  getSurveysRequestAction,
  getAreaRequestAction,
  clearAreaToStoreAction,
} from 'core/actions';
import { useLoginGuard, useWindowSize } from 'hooks';
import { Breadcrumb, Container, Section, SubscriptionBtn, Tag } from 'atoms';
import { EventDescription, SurveyCard } from 'molecules';
import { BannersEventCarousel, EventProgram, OtherEvents, PartnersEvent, SocialsEvent } from 'organisms';
import { MainTemplate } from 'templates';

import { AuthorCard } from '../Author/Components';

import { ActivityControl, Overlay, Comments, Video } from './Components';
import { StarsCarousel } from './Components/StarsCarousel';
import { EventSurvey } from './Components/EventSurvey';

const replaceRawHtmlText = (inputString: string) => {
  // replace "\r\n" with " <br> "
  // replace "<a " with "<a target="_blank" rel="noreferrer" "
  const replacedHTML = inputString.replace(/\r\n/g, ' <br> ').replace(/<a\s/g, '<a target="_blank" rel="noreferrer" ');

  return replacedHTML;
};

export const Event = () => {
  const dispatch = useDispatch();
  const [width] = useWindowSize();
  const { id: idUrl } = useParams<{ id: string }>();
  const { area: areaName } = useParams<{ area: string }>();
  const {
    id,
    title,
    area,
    category,
    startDate,
    viewingCount,
    description,
    sections,
    recommendedEvents,
    backgroundUrl,
    isViewed,
    subscribed,
    authors,
    marks,
    averageMark,
  } = useSelector(getEvent);
  const history = useHistory();
  const isEventLoading = useSelector(getIsEventLoading);
  const { allSurveys } = useSelector(getAllSurveys);

  const { color: colorTheme } = useSelector(getArea);

  useEffect(() => {
    if (areaName) {
      dispatch(getAreaRequestAction({ alias: areaName }));
    }

    return () => {
      dispatch(clearAreaToStoreAction());
    };
  }, [dispatch, areaName]);

  useEffect(() => {
    dispatch(getSurveysRequestAction({ eventId: Number(idUrl) }));
  }, [dispatch, idUrl]);

  const goToSurvey = (surveyId: number) => {
    history.push(`/surveys/${surveyId}`);
  };

  useLoginGuard(useCallback(() => dispatch(getEventRequestAction(Number(idUrl))), [dispatch, idUrl]));

  useEffect(() => {
    dispatch(getEventRequestAction(Number(idUrl)));

    return () => {
      dispatch(clearEventToStoreAction());
    };
  }, [dispatch, idUrl]);

  useEffect(() => {
    const historyListener = history.listen((location) => {
      const isCurrentAppSection = location.pathname.includes('/catalog') || location.pathname.includes('/event');

      if (!isCurrentAppSection) {
        dispatch(resetLastCatalogSearchAction());
      }
    });

    return () => {
      historyListener();
    };
  }, [history, dispatch]);

  if (isEventLoading) {
    return <Spin style={{ position: 'absolute', left: '48%', top: '48%' }} size="large" />;
  }

  const onAuthorClick = (authorId: number) => {
    history.push(`/authors/${authorId}`);
  };

  const onAreaClick = () => {
    history.push(`/area/${area.alias}`);
  };

  const onProgramsClick = () => {
    history.push(`/area/${area.alias}/catalog/all`);
  };

  return (
    <>
      <MainTemplate isMenuButtonVisible>
        <TopBackground backgroundUrl={backgroundUrl} bgColor={colorTheme || undefined}>
          <Container>
            <Breadcrumb>
              <Breadcrumb.Item onClick={onAreaClick}>{area.title}</Breadcrumb.Item>
              <Breadcrumb.Item onClick={onProgramsClick}>Образовательные программы</Breadcrumb.Item>
              <Breadcrumb.Item>{title}</Breadcrumb.Item>
            </Breadcrumb>
            <TagsContainer>
              <Tag size="small" colorType={4}>
                {area.title}
              </Tag>
              <Tag size="small" colorType={4}>
                {category.title}
              </Tag>
              {width <= 1200 && <SubscriptionBtn eventId={id!} isSubscribed={subscribed} size="small" />}
            </TagsContainer>

            {width > 1200 && (
              <SubscriptionContainer>
                <SubscriptionBtn eventId={id!} isSubscribed={subscribed} />
              </SubscriptionContainer>
            )}

            {title && <Title>{title}</Title>}

            <InfoBar>
              <StartDate>{format(new Date(startDate), 'd MMMM yyyy', { locale: ru })}</StartDate>
              <Views>
                <EyeOutlined />
                {viewingCount}
              </Views>
            </InfoBar>

            <VideoContainer isViewed={isViewed}>
              <Overlay color={colorTheme || undefined} />
              <ActivityControl />
              <EventSurvey />
              <Video />
            </VideoContainer>
          </Container>
        </TopBackground>
        <Container>
          <CommentsBlock>
            <Comments />
          </CommentsBlock>
        </Container>
        <StarsCarousel items={marks} eventId={id || 0} averageMark={averageMark} />
        <EventDescription title="Описание мероприятия">
          {ReactHtmlParser(replaceRawHtmlText(description))}
          {authors.map((author) => (
            <AuthorCard
              key={author.id}
              imageUrl={author.imageUrl}
              position={author.position}
              description={author.description}
              eventsTotal={author.totalEvents}
              publicationsTotal={author.totalArticles}
              name={author.fullName}
              onAuthorClick={() => onAuthorClick(author.id)}
            />
          ))}
          {allSurveys && allSurveys.results.length ? (
            <SurveysWrapper>
              {allSurveys.results.map(({ id: surveyId, title: surveyTitle, questions }) => (
                <SurveyCard
                  key={surveyId}
                  title={surveyTitle}
                  questionsQty={questions.length}
                  onClick={() => goToSurvey(surveyId)}
                />
              ))}
            </SurveysWrapper>
          ) : null}
        </EventDescription>

        <SocialsEvent color={colorTheme || undefined} />

        <Banners />

        <PartnersEvent />

        {sections && sections.length > 0 && <EventProgram data={sections} color={colorTheme || undefined} />}

        {recommendedEvents && recommendedEvents.length > 0 && (
          <Section title="С этой темой также смотрят">
            <OtherEvents events={recommendedEvents} />
          </Section>
        )}
      </MainTemplate>
    </>
  );
};

const TopBackground = styled.div<{ backgroundUrl?: string; bgColor?: string }>`
  ${({ backgroundUrl = '', bgColor, theme: { topBackgroundColor } }) => css`
    padding: 32px 0 0 0;
    background-color: ${topBackgroundColor};
    background-image: url(${backgroundUrl});
    background-repeat: no-repeat;
    background-position: 50% 0;
    position: relative;
    z-index: 0;
    background-size: cover;

    &:before {
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0.8;
      background: ${bgColor ?? 'linear-gradient(275.17deg, #525ded 24.3%, #121a7b 102.71%)'};
    }

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 120px;
      z-index: -1;
      background: #f8f8f8;
    }

    @media (min-width: 768px) {
      padding: 32px 0 0 0;

      &:after {
        height: 360px;
      }
    }

    @media (min-width: 1200px) {
      padding: 48px 0 0 0;

      &:after {
        height: 535px;
      }
    }
  `}
`;

const CommentsBlock = styled.div`
  padding-bottom: 20px;

  @media (min-width: 768px) {
    padding-bottom: 56px;
  }

  @media (min-width: 1200px) {
    padding-bottom: 48px;
  }
`;

const Title = styled.h1`
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  padding: 0 60px 0 0;

  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (min-width: 1200px) {
    font-size: 48px;
    line-height: 56px;
  }
`;

const TagsContainer = styled.div`
  margin: 0 0 20px 0;

  @media (max-width: 576px) {
    span {
      margin-bottom: 8px;
    }
  }
`;

const InfoBar = styled.div`
  display: flex;
  margin: 0 0 48px 0;

  @media (min-width: 768px) {
    margin: 0 0 56px 0;
  }

  @media (min-width: 1200px) {
    margin: 0 0 72px 0;
  }
`;

const StartDate = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  margin: 0 24px 0 0;
`;

const Views = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;

  .anticon {
    margin: 0 8px 0 0;
  }
`;

const Banners = styled(BannersEventCarousel)`
  background: #f8f8f8;

  & > div {
    & > div {
      background: #f8f8f8;
    }
  }
`;

const VideoContainer = styled.div<{ isViewed: boolean }>`
  ${({ isViewed }) => css`
    border-radius: 12px;
    overflow: hidden;
    position: relative;

    ${isViewed &&
    css`
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    `};

    iframe {
      border: none;
      width: 100%;
      display: block;
      height: 200px;

      @media (min-width: 768px) {
        height: 549px;
      }

      @media (min-width: 1200px) {
        height: 549px;
      }
    }
  `}
`;

const SubscriptionContainer = styled.div`
  .subscription-btn {
    float: right;
  }
`;

const SurveysWrapper = styled.div`
  padding-top: 36px;
`;
