import { ActionType, createReducer } from 'typesafe-actions';
import update from 'immutability-helper';

import {
  putCurrentCatalogCategoryToStoreAction,
  putSearchASCToStoreAction,
  resetCatalogState,
  getCatalogCategoriesRequestAction,
  getCatalogCategoriesSuccessAction,
  getCatalogCategoriesFailureAction,
  putCatalogCategoriesToStoreAction,
  getTagsRequestAction,
  getTagsSuccessAction,
  getTagsFailureAction,
  putTagsToStoreAction,
  getCatalogEventsRequestAction,
  getCatalogEventsSuccessAction,
  getCatalogEventsFailureAction,
  putCatalogEventsToStoreAction,
  getMoreCatalogEventsRequestAction,
  getMoreCatalogEventsSuccessAction,
  getMoreCatalogEventsFailureAction,
  subscribeSuccessAction,
  unSubscribeSuccessAction,
  ICatalogSearchFormValues,
  setLastCatalogSearchAction,
  resetLastCatalogSearchAction,
} from 'core/actions';
import { CatalogSearchASC, IEventCategory, ITag, IEventListItem } from 'types';

export interface ICatalogState {
  currentCategoryId?: number;
  searchASC: CatalogSearchASC;

  categories: IEventCategory[];
  categoriesLoading: boolean;
  categoriesError: boolean;

  tags: ITag[];
  tagsLoading: boolean;
  tagsError: boolean;

  events: IEventListItem[];
  eventsTotal: number;
  eventsLoading: boolean;
  eventsError: boolean;
  moreEventsLoading: boolean;
  moreEventsError: boolean;

  lastSearch?: Partial<ICatalogSearchFormValues>;
}

const defaultState: ICatalogState = {
  searchASC: CatalogSearchASC.start_date,

  categories: [],
  categoriesLoading: false,
  categoriesError: false,

  tags: [],
  tagsLoading: false,
  tagsError: false,

  events: [],
  eventsTotal: 0,
  eventsLoading: false,
  eventsError: false,
  moreEventsLoading: false,
  moreEventsError: false,
};

const actions = {
  putCurrentCatalogCategoryToStoreAction,
  putSearchASCToStoreAction,
  getCatalogCategoriesRequestAction,
  getCatalogCategoriesSuccessAction,
  getCatalogCategoriesFailureAction,
  putCatalogCategoriesToStoreAction,
  getTagsRequestAction,
  getTagsSuccessAction,
  getTagsFailureAction,
  putTagsToStoreAction,
  getCatalogEventsRequestAction,
  getCatalogEventsSuccessAction,
  getCatalogEventsFailureAction,
  putCatalogEventsToStoreAction,
  getMoreCatalogEventsRequestAction,
  getMoreCatalogEventsSuccessAction,
  getMoreCatalogEventsFailureAction,
  resetCatalogState,
  subscribeSuccessAction,
  unSubscribeSuccessAction,
  setLastCatalogSearchAction,
  resetLastCatalogSearchAction,
};

export const catalogReducer = createReducer<ICatalogState, ActionType<typeof actions>>(defaultState)
  .handleAction(putCurrentCatalogCategoryToStoreAction, (state, { payload: { id } }) => ({
    ...state,
    currentCategoryId: id,
  }))
  .handleAction(putSearchASCToStoreAction, (state, { payload: { asc } }) => ({
    ...state,
    searchASC: asc,
  }))

  .handleAction(getCatalogCategoriesRequestAction, (state) => ({
    ...state,
    categoriesLoading: true,
    categoriesError: false,
  }))
  .handleAction(getCatalogCategoriesSuccessAction, (state) => ({
    ...state,
    categoriesLoading: false,
  }))
  .handleAction(getCatalogCategoriesFailureAction, (state) => ({
    ...state,
    categoriesLoading: false,
    categoriesError: true,
  }))
  .handleAction(putCatalogCategoriesToStoreAction, (state, { payload: { categories } }) => ({
    ...state,
    categories,
  }))

  .handleAction(getTagsRequestAction, (state) => ({
    ...state,
    tagsLoading: true,
    tagsError: false,
  }))
  .handleAction(getTagsSuccessAction, (state) => ({
    ...state,
    tagsLoading: false,
  }))
  .handleAction(getTagsFailureAction, (state) => ({
    ...state,
    tagsLoading: false,
    tagsError: true,
  }))
  .handleAction(putTagsToStoreAction, (state, { payload: { tags } }) => ({
    ...state,
    tags,
  }))

  .handleAction(getCatalogEventsRequestAction, (state) => ({
    ...state,
    eventsLoading: true,
    eventsError: false,
  }))
  .handleAction(getCatalogEventsSuccessAction, (state) => ({
    ...state,
    eventsLoading: false,
  }))
  .handleAction(getCatalogEventsFailureAction, (state) => ({
    ...state,
    eventsLoading: false,
    eventsError: true,
  }))
  .handleAction(putCatalogEventsToStoreAction, (state, { payload: { events, eventsTotal } }) => ({
    ...state,
    events,
    eventsTotal,
  }))

  .handleAction(getMoreCatalogEventsRequestAction, (state) => ({
    ...state,
    moreEventsLoading: true,
    moreEventsError: false,
  }))
  .handleAction(getMoreCatalogEventsSuccessAction, (state) => ({
    ...state,
    moreEventsLoading: false,
  }))
  .handleAction(getMoreCatalogEventsFailureAction, (state) => ({
    ...state,
    moreEventsLoading: false,
    moreEventsError: true,
  }))

  .handleAction(resetCatalogState, (state) => ({
    ...defaultState,
    lastSearch: state.lastSearch,
  }))

  .handleAction(setLastCatalogSearchAction, (state, { payload }) => ({
    ...state,
    lastSearch: {
      ...state.lastSearch,
      ...payload,
    },
  }))
  .handleAction(resetLastCatalogSearchAction, (state) => ({
    ...state,
    lastSearch: undefined,
  }))

  .handleAction(subscribeSuccessAction, (state, { payload: eventId }) => {
    if (state.events.length) {
      const eventIndex = state.events.findIndex((element) => element.id === eventId);
      return update(state, { events: { [eventIndex]: { subscribed: { $set: true } } } });
    }
    return state;
  })
  .handleAction(unSubscribeSuccessAction, (state, { payload: eventId }) => {
    if (state.events.length) {
      const eventIndex = state.events.findIndex((element) => element.id === eventId);
      return update(state, { events: { [eventIndex]: { subscribed: { $set: false } } } });
    }
    return state;
  });
