import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import { TextInput } from 'atoms';

interface IProps extends WrappedFieldProps {
  caption: string;
  required?: boolean;
  isPassword?: boolean;
  onlyLowerCase?: boolean;
}

export const TextField = ({
  input,
  meta: { touched, error },
  required,
  caption,
  isPassword,
  onlyLowerCase,
}: IProps) => {
  const errorText = touched && error ? error : '';

  return (
    <TextInput
      type="primary"
      caption={caption}
      errorMsg={errorText}
      required={required}
      isPassword={isPassword}
      onlyLowerCase={onlyLowerCase}
      {...input}
    />
  );
};
