import React from 'react';
import styled from 'styled-components';

import { IBanner } from 'types';
import { useWindowSize } from 'hooks';

interface Iprops {
  item: IBanner;
}

export const BannersCarouselItem = ({ item: { name, desktopUrl, mobileUrl, url } }: Iprops) => {
  const [width] = useWindowSize();

  const imageUrl = width > 768 ? desktopUrl : mobileUrl;

  return (
    <Wrapper target="_blank" href={url}>
      <img src={imageUrl} alt={name} />
    </Wrapper>
  );
};

const Wrapper = styled.a`
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;

  img {
    width: 100%;
  }
`;
