import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';

import { Container, Button } from 'atoms';
import { View as ViewIcon } from 'assets/icons';
import { getHomeOriginals, getHomeEnterestingEvents } from 'core/selectors';
import { getHomeEnterestingEventsRequestAction } from 'core/actions';
import { useWindowSize } from 'hooks';

export const EMediQOriginals = () => {
  const dispatch = useDispatch();
  const width = useWindowSize()[0];

  const { originalsDescription, originalsTitle, originalsVideoUrl } = useSelector(getHomeOriginals);

  const { enterestingEvents, countEnterestingEvens } = useSelector(getHomeEnterestingEvents);

  useEffect(() => {
    if (!width) return;
    dispatch(getHomeEnterestingEventsRequestAction({ limit: width > 1199 ? 4 : 2 }));
  }, [dispatch, width]);

  const handleLoadMore = () => {
    dispatch(getHomeEnterestingEventsRequestAction({ limit: width > 1199 ? 4 : 2, more: true }));
  };

  if (!originalsVideoUrl) return null;

  return (
    <Container>
      <SectionTitle>eMediQ Originals</SectionTitle>
      <Content>
        <TextContainer>
          <TitleContent>{originalsTitle}</TitleContent>
          <DescriptionContent>
            {originalsDescription.split('\r\n').map((paragraph, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <p key={i}>{paragraph}</p>
            ))}
          </DescriptionContent>
        </TextContainer>
        <VideoContainer>
          <iframe
            src={originalsVideoUrl}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title="video"
          />
          <script src="https://player.vimeo.com/api/player.js" />
        </VideoContainer>
      </Content>
      <EnterestingEvents>
        {enterestingEvents.map(({ id, title, thumbnailUrl, startDate, viewingCount }) => (
          <Event to={`/event/${id}`} key={id}>
            <EventImage $url={thumbnailUrl} />
            <EventTitle>{title}</EventTitle>
            <EventFooter>
              {format(new Date(startDate), 'dd MMMM yyyy', { locale: ru })}
              <Viewing>
                <ViewIcon />
                {viewingCount}
              </Viewing>
            </EventFooter>
          </Event>
        ))}
      </EnterestingEvents>
      {countEnterestingEvens > enterestingEvents.length && (
        <SeeAllButton onClick={handleLoadMore} type="ghost">
          Посмотреть еще
        </SeeAllButton>
      )}
    </Container>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1200px) {
    align-items: center;
    flex-direction: row;
  }
`;

const TextContainer = styled.article`
  flex: 1;
`;

const TitleContent = styled.h3`
  font-weight: bold;
  font-size: 18px;
  @media (min-width: 1200px) {
    font-size: 24px;
  }
`;

const DescriptionContent = styled.div`
  font-size: 16px;
`;

const SectionTitle = styled.h2`
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  padding: 40px 0 32px;

  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 40px;
    padding: 40px 0 48px;
  }

  @media (min-width: 1200px) {
    font-size: 48px;
    line-height: 56px;
    padding: 40px 0 50px;
    display: block;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  position: relative;

  @media (min-width: 1200px) {
    width: 768px;
  }

  iframe {
    height: 200px;
    width: 100%;
    border: none;
    display: block;
    @media (min-width: 768px) {
      height: 424px;
    }
  }
`;

const EnterestingEvents = styled.div`
  margin: 0 -15px;
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 768px) {
    margin-top: 24px;
  }
  @media (min-width: 1200px) {
    margin-top: 48px;
  }
`;

const Event = styled(Link)`
  margin: 0 15px;
  flex: 0 0 calc(50% - 30px);
  margin-bottom: 56px;

  display: flex;
  flex-direction: column;

  color: #000;
  &:hover {
    color: #000;
  }
  @media (min-width: 1200px) {
    flex: 0 0 calc(25% - 30px);
  }
`;

const EventImage = styled.div<{ $url: string }>`
  ${({ $url }) => css`
    border-radius: 12px;
    background-image: url(${$url});
    min-height: 100px;
    height: 90px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    @media (min-width: 768px) {
      height: 208px;
    }
    @media (min-width: 1200px) {
      height: 160px;
    }
  `}
`;

const EventTitle = styled.h4`
  margin-top: 24px;
  margin-bottom: 16px;
  flex: 1;
  font-size: 14px;
  font-weight: bold;

  @media (min-width: 576px) {
    font-size: 18px;
  }
`;

const EventFooter = styled.div`
  display: flex;
  font-size: 10px;
  color: #4853ec;
  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

const Viewing = styled.span`
  display: inline-flex;
  margin-left: 12px;
  align-items: center;
  svg {
    margin-right: 8px;
  }
  @media (min-width: 768px) {
    margin-left: 32px;
  }
`;

const SeeAllButton = styled(Button)`
  display: block;
  margin: auto;
`;
