import { SagaIterator } from 'redux-saga';
import { takeEvery, call, put, SagaReturnType, select } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { convertHomeToAppSchema, convertHomeEnterestinEventToAppSchema, convertEventsToAppSchema } from 'services/apiMappers';
import { eventsService, homeService } from 'services';
import {
  getHomeRequestAction,
  putHomeToStoreAction,
  getHomeSuccessAction,
  getHomeFailureAction,
  getHomeEnterestingEventsRequestAction,
  getHomeEnterestingEventsSuccessAction,
  getHomeEnterestingEventsFailureAction,
  putHomeEnterestingEventsToStoreAction,
  putUpcomingEventsToStoreAction,
  getUpcomingEventsSuccessAction,
  getUpcomingEventsFailureAction,
  getUpcomingEventsRequestAction,
} from 'core/actions';
import { getHomeEnterestingEvents } from 'core/selectors';
import { HomeEnterestingEventsType } from 'core/types/selectorsTypes';
import { CatalogSearchASC } from 'types';

export function* getHomeSaga(): SagaIterator {
  try {
    const { data } = yield call(() => homeService.getHome());
    const home = convertHomeToAppSchema(data);

    yield put(getHomeSuccessAction());
    yield put(putHomeToStoreAction({ home }));
  } catch (error) {
    yield put(getHomeFailureAction());
  }
}

type EventsResult = SagaReturnType<typeof homeService.getEnterestingEvents>;
export function* getHomeEnterestinEventsSaga({
  payload: { limit, more },
}: ActionType<typeof getHomeEnterestingEventsRequestAction>): SagaIterator {
  try {
    const { enterestingEvents }: HomeEnterestingEventsType = yield select(getHomeEnterestingEvents);

    const { data }: EventsResult = yield call(() =>
      homeService.getEnterestingEvents(limit, more ? Math.ceil(enterestingEvents.length / limit) + 1 : 1),
    );
    const newEnterestingEvents = convertHomeEnterestinEventToAppSchema(data);

    yield put(getHomeEnterestingEventsSuccessAction());
    yield put(
      putHomeEnterestingEventsToStoreAction({
        enterestingEvents: more ? [...enterestingEvents, ...newEnterestingEvents] : newEnterestingEvents,
        countEnterestingEvens: data.total,
      }),
    );
  } catch (error) {
    yield put(getHomeEnterestingEventsFailureAction());
  }
}

function* getUpcomingEventsSaga(): SagaIterator {
  try {
    const currentDate = new Date();
    const { results } = yield call(() =>
      eventsService.getEvents({ searchASC: CatalogSearchASC.start_date, upcomingEvents: true, dateStart: currentDate }),
    );

    const upcomingEvents = convertEventsToAppSchema(results);
    yield put(putUpcomingEventsToStoreAction({ upcomingEvents }));
    yield put(getUpcomingEventsSuccessAction());
  } catch (error) {
    yield put(getUpcomingEventsFailureAction());
  }
}

export function* homeRootSaga(): SagaIterator {
  yield takeEvery(getHomeRequestAction, getHomeSaga);
  yield takeEvery(getHomeEnterestingEventsRequestAction, getHomeEnterestinEventsSaga);
  yield takeEvery(getUpcomingEventsRequestAction, getUpcomingEventsSaga);
}
