import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getCatalogCategoriesFromStore } from 'core/selectors';
import { getCatalogCategoriesRequestAction, putCurrentCatalogCategoryToStoreAction } from 'core/actions';

import { Categories } from './Categories';

export const CatalogCategoriesContainer = () => {
  const dispatch = useDispatch();
  const categories = useSelector(getCatalogCategoriesFromStore);
  const history = useHistory();
  const { area, category } = useParams<{ area?: string; category?: string }>();

  useEffect(() => {
    dispatch(getCatalogCategoriesRequestAction({ alias: area }));
  }, [dispatch, area]);

  useEffect(() => {
    if (categories.length) {
      const currentCatalogCategory = categories.find((item) => item.alias === category)?.id;

      if (currentCatalogCategory) {
        dispatch(putCurrentCatalogCategoryToStoreAction({ id: currentCatalogCategory }));
      } else {
        dispatch(putCurrentCatalogCategoryToStoreAction({ id: undefined }));
        history.push(area ? `/area/${area}/catalog/all` : `/catalog/all`);
      }
    }
  }, [dispatch, categories, category, history, area]);

  return (
    <Categories
      categories={categories.map((c) => ({
        ...c,
        to: area ? `/area/${area}/catalog/${c.alias}` : `/catalog/${c.alias}`,
      }))}
      activeCategory={category}
      defaultTo={area ? `/area/${area}/catalog/all` : `/catalog/all`}
    />
  );
};
