import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from 'components/atoms';
import { MainTemplate } from 'components/templates';
import { getUserData } from 'core/selectors';
import { getUserInfoRequestAction } from 'core/actions';

import { UserHistory, UserProfile } from './Components';

export type CabinetSection = 'history' | 'profile';

interface ICustomizedLocationState {
  section: CabinetSection;
}

export const PersonalArea = () => {
  const location = useLocation();
  const history = useHistory();
  const userInfo = useSelector(getUserData);
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState<CabinetSection>('history');
  const state = location.state as ICustomizedLocationState;

  useEffect(() => {
    if (state) {
      setActiveTab(state.section);
    }
  }, [state]);

  useEffect(() => {
    dispatch(getUserInfoRequestAction());
  }, [dispatch]);

  const setActiveSection = (section: CabinetSection) => {
    setActiveTab(section);
  };

  if (!userInfo) {
    history.push('/');
    return null;
  }

  return (
    <MainTemplate isMenuButtonVisible>
      <TopContainer>
        <UserNameTitle>{userInfo.full_name}</UserNameTitle>
        <UserEmail>{userInfo.email}</UserEmail>
        <CabinetTabsWrapper>
          <CabinetSectionTab $isActive={activeTab === 'history'} onClick={() => setActiveSection('history')}>
            История
          </CabinetSectionTab>
          <CabinetSectionTab $isActive={activeTab === 'profile'} onClick={() => setActiveSection('profile')}>
            Профиль
          </CabinetSectionTab>
        </CabinetTabsWrapper>
      </TopContainer>
      <MainContentWrapper>
        <MainContentContainer>{activeTab === 'history' ? <UserHistory /> : <UserProfile />}</MainContentContainer>
      </MainContentWrapper>
    </MainTemplate>
  );
};

const TopContainer = styled(Container)`
  background-color: #f8f8f8;
  padding-top: 40px;

  @media (min-width: 768px) {
    padding-top: 64px;
  }
`;

const MainContentWrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
`;

const MainContentContainer = styled(Container)`
  background-color: #ffffff;
  padding: 64px 0;
`;

const UserNameTitle = styled.div`
  color: #000000;
  font-family: Raleway;
  font-size: 18px;
  font-weight: 800;
  line-height: 28px;
  margin-bottom: 4px;

  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 16px;
  }
`;

const UserEmail = styled.div`
  color: #a7a8ab;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 48px;
  }
`;

const CabinetTabsWrapper = styled.div`
  display: flex;

  @media (max-width: 767px) {
    width: 90vw;
    justify-content: space-between;
  }
`;

const CabinetSectionTab = styled.div<{ $isActive?: boolean }>`
  padding: 16px 0;
  width: 128px;
  color: #828384;
  display: flex;
  justify-content: center;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  @media (min-width: 768px) {
    &:first-of-type {
      margin-right: 48px;
    }
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: #000000;
      border-bottom: 3px solid #4853ec;
    `}

  @media (min-width: 768px) {
    font-size: 16px;
    width: 140px;
  }
`;
