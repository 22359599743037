import qs from 'querystring';

import { IAuthorsRequest, IApiAuthorsRequest, IApiAuthor, IApiAuthorExtended } from 'types';

import { BaseService } from './BaseService';

class AuthorAPIService extends BaseService {
  public defaultAuthorsLimit = 15;

  public async getAuthors({ alias, searchString, offset = 1, limit = this.defaultAuthorsLimit }: IAuthorsRequest) {
    const search = searchString ?? undefined;

    const body: IApiAuthorsRequest = { offset, limit };
    if (search) body.full_name = search;
    if (alias) body.areas__alias = alias;

    const { data } = await this.get<{ results: IApiAuthor[]; total: number }>(`authors?${qs.stringify({ ...body })}`);

    return data;
  }

  public async getAuthor({ id }: { id: string }) {
    const { data } = await this.get<IApiAuthorExtended>(`authors/${id}`);

    return data;
  }
}

export const AuthorService = new AuthorAPIService();
