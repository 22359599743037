import qs from 'querystring';

import { BaseService } from './BaseService';

interface IAreasCroppedBody {
  limit: number;
  offset?: number;
}

class AreaAPIService extends BaseService {
  public defaultAreasLimit = 4;

  public defaultAreasMenuItems = 15;

  public async getAreasAlias() {
    return this.get('aliases');
  }

  public async getAllAreas() {
    const body: IAreasCroppedBody = { limit: this.defaultAreasMenuItems };

    return this.get(`areas?${qs.stringify({ ...body })}`);
  }

  public async getAreasCropped(offset = 0) {
    const body: IAreasCroppedBody = { limit: this.defaultAreasLimit };
    if (offset) {
      body.offset = offset;
    }

    return this.get(`areas?${qs.stringify({ ...body })}`);
  }

  public async getArea(alias: string) {
    const route = `areas/${alias}`;

    return this.get(route);
  }
}

export const areaService = new AreaAPIService();
