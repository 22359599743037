import React, { useState } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'antd';

import { Section as DefaultSection } from 'atoms';
import { IPartner } from 'types';

import { PartnerModal } from './PartnerModal';

interface IProps {
  data?: Array<IPartner>;
  isItemModalNeed?: boolean;
  title?: string;
}

export const Partners = ({ data = [], isItemModalNeed = false, title = 'Партнеры' }: IProps) => {
  const [currentPartner, setCurrentPartner] = useState<IPartner | null>(null);

  const itemClickHandler = (partnerId: number, partnerUrl: string, partnerMaterialUrl: string) => {
    if (isItemModalNeed) {
      const newCurrentPartner = data.find((el) => el.id === partnerId);
      setCurrentPartner(newCurrentPartner || null);
      return;
    }
    const linkToOpen = partnerMaterialUrl || partnerUrl;

    window.open(linkToOpen, '_blank')!.focus();
  };

  const closeModalHandler = () => {
    setCurrentPartner(null);
  };

  return (
    <Section title={title}>
      <Row gutter={[8, 8]}>
        {data.map(({ thumbnailUrl, id, partnerUrl, materialsUrl }) => (
          <Col xs={24} md={8} key={id}>
            <ItemContainer onClick={() => itemClickHandler(id, partnerUrl, materialsUrl)}>
              <img src={thumbnailUrl} alt={String(id)} />
            </ItemContainer>
          </Col>
        ))}
      </Row>

      <PartnerModal partner={currentPartner} onClose={closeModalHandler} />
    </Section>
  );
};

const Section = styled(DefaultSection)`
  background: #ffffff;
`;

const ItemContainer = styled.div`
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  height: 240px;
  text-align: center;
  line-height: 230px;

  img {
    max-width: 50%;
    filter: grayscale(100%);
    transition: all 0.5s ease;
  }

  &:hover {
    img {
      filter: grayscale(0%);
    }
  }
`;
