import React, { CSSProperties, useEffect, useCallback } from 'react';
import { notification } from 'antd';
import styled from 'styled-components';

interface INotificationOpenConfig {
  title: string;
  message?: string;
}

interface INotificationConfirmConfig {
  title: string;
  btnText: string;
  onConfirm: (key: string) => void;
  onClose?: () => void;
  message?: string;
}

export function useNotification() {
  const open = useCallback(({ title, message }: INotificationOpenConfig) => {
    notification.open({
      message: title,
      description: message,
      style: stylesOpen,
    });
  }, []);

  const confirm = useCallback(({ title, btnText, message, onConfirm, onClose }: INotificationConfirmConfig) => {
    const key = `confirm${Date.now()}`;

    notification.open({
      message: title,
      description: message,
      btn: React.createElement(Button, { onClick: () => onConfirm(key) }, btnText),
      key,
      style: stylesConfirm,
      onClose,
    });
  }, []);

  const close = useCallback((key: string) => {
    notification.close(
      key,
    );
  }, []);

  useEffect(() => {
    notification.config({
      placement: 'topLeft',
      bottom: 50,
      duration: 50,
    });
  }, []);

  return {
    confirm,
    open,
    close
  };
}

const stylesOpen: CSSProperties = {
  width: 600,
  borderRadius: '12px',
};

const stylesConfirm: CSSProperties = {
  width: 1000,
  borderRadius: '12px',
};

const Button = styled.button``;
