import React from 'react';
import { useDispatch } from 'react-redux';

import { subscribeRequestAction, unSubscribeRequestAction } from 'core/actions';

import { SubscriptionBtn } from './SubscriptionBtn';

interface IProps {
  size?: 'medium' | 'small';
  eventId?: number;
  isSubscribed?: boolean;
  forListItem?: boolean;
}

export const Container = ({ eventId = 0, isSubscribed = false, size = 'medium', forListItem = false }: IProps) => {
  const dispatch = useDispatch();

  const onClickHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    if (isSubscribed) {
      dispatch(unSubscribeRequestAction(eventId));
    }

    if (!isSubscribed) {
      dispatch(subscribeRequestAction(eventId));
    }
  };

  return (
    <SubscriptionBtn
      size={size}
      isSubscribed={isSubscribed}
      onClickHandler={onClickHandler}
      forListItem={forListItem}
    />
  );
};
