import { ActionType, createReducer } from 'typesafe-actions';
import update from 'immutability-helper';

import {
  putAreaToStoreAction,
  putAreasCroppedToStoreAction,
  clearAreasCroppedToStoreAction,
  clearAreaToStoreAction,
  clearAreasAliasToStoreAction,
  putAreaEventsToStoreAction,
  putCurrentAreaEventsMonthToStoreAction,
  putAreasAliasToStoreAction,
  getAreaRequestAction,
  getAreaFailureAction,
  getAreaSuccessAction,
  putAllAreasToStoreAction,
} from 'core/actions';
import { IArea, IAreaCropped, IAreaShortened, IEventListItem } from 'types';

export interface IAreaState {
  allAreas: IAreaShortened[];
  areasCropped: {
    areas: IAreaCropped[];
    total: number;
  };

  area: IArea;
  isAreaLoading: boolean;

  calendarEvents: {
    items: IEventListItem[];
    currentMonthIndex: number;
  };
}

const defaultState: IAreaState = {
  allAreas: [],
  areasCropped: {
    areas: [],
    total: 0,
  },

  area: createDefaultArea(),
  isAreaLoading: true,

  calendarEvents: {
    items: [],
    currentMonthIndex: 0,
  },
};

const actions = {
  putAreaToStoreAction,
  putAreasCroppedToStoreAction,
  clearAreasCroppedToStoreAction,
  clearAreaToStoreAction,
  clearAreasAliasToStoreAction,
  putAreaEventsToStoreAction,
  putCurrentAreaEventsMonthToStoreAction,
  putAreasAliasToStoreAction,
  getAreaRequestAction,
  getAreaFailureAction,
  getAreaSuccessAction,
  putAllAreasToStoreAction,
};

export const areaReducer = createReducer<IAreaState, ActionType<typeof actions>>(defaultState)
  // areas cropped
  .handleAction(putAreasCroppedToStoreAction, (state, { payload }) => ({
    ...state,
    areasCropped: payload,
  }))
  .handleAction(clearAreasCroppedToStoreAction, (state) => ({ ...state, areasCropped: { areas: [], total: 0 } }))

  // all areas
  .handleAction(putAllAreasToStoreAction, (state, { payload: { areas } }) => ({
    ...state,
    allAreas: areas,
  }))

  // area
  .handleAction(getAreaRequestAction, (state) => ({
    ...state,
    isAreaLoading: true,
  }))
  .handleAction(getAreaSuccessAction, (state) => ({
    ...state,
    isAreaLoading: false,
  }))
  .handleAction(getAreaFailureAction, (state) => ({
    ...state,
    isAreaLoading: false,
  }))
  .handleAction(putAreaToStoreAction, (state, { payload: { area } }) => ({
    ...state,
    area,
  }))
  .handleAction(putAreaEventsToStoreAction, (state, { payload: { events } }) => {
    return update(state, { calendarEvents: { items: { $set: events } } });
  })
  .handleAction(putCurrentAreaEventsMonthToStoreAction, (state, { payload: apiIndex }) => {
    return update(state, { calendarEvents: { currentMonthIndex: { $set: apiIndex } } });
  })
  .handleAction(clearAreaToStoreAction, (state) => ({
    ...state,
    area: createDefaultArea(),
    calendarEvents: {
      items: [],
      currentMonthIndex: 0,
    },
  }));

function createDefaultArea(): IArea {
  return {
    id: null,
    title: '',
    alias: '',
    description: '',
    backgroundUrl: '',
    categories: [],
    partners: [],
    upcomingEvents: [],
    interestingEvents: [],
    socialNetworks: [],
    banners: [],
    color: null,
  };
}
