import React from 'react';
import { Row, Col, Grid } from 'antd';
import { SubmitHandler } from 'redux-form';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { RadioChangeEvent } from 'antd/lib/radio';

import { getIsRegisterLoading, getRegister } from 'core/selectors';
import {
  IRegisterFormFirstStepValues,
  IRegisterFormSecondStepValues,
  IRegisterFormThirdStepValues,
  RegisterSteps,
} from 'types';
import { Button, Checkbox, FormServerError } from 'atoms';
import { putIsPersonalDataApproveValueAction } from 'core/actions';

const { useBreakpoint } = Grid;

type HandleSubmitFirstStepFormType = SubmitHandler<IRegisterFormFirstStepValues, {}>;

type HandleSubmitSecondStepFormType = SubmitHandler<
  IRegisterFormSecondStepValues,
  {
    handleStepDown?: () => void;
  }
>;

type HandleSubmitThirdStepFormType = SubmitHandler<
  IRegisterFormThirdStepValues,
  {
    handleStepDown?: () => void;
  }
>;

interface IProps {
  step: RegisterSteps;
  handleSubmit: HandleSubmitFirstStepFormType | HandleSubmitSecondStepFormType | HandleSubmitThirdStepFormType;
  handleStepDown?: () => void;
}

export const FormFooter = ({ step, handleStepDown, handleSubmit }: IProps) => {
  const register = useSelector(getRegister);
  const isRegisterLoading = useSelector(getIsRegisterLoading);
  const dispatch = useDispatch();

  const TermsCheckboxChangeHandler = (e: RadioChangeEvent) => {
    dispatch(putIsPersonalDataApproveValueAction(e.target.checked));
  };

  const { xs } = useBreakpoint();
  return (
    <>
      <Row gutter={[32, 0]}>
        <Col xs={24} lg={12} xl={12}>
          <Terms>
            <TermsCheckbox
              onChange={TermsCheckboxChangeHandler}
              isError={register.isPersonalDataApprove.isError}
              checked={register.isPersonalDataApprove.value}
            />
            <TermsContent>
              Я даю согласие на обработку моих{' '}
              <a
                href="https://drive.google.com/file/d/1jALGESCWl04bUyUvU_2ZgAfthGWPp309/view?usp=sharing"
                target="_blank"
                rel="noreferrer"
              >
                персональных данных
              </a>{' '}
              и соглашаетесь c{' '}
              <a
                target="_blank"
                href="https://drive.google.com/file/d/1CGjp8LKF3nVqOKgKedE4h_mVamjmfpWy/view?usp=sharing"
                rel="noreferrer"
              >
                политикой конфиденциальности.
              </a>
            </TermsContent>
          </Terms>
        </Col>
        <Col xs={24} lg={12} xl={12} style={{ textAlign: 'right' }}>
          <Row gutter={[8, { xs: 8, lg: 0 }]} justify="end">
            {step !== RegisterSteps.First && (
              <Col flex="12 1 auto" xs={0} sm={11}>
                <ButtonBackRestyled type="default" onClick={handleStepDown}>
                  Вернуться назад
                </ButtonBackRestyled>
              </Col>
            )}
            <Col xs={24} flex={`${xs ? 1 : 0} 1 auto`}>
              <ButtonNextRestyled type="primary" onClick={handleSubmit} loading={isRegisterLoading}>
                {step !== RegisterSteps.Third ? 'Дальше' : 'Зарегистрироваться'}
              </ButtonNextRestyled>
            </Col>
            <Col flex="12 1 auto" xs={24} sm={0}>
              <ButtonBackRestyled type="default" onClick={handleStepDown}>
                Вернуться назад
              </ButtonBackRestyled>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <FormServerError />
      </Row>
    </>
  );
};

const Terms = styled.p`
  ${({ theme: { registerFormTermsLinkColor, registerFormTermsColor } }) => css`
    font-family: 'Open Sans', sans-serif;
    color: ${registerFormTermsColor};
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 30px;

    a {
      color: ${registerFormTermsLinkColor};
      text-decoration: underline;
    }

    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 18px;
    }
  `}
`;

const ButtonBackRestyled = styled(Button)`
  width: 100%;

  @media (min-width: 576px) {
    width: auto;
  }
`;

const ButtonNextRestyled = styled(ButtonBackRestyled)`
  margin-bottom: 0px;

  @media (min-width: 576px) {
    margin-bottom: 0px;
  }
`;

const TermsCheckbox = styled(Checkbox)`
  float: left;
  margin: 6px 0 0 0;
`;

const TermsContent = styled.div`
  padding: 0 0 0 40px;
`;
