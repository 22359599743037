import React, { useEffect, useCallback, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { DownIcon, Exit } from 'assets/icons';
import { Button } from 'components/atoms';
import { logOutAction, putUserDataToStoreAction } from 'core/actions';
import { CabinetSection } from 'components/pages/PersonalArea';

interface IUserInfoDropdownProps {
  name: string;
}

const getDisplayName = (fullName: string) => {
  if (fullName.length < 40) return fullName;

  const nameSplitted = fullName.split(' ');

  const [surname, ...names] = nameSplitted;

  return `${surname} ${names.map((item) => item[0]).join('.')}.`;
};

export const UserInfoDropdown = ({ name }: IUserInfoDropdownProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userInfoRef = useRef<HTMLDivElement>(null);
  const buttonSpanRef = useRef<HTMLSpanElement>(null);

  const defaultDropdownWidth = 276;

  const [isUserInfoOpen, setUserInfoOpen] = useState<boolean>(false);
  const [dropdownWidth, setDropdownWidth] = useState<number>(defaultDropdownWidth);

  const toggleUserInfo = () => {
    setUserInfoOpen((state) => !state);
  };

  const handleClickOutside = useCallback((event: Event) => {
    const path = event.composedPath();

    if (userInfoRef.current && !path.includes(userInfoRef.current)) {
      setUserInfoOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (buttonSpanRef.current && name) {
      setDropdownWidth(buttonSpanRef.current.offsetWidth + 100);
    }
  }, [name]);

  const goToUserProfile = (section: CabinetSection) => {
    setUserInfoOpen(false);
    history.push('/personal-area', { section });
  };

  const logoutHandler = () => {
    dispatch(logOutAction());
    dispatch(putUserDataToStoreAction(null));
    history.push('/');
  };

  return (
    <UserInfoWrapper ref={userInfoRef} $isOpen={isUserInfoOpen}>
      <Button size="small" type="default" onClick={toggleUserInfo} style={{ minWidth: '276px', width: dropdownWidth }}>
        <span ref={buttonSpanRef}>{getDisplayName(name)}</span>
        <DownIconStyled />
      </Button>
      <UserInfoDropdownWrapper $width={String(dropdownWidth)}>
        <UserInfoDropdownMenu>
          <UserInfoDropdownMenuItem onClick={() => goToUserProfile('history')}>История</UserInfoDropdownMenuItem>
          <UserInfoDropdownMenuItem onClick={() => goToUserProfile('profile')}>Профиль</UserInfoDropdownMenuItem>
          <UserInfoDropdownMenuItem onClick={logoutHandler}>
            Выйти <Exit />
          </UserInfoDropdownMenuItem>
        </UserInfoDropdownMenu>
      </UserInfoDropdownWrapper>
    </UserInfoWrapper>
  );
};

const DownIconStyled = styled(DownIcon)`
  margin-left: 8px;
`;

const UserInfoDropdownWrapper = styled.div<{ $width?: string }>`
  border-radius: 0 0 6px 6px;
  position: absolute;
  background-color: #ffffff;
  color: #000000;
  font-size: 16px;
  padding-bottom: 4px;
  top: 88px;
  left: 0;
  right: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: all 0.2s ease;
  box-shadow: 0px 7px 64px 0px rgba(0, 0, 0, 0.07);
  min-width: 276px;
  width: ${({ $width }) => `${$width}px` || '276px'};
`;

export const UserInfoDropdownMenu = styled.ul`
  padding: 0;
`;

export const UserInfoDropdownMenuItem = styled.li`
  padding: 24px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.8px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:last-of-type {
    justify-content: space-between;
    border-top: 1px solid #dcdcdd;
  }

  &:hover {
    background-color: #e2e3fc;
  }
`;

const UserInfoWrapper = styled.div<{ $isOpen: boolean }>`
  position: relative;

  ${({ $isOpen }) => {
    if ($isOpen) {
      return css`
        ${UserInfoDropdownWrapper} {
          transform: scaleY(1);
          transition: all 0.4s ease;
        }

        ${DownIconStyled} {
          transform: rotate(180deg);
        }
      `;
    }
    return css``;
  }}
`;
