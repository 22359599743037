import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getSurveysRequestAction, resetSurveyAction } from 'core/actions';
import { getCurrentSurveyStep, getSurveyData } from 'core/selectors';

import { FinalStep, QuestionStep, StartStep } from './Components';

interface ISurveyContentProps {
  eventSurveyId?: number;
  finalStepCallback?: () => void;
}

export const SurveyContent = ({ eventSurveyId, finalStepCallback }: ISurveyContentProps) => {
  const dispatch = useDispatch();
  const { surveyId } = useParams<{ surveyId?: string }>();
  const { currentStep } = useSelector(getCurrentSurveyStep);
  const { surveyData } = useSelector(getSurveyData);

  useEffect(() => {
    dispatch(
      getSurveysRequestAction({ ...(eventSurveyId && { eventId: eventSurveyId }), surveyIds: [Number(surveyId)] }),
    );

    return () => {
      dispatch(resetSurveyAction());
    };
  }, [dispatch, eventSurveyId, surveyId]);

  // if (!isLoggedIn) {
  //   history.push('/');
  // }

  const isLastStep = (surveyData?.questions.length || 0) + 1;

  // TODO move isEvent prop to wrapper and replace logic
  return currentStep === 0 ? (
    <StartStep isEvent={!!eventSurveyId} />
  ) : currentStep === isLastStep ? (
    <FinalStep isEvent={!!eventSurveyId} callback={finalStepCallback} />
  ) : (
    <QuestionStep isEvent={!!eventSurveyId} />
  );
};
