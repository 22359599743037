import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Pagination } from 'antd';

import { Container } from 'components/atoms';
import { MainTemplate } from 'components/templates';
import { getAllSurveysRequestAction } from 'core/actions';
import { getAllSurveys, getIsLoggedIn } from 'core/selectors';
import { SurveyCard } from 'components/molecules';

export const Surveys = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { allSurveys } = useSelector(getAllSurveys);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    dispatch(getAllSurveysRequestAction({ page }));
  }, [dispatch, page]);

  if (!isLoggedIn) {
    history.push('/');
  }

  const setNewPage = (newPage: number) => {
    if (newPage === page) return;
    setPage(newPage);
  };

  const goToSurvey = (surveyId: number) => {
    history.push(`/surveys/${surveyId}`);
  };

  return (
    <MainTemplate isMenuButtonVisible>
      <TopContainer>
        {allSurveys && allSurveys.results.length ? (
          allSurveys.results.map(({ id, title, questions }) => (
            <SurveyCard key={id} title={title} questionsQty={questions.length} onClick={() => goToSurvey(id)} />
          ))
        ) : (
          <NoSurveysStub>Опросы не найдены</NoSurveysStub>
        )}
        {allSurveys?.count && (
          <PaginationWrapper>
            <Pagination onChange={setNewPage} total={allSurveys.count} />
          </PaginationWrapper>
        )}
      </TopContainer>
    </MainTemplate>
  );
};

const TopContainer = styled(Container)`
  background-color: #f8f8f8;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  @media (min-width: 1024px) {
    padding-bottom: 64px;
  }
`;

const NoSurveysStub = styled.h2`
  font-family: Raleway, sans-serif;
`;

const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
