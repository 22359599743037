import qs from 'querystring';

import { IApiHomeEnterestingEvents } from 'types';

import { BaseService } from './BaseService';

class HomeAPIService extends BaseService {
  public async getHome() {
    return this.get('home');
  }

  public async getEnterestingEvents(limit: number, offset = 1) {
    const body = { offset, limit };

    return this.get<IApiHomeEnterestingEvents>(`interesting-events?${qs.stringify({ ...body })}`);
  }
}

export const homeService = new HomeAPIService();
