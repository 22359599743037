import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Layout, Menu } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Button } from 'atoms';
import { Exit } from 'assets/icons';
import { IAreaShortened } from 'types';
import { isAppSiderCollapsedAction } from 'core/actions';

import { NavMenu, baseLinkStyles } from './NavMenu';

const { Sider } = Layout;

interface IProps {
  isAppSiderCollapsed?: boolean;
  isLoggedIn?: boolean;
  showLoginModal(): void;
  showRegModal(): void;
  logoutHandler(): void;
  linkOnClick(): void;
  area?: string;
  children: ReactNode;
  catalogCategoriesItems: IAreaShortened[];
}

export const AppSider = ({
  isAppSiderCollapsed = true,
  isLoggedIn = false,
  showLoginModal,
  showRegModal,
  logoutHandler,
  area,
  linkOnClick,
  children,
  catalogCategoriesItems,
}: IProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const goToAccountInfo = () => {
    history.push('/personal-area');
    dispatch(isAppSiderCollapsedAction(true));
  };

  return (
    <Layout {...(!isAppSiderCollapsed && { style: { maxHeight: '100dvh' } })}>
      <Layout>{children}</Layout>
      <SiderContainer
        style={{
          height: '100dvh',
          minHeight: '100dvh',
          position: 'fixed',
          right: '0',
          left: '0',
          zIndex: 9,
          display: 'flex',
          overflow: 'hidden',
        }}
        trigger={null}
        theme="light"
        collapsible
        collapsed={isAppSiderCollapsed}
        collapsedWidth={0}
        width="100vw"
      >
        <SiderOverlay />
        <SiderContentWrapper>
          <NavMenu linkOnClick={linkOnClick} catalogCategoriesItems={catalogCategoriesItems} area={area} />

          <AuthMenu>
            {isLoggedIn && (
              <>
                <MenuItem>
                  <Button size="small" type="default" block onClick={goToAccountInfo}>
                    Личный кабинет
                  </Button>
                </MenuItem>
                <MenuItem>
                  <CustomButton onClick={logoutHandler}>
                    Выйти <Exit />
                  </CustomButton>
                </MenuItem>
              </>
            )}

            {!isLoggedIn && (
              <>
                <MenuItem>
                  <Button size="small" type="default" block onClick={showLoginModal}>
                    Войти
                  </Button>
                </MenuItem>

                <MenuItem>
                  <Button size="small" block onClick={showRegModal}>
                    Зарегистрироваться
                  </Button>
                </MenuItem>
              </>
            )}
          </AuthMenu>
        </SiderContentWrapper>
      </SiderContainer>
    </Layout>
  );
};

const SiderContainer = styled(Sider)`
  margin: 0;
  padding: 0;
  box-shadow: 0 4px 10px rgba(72, 83, 236, 0.08);

  .ant-layout-sider-children {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 72px;

    @media (min-width: 768px) {
      padding-top: 80px;
    }
  }
`;

const SiderOverlay = styled.div`
  display: flex;
  flex: 1;
  background-color: rgba(18, 26, 123, 0.64);
`;

const SiderContentWrapper = styled.div`
  width: 262px;
  padding: 32px 24px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 768px) {
    width: 412px;
    padding: 64px 72px;
  }
`;

const AuthMenu = styled(Menu)`
  display: block;
  border: none;
  margin-top: 56px;
`;

const MenuItem = styled.li`
  margin-bottom: 16px;
  padding: 0;

  & > div > a,
  & > a {
    ${baseLinkStyles}
    font-weight: bold;
    line-height: 24px;
    text-transform: uppercase;
  }

  & > svg {
    margin-left: 8px;
  }
`;

const CustomButton = styled.button`
  width: 100%;
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  letter-spacing: 0.4px;
  border-radius: 8px;
  border: none;
  font-weight: 600;
  height: 40px;
  background-color: #ffffff;
  color: #000000;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & > svg {
    height: 16px;
    width: 16px;
    margin-left: 8px;
  }
`;
