import React from 'react';
import styled from 'styled-components';

import {
  FacebookSocial,
  VkontakteSocial,
  TwitterSocial,
  YoutubeSocial,
  InstagramSocial,
  TelegramSocial,
} from 'assets/icons';
import { Socials } from 'types';

interface IProps {
  type: Socials;
  link: string;
  className?: string;
}

const Icons = {
  [Socials.vk]: VkontakteSocial,
  [Socials.facebook]: FacebookSocial,
  [Socials.instagram]: InstagramSocial,
  [Socials.youtube]: YoutubeSocial,
  [Socials.twitter]: TwitterSocial,
  [Socials.telegram]: TelegramSocial,
};

export const SocialItem = ({ type, link, className }: IProps) => (
  <Content className={className} href={link} target="_blank">
    {React.createElement(Icons[type])}
  </Content>
);

const Content = styled.a`
  text-decoration: none;
`;
