import { isEqual } from 'date-fns';

export const getNested = (obj: object, ...args: Array<keyof typeof obj>) => {
  return args.reduce((accumulator: object, level) => accumulator && accumulator[level], obj);
};

export const debounce = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  const debounced = (...args: Parameters<F>) => {
    if (timeout !== null) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => func(...args), waitFor);
  };

  return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

export const delay = (time: number) => new Promise((resolve) => setTimeout(resolve, time));

export const cropEventTitle = (width: number, title: string) => {
  switch (true) {
    case width >= 1200:
      return title.length > 115 ? `${title.substr(0, 115)} ...` : title;
    case width >= 991:
      return title.length > 105 ? `${title.substr(0, 105)} ...` : title;
    case width >= 768:
      return title.length > 75 ? `${title.substr(0, 75)} ...` : title;
    default:
      return title;
  }
};

export const getTodayDate = () => {
  return new Date(new Date().toLocaleString('en-US', { timeZone: 'Europe/Moscow' }));
};

export const getIsTodayDate = (date: Date) => {
  const today = new Date(new Date().toLocaleString('en-US', { timeZone: 'Europe/Moscow' }));

  return isEqual(
    new Date(today.getFullYear(), today.getMonth(), today.getDate()),
    new Date(date.getFullYear(), date.getMonth(), date.getDate()),
  );
};

export const glueDate = (date: string, time: string) => {
  const fullDate = new Date(date);
  fullDate.setHours(Number(time.split(':')[0]));
  fullDate.setMinutes(Number(time.split(':')[1]));

  return fullDate;
};

export const capitalize = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const loadScript = (id: string, src: string, callback: () => void) => {
  const existingScript = document.getElementById(id);
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = src;
    script.id = id;
    document.body.appendChild(script);
    script.onload = () => {
      callback();
    };
  } else {
    callback();
  }
};

export const removeScript = (id: string, callback?: () => void) => {
  const existingScript = document.getElementById(id);
  if (!existingScript) {
    callback?.();
  } else if (existingScript.parentNode) {
    existingScript.parentNode.removeChild(existingScript);
    callback?.();
  } else {
    document.body.removeChild(existingScript);
    callback?.();
  }
};
