import React from 'react';
import { Grid } from 'antd';
import { WrappedFieldProps } from 'redux-form';

import { TagSelect, RegisterSelect } from 'atoms';

const { useBreakpoint } = Grid;

interface IProps extends WrappedFieldProps {
  caption: string;
  tags: { value: string; label?: string }[];
  className?: string;
  isSelect?: boolean;
}

export const TagSelectField = ({ input: { value, onChange }, caption, tags, isSelect, className }: IProps) => {
  const { sm } = useBreakpoint();

  return sm && !isSelect ? (
    <TagSelect tags={tags} caption={caption} activeTag={value} onChange={onChange} className={className} />
  ) : (
    <RegisterSelect
      optionItems={tags.map((tag, i) => ({ id: i + 1, ...tag }))}
      caption={caption}
      value={value}
      onChange={onChange}
      onBlur={(e) => e.preventDefault()}
      className={className}
    />
  );
};
