import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalProps } from 'antd';
import styled, { css } from 'styled-components';

import { RegisterSteps } from 'types';
import { clearAuthServerErrorToStoreAction, putRegisterStepToStore } from 'core/actions';
import { getIsRegisterLoading, getRegisterStep } from 'core/selectors';

import { FirstStepForm, SecondStepForm, ThirdStepForm, ModalTitle, FourthStepForm } from './Components';

interface IProps extends ModalProps {}

export const RegisterModal = ({ ...props }: IProps) => {
  const dispatch = useDispatch();
  const registryStep = useSelector(getRegisterStep);
  const isRegisterLoading = useSelector(getIsRegisterLoading);

  const onStepDown = () => {
    if (registryStep === RegisterSteps.Second) {
      dispatch(clearAuthServerErrorToStoreAction());
    }

    dispatch(putRegisterStepToStore(registryStep === RegisterSteps.Fourth ? RegisterSteps.Third : registryStep === RegisterSteps.Third ? RegisterSteps.Second : RegisterSteps.First ));
  };

  return (
    <ModalContainer {...props} title={<ModalTitle step={registryStep} />} maskClosable={!isRegisterLoading} width={registryStep === RegisterSteps.Third || registryStep === RegisterSteps.Fourth ? 624 : 'auto'}>
      <Loading isLoading={isRegisterLoading} />
      {registryStep === RegisterSteps.First && <FirstStepForm />}
      {registryStep === RegisterSteps.Second && <SecondStepForm handleStepDown={onStepDown} />}
      {registryStep === RegisterSteps.Third && <ThirdStepForm handleStepDown={onStepDown} />}
      {registryStep === RegisterSteps.Fourth && <FourthStepForm handleStepDown={onStepDown} />}
    </ModalContainer>
  );
};

const ModalContainer = styled(Modal)`
  position: relative;

  .ant-modal-content {
    border-radius: 12px;
    overflow: hidden;

    .ant-modal-close {
      top: 33px;
      right: 22px;

      .ant-modal-close-x {
        font-size: 20px;
        width: 26px;
        height: 26px;
        line-height: 26px;
      }

      @media (min-width: 768px) {
        top: 32px;
        right: 40px;

        .ant-modal-close-x {
          font-size: 25px;
          width: 36px;
          height: 36px;
          line-height: 36px;
        }
      }

      @media (min-width: 1200px) {
        top: 60px;
        right: 73px;

        .ant-modal-close-x {
          font-size: 30px;
          width: 56px;
          height: 56px;
          line-height: 56px;
        }
      }
    }

    .ant-modal-header {
      border: none;
      padding: 32px 25px 18px 25px;

      @media (min-width: 768px) {
        padding: 32px 40px;
      }

      @media (min-width: 1200px) {
        padding: 64px 70px 47px 70px;
      }
    }

    .ant-modal-body {
      padding: 0 26px 24px 26px;

      @media (min-width: 768px) {
        padding: 0 40px 32px 40px;
      }

      @media (min-width: 1200px) {
        padding: 0 70px 64px 70px;
      }
    }
  }
`;

const Loading = styled.div<{ isLoading: boolean }>`
  ${({ isLoading }) =>
    css`
      transition: opacity 0.2s;

      background-color: white;
      border-radius: 12px;
      z-index: 10;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      opacity: 0;
      visibility: hidden;

      ${isLoading &&
      css`
        opacity: 0.5;
        visibility: visible;
      `};
    `}
`;
