import { ActionType, createReducer } from 'typesafe-actions';

import {
  IRegisterFormFirstStepValues,
  IRegisterFormSecondStepValues,
  IRegisterFormThirdStepValues,
  RegisterSteps,
  Sex,
} from 'types';
import {
  loginSuccessAction,
  refreshTokenSuccessAction,
  logOutAction,
  clearRegisterToStoreAction,
  putFirstStepRegisterToStore,
  putSecondStepRegisterToStore,
  putThirdStepRegisterToStore,
  putRegisterStepToStore,
  changeCreatePasswordPopupVisibleAction,
  changeForgotPasswordPopupVisibleAction,
  changeLoginPopupVisibleAction,
  changeMailCheckPopupVisibleAction,
  changeRegisterPopupVisibleAction,
  changeSentMessagePopupVisibleAction,
  putSearchASCToStoreAction,
  putCreatePasswordTokenToStoreAction,
  putCodeErrorToStoreAction,
  clearCreatePasswordToStoreAction,
  putAuthServerErrorToStoreAction,
  clearAuthServerErrorToStoreAction,
  isAppSiderCollapsedAction,
  putIsPersonalDataApproveValueAction,
  putIsPersonalDataApproveErrorAction,
  registerRequestAction,
  registerSuccessAction,
  registerFailureAction,
  loginFailureAction,
  loginRequstAction,
  forgotPasswordRequestAction,
  forgotPasswordSuccessAction,
  forgotPasswordFailureAction,
  createPasswordRequestAction,
  createPasswordSuccessAction,
  createPasswordFailureAction,
  setPhoneAction,
  putEmailExistsAction,
} from 'core/actions';

export interface IAuthState {
  authServerError: string;

  isLoggedIn: boolean;
  isLoginLoading: boolean;

  isForgotPasswordLoading: boolean;

  createPasswordToken: string;
  isCreatePasswordLoading: boolean;

  register: {
    firstStep: IRegisterFormFirstStepValues;
    secondStep: IRegisterFormSecondStepValues;
    thirdStep: IRegisterFormThirdStepValues;
    isPersonalDataApprove: {
      value: boolean;
      isError: boolean;
    };
  };
  registerStep: RegisterSteps;
  isRegisterLoading: boolean;

  isAppSiderCollapsed: boolean;

  popupsVisible: {
    login: boolean;
    register: boolean;
    forgotPassword: boolean;
    mailCheck: boolean;
    createPassword: boolean;
    sentMessage: boolean;
  };
  phone: string;
  codeError: string;
  exsists: boolean | null;
}

const defaultState: IAuthState = {
  authServerError: '',

  isLoggedIn: false,
  isLoginLoading: false,

  register: createDefaultRegister(),
  registerStep: RegisterSteps.First,
  isRegisterLoading: false,

  isForgotPasswordLoading: false,

  createPasswordToken: '',
  isCreatePasswordLoading: false,

  isAppSiderCollapsed: true,

  popupsVisible: {
    login: false,
    register: false,
    forgotPassword: false,
    mailCheck: false,
    createPassword: false,
    sentMessage: false,
  },
  phone: '',
  codeError: '',
  exsists: null,
};

const actions = {
  refreshTokenSuccessAction,
  logOutAction,
  putFirstStepRegisterToStore,
  putSecondStepRegisterToStore,
  putThirdStepRegisterToStore,
  clearRegisterToStoreAction,
  putRegisterStepToStore,
  putSearchASCToStoreAction,
  changeCreatePasswordPopupVisibleAction,
  changeForgotPasswordPopupVisibleAction,
  changeLoginPopupVisibleAction,
  changeMailCheckPopupVisibleAction,
  changeRegisterPopupVisibleAction,
  changeSentMessagePopupVisibleAction,
  putCreatePasswordTokenToStoreAction,
  clearCreatePasswordToStoreAction,
  putAuthServerErrorToStoreAction,
  clearAuthServerErrorToStoreAction,
  putCodeErrorToStoreAction,
  isAppSiderCollapsedAction,
  putIsPersonalDataApproveValueAction,
  putIsPersonalDataApproveErrorAction,
  registerRequestAction,
  registerSuccessAction,
  registerFailureAction,
  loginRequstAction,
  loginSuccessAction,
  loginFailureAction,
  forgotPasswordRequestAction,
  forgotPasswordSuccessAction,
  forgotPasswordFailureAction,
  createPasswordRequestAction,
  createPasswordSuccessAction,
  createPasswordFailureAction,
  setPhoneAction,
  putEmailExistsAction,
};

export const authReducer = createReducer<IAuthState, ActionType<typeof actions>>(defaultState)
  .handleAction(putAuthServerErrorToStoreAction, (state, { payload }) => ({
    ...state,
    authServerError: payload,
  }))
  .handleAction(putEmailExistsAction, (state, { payload }) => ({
    ...state,
    exsists: payload,
  }))
  .handleAction(putCodeErrorToStoreAction, (state, {payload}) => ({
    ...state,
    codeError: payload,
  }))
  .handleAction(setPhoneAction, (state, { payload }) => ({
    ...state,
    phone: payload,
  }))
  .handleAction(clearAuthServerErrorToStoreAction, (state) => ({
    ...state,
    authServerError: '',
  }))

  .handleAction(loginRequstAction, (state) => ({
    ...state,
    isLoginLoading: true,
  }))
  .handleAction(loginSuccessAction, (state) => ({
    ...state,
    isLoggedIn: true,
    isLoginLoading: false,
  }))
  .handleAction(loginFailureAction, (state) => ({
    ...state,
    isLoginLoading: false,
  }))

  .handleAction(refreshTokenSuccessAction, (state) => ({
    ...state,
    isLoggedIn: true,
  }))
  .handleAction(logOutAction, (state) => ({
    ...state,
    isLoggedIn: false,
  }))

  .handleAction(forgotPasswordRequestAction, (state) => ({
    ...state,
    isForgotPasswordLoading: true,
  }))
  .handleAction(forgotPasswordSuccessAction, (state) => ({
    ...state,
    isForgotPasswordLoading: false,
  }))
  .handleAction(forgotPasswordFailureAction, (state) => ({
    ...state,
    isForgotPasswordLoading: false,
  }))

  .handleAction(putFirstStepRegisterToStore, (state, { payload }) => ({
    ...state,
    register: {
      firstStep: { ...payload },
      secondStep: { ...state.register.secondStep },
      thirdStep: { ...state.register.thirdStep },
      isPersonalDataApprove: { ...state.register.isPersonalDataApprove },
    },
  }))
  .handleAction(putSecondStepRegisterToStore, (state, { payload }) => ({
    ...state,
    register: {
      firstStep: { ...state.register.firstStep },
      secondStep: { ...payload },
      thirdStep: { ...state.register.thirdStep },
      isPersonalDataApprove: { ...state.register.isPersonalDataApprove },
    },
  }))
  .handleAction(putThirdStepRegisterToStore, (state, { payload }) => ({
    ...state,
    register: {
      firstStep: { ...state.register.firstStep },
      secondStep: { ...state.register.secondStep },
      thirdStep: { ...payload },
      isPersonalDataApprove: { ...state.register.isPersonalDataApprove },
    },
  }))
  .handleAction(clearRegisterToStoreAction, (state) => ({
    ...state,
    register: createDefaultRegister(),
    registerStep: RegisterSteps.First,
  }))
  .handleAction(putRegisterStepToStore, (state, { payload }) => ({
    ...state,
    registerStep: payload,
  }))
  .handleAction(registerRequestAction, (state) => ({
    ...state,
    isRegisterLoading: true,
  }))
  .handleAction(registerSuccessAction, (state) => ({
    ...state,
    isRegisterLoading: false,
  }))
  .handleAction(registerFailureAction, (state) => ({
    ...state,
    isRegisterLoading: false,
  }))
  .handleAction(putIsPersonalDataApproveValueAction, (state, { payload }) => ({
    ...state,
    register: {
      ...state.register,
      isPersonalDataApprove: {
        value: payload,
        isError: false,
      },
    },
  }))
  .handleAction(putIsPersonalDataApproveErrorAction, (state, { payload }) => ({
    ...state,
    register: {
      ...state.register,
      isPersonalDataApprove: {
        value: state.register.isPersonalDataApprove.value,
        isError: payload,
      },
    },
  }))

  .handleAction(putCreatePasswordTokenToStoreAction, (state, { payload }) => ({
    ...state,
    createPasswordToken: payload,
  }))
  .handleAction(clearCreatePasswordToStoreAction, (state) => ({
    ...state,
    createPasswordToken: '',
  }))
  .handleAction(createPasswordRequestAction, (state) => ({
    ...state,
    isCreatePasswordLoading: true,
  }))
  .handleAction(createPasswordSuccessAction, (state) => ({
    ...state,
    isCreatePasswordLoading: false,
  }))
  .handleAction(createPasswordFailureAction, (state) => ({
    ...state,
    isCreatePasswordLoading: false,
  }))

  .handleAction(isAppSiderCollapsedAction, (state, { payload }) => ({
    ...state,
    isAppSiderCollapsed: payload,
  }))

  .handleAction(changeLoginPopupVisibleAction, (state, { payload }) => ({
    ...state,
    popupsVisible: {
      login: payload,
      register: false,
      forgotPassword: false,
      mailCheck: false,
      createPassword: false,
      sentMessage: false,
    },
  }))
  .handleAction(changeRegisterPopupVisibleAction, (state, { payload }) => ({
    ...state,
    popupsVisible: {
      login: false,
      register: payload,
      forgotPassword: false,
      mailCheck: false,
      createPassword: false,
      sentMessage: false,
    },
  }))
  .handleAction(changeCreatePasswordPopupVisibleAction, (state, { payload }) => ({
    ...state,
    popupsVisible: {
      login: false,
      register: false,
      forgotPassword: false,
      mailCheck: false,
      createPassword: payload,
      sentMessage: false,
    },
  }))
  .handleAction(changeForgotPasswordPopupVisibleAction, (state, { payload }) => ({
    ...state,
    popupsVisible: {
      login: false,
      register: false,
      forgotPassword: payload,
      mailCheck: false,
      createPassword: false,
      sentMessage: false,
    },
  }))
  .handleAction(changeMailCheckPopupVisibleAction, (state, { payload }) => ({
    ...state,
    popupsVisible: {
      login: false,
      register: false,
      forgotPassword: false,
      mailCheck: payload,
      createPassword: false,
      sentMessage: false,
    },
  }))
  .handleAction(changeSentMessagePopupVisibleAction, (state, { payload }) => ({
    ...state,
    popupsVisible: {
      login: false,
      register: false,
      forgotPassword: false,
      mailCheck: false,
      createPassword: false,
      sentMessage: payload,
    },
  }));

function createDefaultRegister() {
  return {
    firstStep: {
      name: '',
      sex: Sex.None,
      email: '',
      birthdayDate: '',
      phoneNumber: '',
      country: '',
      city: '',
      region: '',
    },
    secondStep: {
      specify: '',
      position: '',
      workStudyPlace: '',
      degree: '',
    },
    thirdStep: {
      country: '',
      city: '',
      region: '',
    },
    isPersonalDataApprove: {
      value: false,
      isError: false,
    },
  };
}
