import React from 'react';
import ReactDOM from 'react-dom';

import {
  areaService,
  AuthService,
  eventsService,
  PlaceService,
  homeService,
  AuthorService,
  PublicationService,
} from 'services';

import { App } from './App';
import * as serviceWorker from './serviceWorker';

AuthService.setCredentials({ URL: process.env.REACT_APP_API_URL || '' });
AuthService.prefix = 'auth/';
areaService.setCredentials({ URL: process.env.REACT_APP_API_URL || '' });
areaService.prefix = '';
eventsService.setCredentials({ URL: process.env.REACT_APP_API_URL || '' });
eventsService.prefix = '';
PlaceService.setCredentials({ URL: process.env.REACT_APP_API_URL || '' });
PlaceService.prefix = '';
homeService.setCredentials({ URL: process.env.REACT_APP_API_URL || '' });
homeService.prefix = '';
AuthorService.setCredentials({ URL: process.env.REACT_APP_API_URL || '' });
AuthorService.prefix = '';
PublicationService.setCredentials({ URL: process.env.REACT_APP_API_URL || '' });
PublicationService.prefix = '';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.unregister();
