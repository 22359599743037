import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

import { Tag } from 'components/atoms';
import { IPublication } from 'types';
import { useWindowSize } from 'hooks';
import { View as ViewIcon } from 'assets/icons';

interface IProps extends IPublication {
  onClick: (id: number) => void;
  type?: 'fullWidth' | 'default';
  className?: string;
}

export const PublicationCard = ({
  id,
  onClick,
  imageUrl,
  title,
  authors,
  category: { title: categoryTitle, shortTitle: categoryShortTitle },
  area: { title: areaTitle, shortTitle: areaShortTitle },
  date,
  viewCount,
  type = 'default',
  className,
}: IProps) => {
  const width = useWindowSize()[0];
  const [cardWidth, setCardWidth] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      setCardWidth(ref.current.offsetWidth);
    }
  }, [width]);

  return (
    <Container onClick={() => onClick(id)} $type={type} ref={ref} className={className}>
      <Image url={imageUrl} />

      <Tags>
        <Tag colorType={5} size="small">
          {categoryShortTitle || categoryTitle}
        </Tag>
        <Tag colorType={5} size="small">
          {areaShortTitle || areaTitle}
        </Tag>
      </Tags>

      <Title>{getTitle(cardWidth, title)}</Title>

      <Authors>
        {authors.length > 0 && <AuthorTitle>{authors.length === 1 ? 'Автор' : 'Авторы'}</AuthorTitle>}
        {authors[0] && <Author>{authors[0].fullName}</Author>}
        {authors[1] && <Author>{authors[1].fullName}</Author>}
      </Authors>

      <Info>
        <PublicationDate>{format(new Date(date), 'dd MMMM yyyy', { locale: ru })}</PublicationDate>
        <Views>
          <ViewIcon />
          {viewCount}
        </Views>
      </Info>
    </Container>
  );
};

const Container = styled.div<{ $type: 'fullWidth' | 'default' }>`
  ${({ $type }) => css`
    overflow: hidden;
    width: 100%;
    cursor: pointer;

    @media (min-width: 576px) {
      width: ${$type === 'fullWidth' ? '100%' : '370px'};
    }
  `}
`;

const Tags = styled.div`
  padding: 24px 0px 16px 0px;
`;

const Image = styled.div<{ url: string }>`
  ${({ url }) => css`
    height: 176px;
    background-image: url('${url}');
    background-repeat: no-repeat;
    background-position: 50% 0px;
    background-size: cover;

    @media (min-width: 576px) {
      height: 220px;
    }
  `}
`;

const Title = styled.div`
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  color: #000000;

  height: 56px;

  @media (min-width: 576px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

const Authors = styled.div`
  height: 86px;
`;

const AuthorTitle = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #a7a8ab;

  padding: 8px 0px 4px 2px;

  @media (min-width: 576px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const Author = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #000000;

  @media (min-width: 576px) {
    font-size: 16px;
    line-height: 24px;
    height: 220px;
  }
`;

const Info = styled.div`
  padding: 16px 0 0 0;

  display: flex;
  align-items: center;
`;

const PublicationDate = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #4853ec;
`;

const Views = styled.span`
  display: flex;
  align-items: center;

  cursor: pointer;
  outline: none;

  margin-left: 32px;

  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  color: #4853ec;

  svg {
    margin-right: 8px;
  }

  @media (min-width: 768px) {
    margin-left: 32px;
  }
`;

function getTitle(width: number, title: string) {
  const maxLength = Math.floor(width / 5.2);
  return title.length > maxLength ? `${title.substring(0, maxLength)} ...` : title;
}
