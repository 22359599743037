import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getCookies } from 'helpers';
import { AccessDeniedStub, AccessModal } from 'components/molecules';
import { getVisitorStatus } from 'core/selectors';
import { setVisitorStatusAction } from 'core/actions';

export const AccessInterceptor = () => {
  const isDoctor = useSelector(getVisitorStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    const isDoctorCookie = getCookies('IS_DOCTOR');
    if (isDoctorCookie === 'true') {
      dispatch(setVisitorStatusAction({ isDoctor: true }));
    }
    // it was asked to block access only until user refresh screen,
    // if need to block access for 6 months -> uncomment code below
    // if (isDoctorCookie === 'false') {
    //   dispatch(setVisitorStatusAction({ isDoctor: false }));
    // }
  }, [dispatch]);

  if (isDoctor === undefined) return <AccessModal isOpen={isDoctor === undefined} />;

  return <AccessDeniedStub />;
};
