import { ActionType, createReducer } from 'typesafe-actions';

import {
  putCurrentPublicationsCategoryToStoreAction,
  putPublicationsToStoreAction,
  putPublicationsCategoriesToStoreAction,
  getPublicationsRequestAction,
  getPublicationsSuccessAction,
  getPublicationsFailureAction,
  clearPublicationsToStoreAction,
  putPublicationsPageToStoreAction,
  putPublicationToStoreAction,
  getPublicationRequestAction,
  getPublicationSuccessAction,
  getPublicationFailureAction,
  clearPublicationToStoreAction,
  IPublicationsSearchFormValues,
  setLastPublicationsSearchAction,
  resetLastPublicationsSearchAction,
} from 'core/actions';
import { IPublicationCategory, IPublication } from 'types';

export interface IPublcationsState {
  currentCategoryId?: number;

  categories: IPublicationCategory[];
  categoriesLoading: boolean;
  categoriesError: string;

  publications: IPublication[];
  page: number;
  total: number;
  publicationsLoading: boolean;
  publicationsError: string;

  lastSearch?: Partial<IPublicationsSearchFormValues>;
}

const defaultState: IPublcationsState = {
  categories: [],
  categoriesLoading: false,
  categoriesError: '',

  publications: [],
  total: 0,
  page: 1,
  publicationsLoading: false,
  publicationsError: '',
};

const actions = {
  putCurrentPublicationsCategoryToStoreAction,
  putPublicationsCategoriesToStoreAction,
  putPublicationsToStoreAction,
  putPublicationsPageToStoreAction,
  getPublicationsRequestAction,
  getPublicationsSuccessAction,
  getPublicationsFailureAction,
  clearPublicationsToStoreAction,
  putPublicationToStoreAction,
  getPublicationRequestAction,
  getPublicationSuccessAction,
  getPublicationFailureAction,
  clearPublicationToStoreAction,
  setLastPublicationsSearchAction,
  resetLastPublicationsSearchAction,
};

export const publicationsReducer = createReducer<IPublcationsState, ActionType<typeof actions>>(defaultState)
  .handleAction(putCurrentPublicationsCategoryToStoreAction, (state, { payload: { id } }) => ({
    ...state,
    currentCategoryId: id,
  }))
  .handleAction(putPublicationsCategoriesToStoreAction, (state, { payload: { categories } }) => ({
    ...state,
    categories,
  }))

  .handleAction(putPublicationsToStoreAction, (state, { payload: { publications, total } }) => ({
    ...state,
    publications,
    total,
  }))
  .handleAction(putPublicationsPageToStoreAction, (state, { payload: { page } }) => ({
    ...state,
    page,
  }))

  .handleAction(getPublicationsRequestAction, (state) => ({
    ...state,
    publicationsLoading: true,
    publicationsError: '',
  }))
  .handleAction(getPublicationsSuccessAction, (state) => ({
    ...state,
    publicationsLoading: false,
  }))
  .handleAction(getPublicationsFailureAction, (state) => ({
    ...state,
    publicationsLoading: false,
    publicationsError: '',
  }))

  .handleAction(clearPublicationsToStoreAction, (state) => ({
    ...defaultState,
    lastSearch: state.lastSearch,
  }))

  .handleAction(setLastPublicationsSearchAction, (state, { payload }) => ({
    ...state,
    lastSearch: {
      ...state.lastSearch,
      ...payload,
    },
  }))
  .handleAction(resetLastPublicationsSearchAction, (state) => ({
    ...state,
    lastSearch: undefined,
  }));
