import { createAction } from 'typesafe-actions';

import { IEventListItem, IHome, IHomeEnterestingEvent } from 'types';

import { ACTIONS } from './constants';

export const getHomeRequestAction = createAction(ACTIONS.GET_HOME_REQUEST)();
export const getHomeSuccessAction = createAction(ACTIONS.GET_HOME_SUCCESS)();
export const getHomeFailureAction = createAction(ACTIONS.GET_HOME_FAILURE)();
export const putHomeToStoreAction = createAction(ACTIONS.PUT_HOME_TO_STORE)<{
  home: IHome;
}>();
export const clearHomeToStoreAction = createAction(ACTIONS.CLEAR_HOME_TO_STORE)();

export const getHomeEnterestingEventsRequestAction = createAction(ACTIONS.GET_HOME_ENTERESTING_EVENTS_REQUEST)<{
  limit: number;
  more?: boolean;
}>();
export const getHomeEnterestingEventsSuccessAction = createAction(ACTIONS.GET_HOME_ENTERESTING_EVENTS_SUCCESS)();
export const getHomeEnterestingEventsFailureAction = createAction(ACTIONS.GET_HOME_ENTERESTING_EVENTS_FAILURE)();
export const putHomeEnterestingEventsToStoreAction = createAction(ACTIONS.PUT_HOME_ENTERESTING_EVENTS_TO_STORE)<{
  enterestingEvents: IHomeEnterestingEvent[];
  countEnterestingEvens: number;
}>();

export const getUpcomingEventsRequestAction = createAction(ACTIONS.GET_UPCOMING_EVENTS_REQUEST)();
export const getUpcomingEventsSuccessAction = createAction(ACTIONS.GET_UPCOMING_EVENTS_SUCCESS)();
export const getUpcomingEventsFailureAction = createAction(ACTIONS.GET_UPCOMING_EVENTS_FAILURE)();
export const putUpcomingEventsToStoreAction = createAction(ACTIONS.PUT_UPCOMING_EVENTS_TO_STORE)<{
  upcomingEvents: IEventListItem[];
}>();
