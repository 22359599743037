import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { getArea } from 'core/selectors';

import { EducationalSections } from './EducationalSections';

export const Container = () => {
  const { area } = useParams<{ area?: string }>();
  const { categories } = useSelector(getArea);
  const history = useHistory();

  const itemClickHandler = (url: string) => {
    history.push(url);
  };

  return <EducationalSections categories={categories} area={area} itemClickHandler={itemClickHandler} />;
};
