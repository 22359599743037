import React from 'react';
import ReactInputMask, { InputState } from 'react-input-mask';

import { TextInput, ITextInputProps } from 'atoms';

interface IProps extends ITextInputProps {
  mask: string | (string | RegExp)[];
  allowClear?: boolean;
}

export const MaskedTextInput = ({ mask, onChange, onBlur, onFocus, value, allowClear, ...rest }: IProps) => {
  return (
    <ReactInputMask
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      value={value}
      mask={mask}
      alwaysShowMask={false}
      maskChar={null}
      beforeMaskedValueChange={allowClear ? beforeMaskedValueChange : undefined}
    >
      {() => {
        return <TextInput {...rest} type="primary" />;
      }}
    </ReactInputMask>
  );
};

function beforeMaskedValueChange(newState: InputState, oldState: InputState) {
  const { value } = newState;
  const { value: oldValue } = oldState;
  const { selection } = newState;

  return {
    value: value === '' && oldValue === '' ? '' : value,
    selection,
  };
}
