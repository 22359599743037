import React from 'react';
import styled, { css } from 'styled-components';
import { Col, Form as AntForm, Row } from 'antd';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { Button, FormItem } from 'atoms';
import { useWindowSize } from 'hooks';
import { IAreasAlias } from 'types';
import { store } from 'core';
import { IState } from 'core/types';
import { IPublicationsSearchFormValues, setLastPublicationsSearchAction } from 'core/actions';

import { SearchString, AreaSelect } from './Fields';

interface IProps {
  // eslint-disable-next-line react/no-unused-prop-types
  handleSubmit: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  handleOnChange: () => void;
  loading: boolean;
  area?: string;
  areasAlias: IAreasAlias[];
  color?: string;
}

const Form: React.FC<InjectedFormProps<IPublicationsSearchFormValues, IProps> & IProps> = ({
  loading,
  area,
  areasAlias,
  color,
}) => {
  const width = useWindowSize()[0];

  return (
    <StyledForm colon={false}>
      <Row gutter={[20, 0]}>
        {!area && (
          <Col xs={24} md={5}>
            <Field name="areaSelect" component={AreaSelect} options={areasAlias} />
          </Col>
        )}
        <Col xs={24} md={!area ? 19 : 24}>
          <Row align="bottom">
            <Col flex={1}>
              <Field name="searchString" component={SearchString} />
            </Col>
            <Col xs={width > 576 ? undefined : 24}>
              <FormItem>
                <StyledButton $color={color} htmlType="submit" loading={loading} block>
                  Найти
                </StyledButton>
              </FormItem>
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledForm>
  );
};

const mapStateToProps = (state: IState) => {
  return {
    initialValues: state.publications.lastSearch,
  };
};

export const PublicationsForm = connect(mapStateToProps)(
  reduxForm<IPublicationsSearchFormValues, IProps>({
    form: 'publicationsSearchForm',
    onChange: (values, dispatch, props) => {
      props.handleOnChange();
      store.dispatch(setLastPublicationsSearchAction(values));
    },
    onSubmit: (values: any, dispatch: any, props: { handleSubmit: () => void }) => {
      props.handleSubmit();
    },
  })(Form),
);

const StyledForm = styled(AntForm)`
  padding: 10px 0;

  @media (min-width: 768px) {
    padding: 50px 0;
    flex-direction: row;
  }
`;

const StyledButton = styled(Button)<{ $color?: string }>`
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  ${({ $color }) => {
    if ($color) {
      return css`
        background-color: ${$color} !important;
        border: 1px solid ${$color} !important;
      `;
    }
    return css``;
  }}

  @media (min-width: 576px) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
`;
