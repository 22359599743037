import { createAction } from 'typesafe-actions';

import {
  RegisterSteps,
  IRegisterFormFirstStepValues,
  IRegisterFormSecondStepValues,
  IRegisterFormThirdStepValues,
  IForgotPasswordFormValues,
  ICreatePasswordFormValues,
} from 'types';

import { ACTIONS } from './constants';

export const loginRequstAction = createAction(ACTIONS.LOGIN_REQUEST)<{ code: string; phone: string; email: string }>();
export const loginSuccessAction = createAction(ACTIONS.LOGIN_SUCCESS)();
export const loginFailureAction = createAction(ACTIONS.LOGIN_FAILURE)();

export const logOutAction = createAction(ACTIONS.LOG_OUT)();
export const logOutSuccessAction = createAction(ACTIONS.LOG_OUT_SUCCESS)();
export const logOutFailureAction = createAction(ACTIONS.LOG_OUT_FAILURE)();

export const registerRequestAction = createAction(ACTIONS.REGISTER_REQUEST)();
export const registerSuccessAction = createAction(ACTIONS.REGISTER_SUCCESS)();
export const registerFailureAction = createAction(ACTIONS.REGISTER_FAILURE)();
export const putRegisterStepToStore = createAction(ACTIONS.PUT_REGISTER_STEP_TO_STORE)<RegisterSteps>();
export const putFirstStepRegisterToStore = createAction(
  ACTIONS.PUT_FIRST_STEP_REGISTER_TO_STORE,
)<IRegisterFormFirstStepValues>();
export const putSecondStepRegisterToStore = createAction(
  ACTIONS.PUT_SECOND_STEP_REGISTER_TO_STORE,
)<IRegisterFormSecondStepValues>();
export const putThirdStepRegisterToStore = createAction(
  ACTIONS.PUT_THIRD_STEP_REGISTER_TO_STORE,
)<IRegisterFormThirdStepValues>();
export const putFourthStepRegisterToStore = createAction(
  ACTIONS.PUT_FOURTH_STEP_REGISTER_TO_STORE,
)<IRegisterFormThirdStepValues>();
export const clearRegisterToStoreAction = createAction(ACTIONS.CLEAR_REGISTER_TO_STORE)();

export const forgotPasswordRequestAction = createAction(ACTIONS.FORGOT_PASSWORD_REQUEST)<IForgotPasswordFormValues>();
export const forgotPasswordSuccessAction = createAction(ACTIONS.FORGOT_PASSWORD_SUCCESS)();
export const forgotPasswordFailureAction = createAction(ACTIONS.FORGOT_PASSWORD_FAILURE)();
export const clearForgotPasswordToStoreAction = createAction(ACTIONS.CLEAR_FORGOT_PASSWORD_TO_STORE)();

export const createPasswordRequestAction = createAction(ACTIONS.CREATE_PASSWORD_REQUEST)<ICreatePasswordFormValues>();
export const createPasswordSuccessAction = createAction(ACTIONS.CREATE_PASSWORD_SUCCESS)();
export const createPasswordFailureAction = createAction(ACTIONS.CREATE_PASSWORD_FAILURE)();
export const putCreatePasswordTokenToStoreAction = createAction(ACTIONS.PUT_CREATE_PASSWORD_TOKEN_TO_STORE)<string>();
export const clearCreatePasswordToStoreAction = createAction(ACTIONS.CLEAR_CREATE_PASSWORD_REQUEST)();

export const putAuthServerErrorToStoreAction = createAction(ACTIONS.PUT_AUTH_SERVER_ERROR_TO_STORE)<string>();
export const clearAuthServerErrorToStoreAction = createAction(ACTIONS.CLEAR_AUTH_SERVER_ERROR_TO_STORE)();
export const putCodeErrorToStoreAction = createAction(ACTIONS.PUT_CODE_ERROR_TO_STORE)<string>();

export const changeLoginPopupVisibleAction = createAction(ACTIONS.CHANGE_LOGIN_POPUP_VISIBLE)<boolean>();
export const changeRegisterPopupVisibleAction = createAction(ACTIONS.CHANGE_REGISTER_POPUP_VISIBLE)<boolean>();
export const changeForgotPasswordPopupVisibleAction = createAction(
  ACTIONS.CHANGE_FORGOT_PASSWORD_POPUP_VISIBLE,
)<boolean>();
export const changeCreatePasswordPopupVisibleAction = createAction(
  ACTIONS.CHANGE_CREATE_PASSWORD_POPUP_VISIBLE,
)<boolean>();
export const changeMailCheckPopupVisibleAction = createAction(ACTIONS.CHANGE_MAIL_CHECK_POPUP_VISIBLE)<boolean>();
export const changeSentMessagePopupVisibleAction = createAction(ACTIONS.CHANGE_SENT_MESSAGE_POPUP_VISIBLE)<boolean>();

export const isAppSiderCollapsedAction = createAction(ACTIONS.IS_APP_SIDER_COLLAPSED)<boolean>();

export const putIsPersonalDataApproveValueAction = createAction(ACTIONS.PUT_IS_PERSONAL_DATA_APPROVE_VALUE)<boolean>();
export const putIsPersonalDataApproveErrorAction = createAction(ACTIONS.PUT_IS_PERSONAL_DATA_APPROVE_ERROR)<boolean>();

export const getCodeRequestAction = createAction(ACTIONS.POST_GET_CODE_REQUEST)<string>();
export const getVerificationCodeRequestAction = createAction(ACTIONS.POST_GET_VERIFICATION_CODE_REQUEST)<{
  email: string;
  phone: string;
}>();
export const checkEmailRequestAction = createAction(ACTIONS.CHECK_EMAIL_REQUEST)<string>();
export const putEmailExistsAction = createAction(ACTIONS.PUT_EMAIL_EXISTS)<boolean | null>();

export const activatePhoneRequestAction = createAction(ACTIONS.ACTIVATE_PHONE_REQUEST)<{
  code: string;
  phone: string;
}>();

export const setPhoneAction = createAction(ACTIONS.SET_PHONE)<string>();
