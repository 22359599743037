import { ActionType, createReducer } from 'typesafe-actions';

import {
  putAuthorsToStoreAction,
  getAuthorsRequestAction,
  getAuthorsSuccessAction,
  getAuthorsFailureAction,
  clearAuthorsToStoreAction,
  putAuthorsPageToStoreAction,
  IAuthorSearchFormValues,
  setLastAuthorsSearchAction,
  resetLastAuthorsSearchAction,
} from 'core/actions';
import { IAuthor } from 'types';

export interface IAuthorsState {
  authors: IAuthor[];
  page: number;
  total: number;
  authorsLoading: boolean;
  authorsError: string;
  lastSearch?: Partial<IAuthorSearchFormValues>;
}

const defaultState: IAuthorsState = {
  authors: [],
  total: 0,
  page: 1,
  authorsLoading: false,
  authorsError: '',
};

const actions = {
  putAuthorsToStoreAction,
  putAuthorsPageToStoreAction,
  getAuthorsRequestAction,
  getAuthorsSuccessAction,
  getAuthorsFailureAction,
  clearAuthorsToStoreAction,

  setLastAuthorsSearchAction,
  resetLastAuthorsSearchAction,
};

export const authorsReducer = createReducer<IAuthorsState, ActionType<typeof actions>>(defaultState)
  .handleAction(putAuthorsToStoreAction, (state, { payload: { authors, total } }) => ({
    ...state,
    authors,
    total,
  }))
  .handleAction(putAuthorsPageToStoreAction, (state, { payload: { page } }) => ({
    ...state,
    page,
  }))
  .handleAction(getAuthorsRequestAction, (state) => ({
    ...state,
    authorsLoading: true,
    authorsError: '',
  }))
  .handleAction(getAuthorsSuccessAction, (state) => ({
    ...state,
    authorsLoading: false,
  }))
  .handleAction(getAuthorsFailureAction, (state) => ({
    ...state,
    authorsLoading: false,
    authorsError: '',
  }))
  .handleAction(clearAuthorsToStoreAction, (state) => ({
    ...defaultState,
    lastSearch: state.lastSearch,
  }))

  .handleAction(setLastAuthorsSearchAction, (state, { payload }) => ({
    ...state,
    lastSearch: {
      ...state.lastSearch,
      ...payload,
    },
  }))
  .handleAction(resetLastAuthorsSearchAction, (state) => ({
    ...state,
    lastSearch: undefined,
  }));
