import { AcademicDegree, IApiUser, IUser, IUserData } from 'types';

export const convertUserToAppSchema = (user: IApiUser): IUser => {
  return {
    email: user.email,
    fullName: user.full_name,
  };
};

export const convertUserDataToAppSchema = (userData: IApiUser): IUserData => {
  return {
    id: userData.id,
    name: userData.full_name,
    sex: userData.gender,
    email: userData.email,
    birthdayDate: userData.date_of_birth.split('-').reverse().join('.'),
    phoneNumber: userData.phone_number,
    country: userData.country,
    city: userData.city,
    region: userData.region,
    specify: userData.specialty,
    position: userData.position,
    workStudyPlace: userData.working_place,
    degree: userData.academic_degree,
  };
};

export const convertAppUserDataToDto = (userData: IUserData): IApiUser => {
  return {
    id: userData.id,
    email: userData.email,
    full_name: userData.name,
    country: userData.country,
    region: userData.region,
    city: userData.city,
    specialty: userData.specify,
    additional_specialty: '',
    phone_number: userData.phoneNumber,
    gender: userData.sex,
    date_of_birth: userData.birthdayDate.split('.').reverse().join('-'),
    working_place: userData.workStudyPlace,
    position: userData.position,
    academic_degree: userData.degree as AcademicDegree,
  };
};
