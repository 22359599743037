import qs from 'querystring';

import { format } from 'date-fns';

import {
  IEventsRequest,
  IApiEventsRequest,
  IApiTag,
  IApiEventListItem,
  IApiEventCategory,
  IApiEventCategoryRequest,
  IApiPartnerExtended,
  IEventMarkDto,
  IApiSurveys,
  IApiSurveysRequest,
  ISurveysRequest,
  ISurveyAnswersDto,
  ISurveyCustomAnswerDto,
} from 'types';

import { BaseService } from './BaseService';
import { convertOrderingForRequest } from './apiMappers';

class EventsAPIService extends BaseService {
  public defaultEventsLimit = 10;

  public async getAreaEvents(monthIndex: number = 0, alias?: string) {
    const route = alias
      ? `events?month=${monthIndex}&ordering=start_date&limit=10&area__alias=${alias}`
      : `events?month=${monthIndex}&ordering=start_date&limit=10`;
    return this.get(route);
  }

  public async getEvent(eventId: number) {
    const route = `events/${eventId}`;
    return this.get(route);
  }

  public async getEvents({
    alias,
    dateStart,
    dateEnd,
    tags,
    searchString,
    searchASC,
    category,
    offset = 1,
    limit = this.defaultEventsLimit,
    authorId,
    upcomingEvents,
    userEvents,
  }: IEventsRequest) {
    const start_date_after = dateStart ? format(dateStart, 'yyyy-MM-dd') : undefined;
    const start_date_before = dateEnd ? format(dateEnd, 'yyyy-MM-dd') : undefined;
    const tag__in = tags && tags.length > 0 ? tags.join(',') : undefined;
    const title = searchString ?? undefined;
    const ordering = convertOrderingForRequest(searchASC);

    const body: IApiEventsRequest = { ordering, offset, limit };
    if (start_date_after) body.start_date_after = start_date_after;
    if (start_date_before) body.start_date_before = start_date_before;
    if (tag__in) body.tag__in = tag__in;
    if (title) body.title = title;
    if (category) body.category__in = category;
    if (alias) body.area__alias = alias;
    if (authorId) body.author__in = authorId;
    if (upcomingEvents) body.upcoming_events = true;
    if (userEvents) body.user_events = true;

    const { data } = await this.get<{ results: IApiEventListItem[]; total: number }>(
      `events?${qs.stringify({ ...body })}`,
    );

    return data;
  }

  public async subscribe(eventId: string) {
    const body = { event_id: eventId };
    return this.post(`events/${eventId}/subscription`, JSON.stringify(body), { 'Content-Type': 'application/json' });
  }

  public async unsubscribe(eventId: string) {
    return this.remove(`events/${eventId}/subscription`);
  }

  public async getTags() {
    const { data } = await this.get<IApiTag[]>(`tags`);
    return data;
  }

  public async getCategories(alias?: string) {
    const body: IApiEventCategoryRequest = {};
    if (alias) body.area__alias = alias;

    const { data } = await this.get<IApiEventCategory[]>(`categories?${qs.stringify({ ...body })}`);
    return data;
  }

  public async eventViewings(eventId: number) {
    const { data } = await this.post(`viewings`, { event_id: eventId });
    return data;
  }

  public async eventRegister(eventId: number) {
    const { data } = await this.post(`events/${eventId}/registration`, { event: eventId });
    return data;
  }

  public async confirmActivity(id: number) {
    const { data } = await this.post(`checkpoints/${id}/approve`, { checkpoint: id });
    return data;
  }

  public async getActivityStatus(id: number) {
    const { data } = await this.get<{ is_approved: boolean }>(`checkpoints/${id}/verify`);
    return data;
  }

  public getActivityControlUrl(eventId: number) {
    return `${this._credentials?.URL}notify-events/${eventId}/checkpoint`;
  }

  public async verifyEventSurvey(id: number) {
    await this.post(`checkpoints/${id}/servey-approve`, { servey_id: id });
  }

  public async getEventSurveyPassingStatus(id: number) {
    const { data } = await this.get<{ is_approved: boolean }>(`checkpoints/${id}/servey-verify`);
    return data;
  }

  public async setEventViewTime(eventId: number) {
    const { data } = await this.post(`events/${eventId}/viewed-chunks`, { event: eventId });
    return data;
  }

  public async getEventPartner(partnerId: number) {
    const { data } = await this.get<IApiPartnerExtended>(`partners/${partnerId}`);
    return data;
  }

  public async getEventCertificate(eventId: number) {
    const customHeaders = { 'Content-Type': 'application/pdf' };

    const data = await this.getFile(`events/${eventId}/get-certificate`, 'blob', customHeaders)
      .then((res) => {
        const blob = res.data as Blob;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `Certificate_event_${eventId}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });

    return data;
  }

  public async setEventRating(dto: IEventMarkDto) {
    const { data } = await this.post(`events/create-mark`, {
      event_id: dto.eventId,
      mark: dto.mark,
      ...(dto.authorId && { author_id: dto.authorId }),
    });

    return data;
  }

  public async getQuestions({ eventId, isEvent, surveyIds, page }: ISurveysRequest) {
    const body: IApiSurveysRequest = {};
    if (eventId) body.event_id = eventId;
    if (isEvent) body.no_event = true;
    if (surveyIds) body.id__in = surveyIds;
    if (page) body.page = page;

    const { data } = await this.get<IApiSurveys>(`events/all-questions?${qs.stringify({ ...body })}`);

    return data;
  }

  public async setSurveyAnswers(surveyId: number, dto: ISurveyAnswersDto) {
    const { data } = await this.post(`events/validate-answers/${surveyId}/`, dto.surveyAnswers);

    return data;
  }

  public async setCustomSurveyAnswer(dto: ISurveyCustomAnswerDto) {
    const { data } = await this.post('events/additional-question/set_answer/', dto);

    return data;
  }
}

export const eventsService = new EventsAPIService();
