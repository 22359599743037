import React from 'react';
import styled from 'styled-components';
import { Layout, Row, Col } from 'antd';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from 'atoms';
import { TelegramLogo, YoutubeLogo } from 'assets/icons';
import { changeLoginPopupVisibleAction, changeRegisterPopupVisibleAction } from 'core/actions';
import { getIsLoggedIn } from 'core/selectors';

const { Footer } = Layout;

export const AppFooter = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const { area } = useParams<{ area?: string }>();
  const {
    location: { pathname },
    push,
  } = useHistory();

  const toLoginHandler = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    dispatch(changeLoginPopupVisibleAction(true));
  };

  const toRegistryHandler = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    dispatch(changeRegisterPopupVisibleAction(true));
  };

  const toPlatformHandler = () => {
    if (pathname === '/') {
      window.scrollTo({ behavior: 'smooth', top: 0 });
    }
  };

  const toAreaHandler = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();

    if (pathname === '/') {
      push({ pathname: '/', state: { scrollToArea: true } });
      return;
    }

    push({ pathname: '/' });
  };

  return (
    <FooterContainer>
      <Container>
        <Row>
          <Col xs={24} md={16} xl={10}>
            <InfoSection>
              <Paragraph>
                eMedIQ® и любые его производные являются зарегистрированным товарным знаком и охраняются
                законодательством РФ интеллектуальной собственности, авторском и смежных правах.
              </Paragraph>
            </InfoSection>

            <InfoSection>
              <Subtitle>Наши партнеры:</Subtitle>
              <ParagraphLink href="https://transpl.ru" target="_blank">
                Общероссийская ОО &quot;Российское трансплантологическое общество&quot;
              </ParagraphLink>
              <ParagraphLink href="https://pulmodeti.ru" target="_blank">
                Общероссийская ОО &quot;Педиатрическое респираторное общество&quot;
              </ParagraphLink>
              <ParagraphLink href="https://antimicrob.net" target="_blank">
                МОО &quot;Алянс клинических химиотерапевтов и микробиологов&quot;
              </ParagraphLink>
            </InfoSection>
          </Col>
          <Col xs={24} md={8} xl={14}>
            <Row>
              <Col xs={0} md={0} xl={8}>
                <Menu>
                  <MenuTitle>Быстрый доступ</MenuTitle>
                  <NavItem onClick={toPlatformHandler} to="/">
                    О платформе
                  </NavItem>
                  <NavItem onClick={toAreaHandler} to="/">
                    Направления
                  </NavItem>
                  <NavItem to={area ? `/area/${area}/catalog/all` : `/catalog/all`}>Каталог мероприятий</NavItem>
                  <NavItem to={area ? `/area/${area}/publications/all` : `/publications/all`}>Библиотека</NavItem>
                  <NavItem to={area ? `/area/${area}/authors` : `/authors`}>Спикеры</NavItem>
                </Menu>
              </Col>
              <Col xs={0} md={0} xl={8}>
                <Menu>
                  <MenuTitle>Личный Кабинет</MenuTitle>
                  {!isLoggedIn && (
                    <>
                      <NavItem onClick={toLoginHandler} to="/">
                        Вход
                      </NavItem>
                      <NavItem onClick={toRegistryHandler} to="/">
                        Регистрация
                      </NavItem>
                    </>
                  )}
                </Menu>
              </Col>
              <Col md={24} xl={8}>
                <Menu>
                  <MenuTitle>Связаться с нами</MenuTitle>
                  <ContactLink href="mailto:info@emediq.ru">info@emediq.ru</ContactLink>
                  <ContactLink href="mailto:legal@emediq.ru">legal@emediq.ru</ContactLink>
                  <SocialLinks>
                    <a title="Telegram" href="https://t.me/+UhE_0n7qTT8wOGZi" target="_blank" rel="noreferrer">
                      <TelegramLogo />
                    </a>
                    <a title="Youtube" href="http://youtube.com/eMediQ" target="_blank" rel="noreferrer">
                      <YoutubeLogo />
                    </a>
                  </SocialLinks>
                </Menu>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </FooterContainer>
  );
};

const FooterContainer = styled(Footer)`
  margin: 0;
  padding: 40px 0 0 0;
  background: #f8f8f8;
`;

const Paragraph = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #7c797a;
  margin: 0 0 8px 0;
  max-width: 400px;
`;

const ParagraphLink = styled.a`
  display: block;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #7c797a;
  margin: 0 0 8px 0;
  max-width: 400px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: #6972ec;
  }
`;

const Subtitle = styled.p`
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #7c797a;
  margin: 0 0 8px 0;
`;

const InfoSection = styled.div`
  margin: 0 0 24px 0;
`;

const Menu = styled.div`
  a {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    color: #7c797a;
    display: block;
  }
`;

const NavItem = styled(NavLink)`
  &:hover {
    color: #6972ec;
  }
`;

const MenuTitle = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
  margin: 0 0 8px 0;
`;

const ContactLink = styled.a`
  && {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #4853ec;
  }
`;

const SocialLinks = styled.div`
  margin-top: 12px;

  a {
    display: inline-block;
    margin: 0 12px 0 0;

    &:last-child {
      margin: 0;
    }
  }
`;
