import { SagaIterator } from 'redux-saga';
import { takeEvery, call, put, select } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import {
  convertAllAreasToAppSchema,
  convertAreaToAppSchema,
  convertAreasCroppedToAppSchema,
} from 'services/apiMappers';
import { areaService } from 'services';
import {
  getAreaRequestAction,
  getAreaSuccessAction,
  getAreaFailureAction,
  putAreaToStoreAction,
  getMoreAreasCroppedRequestAction,
  getAreasCroppedFailureAction,
  getAreasCroppedSuccessAction,
  putAreasCroppedToStoreAction,
  getAreasCroppedRequestAction,
  getAllAreasRequestAction,
  getAllAreasSuccessAction,
  putAllAreasToStoreAction,
  getAllAreasFailureAction,
} from 'core/actions';
import { getAreasCropped } from 'core/selectors';

export function* getAreasCroppedSaga(): SagaIterator {
  try {
    const {
      data: { results, total },
    } = yield call(() => areaService.getAreasCropped());
    const areasCropped = convertAreasCroppedToAppSchema(results);

    yield put(getAreasCroppedSuccessAction());
    yield put(putAreasCroppedToStoreAction({ areas: areasCropped, total }));
  } catch (error) {
    yield put(getAreasCroppedFailureAction());
  }
}

export function* getAllAreasSaga(): SagaIterator {
  try {
    const {
      data: { results },
    } = yield call(() => areaService.getAllAreas());
    const allAreas = convertAllAreasToAppSchema(results);

    yield put(getAllAreasSuccessAction());
    yield put(putAllAreasToStoreAction({ areas: allAreas }));
  } catch (error) {
    yield put(getAllAreasFailureAction());
  }
}

export function* getMoreAreasCroppedSaga(): SagaIterator {
  try {
    const { areas: oldAreasCropped } = yield select(getAreasCropped);
    const {
      data: { results, total },
    } = yield call(() =>
      areaService.getAreasCropped(Math.ceil(oldAreasCropped.length / areaService.defaultAreasLimit) + 1),
    );

    const newAreasCropped = convertAreasCroppedToAppSchema(results);
    yield put(getAreasCroppedSuccessAction());
    yield put(putAreasCroppedToStoreAction({ areas: [...oldAreasCropped, ...newAreasCropped], total }));
  } catch (error) {
    yield put(getAreasCroppedFailureAction());
  }
}

export function* getAreaSaga({ payload: { alias } }: ActionType<typeof getAreaRequestAction>): SagaIterator {
  try {
    const { data } = yield call(() => areaService.getArea(alias));
    const area = convertAreaToAppSchema(data);

    yield put(getAreaSuccessAction());
    yield put(putAreaToStoreAction({ area }));
  } catch (error) {
    yield put(getAreaFailureAction());
  }
}

export function* areaRootSaga(): SagaIterator {
  yield takeEvery(getAllAreasRequestAction, getAllAreasSaga);
  yield takeEvery(getAreasCroppedRequestAction, getAreasCroppedSaga);
  yield takeEvery(getMoreAreasCroppedRequestAction, getMoreAreasCroppedSaga);
  yield takeEvery(getAreaRequestAction, getAreaSaga);
}
