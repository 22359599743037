import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactYoutube from 'react-youtube';

import { setViewedMinuteRequestAction } from 'core/actions';
import { PlayerState } from 'types';

interface IProps {
  videoUrl: string;
  eventId: number;
}

interface YoutubePlayer {
  pauseVideo: () => void;
}

export const Youtube = ({ videoUrl, eventId }: IProps) => {
  const dispatch = useDispatch();

  const [viewedMinutes, setViewedMinutes] = useState(0);
  const [viewedSeconds, setViewedSeconds] = useState(0);

  const [player, setPlayer] = useState<null | YoutubePlayer>();
  const [playerState, setPlayerState] = useState<PlayerState>(-1);
  const [playbackRate, setPlaybackRate] = useState<number>(1);

  const onReadyChange = useCallback(({ target }: { target: YoutubePlayer }) => {
    setPlayer(target);
  }, []);

  const onStateChange = useCallback(({ data }: { data: PlayerState }) => {
    setPlayerState(data);
  }, []);

  const onPlaybackRateChange = useCallback(({ data }: { data: number }) => {
    setPlaybackRate(data);
  }, []);

  useEffect(() => {
    if (playerState === PlayerState.PLAYING) {
      const interval = setInterval(() => {
        setViewedSeconds(viewedSeconds + 1);
      }, 1000 / playbackRate);

      return () => {
        clearInterval(interval);
      };
    }

    return undefined;
  }, [viewedSeconds, playerState, playbackRate]);

  useEffect(() => {
    setViewedMinutes(Math.floor(viewedSeconds / 60));
  }, [viewedSeconds]);

  useEffect(() => {
    if (viewedMinutes > 0) {
      dispatch(setViewedMinuteRequestAction(eventId));
    }
  }, [dispatch, viewedMinutes, eventId]);

  useEffect(() => {
    if (player) {
      const visibilitychange = () => {
        if (document.hidden) {
          player.pauseVideo();
        }
      };

      document.addEventListener('visibilitychange', visibilitychange);
      return () => document.removeEventListener('visibilitychange', visibilitychange);
    }

    return undefined;
  }, [player]);

  return (
    <ReactYoutube
      videoId={getVideoId(videoUrl)}
      onReady={onReadyChange}
      onStateChange={onStateChange}
      onPlaybackRateChange={onPlaybackRateChange}
    />
  );
};

const getVideoId = (url: string) => {
  const youtube_regex = /^.*(youtu\.be\/|vi?\/|u\/\w\/|embed\/|\?vi?=|&vi?=)([^#&?]*).*/;

  const parsed = url.match(youtube_regex);

  if (parsed && parsed[2]) {
    return parsed[2];
  }

  return '';
};
