import { createAction } from 'typesafe-actions';

import { IEventListItem, IUserData } from 'types';

import { ACTIONS } from './constants';

export const getUserEventsRequestAction = createAction(ACTIONS.GET_USER_EVENTS_REQUEST)<{ offset: number }>();
export const getUserEventsSuccessAction = createAction(ACTIONS.GET_USER_EVENTS_SUCCESS)();
export const getUserEventsFailureAction = createAction(ACTIONS.GET_USER_EVENTS_FAILURE)();
export const putUserEventsToStoreAction = createAction(ACTIONS.PUT_USER_EVENTS_TO_STORE)<{
  events: IEventListItem[];
  total: number;
}>();

export const getUserInfoRequestAction = createAction(ACTIONS.GET_USER_INFO_REQUEST)();
export const getUserInfoSuccessAction = createAction(ACTIONS.GET_USER_INFO_SUCCESS)();
export const getUserInfoFailureAction = createAction(ACTIONS.GET_USER_INFO_FAILURE)();
export const putPersonalUserDataToStore = createAction(ACTIONS.PUT_PERSONAL_USER_DATA_TO_STORE)<IUserData>();

export const saveUserInfoRequestAction = createAction(ACTIONS.SAVE_USERDATA_REQUEST)();
export const saveUserInfoSuccessAction = createAction(ACTIONS.SAVE_USERDATA_SUCCESS)();
export const saveUserInfoFailureAction = createAction(ACTIONS.SAVE_USERDATA_FAILURE)();
export const resetUserInfoUpdateStatusAction = createAction(ACTIONS.RESET_USERDATA_UPDATE_STATUS)();

export const getEventCertificateRequestAction = createAction(ACTIONS.GET_EVENT_CERTIFICATE_REQUEST)<number>();
export const getEventCertificateSuccessAction = createAction(ACTIONS.GET_EVENT_CERTIFICATE_SUCCESS)();
export const getEventCertificateFailureAction = createAction(ACTIONS.GET_EVENT_CERTIFICATE_FAILURE)();
