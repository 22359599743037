import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAuthors, getAuthorsPage } from 'core/selectors';
import { AuthorService } from 'services';
import { putAuthorsPageToStoreAction } from 'core/actions';

import { Paginator } from './Paginator';

export const Container = () => {
  const dispatch = useDispatch();
  const { total } = useSelector(getAuthors);
  const page = useSelector(getAuthorsPage);

  const setActivePage = (newPage: number) => {
    dispatch(putAuthorsPageToStoreAction({ page: newPage }));
  };

  return (
    <Paginator
      pagesCount={Math.ceil(total / AuthorService.defaultAuthorsLimit)}
      activePage={page}
      setActivePage={setActivePage}
    />
  );
};
