import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { Spin } from 'antd';

import { RegisterSelect, IOption } from 'atoms';
import { debounce } from 'helpers';
import { ICountriesApi, ICitiesApi } from 'types';

interface IProps<T> extends WrappedFieldProps {
  caption: string;
  fetchOptions: (...args: any) => Promise<T[]>;
  fetchArgument?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
}

type FieldData = ICountriesApi | ICitiesApi;

export const AsyncSelectField = <T extends FieldData>({
  input: { value, onChange, onFocus },
  meta: { touched, error: errorForm },
  required,
  caption,
  disabled,
  className,
  fetchOptions,
  fetchArgument,
}: IProps<T>) => {
  const [error, setError] = useState(touched && errorForm ? errorForm : '');
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<IOption[]>([]);
  const fetchRef = useRef(0);

  const searchOptions = useMemo(() => {
    const loadOptions = (valueToSeatch: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setIsLoading(true);
      setOptions([]);

      fetchOptions(valueToSeatch, fetchArgument)
        .then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return;
          }
          setOptions(transformToOptions(newOptions));
        })
        .catch(() => {
          setError('Ошибка получения данных');
        })
        .finally(() => setIsLoading(false));
    };

    return debounce(loadOptions, 200);
  }, [fetchOptions, fetchArgument]);

  const onFocusHandler = useCallback(
    (event: React.FocusEvent<any>) => {
      setOptions([]);
      searchOptions('');
      onFocus(event);
    },
    [searchOptions, onFocus],
  );

  const onBlurHandler = useCallback((event: React.FocusEvent<HTMLElement>) => {
    event.preventDefault();
    setOptions([]);
  }, []);

  useEffect(() => {
    if (touched && errorForm) {
      setError(errorForm);
    }
  }, [touched, errorForm]);

  return (
    <RegisterSelect
      value={value}
      optionItems={options}
      caption={caption}
      required={required}
      errorMsg={error}
      disabled={disabled}
      filterOption={false}
      notFoundContent={isLoading ? <Spin size="small" /> : null}
      showSearch
      onSearch={searchOptions}
      onChange={onChange}
      onBlur={onBlurHandler}
      onFocus={onFocusHandler}
      className={className}
    />
  );
};

function transformToOptions<T extends FieldData>(optionsApi: T[]): IOption[] {
  return optionsApi.map((optionApi) => ({ id: optionApi.id, value: optionApi.name, label: optionApi.name }));
}
