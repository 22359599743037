import React, { CSSProperties } from 'react';
import { Select as AntdSelect } from 'antd';
import styled from 'styled-components';

import { Select, ISelect } from 'atoms';
import { IAreasAlias } from 'types';

interface IProps extends ISelect {
  areas: IAreasAlias[];
}

export const AreasSelect = ({ areas, onChange, value }: IProps) => {
  return (
    <SelectRestyled arrowColor="#4853EC" value={value?.title} onChange={onChange} defaultValue={value || ''}>
      <AntdSelect.Option key="all" style={SelectOptionStyles} value="">
        Все направления
      </AntdSelect.Option>
      {areas.map((item) => (
        <AntdSelect.Option key={item.areasAlias} style={SelectOptionStyles} value={item.areasAlias}>
          {item.shortTitle || item.title}
        </AntdSelect.Option>
      ))}
    </SelectRestyled>
  );
};

const SelectRestyled = styled(Select)`
  && {
    width: 100%;
    height: 56px;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #eeeeee;
    display: flex;
    align-items: center;

    .ant-select-selection-item {
      line-height: 33px;
      font-family: 'Raleway', sans-serif;
      font-size: 16px;
      font-weight: 500;
    }
  }
`;

const SelectOptionStyles: CSSProperties = {
  borderBottom: '1px solid #EEEEEE',
  fontFamily: 'Raleway, sans-serif',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24px',
  padding: '10px 16px',
};
