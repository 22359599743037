import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import { RightOutlined } from '@ant-design/icons';

import { Section } from 'atoms';
import { IEventCategory } from 'types';

interface IProps {
  categories?: IEventCategory[];
  area?: string;
  itemClickHandler(url: string): void;
}

export const EducationalSections = ({ categories = [], area, itemClickHandler }: IProps) => (
  <SectionContainer>
    <Section title="Образовательные разделы">
      <Row gutter={[8, 8]}>
        {categories.map(({ description, title, alias, eventsCount, shortTitle }) => (
          <ColContainer xs={24} md={8} xl={4} key={title}>
            <ItemContainer>
              <Title>{shortTitle || title}</Title>
              <Content>{description.length > 200 ? `${description.substr(0, 200)} ...` : description}</Content>
              <Footer className="footer">Более {eventsCount} в разделе</Footer>
              <FooterLink
                className="footer-link"
                onClick={() => itemClickHandler(area ? `/area/${area}/catalog/${alias}` : `/catalog/${alias}`)}
              >
                Перейти в раздел <RightOutlined />
              </FooterLink>
            </ItemContainer>
          </ColContainer>
        ))}
      </Row>
    </Section>
  </SectionContainer>
);

const SectionContainer = styled.div`
  background: #ffffff;
`;

const ColContainer = styled(Col)`
  @media (min-width: 1200px) {
    flex: 0 0 20%;
    max-width: 20%;
  }
`;

const ItemContainer = styled.div`
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 12px;
  padding: 31px 22px;
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 4px 10px rgba(72, 83, 236, 0.08);

    .footer {
      display: none;
    }
    .footer-link {
      display: block;
    }
  }
`;

const Title = styled.div`
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 32px;
  color: #000000;
  margin: 0 0 10px 0;
`;

const Content = styled.div`
  height: 170px;

  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  margin: 0 0 23px 0;

  @media (min-width: 768px) {
    height: 290px;
  }
`;

const Footer = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #9da0aa;
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`;

const FooterLink = styled.span`
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #4853ec;

  @media (min-width: 768px) {
    display: none;
  }
`;
