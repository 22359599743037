import React from 'react';
import styled, { css } from 'styled-components';
import { Col, Row } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { getMonth, getDate, format, subMonths, addMonths } from 'date-fns';
import ru from 'date-fns/locale/ru';

import { Button, Container, Dropdown, Tag, InterestingTag } from 'atoms';
import { IEventListItem } from 'types';
import { useWindowSize } from 'hooks';
import { capitalize, cropEventTitle } from 'helpers';

const getMonthsList = () => {
  /*
   * Current month - 0
   * The first month in list = -7 months back from current
   * The last month in list = +4 months from current
   * */

  const monthsList = [];

  const currentDate = new Date();
  currentDate.setDate(1);

  const firstMonth = subMonths(currentDate, 7);

  for (let i = 0; i < 12; i += 1) {
    const monthIndex = i - 7;

    const date = addMonths(firstMonth, i);

    monthsList.push({
      number: getMonth(date) + 1,
      apiIndex: monthIndex,
      name: capitalize(format(date, 'LLLL', { locale: ru })),
    });
  }

  return monthsList;
};

interface IProps {
  events?: IEventListItem[];
  currentMonthIndex?: number;
  selectFilter?: React.ReactNode;
  monthChangeHandler?(apiIndex: number): void;
  itemClickHandler(url: string): void;
  seeAllBtnHandler(): void;
  color?: string;
}

export const Calendar = ({
  events = [],
  currentMonthIndex = 0,
  monthChangeHandler,
  itemClickHandler,
  seeAllBtnHandler,
  selectFilter,
  color,
}: IProps) => {
  const [width] = useWindowSize();
  const uniqueDates = new Set();

  events?.forEach((item) => {
    uniqueDates.add(getDate(new Date(item.startDate)));
  });

  const uniqueDatesArr = Array.from(uniqueDates);

  const sortedEvents = uniqueDatesArr.map((date) => {
    return events?.filter((event) => getDate(new Date(event.startDate)) === date);
  });

  const MonthsSelectorMobileTitle = () => {
    const monthsList = getMonthsList();

    const selectedMonth = monthsList.find((element) => {
      return element.apiIndex === currentMonthIndex;
    });

    return (
      <>
        <div>{selectedMonth?.name}</div>
        <DownOutlined />
      </>
    );
  };

  const MonthsSelectorMobileitems = getMonthsList().map(({ apiIndex, name }) => ({
    value: String(apiIndex),
    caption: name,
  }));

  return (
    <SectionContainer>
      <Section>
        <Container>
          <ContainerHeader>
            <SectionTitle>Календарь мероприятий</SectionTitle>
            {selectFilter && <AliasSelectorDesktop>{selectFilter}</AliasSelectorDesktop>}
          </ContainerHeader>
          <SectionTitleMobile>
            <Caption>Календарь</Caption>
            <Selectors>
              {selectFilter && <AliasSelectorMobile>{selectFilter}</AliasSelectorMobile>}
              <MonthsSelectorMobile $color={color}>
                <Dropdown
                  title={MonthsSelectorMobileTitle()}
                  options={MonthsSelectorMobileitems}
                  onClick={(value) => monthChangeHandler?.(Number(value))}
                />
              </MonthsSelectorMobile>
            </Selectors>
          </SectionTitleMobile>

          <MonthsSelector>
            {getMonthsList().map(({ name, number, apiIndex }) => {
              const isActive = currentMonthIndex === apiIndex ? 'active' : '';

              return (
                <MonthsSelectorItem
                  className={isActive}
                  $color={color}
                  key={number}
                  onClick={() => monthChangeHandler?.(apiIndex)}
                >
                  {name}
                </MonthsSelectorItem>
              );
            })}
          </MonthsSelector>

          {sortedEvents.map((day) => (
            <DayContainer key={day[0].startDate}>
              <DayTitle>{format(new Date(day[0].startDate), 'd MMMM', { locale: ru })}</DayTitle>

              {day?.map((event) => (
                <EventContainer onClick={() => itemClickHandler(`/event/${event.id}`)} key={event.id}>
                  <Row>
                    <Col xs={24} md={10} xl={6}>
                      <EventImage url={event.thumbnailUrl}>
                        <ImageTagsContainer>
                          {event.tags.map(({ name, color: tagColor }) => (
                            <Tag key={name} bgColor={color || tagColor} size="small" colorType={7}>
                              {name}
                            </Tag>
                          ))}
                        </ImageTagsContainer>
                      </EventImage>
                    </Col>
                    <Col xs={24} md={14} xl={18}>
                      <Row>
                        <Col xs={24} md={24} xl={16}>
                          <CenterContainer>
                            <TagsContainer>
                              {event.isInteresting && <InterestingTag size="small" />}
                              <Tag size="small" colorType={4}>
                                {event.category.shortTitle || event.category.title}
                              </Tag>
                              <Tag size="small" colorType={4}>
                                {event.area.shortTitle || event.area.title}
                              </Tag>
                            </TagsContainer>
                            <Title>{cropEventTitle(width, event.title)}</Title>
                          </CenterContainer>
                        </Col>
                        <Col xs={24} md={24} xl={8}>
                          <TeacherContainer>
                            {event.authors[0] && (
                              <>
                                <Post>{event.authors[0].position}</Post>
                                <Teacher>{event.authors[0].fullName}</Teacher>
                              </>
                            )}
                            {event.authors[1] && (
                              <>
                                <Post>{event.authors[1].position}</Post>
                                <Teacher>{event.authors[1].fullName}</Teacher>
                              </>
                            )}
                          </TeacherContainer>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </EventContainer>
              ))}
            </DayContainer>
          ))}

          <SeeAllButton onClick={seeAllBtnHandler} type="ghost">
            Посмотреть все
          </SeeAllButton>
        </Container>
      </Section>
    </SectionContainer>
  );
};

const SectionContainer = styled.div`
  background: #f8f8f8;
`;

const Section = styled.div`
  width: 100%;
  padding: 48px 0 64px;
  margin: auto;
`;

const ContainerHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const SectionTitle = styled.div`
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  margin: 0 0 32px 0;
  display: none;

  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 40px;
    margin: 0 0 48px 0;
  }

  @media (min-width: 1200px) {
    font-size: 48px;
    line-height: 56px;
    margin: 0 0 70px 0;
    display: block;
  }
`;

const SectionTitleMobile = styled.div`
  margin: 0 0 32px 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  @media (min-width: 1200px) {
    display: none;
  }
`;

const Selectors = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 768px) {
    margin-top: 0px;
  }
`;

const AliasSelectorDesktop = styled.div`
  display: none;
  @media (min-width: 1200px) {
    display: block;
    width: 222px;
    height: 48px;
  }
`;

const AliasSelectorMobile = styled.div`
  width: 180px;
  margin-right: 8px;
  display: block;
  @media (min-width: 1200px) {
    display: none;
  }
`;

const Caption = styled.div`
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  flex: 0 1 auto;
`;

const MonthsSelectorMobile = styled.div<{ $color?: string }>`
  flex: 0 1 auto;

  .ant-dropdown-link {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #ffffff;
    background: ${({ $color }) => $color || '#4853ec'};
    border-radius: 8px;
    padding: 5px 12px;
    cursor: pointer;

    div {
      line-height: 16px;
      padding: 0 10px 0 0;
      display: inline-block;
    }
  }
`;

const MonthsSelector = styled.div`
  border-bottom: 1px solid #d2d7de;
  justify-content: space-between;
  margin: 0 0 72px 0;
  display: none;

  @media (min-width: 1200px) {
    display: flex;
  }
`;

const MonthsSelectorItem = styled.div<{ $color?: string }>`
  flex: 1 1 auto;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: rgba(79, 79, 79, 0.6);
  padding: 8px 0;
  margin: 0 0 -2px 0;
  border-bottom: 3px solid transparent;
  cursor: pointer;

  &.active {
    border-color: ${({ $color }) => $color || '#4853ec'};
  }
`;

const DayContainer = styled.div`
  border-top: 1px solid #d2d7de;
  position: relative;
  padding: 56px 0 0 0;
  margin: 0 0 72px 0;
`;

const DayTitle = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  color: #4853ec;
  background: #f8f8f8;
  position: absolute;
  top: -18px;
  left: 0;
  padding: 0 30px 0 0;
`;

const EventContainer = styled.div`
  cursor: pointer;
  display: block;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 12px;
  overflow: hidden;
  margin: 0 0 8px 0;
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 4px 10px rgba(72, 83, 236, 0.08);
  }
`;

const EventImage = styled.div<{ url: string }>`
  ${({ url }) => css`
    height: 100%;
    min-height: 190px;

    background-image: url('${url}');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  `};
`;

const ImageTagsContainer = styled.div`
  position: absolute;
  left: 15px;
  bottom: 15px;
`;

const CenterContainer = styled.div`
  padding: 24px;
`;

const TagsContainer = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    margin: -8px 0 18px 0;

    & > div {
      margin-top: 8px;
    }
  }
`;

const Title = styled.div`
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  overflow: hidden;

  &:hover {
    color: #000000;
  }

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
    height: 95px;
  }
`;

const TeacherContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  height: 100%;
  padding: 24px;
`;

const Post = styled.div`
  font-family: 'Raleway', sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #a7a8ab;
  margin: 0 0 5px 0;
  flex: 0 1 auto;
`;

const Teacher = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  margin: 0 0 5px 0;
  flex: 0 1 auto;

  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

const SeeAllButton = styled(Button)`
  display: block;
  margin: auto;
`;
