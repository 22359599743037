import React from 'react';
import { Modal, ModalProps } from 'antd';
import styled, { css } from 'styled-components';

import { Button } from 'atoms';

export const SentMessageModal = ({ onOk, ...props }: ModalProps) => {
  return (
    <ModalContainer {...props}>
      <Title>Письмо отправлено на почту</Title>
      <Info>
        Для завершения регистрации необходимо подтвердить адрес электронной почты. Пожалуйста, проверьте Вашу почту и
        перейдите по указанной в письме ссылке. Если Вы не находите письмо в папке «Входящие», проверьте также папки
        «Спам» и «Промоакции».
      </Info>
      <ButtonWrapper>
        <ButtonRestyled onClick={onOk}>Перейти</ButtonRestyled>
      </ButtonWrapper>
    </ModalContainer>
  );
};

const ModalContainer = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
    overflow: hidden;

    .ant-modal-close {
      top: 24px;
      right: 32px;

      .ant-modal-close-x {
        font-size: 20px;
        width: 26px;
        height: 26px;
        line-height: 26px;
      }

      @media (min-width: 768px) {
        top: 32px;
        right: 40px;

        .ant-modal-close-x {
          font-size: 25px;
          width: 36px;
          height: 36px;
          line-height: 36px;
        }
      }

      @media (min-width: 1200px) {
        top: 64px;
        right: 70px;

        .ant-modal-close-x {
          font-size: 30px;
          width: 56px;
          height: 56px;
          line-height: 56px;
        }
      }
    }

    .ant-modal-body {
      padding: 76px 28px 48px;

      @media (min-width: 768px) {
        padding: 188px 80px;
      }

      @media (min-width: 1200px) {
        padding: 232px 336px;
      }
    }
  }
`;

const Title = styled.h3`
  ${() => css`
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 28px;
    color: #231f20;
    text-align: center;
    margin-bottom: 16px;

    @media (min-width: 768px) {
      font-size: 24px;
      line-height: 40px;
    }

    @media (min-width: 1200px) {
      font-size: 32px;
      line-height: 48px;
    }
  `}
`;

const Info = styled.p`
  ${() => css`
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    text-align: center;
    margin-bottom: 40px;

    @media (min-width: 768px) {
      margin-bottom: 48px;
    }

    @media (min-width: 1200px) {
      margin-bottom: 56px;
    }
  `}
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

const ButtonRestyled = styled(Button).attrs({ type: 'primary', block: true })`
  @media (min-width: 768px) {
    max-width: 240px;
  }
`;
