import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Container } from 'atoms';

interface IProps {
  title?: string;
  children: ReactNode;
  className?: string;
}

export const Section = ({ title, children, className }: IProps) => (
  <Content className={className}>
    <Container>
      {title && <Title>{title}</Title>}

      {children}
    </Container>
  </Content>
);

const Content = styled.div`
  width: 100%;
  padding: 48px 0 64px;
  margin: auto;
`;

const Title = styled.div`
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 23px;
  line-height: 31px;
  color: #000000;
  margin: 0 0 32px 0;

  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 40px;
    margin: 0 0 48px 0;
  }

  @media (min-width: 1200px) {
    font-size: 48px;
    line-height: 56px;
    margin: 0 0 70px 0;
  }
`;
