/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { getPublication } from 'core/selectors';

import { PublicationsCarousel } from './PublicationsCarousel';

export const Container = () => {
  const dispatch = useDispatch();
  const publication = useSelector(getPublication);
  const history = useHistory();
  const { area, id: publicationId } = useParams<{ id: string; area?: string }>();

  const itemClickHandler = (id: number) => {
    history.push(area ? `/area/${area}/publication/${id}` : `/publication/${id}`);
  };

  if (!publication) {
    return null;
  }

  // useEffect(() => {
  //   dispatch(getPublicationsRequestAction({ publicationId, area }));
  // }, [dispatch, publicationId, area]);

  return <PublicationsCarousel items={publication.recommended_articles} itemOnClick={itemClickHandler} />;
};
