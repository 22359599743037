import React, { ReactNode } from 'react';
import { Button as AntdButton, ButtonProps } from 'antd';
import styled, { css } from 'styled-components';

export type IBtnType = 'primary' | 'ghost' | 'default' | 'link';

interface IProps extends ButtonProps {
  type?: IBtnType;
  block?: boolean;
  disabled?: boolean;
  size?: 'large' | 'middle' | 'small';
  loading?: boolean;
  onClick?(event: any): void;
  icon?: ReactNode;
  children: ReactNode;
}

const sizes = new Map([
  ['large', 'font-size: 16px; font-weight: 700; height: 56px;'],
  ['middle', 'font-size: 14px; font-weight: 600; height: 48px;'],
  ['small', 'font-size: 14px; font-weight: 600; height: 40px;'],
]);

export const Button = ({
  type = 'primary',
  block = false,
  disabled = false,
  size = 'large',
  loading = false,
  onClick,
  icon,
  children,
  ...props
}: IProps) => (
  <ButtonContainer
    block={block}
    disabled={disabled}
    size={size}
    loading={loading}
    onClick={onClick}
    icon={icon}
    type={type}
    {...props}
  >
    {children}
  </ButtonContainer>
);

const ButtonContainer = styled(AntdButton)`
  ${({
    theme: {
      primaryBtnBgColor,
      primaryBtnTextColor,
      primaryBtnHoverBgColor,
      primaryBtnActiveBgColor,
      primaryBtnDisabledBgColor,
      primaryBtnDisabledTextColor,
      defaultBtnBgColor,
      defaultBtnTextColor,
      defaultBtnHoverBgColor,
      defaultBtnHoverBorderColor,
      defaultBtnActiveBgColor,
      defaultBtnDisabledBgColor,
      defaultBtnDisabledTextColor,
      ghostBtnBgColor,
      ghostBtnTextColor,
      ghostBtnHoverBgColor,
      ghostBtnActiveBgColor,
      ghostBtnDisabledBgColor,
      ghostBtnDisabledTextColor,
    },
    size,
  }) => css`
    & {
      font-family: 'Raleway', sans-serif;
      text-shadow: none;
      padding: 4px 31px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      letter-spacing: 0.4px;
      border-radius: 8px;
      ${sizes.get(String(size))};
    }

    &.ant-btn-primary {
      background-color: ${primaryBtnBgColor};
      border: 2px solid ${primaryBtnBgColor};
      color: ${primaryBtnTextColor};

      &:focus {
        background-color: ${primaryBtnBgColor};
        border-color: ${primaryBtnBgColor};
      }

      &:hover {
        background-color: ${primaryBtnHoverBgColor};
        border-color: ${primaryBtnHoverBgColor};
      }

      &:active {
        background-color: ${primaryBtnActiveBgColor};
        border-color: ${primaryBtnActiveBgColor};
      }

      &:disabled {
        background-color: ${primaryBtnDisabledBgColor};
        border-color: ${primaryBtnDisabledBgColor};
        color: ${primaryBtnDisabledTextColor};
      }

      &:disabled:hover {
        background-color: ${primaryBtnDisabledBgColor};
        border-color: ${primaryBtnDisabledBgColor};
        color: ${primaryBtnDisabledTextColor};
      }
    }

    &.ant-btn-default {
      background-color: ${defaultBtnBgColor};
      border-color: ${defaultBtnBgColor};
      color: ${defaultBtnTextColor};

      &:focus {
        background-color: ${defaultBtnBgColor};
        border-color: ${defaultBtnBgColor};
      }

      &:hover {
        background-color: ${defaultBtnHoverBgColor};
        border-color: ${defaultBtnHoverBorderColor};
      }

      &:active {
        background-color: ${defaultBtnActiveBgColor};
        border-color: ${defaultBtnActiveBgColor};
      }

      &:disabled {
        background-color: ${defaultBtnDisabledBgColor};
        border-color: ${defaultBtnDisabledBgColor};
        color: ${defaultBtnDisabledTextColor};
      }

      &:disabled:hover {
        background-color: ${defaultBtnDisabledBgColor};
        border-color: ${defaultBtnDisabledBgColor};
        color: ${defaultBtnDisabledTextColor};
      }
    }

    &.ant-btn-ghost {
      background-color: ${ghostBtnBgColor};
      border-color: ${ghostBtnBgColor};
      color: ${ghostBtnTextColor};

      &:focus {
        background-color: ${ghostBtnBgColor};
        border-color: ${ghostBtnBgColor};
      }

      &:hover {
        background-color: ${ghostBtnHoverBgColor};
        border-color: ${ghostBtnHoverBgColor};
      }

      &:active {
        background-color: ${ghostBtnActiveBgColor};
        border-color: ${ghostBtnActiveBgColor};
      }

      &:disabled {
        background-color: ${ghostBtnDisabledBgColor};
        border-color: ${ghostBtnDisabledBgColor};
        color: ${ghostBtnDisabledTextColor};
      }

      &:disabled:hover {
        background-color: ${ghostBtnDisabledBgColor};
        border-color: ${ghostBtnDisabledBgColor};
        color: ${ghostBtnDisabledTextColor};
      }
    }

    &.ant-btn-link {
      background-color: white;
      border-color: #e2e3fc;
      color: #4853ec;

      &:focus {
        background-color: white;
        border-color: #e2e3fc;
      }

      &:hover {
        background-color: white;
        border-color: #e2e3fc;
      }

      &:active {
        background-color: white;
        border-color: #e2e3fc;
      }

      &:disabled {
        background-color: white;
        border-color: #e2e3fc;
        color: #4853ec;
      }

      &:disabled:hover {
        background-color: white;
        border-color: #e2e3fc;
        color: #4853ec;
      }
    }
  `}
`;
