import React from 'react';
import { WrappedFieldsProps } from 'redux-form';

import { FormItem, RangePicker, RangeValue } from 'atoms';

export const DatesRange = ({
  dateStart: {
    input: { value: valueDateStart, onChange: onChangeDateStart },
  },
  dateEnd: {
    input: { value: valueDateEnd, onChange: onChangeDateEnd },
  },
}: WrappedFieldsProps) => {
  const commonHandler = (values: RangeValue<Date>) => {
    if (values) {
      const [start, end] = values;
      if (start) onChangeDateStart(start);
      if (end) onChangeDateEnd(end);
    } else {
      onChangeDateStart(null);
      onChangeDateEnd(null);
    }
  };

  return (
    <FormItem label="Даты" $labelTop>
      <RangePicker
        value={[valueDateStart, valueDateEnd]}
        onChange={commonHandler}
        placeholder={['Начало', 'Конец']}
        inputReadOnly
      />
    </FormItem>
  );
};
