import { IState } from 'core/types';

export const getUserEvents = ({ personalArea }: IState) => ({
  userEvents: personalArea.userEvents,
});

export const getUserPersonalData = ({ personalArea }: IState) => ({
  personalData: personalArea.profile.personalData,
});

export const getUserCertificateRequestState = ({ personalArea }: IState) => ({
  personalData: personalArea,
});

export const userDataUpdateStatus = ({ personalArea }: IState) => ({
  isUserDataUpdateSuccess: personalArea.isUserDataUpdateSuccess,
});
