import React, { MouseEventHandler, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-number-input';
import { RadioChangeEvent } from 'antd/lib/radio';

import { Button, Checkbox, FormServerError, InputCode } from 'atoms';
import {
  getVerificationCodeRequestAction,
  loginRequstAction,
  putCodeErrorToStoreAction,
  putEmailExistsAction,
  putIsPersonalDataApproveErrorAction,
  putIsPersonalDataApproveValueAction,
} from 'core/actions';
import { store } from 'core';
import { validationService } from 'services';
import { ILogInFormValues, IFormErrors } from 'types';
import { getCodeError, getEmailExists, getRegister } from 'core/selectors';

import { TextField } from './Components/TextField';

interface IProps {
  showRegModal?: () => void;
}

const Form = ({ showRegModal }: InjectedFormProps<ILogInFormValues, IProps> & IProps) => {
  const dispatch = useDispatch();
  const [isCodeFormVisible, setCodeFormVisible] = useState<boolean>(false);
  const register = useSelector(getRegister);
  // const isLoginLoading = useSelector(getIsLoginLoading);
  const [code, setCode] = useState<string>('');
  const [count, setCount] = useState<number>(59);
  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<any>('');
  const [emailError, setEmailError] = useState<any>('');
  const codeError = useSelector(getCodeError);
  const emailExists = useSelector(getEmailExists);

  const handlePhoneInput = (value: any) => {
    setPhone(value);
  };

  const TermsCheckboxChangeHandler = (e: RadioChangeEvent) => {
    dispatch(putIsPersonalDataApproveValueAction(e.target.checked));
  };

  const handleFormVisible = () => {
    if (email && email.target) {
      setEmailError(validationService.validateEmail(email.target.value));
    } else {
      setEmailError(validationService.validateEmail(''));
    }

    // if(!emailError && email && email.target) {
    //   dispatch(checkEmailRequestAction(email.target.value));
    // }

    if (!store.getState().auth.register.isPersonalDataApprove.value) {
      dispatch(putIsPersonalDataApproveErrorAction(true));
    }

    if (!emailError && phone && store.getState().auth.register.isPersonalDataApprove.value) {
      store.dispatch(getVerificationCodeRequestAction({ email: email.target.value, phone }));
      setCodeFormVisible(Boolean(emailExists));
      dispatch(putEmailExistsAction(null));
    }
  };

  const handleFormInVisible = () => {
    dispatch(putEmailExistsAction(null));
    setCount(59);
  };

  const onSubmit = () => {
    dispatch(putCodeErrorToStoreAction(''));

    const newEmail = email.target.value;
    store.dispatch(loginRequstAction({ code, phone, email: newEmail }));
  };

  const getNewCode = () => {
    store.dispatch(getVerificationCodeRequestAction({ email: email.target.value, phone }));
    setCount(59);
  };

  const onPressRegister: MouseEventHandler = (event) => {
    event.preventDefault();
    if (showRegModal) {
      showRegModal();
    }
  };

  useEffect(() => {
    if (isCodeFormVisible || emailExists) {
      const timer = count > 0 && setTimeout(() => setCount(count - 1), 1000);
      return () => {
        if (typeof timer === 'function' && count < 59) {
          clearInterval(timer);
        }
      };
    }

    return () => {};
  }, [count, isCodeFormVisible, emailExists]);

  return (
    <Container emailExists={emailExists} emailError={emailError}>
      {!emailExists && (
        <>
          <Field
            name="email"
            caption="E-mail"
            format={null}
            component={TextField}
            required
            onlyLowerCase
            onChange={setEmail}
          />
          {!emailExists && emailExists !== null && !emailError && (
            <ErrorMessage>Такой e-mail не зарегистрирован, пройдите регистрацию</ErrorMessage>
          )}
          {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
          <Phone international defaultCountry="RU" value={phone} onChange={handlePhoneInput} />
        </>
      )}
      {emailExists && (
        <>
          <PhoneInfo>
            Мы отправили код на номер: <PhoneNumber>{phone}</PhoneNumber>
          </PhoneInfo>
          {count ? (
            <TimerInfo>Новый код можно получить через 00:{count < 10 ? `0${count}` : count} сек</TimerInfo>
          ) : (
            <NewCodeText onClick={getNewCode}>Получить новый код</NewCodeText>
          )}
          <CodeWrapper>
            <InputCode code={code} setCode={setCode} codeError={codeError} />
          </CodeWrapper>
        </>
      )}
      {!emailExists && (
        <Terms>
          <TermsCheckbox
            onChange={TermsCheckboxChangeHandler}
            isError={register.isPersonalDataApprove.isError}
            checked={register.isPersonalDataApprove.value}
          />
          <TermsContent>
            Я даю согласие на обработку моих{' '}
            <a
              href="https://drive.google.com/file/d/1jALGESCWl04bUyUvU_2ZgAfthGWPp309/view?usp=sharing"
              target="_blank"
              rel="noreferrer"
            >
              персональных данных
            </a>{' '}
            и соглашаетесь c{' '}
            <a
              target="_blank"
              href="https://drive.google.com/file/d/1CGjp8LKF3nVqOKgKedE4h_mVamjmfpWy/view?usp=sharing"
              rel="noreferrer"
            >
              политикой конфиденциальности.
            </a>
          </TermsContent>
        </Terms>
      )}
      <ButtonWrapper>
        {emailExists ? (
          <>
            <ButtonBackRestyled type="default" onClick={handleFormInVisible}>
              Исправить номер
            </ButtonBackRestyled>
            <ButtonNextRestyled type="primary" onClick={onSubmit} loading={false} disabled={code.trim().length < 4}>
              Войти
            </ButtonNextRestyled>
          </>
        ) : (
          <>
            <Button type="primary" onClick={handleFormVisible} loading={false}>
              Получить код
            </Button>
          </>
        )}
      </ButtonWrapper>
      <RegisterText>
        Если Вы ранее не регистрировались на eMediQ,
        <br /> необходимо <RegisterButton onClick={onPressRegister}>пройти регистрацию</RegisterButton>
      </RegisterText>
      <SupportInfo>Служба технической поддержки +7 985 143-77-51</SupportInfo>
      <FormServerError />
    </Container>
  );
};

const validate = ({ email }: ILogInFormValues) => {
  return {
    email: validationService.validateEmail(email),
  } as IFormErrors;
};

const onSubmit = () => {
  // store.dispatch(loginRequstAction({ code, phone, email: email.trim().toLowerCase() }));
};

export const LoginForm = reduxForm<ILogInFormValues, IProps>({
  form: 'LogInForm',
  validate,
  onSubmit,
  touchOnBlur: false,
})(Form);

const Container = styled.div<{ emailExists: boolean | null; emailError: any }>`
  & > div:first-of-type {
    padding-bottom: 0;
    margin-bottom: ${({ emailExists, emailError }) => (emailExists === null && !emailError ? '46px' : '18px')};
  }
`;

const Phone = styled(PhoneInput)`
  display: flex;
  margin: 0 auto 32px;

  & .PhoneInputInput {
    padding-bottom: 8px;
    border: none;
    border-bottom: 1px solid #dcdcdd;
    outline: none;
  }
`;

const Terms = styled.div`
  ${({ theme: { registerFormTermsLinkColor, registerFormTermsColor } }) => css`
    max-width: 484px;
    font-family: 'Open Sans', sans-serif;
    color: ${registerFormTermsColor};
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin: 0 auto 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      color: ${registerFormTermsLinkColor};
      text-decoration: underline;
    }

    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 18px;
    }
  `}
`;

const ButtonBackRestyled = styled(Button)`
  width: 100%;
  margin-right: 16px;

  @media (min-width: 576px) {
    width: auto;
  }
`;

const ButtonNextRestyled = styled(ButtonBackRestyled)`
  margin-bottom: 0px;
  margin-right: 0px;

  @media (min-width: 576px) {
    margin-bottom: 0px;
  }
`;

const TermsCheckbox = styled(Checkbox)`
  float: left;
  margin: 6px 0 0 0;
`;

const TermsContent = styled.div`
  /* padding: 0 0 0 40px; */
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-wrap: wrap;

    & button {
      width: 100%;
      max-width: 360px;
      margin-bottom: 10px;
      margin-right: 0px;
    }
  }
`;

const SupportInfo = styled.p`
  text-align: center;
  color: #9da0aa;
`;

const CodeWrapper = styled.div`
  margin: 56px auto;

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const PhoneInfo = styled.p``;

const PhoneNumber = styled.span`
  font-weight: bold;
`;

const TimerInfo = styled.p`
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  color: #4853ec;
`;

const NewCodeText = styled.p`
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  color: #4853ec;
  cursor: pointer;
`;

const ErrorMessage = styled.p`
  margin-top: 8px;
  font-size: 12px;
  color: #eb5757;
`;

const RegisterText = styled(SupportInfo)`
  font-size: 20px;
  font-weight: 300;
`;

const RegisterButton = styled.span`
  color: #4853ec;
  text-decoration: underline;
  cursor: pointer;
`;
