import React, { memo, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import parser from 'react-html-parser';
import ru from 'date-fns/locale/ru';
import { Spin } from 'antd';

import { getAreasAlias, getPublication, getIsPublicationLoading, getArea } from 'core/selectors';
import { MainTemplate } from 'templates';
import { ViewWhite as ViewIcon, Download as DownloadIcon } from 'assets/icons';
import { Breadcrumb, Tag, Container, Button, Section } from 'atoms';
import {
  getPublicationRequestAction,
  clearPublicationToStoreAction,
  resetLastPublicationsSearchAction,
  getAreaRequestAction,
  clearAreaToStoreAction,
} from 'core/actions';
import { PublicationsPublicationCarousel } from 'components/organisms';

export const Publication = memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { area, id } = useParams<{ area?: string; id: string }>();
  const areaAliases = useSelector(getAreasAlias);
  const publication = useSelector(getPublication);
  const isPublicationLoading = useSelector(getIsPublicationLoading);

  const { color } = useSelector(getArea);

  useEffect(() => {
    if (area) {
      dispatch(getAreaRequestAction({ alias: area }));
    }

    return () => {
      dispatch(clearAreaToStoreAction());
    };
  }, [dispatch, area]);

  const areaTitle = useMemo(() => {
    return areaAliases.find((alias) => alias.areasAlias === area)?.title;
  }, [area, areaAliases]);

  useEffect(() => {
    const historyListener = history.listen((location) => {
      if (!location.pathname.includes('/publication')) {
        dispatch(resetLastPublicationsSearchAction());
      }
    });

    return () => {
      historyListener();
    };
  }, [history, dispatch]);

  useEffect(() => {
    dispatch(getPublicationRequestAction({ id }));

    return () => {
      dispatch(clearPublicationToStoreAction());
    };
  }, [dispatch, id]);

  if (isPublicationLoading) {
    return <Spin style={{ position: 'absolute', left: '48%', top: '48%' }} size="large" />;
  }

  const onClickArea = () => {
    history.push(`/publications/${publication.category.alias}`);
  };

  const onLibraryClick = () => {
    history.push(`/publications/all`);
  };

  return (
    <MainTemplate isMenuButtonVisible contentBgColor="#ffffff">
      <TopBackground bgColor={color || undefined}>
        <Container>
          <Breadcrumb>
            {areaTitle && <Breadcrumb.Item>{areaTitle}</Breadcrumb.Item>}
            <Breadcrumb.Item onClick={onLibraryClick}>Библиотека</Breadcrumb.Item>
            <Breadcrumb.Item onClick={onClickArea}>{publication.category.title}</Breadcrumb.Item>
            <Breadcrumb.Item>{publication.title}</Breadcrumb.Item>
          </Breadcrumb>

          <Tag size="small" colorType={1}>
            {publication.category.shortTitle || publication.category.title}
          </Tag>

          <Tag size="small" colorType={1}>
            {publication.area.shortTitle || publication.area.title}
          </Tag>

          <Title>{publication.title}</Title>

          <SubTitle>
            <DateText>{format(new Date(publication.date), 'd MMMM yyyy', { locale: ru })}</DateText>
            <Views>
              <ViewIcon />
              {publication.viewCount}
            </Views>
          </SubTitle>

          <MainImage src={publication.imageUrl} alt="mainImage" />
        </Container>
      </TopBackground>

      <Background>
        <Container>
          <ShortDescription>{parser(publication.shortDescription.replace(/\r\n/g, ' <br> '))}</ShortDescription>

          {publication.quote.text && (
            <Quote>
              <QuoteText>{parser(publication.quote.text.replace(/\r\n/g, ' <br> '))}</QuoteText>
              <Author>
                <Duty>{publication.quote.duty}</Duty>
                <Surname>{publication.quote.surname}</Surname>
              </Author>
            </Quote>
          )}

          <Description>{parser(publication.description)}</Description>

          <ArticleFooter>
            <SourceWrapper>
              <SourceText>Источник</SourceText>
              <Source href={publication.sourceLink} target="_blank">
                {publication.sourceName}
              </Source>
            </SourceWrapper>
            <Button type="link" href={publication.pdfLink} target="_blank" download>
              <ButtonContent>
                <DownloadIcon />
                Скачать публикацию в pdf
              </ButtonContent>
            </Button>
          </ArticleFooter>
        </Container>
      </Background>

      {publication.recommended_articles.length > 0 && (
        <PublicationSection title="C этой темой также смотрят">
          <PublicationsPublicationCarousel />
        </PublicationSection>
      )}
    </MainTemplate>
  );
});

const TopBackground = styled.div<{ backgroundUrl?: string; bgColor?: string }>`
  ${({ backgroundUrl = '', theme: { topBackgroundColor }, bgColor }) => css`
    padding: 80px 0 15px 0;
    background-color: ${topBackgroundColor};
    background-image: url(${backgroundUrl});
    background-repeat: no-repeat;
    background-position: 50% 0;
    position: relative;
    z-index: 0;
    background-size: cover;

    &:before {
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0.8;
      background: ${bgColor ?? 'linear-gradient(275.17deg, #525ded 24.3%, #121a7b 102.71%)'};
    }

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 100px;
      z-index: -1;
      background: #ffffff;
    }

    @media (min-width: 768px) {
      padding: 100px 0 60px 0;

      &:after {
        height: 200px;
      }
    }
  `}
`;

const Background = styled.div`
  background: #ffffff;
`;

const Title = styled.h1`
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  margin-top: 30px;

  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 48px;
  }

  @media (min-width: 1200px) {
    font-size: 48px;
    line-height: 72px;
  }
`;

const SubTitle = styled.h2`
  display: flex;
  color: #ffffff;
`;

const DateText = styled.div`
  font-size: 16px;
`;

const Views = styled.div`
  margin-left: 32px;
  font-size: 16px;

  svg {
    margin-right: 8px;
  }
`;

const MainImage = styled.img`
  margin-top: 72px;
  border-radius: 12px;
  width: 100%;
`;

const ShortDescription = styled.div`
  font-size: 16px;
  line-height: 28px;

  @media (min-width: 768px) {
    padding: 0px 120px;
  }
`;

const Quote = styled.div`
  background-color: #f8f8f8;
  padding: 10px 20px;
  margin: 40px 0px;
  border-radius: 12px;

  @media (min-width: 768px) {
    padding: 40px 115px;
  }
`;

const QuoteText = styled.div`
  font-size: 16px;
  line-height: 28px;
  color: #4853ec;
  font-weight: 600;
  font-style: italic;
`;

const Author = styled.div`
  margin-top: 24px;
  display: flex;

  flex-wrap: wrap;
`;

const Duty = styled.div`
  color: #9da0aa;
  font-size: 16px;
  font-style: italic;
  width: 100%;

  @media (min-width: 576px) {
    width: unset;
  }
`;

const Surname = styled.div`
  font-weight: 600;
  font-size: 16px;

  @media (min-width: 576px) {
    margin-left: 16px;
  }
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 28px;

  img {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    border-radius: 12px;
  }

  iframe {
    width: 100%;
  }

  @media (min-width: 768px) {
    padding: 0px 120px;

    img {
      margin-top: 40px;
      margin-bottom: 40px;
      margin-left: -120px;
      width: calc(100% + 240px);
    }
  }
`;

const ArticleFooter = styled.div`
  padding: 72px 0px 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  @media (min-width: 992px) {
    padding: 40px 0;
    flex-direction: row;
  }
`;

const SourceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  @media (min-width: 992px) {
    flex-direction: row;
    margin-bottom: 0px;
  }
`;

const SourceText = styled.div`
  color: #9da0aa;
  font-size: 16px;
  font-style: italic;
`;

const Source = styled.a`
  display: block;
  margin-left: 0;
  color: #4853ec;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;

  @media (min-width: 992px) {
    margin-left: 16px;
  }
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  svg {
    margin-right: 10px;
  }
`;

const PublicationSection = styled(Section)`
  padding: 24px 0 0 0;
  background-color: #f8f8f8;

  & > div:first-child {
    & > div:first-child {
      margin-bottom: 32px;
    }
  }

  @media (min-width: 768px) {
    padding: 48px 0 65px 0;
  }
`;
