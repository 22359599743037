import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { Spin } from 'antd';

import { getArea, getIsAreaLoading } from 'core/selectors';
import { MainTemplate } from 'templates';
import { clearAreaToStoreAction, getAreaRequestAction } from 'core/actions';
import {
  BannersAreaCarousel,
  CalendarArea,
  EducationalSections,
  PartnersArea,
  ProgramsAreaCarousel,
  SocialsArea,
} from 'components/organisms';
import { Container } from 'components/atoms';

export const Area = memo(() => {
  const dispatch = useDispatch();
  const { area } = useParams<{ area: string }>();

  const { title, description, backgroundUrl, color, upcomingEvents } = useSelector(getArea);
  const isAreaLoading = useSelector(getIsAreaLoading);

  useEffect(() => {
    dispatch(getAreaRequestAction({ alias: area }));

    return () => {
      dispatch(clearAreaToStoreAction());
    };
  }, [dispatch, area]);

  if (isAreaLoading) return <Spin style={{ position: 'absolute', left: '48%', top: '48%' }} size="large" />;

  return (
    <MainTemplate isMenuButtonVisible>
      <TopBackground
        backgroundColor={color}
        backgroundUrl={backgroundUrl}
        isHasUpcomingEvents={Boolean(upcomingEvents.length)}
        disableGradient={area === 'transplantology'}
      >
        <Container>
          {title && <Title>{title}</Title>}
          {description && <Description>{ReactHtmlParser(description.replace(/\r\n/g, ' <br> '))}</Description>}
          <ProgramsAreaCarousel />
        </Container>
      </TopBackground>
      <EducationalSections />
      <SocialsArea />
      <BannersAreaCarousel />
      <CalendarArea />
      <PartnersArea />
    </MainTemplate>
  );
});

const TopBackground = styled.div<{
  backgroundColor: string | null;
  backgroundUrl?: string;
  isHasUpcomingEvents?: boolean;
  disableGradient?: boolean;
}>`
  ${({
    backgroundColor,
    backgroundUrl = '',
    isHasUpcomingEvents,
    theme: { topBackgroundColor },
    disableGradient,
  }) => css`
    padding: 80px 0 15px 0;
    background-color: ${topBackgroundColor};
    background-image: url(${backgroundUrl});
    background-repeat: no-repeat;
    background-position: 50% 0;
    position: relative;
    background-size: cover;
    z-index: 0;

    ${disableGradient
      ? ''
      : css`
          &:before {
            content: ' ';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: 0.8;
            background: ${backgroundColor ?? 'linear-gradient(275.17deg, #525ded 24.3%, #121a7b 102.71%)'};
          }
        `}

    ${isHasUpcomingEvents &&
    css`
      &:after {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 100%;
        height: 200px;
        z-index: -1;
        background: #f8f8f8;
      }
    `};

    @media (min-width: 768px) {
      padding: 104px 0 60px 0;

      ${isHasUpcomingEvents &&
      css`
        &:after {
          height: 250px;
        }
      `};
    }

    @media (min-width: 1200px) {
      padding: 100px 0 60px 0;
    }
  `}
`;

const Title = styled.h1`
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;

  @media (min-width: 768px) {
    font-size: 56px;
    line-height: 48px;
  }

  @media (min-width: 1200px) {
    font-size: 64px;
    line-height: 72px;
  }
`;

const Description = styled.h2`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: #ffffff;
  margin: 0 0 72px 0;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
    font-weight: normal;
    margin: 0 0 96px 0;
  }

  @media (min-width: 1200px) {
    margin: 0 0 104px 0;
    width: 75%;
  }
`;
