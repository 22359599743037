import React, { ReactNode } from 'react';
import { Tag as AntdTag } from 'antd';
import styled, { css } from 'styled-components';

export type TTagColorType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
export type TTagSize = 'large' | 'middle' | 'small';

interface IProps {
  colorType?: TTagColorType;
  bgColor?: string;
  size?: TTagSize;
  onClick?: () => void;
  children: ReactNode;
}

export const Tag = ({ colorType = 1, bgColor, size = 'large', children, onClick, ...props }: IProps) => (
  <TagContainer colorType={colorType} size={size} onClick={onClick}>
    <AntdTag color={bgColor} {...props}>
      {children}
    </AntdTag>
  </TagContainer>
);

interface ITagContainer {
  size?: TTagSize;
  colorType?: TTagColorType;
}

const TagContainer = styled.div<ITagContainer>`
  ${({ theme: { TagPrimaryTextColor, TagSecondaryTextColor }, colorType, size }) => css`
    display: inline-block;

    & {
      .ant-tag {
        font-family: 'Raleway', sans-serif;
        border: none;
        margin-right: 8px;

        &:hover {
          opacity: 1;
        }
      }

      ${size === 'large' &&
      `
         .ant-tag {
            height: 56px;
            font-weight: bold;
            font-size: 16px;
            letter-spacing: 0.4px;
            padding: 0 24px;
            line-height: 56px;
            border-radius: 8px;
         }
      `}

      ${size === 'middle' &&
      `
         .ant-tag {
            height: 40px;
            font-weight: bold;
            font-size: 12px;
            letter-spacing: 0.4px;
            padding: 0 12px;
            line-height: 40px;
            border-radius: 8px;
         }
      `}
      
      ${size === 'small' &&
      `
         .ant-tag {
            height: 28px;
            font-weight: bold;
            font-size: 12px;
            letter-spacing: 0.4px;
            padding: 0 12px;
            line-height: 28px;
            border-radius: 4px;
         }
      `}
      
      ${colorType === 1 &&
      `
         .ant-tag {
            color: ${TagSecondaryTextColor};
            background-color: rgba(255, 255, 255, 0.24);
         }
      `}
      
      ${colorType === 2 &&
      `
         .ant-tag {
            color: ${TagSecondaryTextColor};
            background-color: #e0e1e3;
         }
      `}
      
      ${colorType === 3 &&
      `
         .ant-tag {
            color: ${TagPrimaryTextColor};
            background-color: #ffffff;
         }
      `}
      
      ${colorType === 4 &&
      `
         .ant-tag {
            color: ${TagPrimaryTextColor};
            background-color: #e2e3fc;
         }
      `}
      
      
      ${colorType === 5 &&
      `
         .ant-tag {
            color: ${TagPrimaryTextColor};
            background-color: #f0f1fd;
         }
      `}
      
      ${colorType === 6 &&
      `
         .ant-tag {
            color: ${TagPrimaryTextColor};
            background-color: #c4c8f9;
         }
      `}
      ${colorType === 7 &&
      `
         .ant-tag {
            color: ${TagSecondaryTextColor};
            font-family: 'Open Sans', sans-serif;
            background-color: #4299ff;
         }
      `}
      ${colorType === 8 &&
      `
         .ant-tag {
            color: ${TagSecondaryTextColor};
            font-family: 'Open Sans', sans-serif;
            background-color: #4853ec;
         }
      `}
      ${colorType === 9 &&
      `
         .ant-tag {
            color: ${TagSecondaryTextColor};
            font-family: 'Open Sans', sans-serif;
            background-color: #1cb594;
         }
      `}
    }
  `}
`;
