/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { getAuthor } from 'core/selectors';

import { PublicationsCarousel } from './PublicationsCarousel';

export const Container = () => {
  const dispatch = useDispatch();
  const author = useSelector(getAuthor);
  const history = useHistory();
  const { area, id: authorId } = useParams<{ id: string; area?: string }>();

  const itemClickHandler = (id: number) => {
    history.push(area ? `/area/${area}/publication/${id}` : `/publication/${id}`);
  };

  if (!author) {
    return null;
  }
  // useEffect(() => {
  //   dispatch(getAuthorEventsRequestAction({ authorId, area }));
  // }, [dispatch, authorId, area]);

  return <PublicationsCarousel items={author.publications} itemOnClick={itemClickHandler} />;
};
