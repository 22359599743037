export enum Sex {
  None = 'other',
  Male = 'man',
  Female = 'woman',
}

export enum RegisterSteps {
  First = 1,
  Second = 2,
  Third = 3,
  Fourth = 4,
}

export enum ValidationTypes {
  None = 0,
  Required = 1,
  Email = 2,
  Date = 3,
  Password = 4,
  ConfirmPassword = 5,
}

export enum AcademicDegree {
  pdm = 'pdm',
  md = 'md',
}

export interface IRegisterFormFirstStepValues {
  name: string;
  sex: Sex;
  email: string;
  birthdayDate: string;
  phoneNumber: string;
  country: string;
  city: string;
  region: string;
}

export interface IRegisterFormSecondStepValues {
  specify: string;
  position: string;
  workStudyPlace: string;
  degree: string;
}

export interface IRegisterFormThirdStepValues {
  country: string;
  city: string;
  region: string;
}

export interface IFormErrors {
  [key: string]: string;
}

export interface IApiRegisterValues {
  email: string;
  full_name: string;
  country: string;
  region: string;
  city: string;
  specialty: string;
  additional_specialty: string;
  phone_number: string;
  gender: Sex;
  date_of_birth: string;
  working_place: string;
  position: string;
  academic_degree: string;
}

export interface ILogInFormValues {
  email: string;
  password: string;
}

export interface IForgotPasswordFormValues {
  email: string;
}

export interface ICreatePasswordFormValues {
  password: string;
  repeatedPassword: string;
}

export interface ICountriesApi {
  id: string;
  name: string;
  fullname: string;
  english: string;
}

export interface ICitiesApi {
  id: number;
  name: string;
}
