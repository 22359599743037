import { IApiPartner, IPartner, IApiPartnerExtended, IPartnerExtended } from 'types';

export const convertPartnersToAppSchema = (partners: IApiPartner[]): IPartner[] => {
  return partners.map((item) => ({
    id: item.id,
    partnerUrl: item.partner_url,
    thumbnailUrl: item.thumbnail_url,
    materialsUrl: item.materials_url,
  }));
};

export const convertPartnerExtendedToAppSchema = (partner: IApiPartnerExtended): IPartnerExtended => {
  return {
    id: partner.id,
    name: partner.name,
    contacts: partner.contacts,
    description: partner.description,
    logoUrl: partner.logo_url,
    partnerUrl: partner.partner_url,
    thumbnailUrl: partner.thumbnail_url,
    vimeoUrl: partner.vimeo_url,
  };
};
