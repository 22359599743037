import qs from 'querystring';

import { IApiRegisterValues, IApiUser } from 'types';
import { BaseService } from 'services';

class AuthAPIService extends BaseService {
  public async refreshToken() {
    const refreshToken = localStorage.getItem('refresh_token');
    const isRefreshTokenExists = Boolean(refreshToken);

    if (!isRefreshTokenExists) {
      throw new Error('Refresh token is not exist');
    }

    const data = {
      refresh: refreshToken,
    };

    const {
      data: { access: access_token },
    } = await this.post('refresh_token', qs.stringify(data), { 'Content-Type': 'application/x-www-form-urlencoded' });

    localStorage.setItem('access_token', access_token);

    return access_token;
  }

  public async logIn(code: string, phone_number: string, email: string) {
    return this.post('verify_account', { code, phone_number, email });
  }

  public async register(values: IApiRegisterValues) {
    const data = {
      ...values,
    };

    return this.post('register', JSON.stringify(data), {
      'Content-Type': 'application/json',
    });
  }

  public async forgotPassword(email: string) {
    return this.post('reset_password', { email });
  }

  public async createPassword(token: string, password: string) {
    return this.post(`change_password/${token}`, { password });
  }

  public async getUser(): Promise<IApiUser> {
    const { data } = await this.get<IApiUser>(`profile`);
    return data;
  }

  public async updateUser(id: string, userDto: IApiUser) {
    const { data } = await this.put(`update-profile/${id}/`, userDto);
    return data;
  }

  public async getCode(phone_number: string) {
    await this.post(`code`, { phone_number, type: 'act' });
  }

  public async getVerificationCode(phone_number: string) {
    await this.post(`code`, { phone_number, type: 'ver' });
  }

  public async activatePhone(code: string, phone_number: string) {
    const { data } = await this.post(`activate_phone`, { phone_number, code });
    return data;
  }

  public async checkEmail(email: string) {
    const { data } = await this.get(`check_email/${email}`);

    return data;
  }
}

export const AuthService = new AuthAPIService();
