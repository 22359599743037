import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import { SearchTag } from 'atoms';
import { ITag } from 'types';

interface IProps extends WrappedFieldProps {
  allTags: ITag[];
}

export const TagsCheckboxes = ({ input: { value, onChange }, allTags }: IProps) => {
  const tagsOnChange = (tag: string) => {
    if (value.includes(tag)) onChange(value.filter((item: string) => item !== tag));
    else onChange([...value, tag]);
  };

  return allTags.map(({ name }) => {
    return (
      <SearchTag key={name} checked={value.includes(name)} onChange={() => tagsOnChange(name)}>
        {name}
      </SearchTag>
    );
  });
};
