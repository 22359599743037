import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import { useLocation } from 'react-router-dom';

import { Button, Section as DefaultSection } from 'atoms';
import { IAreaCropped } from 'types';
import { useWindowSize } from 'hooks';

import { AreaCard } from './AreaCard';

interface IProps {
  areas: IAreaCropped[];
  total: number;
  loadMoreOnClick: () => void;
  areaOnClick: (alias: string) => void;
}

export const Areas = ({ areas, total, loadMoreOnClick, areaOnClick }: IProps) => {
  const [width] = useWindowSize();
  const { pathname, state } = useLocation<null | { scrollToArea?: boolean }>();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (state?.scrollToArea && ref.current) {
      window.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop - 120 });
    }
  }, [state, pathname]);

  return (
    <div ref={ref}>
      <Section title="Научные направления">
        <Row gutter={[8, 8]}>
          {areas.map((area) => (
            <Col xs={24} md={12} key={area.alias}>
              <AreaCard {...area} onClick={() => areaOnClick(area.alias)} width={width} />
            </Col>
          ))}
        </Row>
        {total > areas.length && (
          <Row justify="center">
            <Col>
              <ButtonStyled onClick={loadMoreOnClick}>Посмотреть ещё</ButtonStyled>
            </Col>
          </Row>
        )}
      </Section>
    </div>
  );
};

const Section = styled(DefaultSection)`
  background: #ffffff;
`;

const ButtonStyled = styled(Button)`
  margin-top: 50px;
  margin-bottom: 40px;
  box-shadow: none;

  &.ant-btn-primary {
    border: 2px solid #f8f8f8;
    background-color: #e6eaf1;
    color: #9da0aa;
    box-shadow: none;

    &:hover {
      border: 2px solid #f8f8f8;
      background-color: #e6eaf1;
      color: #9da0aa;
      box-shadow: none;
    }
  }
`;
