import {
  IApiEventListItem,
  IEventListItem,
  IApiEvent,
  IEvent,
  CatalogSearchASC,
  IApiEventOrderingRequest,
  IApiEventCategory,
  IEventCategory,
  IEventMarkType,
  IApiEventMark,
  IEventMark,
  IApiAuthor,
} from 'types';

import { convertAreaSocialsToAppSchema } from './area';
import { convertAuthorsToAppSchema } from './authors';
import { convertPartnersToAppSchema } from './partners';

export const convertEventsCategoriesToAppSchema = (categories: IApiEventCategory[]): IEventCategory[] => {
  return categories.map((item) => ({
    alias: item.alias,
    description: item.description,
    id: item.id,
    eventsCount: item.count_events,
    title: item.title,
    areaTitle: item.area_title,
    shortTitle: item.short_title,
  }));
};

export const convertEventsToAppSchema = (events: IApiEventListItem[]): IEventListItem[] => {
  return events.map((event) => ({
    id: event.id,
    title: event.title,
    description: event.description,
    startDate: event.start_date,
    thumbnailUrl: event.thumbnail_url,
    viewingCount: event.viewing_count,
    subscribed: event.subscribed,
    isInteresting: event.is_interesting,
    authors: convertAuthorsToAppSchema(event.authors),
    area: {
      alias: event.area.alias,
      title: event.area.title,
      shortTitle: event.area.short_title,
    },
    category: {
      id: event.category.id,
      title: event.category.title,
      shortTitle: event.category.short_title,
    },
    tags: event.tags.map((tag) => ({
      name: tag.name,
      color: tag.color,
    })),
    marks: event.marks,
    averageMark: event.average_mark,
    checkpoints: event.checkpoints,
    userCode: event.user_code,
    questionsIds: event.questions_ids,
  }));
};

export const convertEventToAppSchema = (event: IApiEvent): IEvent => {
  if (event.sections)
    return {
      id: event.id,
      title: event.title,
      description: event.description,
      startDate: event.start_date,
      startTime: event.start_time,
      thumbnailUrl: event.thumbnail_url,
      backgroundUrl: event.background_url,
      videoUrl: event.vimeo_url,
      viewingCount: event.viewing_count,
      isViewed: event.is_viewed,
      isRegistered: event.is_registered,
      subscribed: event.subscribed,
      authors: convertAuthorsToAppSchema(event.authors),
      area: {
        alias: event.area.alias,
        title: event.area.title,
        shortTitle: event.area.short_title,
      },
      category: {
        id: event.category.id,
        alias: event.category.alias,
        title: event.category.title,
      },
      sections: event.sections.map((section) => ({
        title: section.title,
        startTime: section.start_time,
        endTime: section.end_time,
        description: section.description,
        authors: convertAuthorsToAppSchema(section.authors),
      })),
      recommendedEvents: event.recommended_events.map((recommendedEvent) => ({
        id: recommendedEvent.id,
        title: recommendedEvent.title,
        description: recommendedEvent.description,
        startDate: recommendedEvent.start_date,
        thumbnailUrl: recommendedEvent.thumbnail_url,
        viewingCount: recommendedEvent.viewing_count,
        subscribed: recommendedEvent.subscribed,
        isInteresting: recommendedEvent.is_interesting,
        authors: convertAuthorsToAppSchema(recommendedEvent.authors),
        area: {
          alias: recommendedEvent.area.alias,
          title: recommendedEvent.area.title,
          shortTitle: recommendedEvent.area.short_title,
        },
        category: {
          id: recommendedEvent.category.id,
          title: recommendedEvent.category.title,
          shortTitle: recommendedEvent.category.short_title,
        },
        tags: recommendedEvent.tags.map((tag) => ({
          name: tag.name,
          color: tag.color,
        })),
        marks: recommendedEvent.marks,
        averageMark: recommendedEvent.average_mark,
        checkpoints: recommendedEvent.checkpoints,
        userCode: recommendedEvent.user_code,
        questionsIds: recommendedEvent.questions_ids,
      })),
      socialNetworks: convertAreaSocialsToAppSchema(event.social_networks),
      banners: event.banners.map((banner) => ({
        name: banner.name,
        desktopUrl: banner.desktop_url,
        mobileUrl: banner.mobile_url,
        url: banner.url,
      })),
      partners: convertPartnersToAppSchema(event.partners),
      marks: convertApiMarksToAppSchema(event.marks, event.authors),
      averageMark: event.average_mark,
    };

  return {
    id: event.id,
    title: event.title,
    description: event.description,
    startDate: event.start_date,
    startTime: event.start_time,
    thumbnailUrl: event.thumbnail_url,
    backgroundUrl: event.background_url,
    videoUrl: event.vimeo_url,
    viewingCount: event.viewing_count,
    isViewed: event.is_viewed,
    isRegistered: event.is_registered,
    subscribed: event.subscribed,
    authors: convertAuthorsToAppSchema(event.authors),
    area: {
      alias: event.area.alias,
      title: event.area.title,
      shortTitle: event.area.short_title,
    },
    category: {
      id: event.category.id,
      alias: event.category.alias,
      title: event.category.title,
    },
    recommendedEvents: event.recommended_events.map((recommendedEvent) => ({
      id: recommendedEvent.id,
      title: recommendedEvent.title,
      description: recommendedEvent.description,
      startDate: recommendedEvent.start_date,
      thumbnailUrl: recommendedEvent.thumbnail_url,
      viewingCount: recommendedEvent.viewing_count,
      subscribed: recommendedEvent.subscribed,
      isInteresting: recommendedEvent.is_interesting,
      authors: convertAuthorsToAppSchema(recommendedEvent.authors),
      area: {
        alias: recommendedEvent.area.alias,
        title: recommendedEvent.area.title,
        shortTitle: recommendedEvent.area.short_title,
      },
      category: {
        id: recommendedEvent.category.id,
        title: recommendedEvent.category.title,
        shortTitle: recommendedEvent.category.short_title,
      },
      tags: recommendedEvent.tags.map((tag) => ({
        name: tag.name,
        color: tag.color,
      })),
      marks: recommendedEvent.marks,
      averageMark: recommendedEvent.average_mark,
      checkpoints: recommendedEvent.checkpoints,
      userCode: recommendedEvent.user_code,
      questionsIds: recommendedEvent.questions_ids,
    })),
    socialNetworks: convertAreaSocialsToAppSchema(event.social_networks),
    banners: event.banners.map((banner) => ({
      name: banner.name,
      desktopUrl: banner.desktop_url,
      mobileUrl: banner.mobile_url,
      url: banner.url,
    })),
    partners: convertPartnersToAppSchema(event.partners),
    marks: convertApiMarksToAppSchema(event.marks, event.authors),
    averageMark: event.average_mark,
  };
};

const convertApiMarksToAppSchema = (apiMarks: IApiEventMark[], authors: IApiAuthor[]): IEventMark[] => {
  const marksMap: IEventMarkType = {
    '0': {
      mark: 0,
      authorName: 'Ваша реакция',
    },
  };

  [...authors].forEach((author) => {
    marksMap[String(author.id)] = { authorName: author.full_name, mark: 0 };
  });

  [...apiMarks].forEach((item) => {
    const authorId = item.author ? String(item.author) : '0';
    marksMap[authorId] = { ...marksMap[authorId], mark: item.mark };
  });

  const countedMarksArray = Object.entries(marksMap);
  const ratingData = countedMarksArray.map(([authorId, marksData]) => {
    return {
      authorId,
      authorName: marksData.authorName,
      mark: marksData.mark,
    };
  });
  return ratingData;
};

export const convertOrderingForRequest = (asc: CatalogSearchASC): IApiEventOrderingRequest => {
  switch (asc) {
    case CatalogSearchASC.created:
      return 'created';
    case CatalogSearchASC.start_date:
      return 'start_date';
    case CatalogSearchASC.end_date:
      return '-start_date';
    case CatalogSearchASC.subscriptions:
      return '-subscription_count';
    case CatalogSearchASC.viewings:
      return '-viewing_count';
    default:
      return 'created';
  }
};
