import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { AppFooter, AppHeader, AppSider } from 'organisms';

interface IProps {
  children?: ReactNode;
  isMenuButtonVisible?: boolean;
  noFooter?: boolean;
  noHeader?: boolean;
  contentBgColor?: string;
}

export const MainTemplate = ({ children, isMenuButtonVisible, noHeader, noFooter, contentBgColor }: IProps) => (
  <Container>
    <AppSider>
      <Wrapper noHeader={noHeader}>
        {!noHeader && <AppHeader isMenuButtonVisible={isMenuButtonVisible} />}
        <Content bgColor={contentBgColor}>{children}</Content>
        {!noFooter && <AppFooter />}
      </Wrapper>
    </AppSider>
  </Container>
);

const Container = styled.div`
  overflow: hidden;
`;

const Wrapper = styled.div<{ noHeader?: boolean }>`
  ${({ noHeader }) => css`
    ${!noHeader &&
    css`
      padding: 72px 0 0 0;

      @media (min-width: 768px) {
        padding: 80px 0 0 0;
      }

      @media (min-width: 1200px) {
        padding: 120px 0 0 0;
      }
    `}
  `};
`;

const Content = styled.div<{ bgColor?: string }>`
  ${({ bgColor }) => css`
    margin: 0 auto 0;
    background-color: ${bgColor ?? '#f8f8f8'};
  `};
`;
