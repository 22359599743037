import React from 'react';
import styled from 'styled-components';
import rulocale from 'antd/es/date-picker/locale/ru_RU';
import { RangePickerProps } from 'antd/es/date-picker/generatePicker';
import './datePickerStyles.css'; // decision for mobile one panel

import { DatePicker } from '../DatePickerDateFns';

rulocale.lang.locale = 'ru'; // rc-picker require this string, not ru_RU

export const RangePicker = ({ ...rest }: RangePickerProps<Date>) => {
  return <StyledRangePicker {...rest} locale={rulocale} />;
};

const StyledRangePicker = styled(DatePicker.RangePicker)`
  width: 100%;
  height: 56px;
  border-radius: 8px;
`;
