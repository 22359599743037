import React, { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';

import { saveSurveyAnswersToStoreAction } from 'core/actions';
import { TextInput } from 'components/atoms';

interface ICustomAnswerProps {
  questionId: number;
  customAnswer?: string;
  setCustomAnswer?: (value: string) => void;
}

const CUSTOM_ANSWER_LENGTH_LIMIT = 400;

export const CustomAnswer = ({ questionId, customAnswer = '', setCustomAnswer }: ICustomAnswerProps) => {
  const dispatch = useDispatch();

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length < CUSTOM_ANSWER_LENGTH_LIMIT) {
      // TODO hot solution due to backend bad implementation with separate logic for custom input and other types of answers
      // better to change backend implementation
      dispatch(
        saveSurveyAnswersToStoreAction({
          answers: {
            question: questionId,
            answers: e.target.value ? [1] : [],
            customInput: e.target.value,
          },
        }),
      );

      if (setCustomAnswer) {
        setCustomAnswer(e.target.value);
      }
    }
  };

  return <TextInput value={customAnswer} onChange={onInputChange} required autoFocus />;
};
