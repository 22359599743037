import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface IStepWrapperProps {
  children: ReactNode;
  className?: string;
}

export const StepWrapper = ({ children, className }: IStepWrapperProps) => {
  return <StartStepWrapper className={className}>{children}</StartStepWrapper>;
};

const StartStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  padding: 32px 24px;
  height: 100%;
  flex: 1;

  @media (min-width: 768px) {
    padding: 40px 32px;
  }

  @media (min-width: 768px) {
    padding: 64px 72px;
  }
`;
