import { SagaIterator } from 'redux-saga';
import { takeLatest, takeEvery, call, put, select, SagaReturnType } from 'redux-saga/effects';
import { getFormValues } from 'redux-form';
import { ActionType } from 'typesafe-actions';

import {
  convertEventsCategoriesToAppSchema,
  convertEventsToAppSchema,
  convertTagsToAppSchema,
} from 'services/apiMappers';
import { eventsService } from 'services';
import {
  getTagsRequestAction,
  getCatalogCategoriesRequestAction,
  getCatalogEventsRequestAction,
  getMoreCatalogEventsRequestAction,
  getCatalogEventsSuccessAction,
  putCatalogEventsToStoreAction,
  getCatalogEventsFailureAction,
  getMoreCatalogEventsSuccessAction,
  getMoreCatalogEventsFailureAction,
  getTagsSuccessAction,
  putTagsToStoreAction,
  getTagsFailureAction,
  getCatalogCategoriesSuccessAction,
  putCatalogCategoriesToStoreAction,
  getCatalogCategoriesFailureAction,
} from 'core/actions';
import { getSearchASCFromStore, getEventsFromStore, getCurrentCatalogCategoryFromStore } from 'core/selectors';
import { SearchASCType, SearchResultType, CurrentCatalogCategoryType } from 'core/types/selectorsTypes';

type EventsResult = SagaReturnType<typeof eventsService.getEvents>;
function* getSearchResult({ payload: { alias } }: ActionType<typeof getCatalogEventsRequestAction>): SagaIterator {
  try {
    const searchASC: SearchASCType = yield select(getSearchASCFromStore);
    const category: CurrentCatalogCategoryType = yield select(getCurrentCatalogCategoryFromStore);
    const formValues = yield select(getFormValues('catalogSearchForm'));
    const { dateStart, dateEnd, searchString, checkedTags } = formValues || {};

    const { results, total }: EventsResult = yield call(() =>
      eventsService.getEvents({
        alias,
        category,
        searchASC,
        dateStart,
        dateEnd,
        searchString,
        tags: checkedTags,
        offset: 1,
      }),
    );
    const catalogResult = convertEventsToAppSchema(results);

    yield put(getCatalogEventsSuccessAction());
    yield put(putCatalogEventsToStoreAction({ events: catalogResult, eventsTotal: total }));
  } catch (error) {
    yield put(getCatalogEventsFailureAction());
  }
}

function* getMoreSearchResult({
  payload: { alias },
}: ActionType<typeof getMoreCatalogEventsRequestAction>): SagaIterator {
  try {
    const searchResult: SearchResultType = yield select(getEventsFromStore);
    const searchASC: SearchASCType = yield select(getSearchASCFromStore);
    const category: CurrentCatalogCategoryType = yield select(getCurrentCatalogCategoryFromStore);
    const formValues = yield select(getFormValues('catalogSearchForm'));
    const { dateStart, dateEnd, searchString, checkedTags } = formValues || {};

    const { results, total }: EventsResult = yield call(() =>
      eventsService.getEvents({
        alias,
        searchASC,
        dateStart,
        dateEnd,
        tags: checkedTags,
        searchString,
        offset: Math.ceil(searchResult.length / eventsService.defaultEventsLimit) + 1,
        category,
      }),
    );

    const newSearchResult = convertEventsToAppSchema(results);
    yield put(getMoreCatalogEventsSuccessAction());
    yield put(putCatalogEventsToStoreAction({ events: [...searchResult, ...newSearchResult], eventsTotal: total }));
  } catch (error) {
    yield put(getMoreCatalogEventsFailureAction());
  }
}

type Tags = SagaReturnType<typeof eventsService.getTags>;
function* getTags(): SagaIterator {
  try {
    const data: Tags = yield call(() => eventsService.getTags());
    const tags = convertTagsToAppSchema(data);

    yield put(getTagsSuccessAction());
    yield put(putTagsToStoreAction({ tags }));
  } catch (error) {
    yield put(getTagsFailureAction());
  }
}

type Categories = SagaReturnType<typeof eventsService.getCategories>;
function* getCategories({ payload: { alias } }: ActionType<typeof getCatalogCategoriesRequestAction>): SagaIterator {
  try {
    const data: Categories = yield call(() => eventsService.getCategories(alias));
    const categories = convertEventsCategoriesToAppSchema(data);

    yield put(getCatalogCategoriesSuccessAction());
    yield put(putCatalogCategoriesToStoreAction({ categories }));
  } catch (error) {
    yield put(getCatalogCategoriesFailureAction());
  }
}

export function* catalogRootSaga(): SagaIterator {
  yield takeLatest(getCatalogEventsRequestAction, getSearchResult);
  yield takeLatest(getMoreCatalogEventsRequestAction, getMoreSearchResult);
  yield takeEvery(getTagsRequestAction, getTags);
  yield takeEvery(getCatalogCategoriesRequestAction, getCategories);
}
