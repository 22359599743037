import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination } from 'antd';
import moment from 'moment';
import 'moment/locale/ru';

import { Container } from 'components/atoms';
import { getUserEvents } from 'core/selectors';
import { getUserEventsRequestAction } from 'core/actions';
import { IUserEventInfo } from 'core';

import { UserHistoryItem } from './UserHistoryItem';

interface IEventsHistory {
  events: IUserEventInfo[];
}

const EventsHistory = ({ events }: IEventsHistory) => {
  return (
    <>
      {events.map((event) => (
        <UserHistoryItem key={event.id} event={event} />
      ))}
    </>
  );
};

export const UserHistory = () => {
  const [page, setPage] = useState<number>(1);

  const dispatch = useDispatch();
  const { userEvents } = useSelector(getUserEvents);

  useEffect(() => {
    dispatch(getUserEventsRequestAction({ offset: page }));
  }, [page, dispatch]);

  const setNewPage = (newPage: number) => {
    if (newPage === page) return;
    setPage(newPage);
  };

  return (
    <HistoryContainer>
      <ViewHistory>История просмотров</ViewHistory>
      {Object.entries(userEvents.events).map(([eventDate, events]) => {
        const date = moment(eventDate);

        return (
          <EventDateWrapper key={eventDate}>
            <EventDate>{date.format('DD MMMM')}</EventDate>
            <EventsHistory events={events} />
          </EventDateWrapper>
        );
      })}
      {userEvents.total ? (
        <PaginationWrapper>
          <Pagination onChange={setNewPage} total={userEvents.total} />
        </PaginationWrapper>
      ) : null}
    </HistoryContainer>
  );
};

const HistoryContainer = styled(Container)`
  background-color: #ffffff;
`;

const ViewHistory = styled.div`
  color: #000;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 32px;
  }
`;

const EventDateWrapper = styled.div`
  margin-bottom: 32px;
`;

const EventDate = styled.div`
  color: #4853ec;
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  display: flex;
  margin-bottom: 16px;

  &:after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #d2d7de;
    margin: auto;
    margin-left: 32px;
  }
`;

const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
