import React from 'react';
import { useSelector } from 'react-redux';

import { getEvent } from 'core/selectors';

import { Socials } from './Socials';

interface ISocialsEventContainerProps {
  color?: string;
}

export const Container = ({ color }: ISocialsEventContainerProps) => {
  const { socialNetworks } = useSelector(getEvent);

  if (!socialNetworks.length) {
    return null;
  }

  return <Socials socials={socialNetworks} color={color} />;
};
