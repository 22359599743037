import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Field, InjectedFormProps, change, reduxForm } from 'redux-form';
import styled, { css } from 'styled-components';
import { notification } from 'antd';
import type { ArgsProps as NotificationBaseProps } from 'antd/lib/notification';

import { IFormErrors, IUserData } from 'types';
import { PlaceService, validationService } from 'services';
import { store } from 'core';
import { putPersonalUserDataToStore, resetUserInfoUpdateStatusAction, saveUserInfoRequestAction } from 'core/actions';
import { IState } from 'core/types/reduxTypes';
import { countriesList, degreeList, specifiesList, tagsSelectList } from 'mock';
import {
  AsyncSelectField,
  DateField,
  SelectField,
  TagSelectField,
  TextField,
} from 'components/organisms/RegisterModal/Components';
import { Button } from 'components/atoms';
import { getUserPersonalData, userDataUpdateStatus } from 'core/selectors';

const fieldTitleStyles = css`
  & > div:first-child {
    color: #9da0aa;
    font-family: Raleway;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 14px;
    }
  }
`;

const inputStyles = css`
  color: #000000;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.8px;

  &:disabled {
    color: gray;
    background-color: #ffffff;
  }
`;

const TextFieldStyled = styled(TextField)`
  margin-bottom: 0px;

  & > input {
    ${inputStyles}
  }

  ${fieldTitleStyles}

  padding: 10px 0 19px !important;

  & > div:first-of-type {
    top: 20px;
  }
`;

const SelectFieldStyled = styled(SelectField)`
  ${fieldTitleStyles}

  .ant-select-selection-item {
    color: #000000;

    @media (max-width: 767px) {
      font-weight: 600 !important;
    }
  }
`;

const DateFieldStyled = styled(DateField)`
  ${fieldTitleStyles}

  & > input {
    ${inputStyles}
  }

  padding: 10px 0 19px !important;

  & > div:first-of-type {
    top: 20px;
  }
`;

const AsyncSelectFieldStyled = styled(AsyncSelectField)`
  ${fieldTitleStyles}

  .ant-select-selection-item {
    color: #000000;

    @media (max-width: 767px) {
      font-weight: 600 !important;
    }
  }
`;

const TagSelectFieldStyled = styled(TagSelectField)`
  padding-bottom: 17px;

  .ant-select-selection-item {
    color: #000000;

    @media (max-width: 767px) {
      font-weight: 600 !important;
    }
  }
`;

const getWindowWidth = () => {
  const { innerWidth: width } = window;
  return width;
};

const Form = ({ dirty, handleSubmit }: InjectedFormProps<IUserData>) => {
  const dispatch = useDispatch();
  const {
    personalData: { region, country },
  } = useSelector(getUserPersonalData);
  const { isUserDataUpdateSuccess } = useSelector(userDataUpdateStatus);
  const [co, setCo] = useState('');
  const [re, setRe] = useState('');
  const [windowWidth, setWindowWidth] = useState(getWindowWidth());

  useEffect(() => {
    setCo(country);
    setRe(region);
  }, [country, region]);

  useEffect(() => {
    if (isUserDataUpdateSuccess !== null) {
      const notificationProps: Partial<NotificationBaseProps> = {
        placement: 'bottomRight',
        style: {
          fontFamily: 'Raleway, sans-serif',
          fontWeight: 500,
        },
        onClose: () => {
          dispatch(resetUserInfoUpdateStatusAction());
        },
      };

      isUserDataUpdateSuccess
        ? notification.success({ ...notificationProps, message: 'Данные успешно обновлены' })
        : notification.error({
            ...notificationProps,
            message: 'Что-то пошло не так, попробуйте позднее',
          });
    }
  }, [dispatch, isUserDataUpdateSuccess]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(getWindowWidth());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <form>
      <FormContent>
        <SectionTitle>Личные данные</SectionTitle>
        <FormWrapper>
          <Field name="name" caption="Ваше ФИО" component={TextFieldStyled} required />
          <Field name="birthdayDate" caption="Дата рождения" component={DateFieldStyled} required />
          <Field
            name="sex"
            caption="Пол"
            component={TagSelectFieldStyled}
            tags={tagsSelectList}
            {...{ isSelect: windowWidth < 990 }}
          />
          <Field
            name="country"
            caption="Страна"
            onChange={(value: any) => {
              dispatch(change('PrivateUserData', 'region', ''));
              dispatch(change('PrivateUserData', 'city', ''));
              setCo(value);
            }}
            component={AsyncSelectFieldStyled}
            fetchOptions={requestCountries}
            required
          />
          <Field name="phoneNumber" caption="Телефон" component={TextFieldStyled} required />
          <Field
            name="region"
            caption="Регион"
            onChange={(value: any) => {
              dispatch(change('PrivateUserData', 'city', ''));
              setRe(value);
            }}
            component={SelectFieldStyled}
            options={getRegions('Россия')}
            required={co === 'Россия'}
            disabled={co !== 'Россия'}
          />
          <Field name="email" caption="E-mail" component={TextFieldStyled} required onlyLowerCase disabled />
          {co !== 'Россия' ? (
            <Field
              name="city"
              caption="Город"
              component={AsyncSelectFieldStyled}
              fetchOptions={requestCities}
              fetchArgument={co}
              required
            />
          ) : (
            <Field
              name="city"
              caption="Город"
              component={SelectFieldStyled}
              required
              options={getCities('Россия', re)}
            />
          )}
        </FormWrapper>
      </FormContent>

      <FormContent>
        <SectionTitle>Профессиональные данные</SectionTitle>
        <FormWrapper>
          <Field
            name="specify"
            caption="Специальность"
            required
            options={specifiesList}
            defaultValue="Выберите специальность"
            component={SelectFieldStyled}
          />
          <Field name="workStudyPlace" caption="Место работы" component={TextFieldStyled} required />
          <Field name="position" caption="Должность" component={TextFieldStyled} />
          <Field name="degree" caption="Ученая степень" options={degreeList} defaultValue="" component={SelectField} />
        </FormWrapper>
      </FormContent>

      <Button onClick={dirty ? handleSubmit : undefined} disabled={!dirty}>
        Сохранить изменения
      </Button>
    </form>
  );
};

function requestCountries(value: string) {
  return PlaceService.getCountries(value);
}

function getCities(countryName?: string, regionName?: string) {
  if (!regionName) {
    return [];
  }

  const country = countriesList.find((c) => c.value === countryName);

  if (!country) {
    return [];
  }

  const region = country.regions.find((r) => r.value === regionName);

  if (!region) {
    return [];
  }

  return region.cities;
}

function requestCities(value: string, country: string) {
  return PlaceService.getCities(country, value);
}

function getRegions(countryName?: string) {
  if (!countryName) {
    return [];
  }

  return countriesList.find((c) => c.value === countryName)?.regions || [];
}

const validatePrivateData = ({
  name,
  email,
  birthdayDate,
  phoneNumber,
  country,
  city,
  specify,
  position,
  workStudyPlace,
  degree,
}: IUserData) => {
  return {
    name: validationService.validateName(name),
    sex: '',
    email: validationService.validateEmail(email),
    birthdayDate: validationService.validateDate(birthdayDate),
    phoneNumber: validationService.validatePhoneNumber(phoneNumber),
    country: validationService.validateCountry(country),
    city: validationService.validateCountry(city),
    specify: validationService.validateRequired(specify),
    position: position ? validationService.validateLength(position, 60) : '',
    workStudyPlace: validationService.validateRequired(workStudyPlace),
    degree: degree ? validationService.validateLength(degree, 60) : '',
  } as IFormErrors;
};

const onPrivateUserDataSubmit = (formData: IUserData) => {
  const updatedUserData = {
    ...formData,
    email: formData.email.trim().toLowerCase(),
    country: formData.country,
    city: formData.city,
  };
  store.dispatch(putPersonalUserDataToStore(updatedUserData));
  store.dispatch(saveUserInfoRequestAction());
};

const mapStateToProps = (state: IState) => {
  return {
    initialValues: state.personalArea.profile.personalData,
  };
};

export const PrivateUserData = connect(mapStateToProps)(
  reduxForm<IUserData, {}>({
    form: 'PrivateUserData',
    validate: validatePrivateData,
    onSubmit: onPrivateUserDataSubmit,
    enableReinitialize: true,
    touchOnBlur: false,
  })(Form),
);

const FormContent = styled.div`
  margin-bottom: 2px;
  padding-top: 19px;

  @media (min-width: 768px) {
    margin-bottom: 64px;
    padding-top: 0px;

    &:last-of-type {
      margin-bottom: 48px;
    }
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > div {
    width: 100%;
  }

  @media (min-width: 768px) {
    & > div {
      width: calc(50% - 32px);
      height: 83px;
    }
  }
`;

const SectionTitle = styled.div`
  color: #000000;
  font-family: Raleway;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 32px;
  }
`;
