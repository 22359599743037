import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { setSurveyStepAction, setSurveyAnswersRequestAction, setSurveyCustomAnswerRequestAction } from 'core/actions';
import { getCurrentSurveyStep, getSurveyAnswers, getSurveyData } from 'core/selectors';
import { SurveyAnswerOption } from 'types';

import { StepWrapper } from './StepWrapper';
import { CheckboxGroup } from './CheckboxGroup';
import { RadioButtonGroup } from './RadioButtonGroup';
import { AnswerImageSelector } from './AnswerImageSelector';
import { CustomAnswer } from './CustomAnswer';

interface IFinalStepProps {
  isEvent?: boolean;
}

const questionStepInnerComponentMap = {
  [SurveyAnswerOption.CHECKBOX]: CheckboxGroup,
  [SurveyAnswerOption.RADIOBUTTON]: RadioButtonGroup,
  [SurveyAnswerOption.CUSTOM_INPUT]: CustomAnswer,
};

export const QuestionStep = ({ isEvent }: IFinalStepProps) => {
  const dispatch = useDispatch();
  const { currentStep } = useSelector(getCurrentSurveyStep);
  const { surveyData } = useSelector(getSurveyData);
  const { surveyAnswers } = useSelector(getSurveyAnswers);

  const [customAnswer, setCustomAnswer] = useState<string>('');

  if (!surveyData) {
    return null;
  }

  const currentQuestion = surveyData.questions[currentStep - 1];

  const setCustomAnswerHandler = (value: string) => {
    setCustomAnswer(value);
  };

  const answerQuestion = () => {
    if (currentQuestion.answerType === SurveyAnswerOption.CUSTOM_INPUT) {
      dispatch(
        setSurveyCustomAnswerRequestAction({
          customAnswerDto: {
            question: currentQuestion.id,
            answer: surveyAnswers[surveyAnswers.length - 1].customInput || '',
          },
        }),
      );
      setCustomAnswer('');
    }
    dispatch(setSurveyStepAction());
  };

  const finishSurvey = () => {
    const cleanedCustomAnswers = surveyAnswers.filter((answer) => !answer.customInput);

    dispatch(
      setSurveyAnswersRequestAction({
        surveyId: surveyData.id,
        surveyDto: {
          surveyAnswers: cleanedCustomAnswers,
        },
      }),
    );
    dispatch(setSurveyStepAction());
  };

  const isLastQuestion = currentStep !== surveyData.questions.length;

  const isButtonEnabled = () => {
    const initedSteps = surveyAnswers.length;
    const isLastQuestionAnswered = Boolean(surveyAnswers[surveyAnswers.length - 1]?.answers.length);
    return initedSteps === currentStep && isLastQuestionAnswered;
  };

  const InnerComponent = questionStepInnerComponentMap[currentQuestion.answerType];

  return (
    <StepWrapperStyled $isEvent={isEvent}>
      <QuestionStepStyled>
        {currentStep}/{surveyData.questions.length}
      </QuestionStepStyled>
      <QuestionStyled>
        {currentQuestion.question}
        {currentQuestion.imageUrl && (
          <ImageWrapper>
            <QuestionImage src={currentQuestion.imageUrl} alt="question-image" />
          </ImageWrapper>
        )}
      </QuestionStyled>
      <AnswersContentWrapper>
        {/* TODO move to component selector interceptor */}
        <AnswersWrapper>
          {currentQuestion.questionType === 'photo' ? (
            <AnswerImageSelector
              questionId={currentQuestion.id}
              answerType={currentQuestion.answerType}
              options={currentQuestion.answers}
            />
          ) : (
            <InnerComponent
              options={currentQuestion.answers}
              questionType={currentQuestion.questionType}
              questionId={currentQuestion.id}
              customAnswer={customAnswer}
              setCustomAnswer={setCustomAnswerHandler}
            />
          )}
        </AnswersWrapper>
      </AnswersContentWrapper>
      <ButtonStyled disabled={!isButtonEnabled()} onClick={isLastQuestion ? answerQuestion : finishSurvey}>
        {isLastQuestion ? 'Следующий вопрос' : 'Завершить опрос'}
      </ButtonStyled>
    </StepWrapperStyled>
  );
};

const StepWrapperStyled = styled(StepWrapper)<{ $isEvent?: boolean }>`
  background: #ffffff;

  ${({ $isEvent }) =>
    $isEvent &&
    `
      overflow-y: auto;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    `}
`;

const QuestionStepStyled = styled.div`
  color: #000000;
  font-family: Open Sans;
  font-size: 16px;
  line-height: 24px;
`;

const AnswersContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
    margin: 32px 0 48px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  max-width: 100%;
  max-height: 260px;
  margin: 18px 0 24px;

  @media (min-width: 768px) {
    flex-direction: row;
    font-size: 24px;
    line-height: 32px;
    margin-top: 32px;
    width: auto;
    max-height: unset;
  }
`;

const QuestionImage = styled.img`
  max-width: 90%;
  width: auto;
  height: auto;
  object-fit: cover;
  border-radius: 12px;
`;

const AnswersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const QuestionStyled = styled.div`
  color: #000000;
  text-align: center;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin: 16px 0 32px;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
    margin-top: 32px;
    width: 100%;
  }
`;

const ButtonStyled = styled.button`
  border-radius: 8px;
  background-color: #4853ec;
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.4px;
  border: none;
  cursor: pointer;
  margin-top: 32px;

  &:disabled {
    background-color: #9da1d3;
    cursor: default;
  }

  @media (min-width: 768px) {
    margin-top: 48px;
  }
`;
