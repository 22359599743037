import { ActionType, createReducer } from 'typesafe-actions';

import {
  getUserEventsRequestAction,
  getUserEventsSuccessAction,
  getUserEventsFailureAction,
  putUserEventsToStoreAction,
  putPersonalUserDataToStore,
  getEventCertificateRequestAction,
  getEventCertificateSuccessAction,
  getEventCertificateFailureAction,
  saveUserInfoSuccessAction,
  saveUserInfoFailureAction,
  resetUserInfoUpdateStatusAction,
} from 'core/actions';
import { IApiEventMark, ITag, IUserData, Sex } from 'types';

export interface IUserEventsState {
  userEvents: {
    events: IUserEventsMapType;
    total: number;
  };
  userEventsLoading: boolean;
  userEventsError: boolean;

  profile: {
    personalData: IUserData;
  };
  isUserDataUpdateSuccess: boolean | null;
  isCertificateRequestLoading: boolean;
}

const defaultState: IUserEventsState = {
  userEvents: {
    events: {},
    total: 0,
  },
  userEventsLoading: false,
  userEventsError: false,

  profile: {
    personalData: {
      id: '',
      name: '',
      sex: Sex.None,
      email: '',
      birthdayDate: '',
      phoneNumber: '',
      country: '',
      city: '',
      region: '',
      specify: '',
      position: '',
      workStudyPlace: '',
      degree: '',
    },
  },
  isUserDataUpdateSuccess: null,
  isCertificateRequestLoading: false,
};

const actions = {
  getUserEventsRequestAction,
  getUserEventsSuccessAction,
  getUserEventsFailureAction,
  putUserEventsToStoreAction,

  putPersonalUserDataToStore,

  getEventCertificateRequestAction,
  getEventCertificateSuccessAction,
  getEventCertificateFailureAction,

  saveUserInfoSuccessAction,
  saveUserInfoFailureAction,
  resetUserInfoUpdateStatusAction,
};

export interface IUserEventInfo {
  tags: ITag[];
  marks: IApiEventMark[];
  averageMark: number;
  checkpoints: string;
  title: string;
  id: number;
  userCode: string;
  isCertificate: boolean;
}

type IUserEventsMapType = Record<string, IUserEventInfo[]>;

export const personalAreaReducer = createReducer<IUserEventsState, ActionType<typeof actions>>(defaultState)
  .handleAction(getUserEventsRequestAction, (state) => ({
    ...state,
    userEventsLoading: true,
    userEventsError: false,
  }))
  .handleAction(getUserEventsSuccessAction, (state) => ({
    ...state,
    userEventsLoading: false,
  }))
  .handleAction(getUserEventsFailureAction, (state) => ({
    ...state,
    userEventsLoading: false,
    userEventsError: true,
  }))
  .handleAction(putUserEventsToStoreAction, (state, { payload }) => {
    const eventsMap: IUserEventsMapType = {};

    const viewHistoryData = payload.events.reduce((acc, value) => {
      const eventDate = value.startDate;
      return {
        ...acc,
        [eventDate]: [
          ...(acc[eventDate] || []),
          {
            marks: value.marks,
            checkpoints: value.checkpoints,
            title: value.title,
            tags: value.tags,
            id: value.id,
            userCode: value.userCode,
            isCertificate: Boolean(value.userCode) && Boolean(value.viewingCount),
            averageMark: value.averageMark,
          },
        ],
      };
    }, eventsMap);

    return {
      ...state,
      userEvents: {
        events: viewHistoryData,
        total: payload.total,
      },
    };
  })

  .handleAction(putPersonalUserDataToStore, (state, { payload }) => {
    return {
      ...state,
      profile: {
        ...state.profile,
        personalData: {
          ...state.profile.personalData,
          ...payload,
        },
      },
    };
  })

  .handleAction(getEventCertificateRequestAction, (state) => ({
    ...state,
    isCertificateRequestLoading: true,
  }))
  .handleAction(getEventCertificateSuccessAction, (state) => ({
    ...state,
    isCertificateRequestLoading: false,
  }))
  .handleAction(getEventCertificateFailureAction, (state) => ({
    ...state,
    isCertificateRequestLoading: false,
  }))

  .handleAction(saveUserInfoSuccessAction, (state) => ({
    ...state,
    isUserDataUpdateSuccess: true,
  }))
  .handleAction(saveUserInfoFailureAction, (state) => ({
    ...state,
    isUserDataUpdateSuccess: false,
  }))
  .handleAction(resetUserInfoUpdateStatusAction, (state) => ({
    ...state,
    isUserDataUpdateSuccess: null,
  }));
