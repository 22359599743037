import React, { useEffect, useState } from 'react';
import { formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { Row } from 'antd';
import styled from 'styled-components';
import { connect, useSelector } from 'react-redux';

import { IFormErrors, IRegisterFormThirdStepValues } from 'types';
import { validationService } from 'services';
import {
  activatePhoneRequestAction,
  getCodeRequestAction,
  putIsPersonalDataApproveErrorAction,
  putThirdStepRegisterToStore,
} from 'core/actions';
import { store } from 'core';
import { IState } from 'core/types/reduxTypes';
import { Button, FormServerError, InputCode } from 'components/atoms';
import { getCodeError, getPhone } from 'core/selectors';

export const Form = ({
  handleStepDown,
}: InjectedFormProps<
  IRegisterFormThirdStepValues,
  { handleStepDown?: () => void; country?: string; region?: string }
> & {
  handleStepDown?: () => void;
}) => {
  const [code, setCode] = useState<string>('');
  const [count, setCount] = useState<number>(59);
  const phone = useSelector(getPhone);
  const codeError = useSelector(getCodeError);
  // const email = useSelector(getEmail);

  const handleSubmitRegistration = () => {
    if (store.getState().auth.register.isPersonalDataApprove.value) {
      store.dispatch(activatePhoneRequestAction({ code, phone }));
      // store.dispatch(loginRequstAction({ code, phone, email }));
    }
  };

  const getNewCode = () => {
    store.dispatch(getCodeRequestAction(phone));
    setCount(59);
  };

  useEffect(() => {
    const timer = count > 0 && setTimeout(() => setCount(count - 1), 1000);
    return () => {
      if (typeof timer === 'function' && count < 59) {
        clearInterval(timer);
      }
    };
  }, [count]);

  return (
    <form>
      <PhoneInfo>
        Мы отправили код на номер: <PhoneNumber>{phone}</PhoneNumber>
      </PhoneInfo>
      {count ? (
        <TimerInfo>Новый код можно получить через 00:{count < 10 ? `0${count}` : count} сек</TimerInfo>
      ) : (
        <NewCodeText onClick={getNewCode}>Получить новый код</NewCodeText>
      )}
      <CodeWrapper>
        <InputCode code={code} setCode={setCode} codeError={codeError} />
      </CodeWrapper>
      <ButtonWrapper>
        <ButtonBackRestyled type="default" onClick={handleStepDown}>
          Исправить номер
        </ButtonBackRestyled>
        <ButtonNextRestyled type="primary" onClick={handleSubmitRegistration} disabled={code.trim().length < 4}>
          Войти
        </ButtonNextRestyled>
      </ButtonWrapper>
      <SupportInfo>Служба технической поддержки +7 985 143-77-51</SupportInfo>
      <Row>
        <FormServerError />
      </Row>
    </form>
  );
};

const validateThirdStep = ({ country, city }: IRegisterFormThirdStepValues) => {
  return {
    country: validationService.validateRequired(country),
    city: validationService.validateRequired(city),
    region: '',
  } as IFormErrors;
};

const onThirdStepFormSubmit = (formData: IRegisterFormThirdStepValues) => {
  if (store.getState().auth.register.isPersonalDataApprove.value) {
    store.dispatch(putThirdStepRegisterToStore(formData));
    return;
  }

  store.dispatch(putIsPersonalDataApproveErrorAction(true));
};

const selector = formValueSelector('RegisterFormThirdStep');
const mapStateToProps = (state: IState) => ({
  initialValues: state.auth.register.thirdStep,
  country: selector(state, 'country'),
  region: selector(state, 'region'),
});

export const FourthStepForm = connect(mapStateToProps)(
  reduxForm<IRegisterFormThirdStepValues, { handleStepDown?: () => void }>({
    form: 'RegisterFormThirdStep',
    validate: validateThirdStep,
    onSubmit: onThirdStepFormSubmit,
    enableReinitialize: true,
    touchOnBlur: false,
  })(Form),
);

const CodeWrapper = styled.div`
  margin: 56px 0;

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const PhoneInfo = styled.p``;

const PhoneNumber = styled.span`
  font-weight: bold;
`;

const TimerInfo = styled.p`
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  color: #4853ec;
`;

const NewCodeText = styled.p`
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  color: #4853ec;
  cursor: pointer;
`;

const ButtonBackRestyled = styled(Button)`
  width: 100%;
  margin-right: 16px;
`;

const ButtonNextRestyled = styled(ButtonBackRestyled)`
  margin-bottom: 0px;
  margin-right: 0px;

  @media (min-width: 576px) {
    margin-bottom: 0px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
`;

const SupportInfo = styled.p`
  text-align: center;
  color: #9da0aa;
`;
