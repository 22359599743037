import { IApiHome, IHome, IApiHomeEnterestingEvents, IHomeEnterestingEvent } from 'types';

import { convertPopularAreasToAppSchema } from './area';
import { convertPartnersToAppSchema } from './partners';

export const convertHomeToAppSchema = (home: IApiHome): IHome => {
  return {
    title: home.title,
    description: home.description,
    backgroundUrl: home.background_url,
    popularAreas: convertPopularAreasToAppSchema(home.popular_areas),
    partners: convertPartnersToAppSchema(home.partners),
    banners: home.banners.map((banner) => ({
      name: banner.name,
      desktopUrl: banner.desktop_url,
      mobileUrl: banner.mobile_url,
      url: banner.url,
    })),
    originalsTitle: home.originals_title,
    originalsDescription: home.originals_description,
    originalsVideoUrl: home.originals_video_url,
  };
};

export const convertHomeEnterestinEventToAppSchema = (home: IApiHomeEnterestingEvents): IHomeEnterestingEvent[] => {
  return home.results.map(({ id, start_date, title, viewing_count, area, thumbnail_url }) => ({
    id,
    title,
    alias: area.alias,
    startDate: start_date,
    viewingCount: viewing_count,
    thumbnailUrl: thumbnail_url,
  }));
};
