import React from 'react';
import { DotProps } from 'react-multi-carousel/lib/types';
import styled, { css } from 'styled-components';

export const Dot = ({ onClick, ...rest }: DotProps) => {
  const { index, active } = rest;

  return (
    <ItemContainer className={active ? 'active' : 'inactive'} onClick={onClick}>
      0{index! + 1}
    </ItemContainer>
  );
};

const ItemContainer = styled.button`
  ${({ theme: { primaryBtnBgColor } }) => css`
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    background: transparent;
    color: #a7a8ab;
    cursor: pointer;
    border: none;
    transition: border 0.1s linear;
    padding: 0 0 19px 0;
    margin: 0 33px -2px 0;
    border-bottom: 3px solid transparent;
    outline: none;
    display: none;

    @media (min-width: 768px) {
      display: inline-block;
    }

    &:last-child {
      margin-right: 0;
    }

    &.active {
      color: #000000;
      border-color: ${primaryBtnBgColor};
    }
  `}
`;
