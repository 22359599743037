import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface ICategory {
  to: string;
  id: number;
  alias: string;
  title: string;
  shortTitle: string;
}

interface IProps {
  categories: ICategory[];
  defaultTo: string;
  activeCategory?: string;
}

export const Categories = ({ categories, defaultTo, activeCategory }: IProps) => {
  return (
    <Container>
      <StyledLink $isActive={activeCategory === 'all'} to={defaultTo}>
        Все категории
      </StyledLink>
      {categories &&
        categories.map(({ id, title, shortTitle, to, alias }) => (
          <StyledLink $isActive={activeCategory === alias} key={id} to={to}>
            {shortTitle || title}
          </StyledLink>
        ))}
    </Container>
  );
};

const Container = styled.div``;

const StyledLink = styled(Link)<{ $isActive: boolean }>`
  ${({ $isActive }) => css`
    display: inline-block;
    box-sizing: border-box;
    margin: 4px;
    height: 40px;
    line-height: 40px;
    padding: 0px 16px;
    font-size: 14px;
    background: rgba(255, 255, 255, 0.24);
    color: #ffffff;
    border-radius: 8px;
    font-weight: 700;

    &:hover {
      color: #ffffff;
    }

    ${$isActive && isActiveStyles}

    @media (min-width: 768px) {
      height: 56px;
      line-height: 48px;
      padding: 4px 24px;
      font-size: 16px;
    }
  `}
`;

const isActiveStyles = css`
  background-color: rgba(255, 255, 255);
  color: rgba(72, 83, 236, 1);
  &:hover {
    color: rgba(72, 83, 236, 1);
  }
`;
