import React from 'react';
import styled from 'styled-components';

import { Container } from 'components/atoms';
import { MainTemplate } from 'components/templates';

import { AuthSurveyInterceptor } from './AuthSurveyInterceptor';

export const Survey = () => {
  return (
    <MainTemplate isMenuButtonVisible>
      <TopContainer>
        <AuthSurveyInterceptor />
      </TopContainer>
    </MainTemplate>
  );
};

const TopContainer = styled(Container)`
  background-color: #f8f8f8;
  padding-top: 40px;
  padding-bottom: 40px;
  min-height: 50vh;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  @media (min-width: 1024px) {
    min-height: calc(100vh - 380px);
    padding-bottom: 64px;
  }
`;
