import React from 'react';
import styled, { css } from 'styled-components';
import Carousel from 'react-multi-carousel';
import { Link } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';

import { IEventListItem } from 'types';

import { ProgramsCarouselItem } from './ProgramsCarouselItem';
import { Dot } from './Dot';
import { ButtonGroup } from './ButtonGroup';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 992 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 992, min: 768 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 768, min: 576 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 576, min: 0 },
    items: 1,
  },
};

interface IProps {
  items?: IEventListItem[];
  itemClickHandler(url: string): void;
  seeAllBtnHandler(): void;
  disableTitle?: boolean;
  bordered?: boolean;
  color?: string;
}

export const ProgramsCarousel = ({
  items = [],
  itemClickHandler,
  seeAllBtnHandler,
  disableTitle = false,
  bordered = false,
  color,
}: IProps) => {
  return (
    <Wrapper>
      {!disableTitle && (
        <BlockTitle>
          <Title>Ближайшие мероприятия</Title>
          <SeeAll onClick={seeAllBtnHandler}>
            Смотреть все <RightOutlined />
          </SeeAll>
          <SeeAllMobile to="/">
            Ближайшие мероприятия <RightOutlined />
          </SeeAllMobile>
        </BlockTitle>
      )}
      <CarouselWrapper
        ssr={false}
        showDots
        arrows={false}
        removeArrowOnDeviceType={['tablet', 'mobile']}
        responsive={responsive}
        customDot={<Dot />}
        renderDotsOutside
        renderButtonGroupOutside
        customButtonGroup={items.length > 0 ? <ButtonGroup /> : null}
        bordered={bordered}
      >
        {items.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <ProgramsCarouselItem color={color} itemClickHandler={itemClickHandler} item={item} key={index} />
        ))}
      </CarouselWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 0;
  position: relative;

  .react-multi-carousel-dot-list {
    position: static;
    display: inline-block;
    text-align: left;
    border-bottom: 1px solid #f6f8fb;
    margin: 0 0 0 25px;
  }
`;

const CarouselWrapper = styled(Carousel)<{ bordered?: boolean }>`
  ${({ bordered }) => css`
    box-shadow: 0 4px 10px rgba(72, 83, 236, 0.03);
    border-radius: 12px;
    overflow: hidden;
    margin: 0 0 34px 0;

    ${bordered &&
    css`
      border: 1px solid #eeeeee;
    `};
  `};
`;

const BlockTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 30px 0;
`;

const Title = styled.div`
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  color: #ffffff;
  display: none;

  @media (min-width: 768px) {
    display: block;
  }

  @media (min-width: 1200px) {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
  }
`;

const SeeAll = styled.span`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  color: #ffffff;
  display: none;
  cursor: pointer;

  &:hover {
    color: #ffffff;
  }

  @media (min-width: 768px) {
    display: block;
  }
`;

const SeeAllMobile = styled(Link)`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;

  @media (min-width: 768px) {
    display: none;
  }
`;
