import React, { useState, useRef, useEffect } from 'react';
import { Input as AntdInput, InputProps } from 'antd';
import styled, { css } from 'styled-components';

export type TTextInputType = 'primary' | 'secondary';

export interface ITextInputProps extends InputProps {
  value?: string;
  type?: TTextInputType;
  caption?: string;
  placeholder?: string;
  required?: boolean;
  isPassword?: boolean;
  errorMsg?: string;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onlyLowerCase?: boolean;
}

export const TextInput = ({
  value = '',
  type = 'primary',
  caption,
  placeholder,
  required = false,
  isPassword = false,
  errorMsg = '',
  className,
  onChange,
  onlyLowerCase,
  ...props
}: ITextInputProps) => {
  const [captionIsOpened, setCaptionIsOpened] = useState(Boolean(value));
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    if (!value && isFocus) {
      setCaptionIsOpened(true);
    } else {
      setCaptionIsOpened(Boolean(value));
    }
  }, [value, isFocus]);

  const input = useRef<any>(null);

  const containerClickHandler = () => {
    if (input) {
      input.current!.focus();
    }
  };

  const containerFocusHandler = () => {
    setCaptionIsOpened(true);
    setIsFocus(true);
  };

  const containerBlurHandler = () => {
    if (!value) setCaptionIsOpened(false);
    setIsFocus(false);
  };

  if (type === 'primary') {
    return (
      <PrimaryTextInputContainer
        className={className}
        isError={!!errorMsg}
        onClick={containerClickHandler}
        tabIndex={0}
        onlyLowerCase={onlyLowerCase}
      >
        {caption && (
          <MovedCaption isOpened={captionIsOpened} required={required}>
            {caption}
          </MovedCaption>
        )}
        {isPassword ? (
          <AntdInput.Password
            ref={input}
            value={value}
            {...props}
            onBlur={containerBlurHandler}
            onChange={onChange}
            onFocus={containerFocusHandler}
          />
        ) : (
          <AntdInput
            ref={input}
            value={value}
            {...props}
            onBlur={containerBlurHandler}
            onChange={onChange}
            onFocus={containerFocusHandler}
            autoCapitalize={onlyLowerCase ? 'off' : undefined}
            autoComplete={onlyLowerCase ? 'off' : undefined}
            autoCorrect={onlyLowerCase ? 'off' : undefined}
            spellCheck="false"
          />
        )}
        {errorMsg && <ErrorContainer>{errorMsg}</ErrorContainer>}
      </PrimaryTextInputContainer>
    );
  }

  return (
    <SecondaryTextInputContainer className={className}>
      {caption && <Caption>{caption}</Caption>}
      {isPassword ? (
        <AntdInput.Password ref={input} value={value} placeholder={placeholder} onChange={onChange} {...props} />
      ) : (
        <AntdInput ref={input} value={value} placeholder={placeholder} onChange={onChange} {...props} />
      )}
      {errorMsg && <ErrorContainer>{errorMsg}</ErrorContainer>}
    </SecondaryTextInputContainer>
  );
};

interface IPrimaryTextInputContainer {
  isError: boolean;
  onlyLowerCase?: boolean;
}

const PrimaryTextInputContainer = styled.div<IPrimaryTextInputContainer>`
  ${({
    theme: { TextInputTextColor, TextInputIconColor, TextInputBorderActiveColor, TextInputErrorTextColor },
    isError,
    onlyLowerCase,
  }) => css`
    && {
      position: relative;
      outline: none;
      padding: 0 0 25px 0;
      margin-bottom: 5px;

      .ant-input-affix-wrapper {
        border: none;
        padding: 0;
        margin: 0;
        outline: none;
        box-shadow: none;

        &:focus {
          box-shadow: none;
        }

        .ant-input-suffix {
          position: absolute;
          right: 0;
          top: 35%;
          font-size: 22px;
        }
      }

      .ant-input {
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        color: ${TextInputTextColor};
        background: #ffffff;
        border: none;
        box-sizing: border-box;
        border-radius: 0;
        height: 51px;
        padding-left: 0;
        padding-bottom: 0px;
        padding-top: 10px;
        border-bottom: 1px solid #dcdcdc;

        ${onlyLowerCase &&
        css`
          text-transform: lowercase;
        `}

        ${isError &&
        `
          border-color: ${TextInputErrorTextColor};
        `}
      }

      &&& .ant-input-group-addon {
        background: #ffffff;
        border: 1px solid #eeeeee;
        border-left: none;
        border-radius: 0 12px 12px 0;
        padding: 0 10px 0 0;

        button {
          border: none;
          box-shadow: none;

          .anticon {
            color: ${TextInputIconColor};
          }
        }
      }

      .ant-input:hover,
      .ant-input-focused {
        box-shadow: none;
        border-color: #eeeeee;
        z-index: auto;
      }

      .ant-input:focus {
        border-bottom: 1px solid ${TextInputBorderActiveColor};
        box-shadow: none;
      }

      @media (min-width: 768px) {
        margin-bottom: 10px;
        .ant-input {
          font-size: 16px;
          height: 53px;
        }
      }
    }
  `}
`;

const SecondaryTextInputContainer = styled.div`
  ${({ theme: { TextInputTextColor, TextInputIconColor } }) => css`
    & {
      position: relative;
      outline: none;
      padding: 0 0 25px 0;

      .ant-input {
        font-family: 'Open Sans', sans-serif;
        color: ${TextInputTextColor};
        background: #ffffff;
        border: 1px solid #eeeeee;
        box-sizing: border-box;
        border-radius: 12px;
        height: 56px;
      }

      &&& .ant-input-group-addon {
        background: #ffffff;
        border: 1px solid #eeeeee;
        border-left: none;
        border-radius: 0 12px 12px 0;
        padding: 0 10px 0 0;

        button {
          border: none;
          box-shadow: none;

          .anticon {
            color: ${TextInputIconColor};
          }
        }
      }

      .ant-input:focus,
      .ant-input:hover,
      .ant-input-focused {
        box-shadow: none;
        border-color: #eeeeee;
        z-index: auto;
      }
    }
  `}
`;

const Caption = styled.div`
  ${({ theme: { TextInputCaptionColor } }) => css`
    & {
      font-family: 'Raleway', sans-serif;
      color: ${TextInputCaptionColor};
      font-weight: 700;
      font-size: 16px;
      line-height: 32px;
      margin: 0 0 6px 0;
    }
  `}
`;

interface IMovedCaption {
  isOpened: boolean;
  required: boolean;
}

const MovedCaption = styled.div<IMovedCaption>`
  ${({ theme: { TextInputCaptionColor, TextInputErrorTextColor }, isOpened, required }) => css`
    & {
      font-family: 'Raleway', sans-serif;
      color: ${TextInputCaptionColor};
      font-weight: 600;
      font-size: 14px;
      line-height: 32px;
      position: absolute;
      left: 0;
      top: 10px;
      z-index: 2;
      transition: font-size 0.1s linear, transform 0.1s linear;

      ${required &&
      `
        &:after {
          display: inline;
          content: '*';
          color: ${TextInputErrorTextColor};
          font-size: 20px;
          vertical-align: top;
          line-height: 20px;
          padding: 0 0 0 3px;
        }
      `}

      ${isOpened &&
      `
        font-size: 14px;
        transform: translate(0, -20px);

      `}

      @media (min-width: 768px) {
        font-size: 16px;
      }
    }
  `}
`;

const ErrorContainer = styled.div`
  ${({ theme: { TextInputErrorTextColor } }) => css`
    & {
      font-family: 'Raleway', sans-serif;
      color: ${TextInputErrorTextColor};
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 10px;
      position: absolute;
      left: 0;
      bottom: 0;

      @media (min-width: 768px) {
        font-size: 12px;
        line-height: 12px;
      }
    }
  `}
`;
