import { IState } from 'core/types';

export const getCatalogCategoriesFromStore = ({ catalog: { categories } }: IState) => categories;
export const getCatalogCategoriesLoadingFromStore = ({ catalog: { categoriesLoading } }: IState) => categoriesLoading;
export const getCatalogCategoriesErrorFromStore = ({ catalog: { categoriesError } }: IState) => categoriesError;

export const getCurrentCatalogCategoryFromStore = ({ catalog: { currentCategoryId } }: IState) => currentCategoryId;

export const getTagsFromStore = ({ catalog: { tags } }: IState) => tags;
export const getTagsLoadingFromStore = ({ catalog: { tagsLoading } }: IState) => tagsLoading;
export const getTagsErrorFromStore = ({ catalog: { tagsError } }: IState) => tagsError;

export const getSearchASCFromStore = ({ catalog: { searchASC } }: IState) => searchASC;

export const getEventsFromStore = ({ catalog: { events } }: IState) => events;
export const getEventsLoadingFromStore = ({ catalog: { eventsLoading } }: IState) => eventsLoading;
export const getEventsErrorFromStore = ({ catalog: { eventsError } }: IState) => eventsError;
export const getEventsTotalFromStore = ({ catalog: { eventsTotal } }: IState) => eventsTotal;

export const getMoreEventsLoadingFromStore = ({ catalog: { moreEventsLoading } }: IState) => moreEventsLoading;
export const getMoreEventsErrorFromStore = ({ catalog: { moreEventsError } }: IState) => moreEventsError;

export const getLastCatalogSearch = ({ catalog }: IState) => catalog.lastSearch;
