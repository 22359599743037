import { SagaIterator } from 'redux-saga';
import { takeEvery, call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { convertEventsToAppSchema } from 'services/apiMappers';
import { eventsService } from 'services';
import {
  getAreaEventsRequestAction,
  getAreaEventsSuccessAction,
  getAreaEventsFailureAction,
  putAreaEventsToStoreAction,
} from 'core/actions';

export function* getAreaEventsSaga({
  payload: { monthIndex, alias },
}: ActionType<typeof getAreaEventsRequestAction>): SagaIterator {
  try {
    const { data } = yield call(() => eventsService.getAreaEvents(monthIndex, alias));
    const events = convertEventsToAppSchema(data.results);
    yield put(getAreaEventsSuccessAction());
    yield put(putAreaEventsToStoreAction({ events }));
  } catch (error) {
    yield put(getAreaEventsFailureAction());
  }
}

export function* areaEventsRootSaga(): SagaIterator {
  yield takeEvery(getAreaEventsRequestAction, getAreaEventsSaga);
}
