import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { Row, Col } from 'antd';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';

import { IPublication } from 'types';
import { PaginatorPublications, PublicationCard } from 'components/molecules';
import { Button } from 'components/atoms';
import { PublicationService } from 'services';

interface IProps {
  result: IPublication[];
  total: number;
  loading: boolean;
  cardOnClick: (id: number) => void;
  loadMoreOnClick: () => void;
  screen: Partial<Record<Breakpoint, boolean>>;
}

export const Results = memo(({ cardOnClick, total, loadMoreOnClick, result, loading, screen }: IProps) => {
  return (
    <Wrapper isLoading={loading}>
      <RowStyled gutter={[15, 40]} justify="space-between">
        {result.map((item) => (
          <Col xs={screen.md ? undefined : 24} key={item.id}>
            <PublicationCard onClick={cardOnClick} {...item} />
          </Col>
        ))}
      </RowStyled>

      <RowStyled2 gutter={[15, 40]} justify={screen.md ? undefined : 'center'}>
        <Col xs={0} md={24}>
          <PaginatorPublications />
        </Col>
        {total - result.length > 0 && (
          <Col xs={24} md={0}>
            <SeeAllButton onClick={loadMoreOnClick} type="ghost">
              Загрузить еще{' '}
              {total - result.length > PublicationService.defaultPublicationsLimit
                ? PublicationService.defaultPublicationsLimit
                : total - result.length}{' '}
              публикаций
            </SeeAllButton>
          </Col>
        )}
      </RowStyled2>
    </Wrapper>
  );
});

const Wrapper = styled.div<{ isLoading: boolean }>`
  ${({ isLoading }) => css`
    margin: 0;
    transition: all 0.2s;
    ${isLoading && WrapperLoadingStyles}
  `}
`;

const RowStyled = styled(Row)`
  &::after {
    content: '';
    width: 384px;
  }
`;

const RowStyled2 = styled(Row)`
  margin-top: 25px;

  @media (min-width: 768px) {
    margin-top: 56px;
  }
`;

const WrapperLoadingStyles = css`
  opacity: 0.5;
  pointer-events: none;
`;

const SeeAllButton = styled(Button)`
  width: 100%;
`;
