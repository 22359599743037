import { createAction } from 'typesafe-actions';

import { IApiUser, IAreasAlias, ServerErrors } from 'types';

import { ACTIONS } from './constants';

export const initAppRequestAction = createAction(ACTIONS.INIT_APP_REQUEST)();
export const initAppSuccessAction = createAction(ACTIONS.INIT_APP_SUCCESS)();
export const initAppFailureAction = createAction(ACTIONS.INIT_APP_FAILURE)();
export const startInitAppLoadingAction = createAction(ACTIONS.START_INIT_APP_LOADING)();
export const endInitAppLoadingAction = createAction(ACTIONS.END_INIT_APP_LOADING)();

export const refreshTokenSuccessAction = createAction(ACTIONS.REFRESH_TOKEN_SUCCESS)();
export const refreshTokenFailureAction = createAction(ACTIONS.REFRESH_TOKEN_FAILURE)();

export const getAreasAliasSuccessAction = createAction(ACTIONS.GET_AREAS_ALIAS_SUCCESS)();
export const getAreasAliasFailureAction = createAction(ACTIONS.GET_AREAS_ALIAS_FAILURE)();
export const putAreasAliasToStoreAction = createAction(ACTIONS.PUT_AREAS_ALIAS_TO_STORE)<{
  areasAlias: IAreasAlias[];
}>();
export const clearAreasAliasToStoreAction = createAction(ACTIONS.CLEAR_AREAS_ALIAS_TO_STORE)();

export const setAppServerError = createAction(ACTIONS.SET_APP_SERVER_ERROR)<{ error: ServerErrors }>();

export const setVisitorStatusAction = createAction(ACTIONS.SET_VISITOR_STATUS_ACTION)<{ isDoctor: boolean }>();

export const userDataRequestAction = createAction(ACTIONS.USERDATA_REQUEST)();
export const userDataSuccessAction = createAction(ACTIONS.USERDATA_SUCCESS)();
export const userDataFailureAction = createAction(ACTIONS.USERDATA_FAILURE)();
export const putUserDataToStoreAction = createAction(ACTIONS.PUT_USERDATA_TO_STORE)<IApiUser | null>();
