import React from 'react';
import Carousel from 'react-multi-carousel';
import styled from 'styled-components';

import { IPublication } from 'types';
import { PublicationCard } from 'components/molecules';

interface IProps {
  items: IPublication[];
  itemOnClick: (id: number) => void;
}

const responsive = {
  extraSuperLargeDesktop: {
    breakpoint: { max: 4000, min: 1200 },
    items: 4,
  },
  superLargeDesktop: {
    breakpoint: { max: 1200, min: 992 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 992, min: 768 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 768, min: 576 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 576, min: 0 },
    items: 1,
  },
};

export const PublicationsCarousel = ({ items = [], itemOnClick }: IProps) => {
  return (
    <CarouselWrapper ssr={false} arrows={false} responsive={responsive} itemClass="carousel-item-padding-30-px">
      {items.map((item) => (
        <PublicationCard key={item.id} onClick={(id) => itemOnClick(id)} {...item} type="fullWidth" />
      ))}
    </CarouselWrapper>
  );
};

const CarouselWrapper = styled(Carousel)`
  .carousel-item-padding-30-px {
    @media (min-width: 576px) {
      padding-right: 15px;
    }
  }
`;
