import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';

import { Container as DefaultContainer } from 'atoms';
import { getHome } from 'core/selectors';

export const AreaSwitcher = () => {
  const { popularAreas } = useSelector(getHome);

  if (!popularAreas.length) {
    return null;
  }

  return (
    <Container>
      <Title>Популярные направления</Title>
      {popularAreas.map(({ alias, shortTitle, title }) => (
        <StyledLink $isActive={false} key={alias} to={`/area/${alias}`}>
          {shortTitle || title}
        </StyledLink>
      ))}
    </Container>
  );
};

const Container = styled(DefaultContainer)`
  padding: 0;
  margin-bottom: 40px;
`;

const Title = styled.h1`
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-size: 18px;
  font-weight: bold;
  line-height: 32px;
  color: #ffffff;
  margin-bottom: 32px;

  @media (min-width: 1200px) {
    font-size: 24px;
  }
`;

const StyledLink = styled(Link)<{ $isActive: boolean }>`
  ${({ $isActive }) => css`
    display: inline-block;
    box-sizing: border-box;
    margin: 4px;
    height: 40px;
    line-height: 40px;
    padding: 0px 16px;
    font-size: 14px;
    background: rgba(255, 255, 255, 0.24);
    color: #ffffff;
    border-radius: 8px;
    font-weight: 700;

    &:hover {
      color: #ffffff;
    }

    ${$isActive && isActiveStyles}

    @media (min-width: 768px) {
      height: 56px;
      line-height: 48px;
      padding: 4px 24px;
      font-size: 16px;
    }
  `}
`;

const isActiveStyles = css`
  background-color: rgba(255, 255, 255);
  color: rgba(72, 83, 236, 1);
  &:hover {
    color: rgba(72, 83, 236, 1);
  }
`;
