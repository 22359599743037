import React from 'react';
import styled, { css } from 'styled-components';

import { Author as AuthorIcon, View as ViewIcon, Video as VideoIcon } from 'assets/icons';
import { IAreaCropped } from 'types';

interface IProps extends IAreaCropped {
  onClick: () => void;
  width: number;
}

export const AreaCard = ({
  logoUrl,
  title,
  description,
  viewsCount,
  videosCount,
  authorsCount,
  width,
  onClick,
}: IProps) => (
  <Container onClick={onClick}>
    <Logo>
      <img src={logoUrl} alt="logo-area" />
    </Logo>
    <Title>{title}</Title>
    <Description>{cropDescription(description, width)}</Description>
    <Statistics>
      <StatisticItem>
        <VideoIcon />
        <StatisticNumber>{videosCount}</StatisticNumber>
      </StatisticItem>
      <StatisticItem>
        <ViewIcon />
        <StatisticNumber>{viewsCount}</StatisticNumber>
      </StatisticItem>
      <StatisticItem>
        <AuthorIcon />
        <StatisticNumber>{authorsCount}</StatisticNumber>
      </StatisticItem>
    </Statistics>
  </Container>
);

const Container = styled.div`
  ${() => css`
    padding: 32px 24px;
    border: 1px solid #eeeeee;
    cursor: pointer;
    box-shadow: 0px 4px 10px rgba(72, 83, 236, 0.08);
    border-radius: 12px;
  `}
`;

const Logo = styled.div`
  display: flex;
  width: 280px;
  height: 104px;
  margin-bottom: 24px;

  img {
    max-height: 100%;
    max-width: 100%;
    margin: auto 0;
  }
`;

const Title = styled.p`
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 32px;
  color: #000000;

  margin-bottom: 8px;
`;

const Description = styled.p`
  height: 75px;

  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;

  margin-bottom: 32px;
`;

const Statistics = styled.div`
  display: flex;
  align-items: center;
`;

const StatisticItem = styled.div`
  display: flex;
  align-items: center;

  margin-left: 32px;
  &:first-child {
    margin-left: 0px;
  }
`;

const StatisticNumber = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #4853ec;

  margin-left: 6px;
`;

function cropDescription(description: string, width: number) {
  if (width > 1200) {
    return description.length > 180 ? `${description.substr(0, 180)} ...` : description;
  }

  if (width > 768) {
    return description.length > 100 ? `${description.substr(0, 100)} ...` : description;
  }

  return description.length > 80 ? `${description.substr(0, 80)} ...` : description;
}
