import React from 'react';
import styled from 'styled-components';
import Carousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';

import { IEventMark } from 'types';
import { ButtonGroup } from 'components/organisms/ProgramsCarousel/ButtonGroup';
import { Container as DefaultContainer } from 'components/atoms';

import { StarRating } from './StarRating';

const responsive = {
  all: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
};

interface IProps {
  items: IEventMark[];
  eventId: number;
  averageMark: number;
}

export const StarsCarousel = ({ items = [], eventId, averageMark }: IProps) => {
  return (
    <ContainerStyled>
      <CarouselWrapper
        ssr={false}
        arrows={false}
        removeArrowOnDeviceType={['tablet', 'mobile']}
        responsive={responsive}
        renderDotsOutside
        renderButtonGroupOutside
        customButtonGroup={items.length > 1 ? <ButtonGroupStyled /> : null}
      >
        {items.map((item) => (
          <StarRating
            key={item.authorId}
            authorId={item.authorId}
            rating={item.mark}
            authorName={item.authorName}
            eventId={eventId}
            averageMark={averageMark}
          />
        ))}
      </CarouselWrapper>
    </ContainerStyled>
  );
};

const ContainerStyled = styled(DefaultContainer)`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #eeeeee;
`;

const ButtonGroupStyled = styled(ButtonGroup)`
  position: relative;
  margin-bottom: 30px;
  align-self: end;
`;

const CarouselWrapper = styled(Carousel)`
  overflow: hidden;
  margin: 0 0 16px 0;
`;
