import { IState } from 'core/types';

// Authors
export const getAuthors = ({ authors }: IState) => ({
  authors: authors.authors,
  total: authors.total,
});
export const getAuthorsPage = ({ authors }: IState) => authors.page;
export const getIsAuthorsLoading = ({ authors }: IState) => authors.authorsLoading;
export const getAuthorsError = ({ authors }: IState) => authors.authorsError;
export const getLastAuthorsSearch = ({ authors }: IState) => authors.lastSearch;

// Author
export const getAuthor = ({ author }: IState) => author.author;
export const getAuthorEvents = ({ author }: IState) => author.authorEvents;
export const getIsAuthorLoading = ({ author: { isAuthorLoading } }: IState) => isAuthorLoading;
