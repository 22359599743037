import React from 'react';
import styled from 'styled-components';
import { Col, Form as AntForm, Row } from 'antd';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { Field, InjectedFormProps, reduxForm, Fields } from 'redux-form';
import { connect } from 'react-redux';

import { Button, FormItem } from 'atoms';
import { ITag } from 'types';
import { store } from 'core';
import { IState } from 'core/types';
import { ICatalogSearchFormValues, setLastCatalogSearchAction } from 'core/actions';

import { SearchString, DatesRange, TagsCheckboxes } from './Fields';

interface IProps {
  // eslint-disable-next-line react/no-unused-prop-types
  handleSubmit: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  handleOnChange: () => void;
  tags: ITag[];
  loading: boolean;
  screen: Partial<Record<Breakpoint, boolean>>;
}

const Form: React.FC<InjectedFormProps<ICatalogSearchFormValues, IProps> & IProps> = ({ tags, screen, loading }) => (
  <StyledForm colon={false}>
    <Row gutter={16} align="bottom">
      <Col xs={screen.md ? undefined : 24}>
        <Fields names={['dateStart', 'dateEnd']} component={DatesRange} />
      </Col>
      <Col xs={screen.md ? undefined : 24} flex={1}>
        <Field name="searchString" component={SearchString} />
      </Col>
      <Col xs={screen.md ? undefined : 24}>
        <FormItem>
          <Button htmlType="submit" loading={loading} block>
            Найти {screen.xl && 'видео материал'}
          </Button>
        </FormItem>
      </Col>
    </Row>
    <TagsWrapper>
      <Field name="checkedTags" component={TagsCheckboxes} allTags={tags} />
    </TagsWrapper>
  </StyledForm>
);

const mapStateToProps = (state: IState) => {
  return {
    initialValues: state.catalog.lastSearch,
  };
};

export const CatalogForm = connect(mapStateToProps)(
  reduxForm<ICatalogSearchFormValues, IProps>({
    form: 'catalogSearchForm',
    onChange: (values, dispatch, props) => {
      store.dispatch(setLastCatalogSearchAction(values));
      props.handleOnChange();
    },
    onSubmit: (values: any, dispatch: any, props: { handleSubmit: () => void }) => {
      props.handleSubmit();
    },
  })(Form),
);

const StyledForm = styled(AntForm)`
  padding: 10px 0;

  @media (min-width: 768px) {
    padding: 50px 0;
    flex-direction: row;
  }
`;

const TagsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
