import { Socials } from 'types';

export const socials = [
  {
    type: Socials.facebook,
    link: 'https://www.facebook.com/',
  },
  {
    type: Socials.vk,
    link: 'https://vk.com/',
  },
  {
    type: Socials.twitter,
    link: 'https://twitter.com/',
  },
  {
    type: Socials.youtube,
    link: 'https://www.youtube.com/',
  },
  {
    type: Socials.instagram,
    link: 'https://www.instagram.com/',
  },
  {
    type: Socials.telegram,
    link: 'https://telegram.org/',
  },
];
