import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useParams } from 'react-router-dom';

import {
  getSearchASCFromStore,
  getEventsFromStore,
  getEventsLoadingFromStore,
  getEventsTotalFromStore,
  getCatalogCategoriesFromStore,
} from 'core/selectors';
import {
  getCatalogEventsRequestAction,
  getMoreCatalogEventsRequestAction,
  putSearchASCToStoreAction,
} from 'core/actions';
import { CatalogSearchASC } from 'types';

import { Results } from './Results';

export const ResultsContainer = () => {
  const dispatch = useDispatch();
  const screen = useBreakpoint();
  const { area } = useParams<{ area?: string }>();

  const categories = useSelector(getCatalogCategoriesFromStore);
  const { category } = useParams<{ category?: string }>();
  const searchASC = useSelector(getSearchASCFromStore);
  const total = useSelector(getEventsTotalFromStore);
  const result = useSelector(getEventsFromStore);
  const eventsLoading = useSelector(getEventsLoadingFromStore);

  const searchAscOnChange = useCallback(
    (newSearchASC: CatalogSearchASC) => {
      dispatch(putSearchASCToStoreAction({ asc: newSearchASC }));
    },
    [dispatch],
  );

  const loadMoreOnClick = useCallback(() => {
    dispatch(getMoreCatalogEventsRequestAction({ alias: area }));
  }, [dispatch, area]);

  useEffect(() => {
    if (categories.length) dispatch(getCatalogEventsRequestAction({ alias: area }));
  }, [dispatch, searchASC, categories, category, area]);

  return (
    <Results
      searchASC={searchASC}
      result={result}
      total={total}
      loading={eventsLoading}
      screen={screen}
      searchAscOnChange={searchAscOnChange}
      loadMoreOnClick={loadMoreOnClick}
    />
  );
};
