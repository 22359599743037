import { SagaIterator } from 'redux-saga';
import { takeLatest, call, put, select, SagaReturnType } from 'redux-saga/effects';
import { getFormValues } from 'redux-form';
import { ActionType } from 'typesafe-actions';

import {
  convertAuthorsToAppSchema,
  convertAuthorExtendedToAppSchema,
  convertEventsToAppSchema,
} from 'services/apiMappers';
import { AuthorService, eventsService } from 'services';
import {
  getAuthorsRequestAction,
  getMoreAuthorsRequestAction,
  getAuthorsSuccessAction,
  putAuthorsToStoreAction,
  getAuthorsFailureAction,
  getAuthorRequestAction,
  getAuthorSuccessAction,
  putAuthorToStoreAction,
  getAuthorFailureAction,
  getAuthorEventsRequestAction,
  getAuthorEventsFailureAction,
  putAuthorEventsToStoreAction,
  getAuthorEventsSuccessAction,
} from 'core/actions';
import { AuthorsPageType, AuthorsType } from 'core/types/selectorsTypes';
import { getAuthors, getAuthorsPage } from 'core/selectors';
import { CatalogSearchASC } from 'types';

type AuthorsResult = SagaReturnType<typeof AuthorService.getAuthors>;
function* getAuthorsWorker({ payload: { alias } }: ActionType<typeof getAuthorsRequestAction>): SagaIterator {
  try {
    const formValues = yield select(getFormValues('authorsSearchForm'));
    const { searchString, areaSelect } = formValues || {};
    const page: AuthorsPageType = yield select(getAuthorsPage);

    const { results, total }: AuthorsResult = yield call(() =>
      AuthorService.getAuthors({
        alias: alias || areaSelect,
        searchString,
        offset: page,
      }),
    );
    const authorsResult = convertAuthorsToAppSchema(results);

    yield put(getAuthorsSuccessAction());
    yield put(putAuthorsToStoreAction({ authors: authorsResult, total }));
  } catch (error) {
    yield put(getAuthorsFailureAction());
  }
}

function* getMoreAuthorsWorker({ payload: { alias } }: ActionType<typeof getMoreAuthorsRequestAction>): SagaIterator {
  try {
    const formValues = yield select(getFormValues('authorsSearchForm'));
    const { searchString, areaSelect } = formValues || {};
    const { authors: oldAuthors }: AuthorsType = yield select(getAuthors);

    const { results, total }: AuthorsResult = yield call(() =>
      AuthorService.getAuthors({
        alias: alias || areaSelect,
        searchString,
        offset: Math.ceil(oldAuthors.length / AuthorService.defaultAuthorsLimit) + 1,
      }),
    );
    const authorsResult = convertAuthorsToAppSchema(results);

    yield put(getAuthorsSuccessAction());
    yield put(putAuthorsToStoreAction({ authors: [...oldAuthors, ...authorsResult], total }));
  } catch (error) {
    yield put(getAuthorsFailureAction());
  }
}

type Author = SagaReturnType<typeof AuthorService.getAuthor>;
function* getAuthorWorker({ payload: { id } }: ActionType<typeof getAuthorRequestAction>): SagaIterator {
  try {
    const authorApi: Author = yield call(() =>
      AuthorService.getAuthor({
        id,
      }),
    );
    const author = convertAuthorExtendedToAppSchema(authorApi);

    yield put(getAuthorSuccessAction());
    yield put(putAuthorToStoreAction({ author }));
  } catch (error) {
    yield put(getAuthorFailureAction());
  }
}

type Events = SagaReturnType<typeof eventsService.getEvents>;
function* getAuthorEventsWorker({
  payload: { id, area },
}: ActionType<typeof getAuthorEventsRequestAction>): SagaIterator {
  try {
    const { results }: Events = yield call(() =>
      eventsService.getEvents({
        authorId: [Number(id)],
        alias: area,
        searchASC: CatalogSearchASC.start_date,
      }),
    );
    const events = convertEventsToAppSchema(results);

    yield put(getAuthorEventsSuccessAction());
    yield put(putAuthorEventsToStoreAction({ events }));
  } catch (error) {
    yield put(getAuthorEventsFailureAction());
  }
}

export function* authorsRootSaga(): SagaIterator {
  yield takeLatest(getAuthorsRequestAction, getAuthorsWorker);
  yield takeLatest(getMoreAuthorsRequestAction, getMoreAuthorsWorker);
  yield takeLatest(getAuthorRequestAction, getAuthorWorker);
  yield takeLatest(getAuthorEventsRequestAction, getAuthorEventsWorker);
}
