import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getPublicationsCategoriesFromStore } from 'core/selectors';
import { getPublicationsCategoriesRequestAction, putCurrentPublicationsCategoryToStoreAction } from 'core/actions';

import { Categories } from './Categories';

export const PublicationsCategoriesContainer = () => {
  const dispatch = useDispatch();
  const categories = useSelector(getPublicationsCategoriesFromStore);
  const { area, category } = useParams<{ area?: string; category?: string }>();
  const history = useHistory();

  useEffect(() => {
    dispatch(getPublicationsCategoriesRequestAction({ alias: area }));
  }, [dispatch, area]);

  useEffect(() => {
    if (categories.length) {
      const currentPublicationCategory = categories.find((item) => item.alias === category)?.id;

      if (currentPublicationCategory) {
        dispatch(
          putCurrentPublicationsCategoryToStoreAction({
            id: currentPublicationCategory,
          }),
        );
      } else {
        dispatch(
          putCurrentPublicationsCategoryToStoreAction({
            id: undefined,
          }),
        );
        history.push(area ? `/area/${area}/publications/all` : `/publications/all`);
      }
    }
  }, [dispatch, categories, category, history, area]);

  return (
    <Categories
      categories={categories.map((c) => ({
        ...c,
        to: area ? `/area/${area}/publications/${c.alias}` : `/publications/${c.alias}`,
      }))}
      activeCategory={category}
      defaultTo={area ? `/area/${area}/publications/all` : `/publications/all`}
    />
  );
};
