import { SagaIterator } from 'redux-saga';
import { takeEvery, call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { convertEventsToAppSchema } from 'services/apiMappers';
import { eventsService } from 'services';
import {
  getHomeEventsRequestAction,
  getHomeEventsSuccessAction,
  getHomeEventsFailureAction,
  putHomeEventsToStoreAction,
} from 'core/actions';

export function* getHomeEventsSaga({
  payload: { monthIndex, alias },
}: ActionType<typeof getHomeEventsRequestAction>): SagaIterator {
  try {
    const { data } = yield call(() => eventsService.getAreaEvents(monthIndex, alias));
    const events = convertEventsToAppSchema(data.results);
    yield put(getHomeEventsSuccessAction());
    yield put(putHomeEventsToStoreAction({ events }));
  } catch (error) {
    yield put(getHomeEventsFailureAction());
  }
}

export function* homeEventsRootSaga(): SagaIterator {
  yield takeEvery(getHomeEventsRequestAction, getHomeEventsSaga);
}
