import React from 'react';
import { useSelector } from 'react-redux';

import { getEvent } from 'core/selectors';

import { Vimeo } from './Vimeo';
import { Youtube } from './Youtube';
import { Facecast } from './Facecast';

export const Video = () => {
  const { videoUrl, id: eventId } = useSelector(getEvent);

  if (!videoUrl || !eventId) {
    return null;
  }

  if (videoUrl.includes('vimeo')) {
    return <Vimeo videoUrl={videoUrl} eventId={eventId} />;
  }

  if (videoUrl.includes('youtube')) {
    return <Youtube videoUrl={videoUrl} eventId={eventId} />;
  }

  if (videoUrl.includes('facecast')) {
    return <Facecast videoUrl={videoUrl} />;
  }

  return null;
};
