import React from 'react';
import styled, { css } from 'styled-components';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

interface IProps {
  next: () => void;
  prev: () => void;
  activePage: number;
  pagesCount: number;
  className?: string;
}

export const PageSwitchers = ({ next, prev, activePage, pagesCount, className }: IProps) => {
  return (
    <Wrapper className={className}>
      <Button disabled={activePage === 1} onClick={prev}>
        <LeftOutlined />
      </Button>
      <Button disabled={activePage === pagesCount} onClick={next}>
        <RightOutlined />
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Button = styled.button`
  ${({ theme: { primaryBtnActiveBgColor } }) => css`
    background: #ffffff;
    border-radius: 150px;
    border: none;
    outline: none;
    cursor: pointer;
    color: #a7a8ab;
    height: 50px;
    width: 50px;
    transition: color, background-color 0.1s linear;
    margin: 0 0 0 15px;
    display: inline-block;

    &:hover {
      background-color: ${primaryBtnActiveBgColor};
      color: #ffffff;
    }

    &.disable:hover {
      background-color: #6972ec;
      color: #ffffff;
    }
  `}
`;
