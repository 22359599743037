import React from 'react';
import { Tag as AntdTag } from 'antd';
import styled, { css } from 'styled-components';

import { InterestingIcon } from 'assets/icons';

export type TTagSize = 'large' | 'small';

interface IProps {
  size?: TTagSize;
}

export const InterestingTag = ({ size = 'large', ...props }: IProps) => (
  <Container size={size}>
    <AntdTag {...props} icon={<InterestingIcon />} />
  </Container>
);

const Container = styled.div<{ size?: TTagSize }>`
  ${({ size }) => css`
    display: inline-block;

    & {
      .ant-tag {
        border: none;
        color: #c9a600;
        background-color: #ffee99;
        margin-right: 8px;
        box-sizing: border-box;

        &:hover {
          opacity: 1;
        }
      }

      ${size === 'large' &&
      css`
        .ant-tag {
          width: 40px;
          height: 40px;
          padding: 8px;
          border-radius: 8px;
        }
      `}

      ${size === 'small' &&
      css`
        .ant-tag {
          width: 28px;
          height: 28px;
          padding: 6px;
          border-radius: 4px;
        }
      `}
    }
  `}
`;
