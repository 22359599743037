import { IState } from 'core/types';

export const getRegister = ({ auth }: IState) => auth.register;
export const getIsRegisterLoading = ({ auth }: IState) => auth.isRegisterLoading;
export const getRegisterStep = ({ auth }: IState) => auth.registerStep;

export const getAuthPopupsVisible = ({ auth }: IState) => auth.popupsVisible;

export const getIsLoggedIn = ({ auth: { isLoggedIn } }: IState) => isLoggedIn;
export const getIsLoginLoading = ({ auth: { isLoginLoading } }: IState) => isLoginLoading;

export const getIsForgotPasswordLoading = ({ auth: { isForgotPasswordLoading } }: IState) => isForgotPasswordLoading;

export const getCreatePasswordToken = ({ auth: { createPasswordToken } }: IState) => createPasswordToken;
export const getIsCreatePasswordLoading = ({ auth: { isCreatePasswordLoading } }: IState) => isCreatePasswordLoading;

export const getAuthServerError = ({ auth: { authServerError } }: IState) => authServerError;
export const getCodeError = ({ auth: { codeError } }: IState) => codeError;
export const getEmail = ({ auth: { register } }: IState) => register.firstStep.email;
export const getEmailExists = ({ auth: { exsists } }: IState) => exsists;

export const getIsAppSiderCollapsed = ({ auth: { isAppSiderCollapsed } }: IState) => isAppSiderCollapsed;

export const getPhone = ({ auth: { phone } }: IState) => phone;
