import { SagaIterator } from 'redux-saga';
import { takeEvery, call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { eventsService } from 'services';
import {
  getSurveysSuccessAction,
  getSurveysFailureAction,
  getSurveysRequestAction,
  saveSurveyToStoreAction,
  setSurveyAnswersRequestAction,
  setSurveyAnswersSuccessAction,
  setSurveyAnswersFailureAction,
  getAllSurveysRequestAction,
  saveAllSurveysToStoreAction,
  setSurveyCustomAnswerRequestAction,
  setSurveyCustomAnswerSuccessAction,
} from 'core/actions';
import { IApiSurveys } from 'types';
import { convertApiSurveysToAppSchema } from 'services/apiMappers';

function* getAllSurveysSaga({ payload }: ActionType<typeof getAllSurveysRequestAction>): SagaIterator {
  try {
    const data: IApiSurveys = yield call(() =>
      eventsService.getQuestions({
        ...(payload.page && { page: payload.page }),
      }),
    );

    const convertedData = convertApiSurveysToAppSchema(data);

    yield put(saveAllSurveysToStoreAction(convertedData));
    yield put(getSurveysSuccessAction());
  } catch (error) {
    yield put(getSurveysFailureAction());
  }
}

function* getSurveysSaga({ payload }: ActionType<typeof getSurveysRequestAction>): SagaIterator {
  try {
    const dto = {
      ...(payload.isEvent && { isEvent: payload.isEvent }),
      ...(payload.eventId && { eventId: payload.eventId }),
      ...(payload.surveyIds?.length && { surveyIds: payload.surveyIds }),
    };
    const data: IApiSurveys = yield call(() => eventsService.getQuestions(dto));

    const convertedData = convertApiSurveysToAppSchema(data);

    if (payload.eventId) {
      yield put(saveAllSurveysToStoreAction(convertedData));
    }

    if (convertedData.results.length) {
      yield put(saveSurveyToStoreAction(convertedData.results[0]));
    }
    yield put(getSurveysSuccessAction());
  } catch (error) {
    yield put(getSurveysFailureAction());
  }
}

export function* setSurveyAnswersSaga({
  payload: { surveyId, surveyDto },
}: ActionType<typeof setSurveyAnswersRequestAction>): SagaIterator {
  try {
    yield call(() => eventsService.setSurveyAnswers(surveyId, surveyDto));

    yield put(setSurveyAnswersSuccessAction());
  } catch (error: any) {
    // TODO move errors handler to helper
    const errorMessageText = 'Опрос пройден Вами ранее';

    yield put(setSurveyAnswersFailureAction(errorMessageText));
  }
}

export function* setSurveyCustomAnswerSaga({
  payload: { customAnswerDto },
}: ActionType<typeof setSurveyCustomAnswerRequestAction>): SagaIterator {
  try {
    yield call(() => eventsService.setCustomSurveyAnswer(customAnswerDto));

    yield put(setSurveyCustomAnswerSuccessAction());
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
  }
}

export function* surveysRootSaga(): SagaIterator {
  yield takeEvery(getAllSurveysRequestAction, getAllSurveysSaga);
  yield takeEvery(getSurveysRequestAction, getSurveysSaga);
  yield takeEvery(setSurveyAnswersRequestAction, setSurveyAnswersSaga);
  yield takeEvery(setSurveyCustomAnswerRequestAction, setSurveyCustomAnswerSaga);
}
