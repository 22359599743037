import {
  IApiPublicationCategory,
  IPublicationCategory,
  IApiPublication,
  IPublication,
  IApiPublicationExtended,
  IPublicationExtended,
} from 'types';

import { convertAuthorsToAppSchema } from './authors';

export const convertPublicationCategoryToAppSchema = (category: IApiPublicationCategory): IPublicationCategory => {
  return {
    id: category.id,
    title: category.title,
    alias: category.alias,
    areaTitle: category.area_title,
    shortTitle: category.short_title,
  };
};

export const convertPublicationsCategoriesToAppSchema = (
  categories: IApiPublicationCategory[],
): IPublicationCategory[] =>
  categories.map((item) => ({
    id: item.id,
    title: item.title,
    alias: item.alias,
    areaTitle: item.area_title,
    shortTitle: item.short_title,
  }));

export const convertPublicationsToAppSchema = (publications: IApiPublication[]): IPublication[] => {
  return publications.map((item) => ({
    id: item.id,
    imageUrl: item.thumbnail_url,
    category: convertPublicationCategoryToAppSchema(item.category),
    area: {
      title: item.area.title,
      alias: item.area.alias,
      shortTitle: item.area.short_title,
    },
    title: item.title,
    authors: convertAuthorsToAppSchema(item.authors),
    date: item.publish_date,
    viewCount: item.total_viewing,
  }));
};

export const convertPublicationToAppSchema = (apiPublication: IApiPublicationExtended): IPublicationExtended => ({
  id: apiPublication.id,
  imageUrl: apiPublication.thumbnail_url,
  title: apiPublication.title,
  date: apiPublication.publish_date,
  viewCount: apiPublication.total_viewing,
  description: apiPublication.description,
  shortDescription: apiPublication.short_description,
  quote: {
    duty: apiPublication.quote_author_position,
    surname: apiPublication.quote_author_full_name,
    text: apiPublication.quote_text,
  },
  area: {
    alias: apiPublication.area.alias,
    title: apiPublication.area.title,
    shortTitle: apiPublication.area.short_title,
  },
  category: convertPublicationCategoryToAppSchema(apiPublication.category),
  authors: convertAuthorsToAppSchema(apiPublication.authors),
  sourceName: apiPublication.source_name,
  sourceLink: apiPublication.source_url,
  pdfLink: apiPublication.pdf_url,
  recommended_articles: convertPublicationsToAppSchema(apiPublication.recommended_articles),
});
