import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Modal as AntModal, ModalProps } from 'antd';

import { Button } from 'components/atoms';
import { setVisitorStatusAction } from 'core/actions';
import { setCookies } from 'helpers';

interface IModalProps extends ModalProps {
  isOpen: boolean;
}

export const AccessModal = ({ isOpen }: IModalProps) => {
  const dispatch = useDispatch();

  const onGetAccess = () => {
    dispatch(setVisitorStatusAction({ isDoctor: true }));
    setCookies('IS_DOCTOR', 'true');
  };

  const onDenyAccess = () => {
    dispatch(setVisitorStatusAction({ isDoctor: false }));
    setCookies('IS_DOCTOR', 'false');
  };

  return (
    <ModalStyled
      centered
      destroyOnClose
      visible={isOpen}
      onCancel={onDenyAccess}
      onOk={onGetAccess}
      okText="Да"
      cancelText="Нет"
      closable={false}
      keyboard={false}
      footer={null}
      maskStyle={{ backgroundColor: '#121a7b', opacity: '0.64' }}
    >
      <ModalTitle>Вы являетесь специалистом здравохранения?</ModalTitle>
      <ModalText>Данный сайт предназначен только для действующих специалистов в области здравоохранения.</ModalText>
      <ButtonsWrapper>
        <Button type="default" onClick={onDenyAccess}>
          Нет
        </Button>
        <Button onClick={onGetAccess}>Да</Button>
      </ButtonsWrapper>
    </ModalStyled>
  );
};

const ModalStyled = styled(AntModal)`
  @media (max-width: 767px) {
    // need to fix bug in antd with screens width <768px
    width: unset !important;
  }

  .ant-modal-content {
    color: #000000;
    border-radius: 12px;
    padding: 8px 0px;
    width: 296px;
    max-width: 92.5vw;

    @media (min-width: 768px) {
      padding: 16px 8px;
      width: 548px;
    }

    @media (min-width: 1200px) {
      padding: 40px 46px;
      width: 624px;
    }
  }
`;

const ModalTitle = styled.div`
  font-family: 'Raleway';
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 40px;
  }

  @media (min-width: 1200px) {
    max-width: 484px;
    font-size: 32px;
    line-height: 48px;
  }
`;

const ModalText = styled.div`
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 24px;
  margin-top: 16px;

  @media (min-width: 1200px) {
    font-size: 16px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-top: 40px;

  & > button {
    width: 100%;

    &:first-of-type {
      margin-top: 16px;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 48px;

    & > button {
      width: calc(50% - 8px);

      &:first-of-type {
        margin-top: 0px;
      }
    }
  }

  @media (min-width: 1200px) {
    font-size: 32px;
    line-height: 48px;
  }
`;
