import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { IEventListItem } from 'types';

import { OtherEventsItem } from './OtherEventsItem';

interface IProps {
  events: IEventListItem[];
}

export const OtherEvents = ({ events }: IProps) => {
  const { area } = useParams<{ area?: string }>();

  return (
    <Wrapper>
      <List>
        {events.map((event) => (
          <OtherEventsItem key={event.id} event={event} area={area} />
        ))}
      </List>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;

  @media (min-width: 768px) {
    justify-content: center;
  }

  @media (min-width: 1200px) {
    justify-content: flex-start;
  }
`;

const List = styled.div`
  box-shadow: 0 4px 10px rgba(72, 83, 236, 0.03);
  border-radius: 12px;
  overflow: hidden;
  margin: 0 0 34px 0;

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    & > div {
      width: 416px;
    }
  }

  @media (min-width: 1200px) {
    justify-content: flex-start;
    & > div {
      width: 280px;
    }
  }
`;
