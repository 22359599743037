import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { resetSurveyAction } from 'core/actions';
import { getSurveyAnswerStatus, getSurveyData } from 'core/selectors';
import { eventsService } from 'services';

import { StepWrapper } from './StepWrapper';

interface IFinalStepProps {
  isEvent?: boolean;
  callback?: () => void;
}

export const FinalStep = ({ isEvent, callback }: IFinalStepProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { surveyAnswersStatus } = useSelector(getSurveyAnswerStatus);
  const { surveyData } = useSelector(getSurveyData);

  useEffect(() => {
    return () => {
      dispatch(resetSurveyAction());
    };
  }, [dispatch]);

  const hideSurvey = () => {
    if (surveyData) {
      eventsService.verifyEventSurvey(surveyData.id);
    }

    if (callback) {
      callback();
    }
  };

  const goToMainPage = () => {
    history.push('/');
  };

  return (
    <StepWrapperStyled $isEvent={isEvent}>
      <TextContentWrapper>
        <TitleStyled>{surveyAnswersStatus?.message || ''}</TitleStyled>
      </TextContentWrapper>
      <ButtonStyled onClick={isEvent ? hideSurvey : goToMainPage}>
        {isEvent ? 'Вернуться к просмотру' : 'Перейти на главную'}
      </ButtonStyled>
    </StepWrapperStyled>
  );
};

const StepWrapperStyled = styled(StepWrapper)<{ $isEvent?: boolean }>`
  background: linear-gradient(146deg, #121a7b 0%, #4853ec 100%);

  ${({ $isEvent }) =>
    $isEvent &&
    `
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  `}
`;

const TextContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const TitleStyled = styled.div`
  color: #ffffff;
  text-align: center;
  font-family: Raleway;
  font-size: 18px;
  font-weight: 900;
  line-height: 28px;
  margin-top: 16px;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 40px;
    margin-top: 32px;
  }

  @media (min-width: 1024px) {
    font-size: 48px;
    font-weight: 800;
    line-height: 56px;
  }
`;

const ButtonStyled = styled.button`
  border-radius: 8px;
  background-color: #ff8686;
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.4px;
  border: none;
  cursor: pointer;
  margin-top: 32px;

  @media (min-width: 768px) {
    margin-top: 48px;
  }
`;
