import React from 'react';
import { useSelector } from 'react-redux';

import { getArea } from 'core/selectors';

import { Socials } from './Socials';

export const Container = () => {
  const { socialNetworks, color } = useSelector(getArea);

  if (!socialNetworks.length) {
    return null;
  }

  return <Socials socials={socialNetworks} color={color || undefined} />;
};
