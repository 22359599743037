import React, { useCallback, useEffect, useRef, useState } from 'react';
import PlayerVimeo from '@vimeo/player';

interface IProps {
  video: string;
  onPlaybackRateChange?: (event: { playbackRate: number }) => void;
  onTimeUpdate?: () => void;
  onEnd?: () => void;
  onPause?: () => void;
  onSeeking?: () => void;
  onSeeked?: () => void;
  onBufferStart?: () => void;
  onBufferEnd?: () => void;
}

export const ReactVimeo = ({
  video,
  onPlaybackRateChange,
  onTimeUpdate,
  onEnd,
  onPause,
  onSeeking,
  onBufferStart,
  onBufferEnd,
  onSeeked,
}: IProps) => {
  const ref = useRef<HTMLIFrameElement>(null);
  const [vimeoApi, setVimeoApi] = useState<null | PlayerVimeo>(null);

  const onReady = useCallback(() => {
    vimeoApi?.pause();
  }, [vimeoApi]);

  useEffect(() => {
    if (ref.current) {
      setVimeoApi(new PlayerVimeo(ref.current, { responsive: true }));
    }
  }, [video]);

  useEffect(() => {
    if (vimeoApi) {
      vimeoApi.on('loaded', onReady);
      if (onTimeUpdate) vimeoApi.on('timeupdate', onTimeUpdate);
      if (onSeeking) vimeoApi.on('seeking', onSeeking);
      if (onPlaybackRateChange) vimeoApi.on('playbackratechange', onPlaybackRateChange);
      if (onBufferEnd) vimeoApi.on('bufferend', onBufferEnd);
      if (onBufferStart) vimeoApi.on('bufferstart', onBufferStart);
      if (onEnd) vimeoApi.on('ended', onEnd);
      if (onPause) vimeoApi.on('pause', onPause);
      if (onSeeked) vimeoApi.on('seeked', onSeeked);

      return () => {
        vimeoApi.off('loaded', onReady);
        if (onTimeUpdate) vimeoApi.off('timeupdate', onTimeUpdate);
        if (onSeeking) vimeoApi.off('seeking', onSeeking);
        if (onPlaybackRateChange) vimeoApi.off('playbackratechange', onPlaybackRateChange);
        if (onBufferEnd) vimeoApi.off('bufferend', onBufferEnd);
        if (onBufferStart) vimeoApi.off('bufferstart', onBufferStart);
        if (onEnd) vimeoApi.off('ended', onEnd);
        if (onPause) vimeoApi.off('pause', onPause);
        if (onSeeked) vimeoApi.off('seeked', onSeeked);
      };
    }

    return undefined;
  }, [
    vimeoApi,
    onTimeUpdate,
    onSeeking,
    onPlaybackRateChange,
    onBufferEnd,
    onBufferStart,
    onEnd,
    onPause,
    onSeeked,
    onReady,
  ]);

  useEffect(() => {
    if (vimeoApi) {
      const visibilitychange = () => {
        if (document.hidden) {
          vimeoApi.pause();
        }
      };

      document.addEventListener('visibilitychange', visibilitychange);
      return () => document.removeEventListener('visibilitychange', visibilitychange);
    }

    return undefined;
  }, [vimeoApi]);

  return (
    <iframe
      src={video}
      ref={ref}
      frameBorder="0"
      allow="fullscreen; picture-in-picture"
      allowFullScreen
      title="video"
    />
  );
};
