import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import { ServerError as ServerErrorIcon } from 'assets/icons';
import { setAppServerError } from 'core/actions';
import { MainTemplate } from 'components/templates';
import { ServerErrors } from 'types';
import { getServerError } from 'core/selectors';

export const ServerError = memo(() => {
  const [isRedirect, setIsRedirect] = useState(true);
  const dispatch = useDispatch();
  const query = useQuery();
  const serverError = useSelector(getServerError);

  useEffect(() => {
    if (serverError === ServerErrors.None) {
      return undefined;
    }

    setIsRedirect(false);
    dispatch(
      setAppServerError({
        error: ServerErrors.None,
      }),
    );

    return undefined;
  }, [dispatch, serverError]);

  if (serverError === ServerErrors.None && isRedirect) {
    return <Redirect to={{ pathname: `${decodeURIComponent(query.get('backUrl') ?? '')}` }} push />;
  }

  return (
    <MainTemplate noFooter noHeader>
      <Container>
        <ServerErrorIcon />

        <Text>Ведутся технические работы</Text>

        <Description>Приносим свои извинения за временные неудобства. Спасибо за понимание</Description>
      </Container>
    </MainTemplate>
  );
});

const useQuery = () => new URLSearchParams(useLocation().search);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 100vh;

  svg {
    margin: 0 0 0 0px;
    max-width: 500px;
    width: 100%;
    padding: 0 20px;
  }

  @media (min-width: 576px) {
    svg {
      margin: 0 0 20px 0;
    }
  }

  @media (min-width: 768px) {
    svg {
      margin: 0 0 0 0;
    }
  }
`;

const Text = styled.p`
  font-family: 'Raleway', sans-serif;
  font-weight: 900;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #231f20;

  margin: 10px 0 0 0;

  @media (min-width: 768px) {
    margin: 40px 0 0 0;
    font-size: 40px;
    line-height: 56px;
  }
`;

const Description = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  text-align: center;
  max-width: 490px;

  margin: 16px 0 36px 0;

  @media (min-width: 768px) {
    margin: 16px 0 56px 0;
  }
`;
