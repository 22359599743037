import moment from 'moment';

const _emailLanguageRegExp = /^[A-z._@\s\-\d]+$/;
const _emailRegExp = /(^|\s+)[\w\-.]+@([\w-]+\.)+[\w-]{2,4}($|\s+)/;
const _dateRegExP = /^([0-2][0-9]|(3)[0-1])(\.)(((0)[0-9])|((1)[0-2]))(\.)\d{4}$/;
const _passwordLanguageRegExp = /^[A-z\d]+$/;
const _passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{0,}$/;
// const _phoneRegExp = /^(\+)?[\s-]?\(?[0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/;

const REQUIRED_FIELD = 'Заполните обязательные поля';
const WRONG_MIN_LENGTH = (min: number) => `Ограничение на ввод менее ${min} символов`;
const WRONG_MAX_LENGTH = (max: number) => `Ограничение на ввод более ${max} символов`;
const WRONG_NAME = 'Имя должно быть не больше 60 символов';
const ENTER_EMAIL = 'Введите почту';
const WRONG_EMAIL_LANGUAGE = 'Почта должна содержать только латинские буквы';
const WRONG_EMAIL = 'Почта должна содержать специальный символ @ и домен';
const WRONG_EMAIL_LENGTH = 'Ограничение на ввод более 40 символов';
// const WRONG_PHONE = 'Номер должен быть в формате +375 (XX) XXX-XX-XX';
const WRONG_PHONE = 'Проверьте корректность введенного номера';
const ENTER_PASSWORD = 'Введите пароль';
const ENTER_REPEATED_PASSWORD = 'Повторите пароль';
const WRONG_PASSWORD_LANGUAGE = 'Пароль должен состоять только из цифр и латинских букв';
const WRONG_PASSWORD_FORMAT = 'Пароль должен содержать не менее 1 цифры, 1 прописной и 1 строчной буквы';
const WRONG_PASSWORD_LENGTH = 'Пароль должен содержать не менее 8 символов и не более 30 символов';
const WRONG_REPEATED_PASSWORD = 'Пароли не совпадают';
const WRONG_DATE_FORMAT = 'Дата должна быть в формате DD.MM.YYYY';

const NOT_ERROR = '';
const MIN_YEAR_BIRTHDAY = 1920;
const INVALID_DATE = 'Неверная дата';

class ValidationService {
  public validateRequired(value: string): string {
    if (!value) {
      return REQUIRED_FIELD;
    }

    return NOT_ERROR;
  }

  public validateLength(value: string, max?: number, min?: number): string {
    if (!value) {
      return REQUIRED_FIELD;
    }

    if (max !== undefined) {
      return value.length > max ? WRONG_MAX_LENGTH(max) : NOT_ERROR;
    }

    if (min !== undefined) {
      return value.length < min ? WRONG_MIN_LENGTH(min) : NOT_ERROR;
    }

    return NOT_ERROR;
  }

  public validateName(name: string): string {
    if (!name) {
      return REQUIRED_FIELD;
    }

    if (name.length > 60) {
      return WRONG_NAME;
    }

    return NOT_ERROR;
  }

  public validateEmail(email: string): string {
    if (!email) {
      return ENTER_EMAIL;
    }

    if (email.length > 40) {
      return WRONG_EMAIL_LENGTH;
    }

    if (!_emailLanguageRegExp.test(email)) {
      return WRONG_EMAIL_LANGUAGE;
    }

    if (!_emailRegExp.test(email)) {
      return WRONG_EMAIL;
    }

    return NOT_ERROR;
  }

  public validateDate(date: string): string {
    const dateObj = moment(date, 'DD.MM.YYYY');
    const now = moment();

    if (!date) {
      return REQUIRED_FIELD;
    }

    if (!_dateRegExP.test(date)) {
      return WRONG_DATE_FORMAT;
    }

    const values = date.split('.');

    const onlyZero = values.some(
      (value) => (value === '00' && value.length === 2) || (value === '0000' && value.length === 4),
    );

    if (onlyZero) {
      return WRONG_DATE_FORMAT;
    }

    if (!dateObj.isValid() || !(dateObj.year() >= MIN_YEAR_BIRTHDAY && dateObj.year() <= now.year())) {
      return INVALID_DATE;
    }

    return NOT_ERROR;
  }

  public validatePhoneNumber(phoneNumber: string): string {
    if (!phoneNumber) {
      return REQUIRED_FIELD;
    }

    if (phoneNumber.length < 7) {
      return WRONG_PHONE;
    }

    // if (!_phoneRegExp.test(phoneNumber)) {
    //   return WRONG_PHONE;
    // }

    return NOT_ERROR;
  }

  public validateCountry(country: string): string {
    if (!country) {
      return REQUIRED_FIELD;
    }

    return NOT_ERROR;
  }

  public validatePassword(password: string): string {
    if (!password) {
      return ENTER_PASSWORD;
    }

    if (!_passwordLanguageRegExp.test(password)) {
      return WRONG_PASSWORD_LANGUAGE;
    }

    if (!_passwordRegExp.test(password)) {
      return WRONG_PASSWORD_FORMAT;
    }

    if (password.length < 8) {
      return WRONG_PASSWORD_LENGTH;
    }

    if (password.length > 30) {
      return WRONG_PASSWORD_LENGTH;
    }

    return NOT_ERROR;
  }

  public validateRepeatedPassword(password: string, repeatedPassword: string): string {
    if (!password) {
      return NOT_ERROR;
    }

    if (!repeatedPassword) {
      return ENTER_REPEATED_PASSWORD;
    }

    if (password !== repeatedPassword) {
      return WRONG_REPEATED_PASSWORD;
    }

    return NOT_ERROR;
  }
}

export const validationService = new ValidationService();
