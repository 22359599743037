import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setViewedMinuteRequestAction } from 'core/actions';
import { PlayerState } from 'types';
import { ReactVimeo } from 'atoms';

interface IProps {
  videoUrl: string;
  eventId: number;
}

export const Vimeo = ({ videoUrl, eventId }: IProps) => {
  const dispatch = useDispatch();
  const [viewedMinutes, setViewedMinutes] = useState(0);
  const [viewedSeconds, setViewedSeconds] = useState(0);
  const [playerState, setPlayerState] = useState<PlayerState>(-1);
  const [playbackRate, setPlaybackRate] = useState<number>(1);

  const onPlaybackRateChange = useCallback(({ playbackRate: newPlaybackRate }: { playbackRate: number }) => {
    setPlaybackRate(newPlaybackRate);
  }, []);

  const onVideoPlaying = useCallback(() => {
    setPlayerState(PlayerState.PLAYING);
  }, []);

  const onVideoPaused = useCallback(() => {
    setPlayerState(PlayerState.PAUSED);
  }, []);

  useEffect(() => {
    if (playerState === PlayerState.PLAYING) {
      const interval = setInterval(() => {
        setViewedSeconds(viewedSeconds + 1);
      }, 1000 / playbackRate);

      return () => {
        clearInterval(interval);
      };
    }

    return undefined;
  }, [viewedSeconds, playerState, playbackRate]);

  useEffect(() => {
    setViewedMinutes(Math.floor(viewedSeconds / 60));
  }, [viewedSeconds]);

  useEffect(() => {
    if (viewedMinutes > 0) {
      dispatch(setViewedMinuteRequestAction(eventId));
    }
  }, [dispatch, viewedMinutes, eventId]);

  return (
    <ReactVimeo
      video={videoUrl}
      onPlaybackRateChange={onPlaybackRateChange}
      onTimeUpdate={onVideoPlaying}
      onEnd={onVideoPaused}
      onPause={onVideoPaused}
      onBufferStart={onVideoPaused}
    />
  );
};
