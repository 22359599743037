import React from 'react';
import { Menu, Dropdown as AntdDropdown } from 'antd';
import styled, { css } from 'styled-components';
import { DownOutlined } from '@ant-design/icons';

interface IOption {
  id: number;
  value: string;
}

interface IProps {
  value?: string;
  defaultValue?: string;
  optionItems: IOption[];
  caption?: string;
  required?: boolean;
  isShowDefaultOption?: boolean;
  errorMsg?: string;
}

export const PrimaryDropdown = ({
  value = '',
  defaultValue = 'Не выбрано',
  optionItems,
  caption,
  required = false,
  isShowDefaultOption = false,
  errorMsg = '',
  ...props
}: IProps) => {
  const selectOptions = [...optionItems];
  if (isShowDefaultOption) {
    selectOptions.push({ id: 0, value: 'Не выбрано' });
  }

  const menu = () => (
    <MenuContainer>
      {optionItems.map((item) => (
        <Menu.Item key={item.id}>{item.value}</Menu.Item>
      ))}
    </MenuContainer>
  );

  return (
    <DropdownContainer isError={!!errorMsg} overlay={menu} trigger={['click']} {...props}>
      <CaptionContainer isError={!!errorMsg}>
        {caption && (
          <MovedCaption isOpened required={required}>
            {caption}
          </MovedCaption>
        )}
        {value || defaultValue}
        <DownIcon />
        {errorMsg && <ErrorContainer>{errorMsg}</ErrorContainer>}
      </CaptionContainer>
    </DropdownContainer>
  );
};

const MenuContainer = styled(Menu)`
  ${({
    theme: { dropDownMenuBgColor, dropDownMenuTextColor, dropDownMenuActiveBgColor, dropDownMenuActiveTextColor },
  }) => css`
    background-color: ${dropDownMenuBgColor};
    box-shadow: 0 4px 8px rgba(136, 149, 158, 0.08);
    border-radius: 12px;
    overflow: hidden;
    padding: 16px 0;

    .ant-dropdown-menu-item {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: ${dropDownMenuTextColor};
      padding: 8px 30px 8px 16px;

      &-active {
        color: ${dropDownMenuActiveTextColor};
        background-color: ${dropDownMenuActiveBgColor};
      }
    }
  `}
`;

interface IDropdownContainer {
  isError: boolean;
}

const DropdownContainer = styled(AntdDropdown)<IDropdownContainer>`
  ${({ theme: { TextInputTextColor, TextInputErrorTextColor, TextInputBorderActiveColor }, isError }) => css`
    position: relative;
    outline: none;
    padding: 0 0 15px 0;
    font-family: 'Open Sans', sans-serif;
    color: ${TextInputTextColor};
    background: #ffffff;
    box-sizing: border-box;
    height: 56px;
    line-height: 56px;
    padding-left: 0;
    border-bottom: 1px solid #dcdcdc;

    ${isError &&
    `
      border-color: ${TextInputErrorTextColor};
    `}

    &.ant-dropdown-open {
      border-bottom: 1px solid ${TextInputBorderActiveColor};
    }
  `}
`;

interface ICaptionContainer {
  isError: boolean;
}

const CaptionContainer = styled.div<ICaptionContainer>`
  width: 100%;
`;

interface IMovedCaption {
  isOpened: boolean;
  required: boolean;
}

const MovedCaption = styled.div<IMovedCaption>`
  ${({ theme: { TextInputCaptionColor, TextInputErrorTextColor }, isOpened, required }) => css`
    & {
      font-family: 'Raleway', sans-serif;
      color: ${TextInputCaptionColor};
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      position: absolute;
      left: 0;
      top: 10px;
      z-index: 2;
      transition: font-size 0.1s linear, transform 0.1s linear;

      ${required &&
      `
        &:after {
          display: inline;
          content: '*';
          color: ${TextInputErrorTextColor};
          font-size: 20px;
          vertical-align: top;
          line-height: 20px;
          padding: 0 0 0 3px;
        }
      `}

      ${isOpened &&
      `
        font-size: 14px;
        transform: translate(0, -20px);

      `}
    }
  `}
`;

const ErrorContainer = styled.div`
  ${({ theme: { TextInputErrorTextColor } }) => css`
    & {
      font-family: 'Raleway', sans-serif;
      color: ${TextInputErrorTextColor};
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  `}
`;

const DownIcon = styled(DownOutlined)`
  position: absolute;
  right: 0;
  top: 30px;

  svg {
    font-size: 14px;
    color: #4853ec;
  }
`;
