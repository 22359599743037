import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';

import { Tag, InterestingTag } from 'atoms';
import { IEventListItem } from 'types';

interface Iprops {
  item: IEventListItem;
  itemClickHandler(url: string): void;
  color?: string;
}

const getPlainTextFromHtml = (rawString: string) => {
  // TODO replace with dompurify library
  const plainText = rawString
    .replace(/&quot;/g, '"')
    .replace(/&nbsp;/g, ' ')
    .replace(/<[^>]*>?/gm, '');

  return plainText.length > 230 ? `${plainText.substr(0, 230)}...` : plainText;
};

export const ProgramsCarouselItem = ({
  item: { id, authors, category, description, startDate, title, isInteresting },
  itemClickHandler,
  color,
}: Iprops) => {
  const eventStartDate = new Date(startDate);

  return (
    <Wrapper $color={color}>
      <CategoryWrapper>
        {isInteresting && <InterestingTag size="large" />}
        <ItemCategory size="middle" colorType={4}>
          {category.shortTitle || category.title}
        </ItemCategory>
      </CategoryWrapper>
      <StartDate>{format(eventStartDate, 'd MMMM', { locale: ru })}</StartDate>
      <Title>{title}</Title>
      {authors[0] && (
        <>
          <Post>{authors[0].position}</Post>
          <Teacher>{authors[0].fullName}</Teacher>
        </>
      )}
      {authors[1] && (
        <>
          <Post>{authors[1].position}</Post>
          <Teacher>{authors[1].fullName}</Teacher>
        </>
      )}
      <Description $color={color} onClick={() => itemClickHandler(`/event/${id}`)} className="description">
        {getPlainTextFromHtml(description)}
      </Description>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $color?: string }>`
  height: 100%;
  background: #ffffff;
  padding: 24px 25px 32px 24px;
  border-right: 1px solid #eeeeee;
  cursor: pointer;
  position: relative;

  &:hover {
    background: ${({ $color }) => $color || '#4853ec'};
    border-color: ${({ $color }) => $color || '#4853ec'};

    .description {
      display: block;
    }
  }

  @media (min-width: 1200px) {
    padding: 32px 22px 56px 24px;
  }
`;

const StartDate = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #4853ec;
  margin: 0 0 15px 0;
`;

const Title = styled.div`
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 32px;
  height: 96px;
  overflow: hidden;
  color: #000000;
  margin: 0 0 24px 0;

  @media (min-width: 1200px) {
    font-size: 24px;
  }
`;

const Teacher = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin: 0 0 10px 0;
`;

const Post = styled.div`
  font-family: 'Raleway', sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #a7a8ab;
  margin: 0 0 5px 0;
`;

const Description = styled.div<{ $color?: string }>`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  background: ${({ $color }) => $color || '#4853ec'};
  display: none;
  position: absolute;
  top: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 25px 62px 25px;

  &:hover {
    color: #ffffff;
  }
`;

const CategoryWrapper = styled.div`
  display: flex;
  margin: 0 0 40px 0;

  div:last-child {
    span {
      margin-right: 0;
    }
  }
`;

const ItemCategory = styled(Tag)``;
