import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Container } from 'atoms';

interface IProps {
  title?: string;
  children: ReactNode;
}

export const EventDescription = ({ title, children }: IProps) => (
  <Content>
    <Container>
      {title && <Title>{title}</Title>}
      {children}
    </Container>
  </Content>
);

const Content = styled.div`
  background: #f8f8f8;
  width: 100%;
  padding: 48px 0;
  margin: auto;

  p {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin: 0 0 30px 0;
  }
`;

const Title = styled.div`
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  margin: 0 0 8px 0;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 40px;
    margin: 0 0 16px 0;
  }

  @media (min-width: 1200px) {
    font-size: 32px;
    font-weight: 900;
    line-height: 48px;
    margin: 0 0 16px 0;
  }
`;
