import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { differenceInMilliseconds } from 'date-fns';
import styled from 'styled-components';

import { eventsService } from 'services';
import { getEvent, getIsLoggedIn, getSurveyData } from 'core/selectors';
import { useEventSource } from 'hooks';
import { SurveyContent } from 'components/pages/Survey';
import { getIsTodayDate, getTodayDate, glueDate } from 'helpers';

import { checkFullScreen, closeFullScreen } from './utils';

interface IMessage {
  id: number;
  created: string;
}

const EVENT_SOURCE_API = process.env.REACT_APP_API_URL || '';

export const EventSurvey = () => {
  const { startDate, startTime, id, isViewed } = useSelector(getEvent);
  const { surveyData } = useSelector(getSurveyData);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const [isOpen, setIsOpen] = useState(false);
  const [isApproved, setIsApproved] = useState<boolean>(false);
  const [isFinished, setIsFinished] = useState<boolean>(false);

  const { lastMessage } = useEventSource(id ? `${EVENT_SOURCE_API}notify-events/${id}/survey` : '', {
    isNeedToConnect: (startDate ? getIsTodayDate(glueDate(startDate, startTime)) : false) && isLoggedIn && isViewed,
  });

  const lastMessageParsed: IMessage | null = useMemo(
    () => (lastMessage ? JSON.parse(lastMessage) : null),
    [lastMessage],
  );

  useEffect(() => {
    if (lastMessageParsed && !isApproved && !isFinished) {
      setIsOpen(true);
    }
  }, [lastMessageParsed, isApproved, isFinished]);

  useEffect(() => {
    const isFullscreen = checkFullScreen();
    if (isViewed && isOpen && isFullscreen) {
      closeFullScreen();
    }
  }, [isOpen, isViewed]);

  useEffect(() => {
    if (surveyData && isFinished) {
      eventsService.getEventSurveyPassingStatus(surveyData.id).then(({ is_approved }) => {
        if (is_approved) {
          setIsApproved(true);
          setIsOpen(false);
        }
      });
    }
  }, [surveyData, isFinished]);

  const closeEventSurvey = () => {
    setIsFinished(true);
    setIsOpen(false);
  };

  if ((lastMessageParsed && !isActualMessage(lastMessageParsed?.created)) || !lastMessageParsed) {
    return null;
  }

  if (!isViewed) {
    return null;
  }

  if (!isOpen) {
    return null;
  }

  if (!id) {
    return null;
  }

  if (isApproved || isFinished) {
    return null;
  }

  return (
    <Container>
      <SurveyContent eventSurveyId={id} finalStepCallback={closeEventSurvey} />
    </Container>
  );
};

const CONTROL_VIEW_TIME = 60000;

const isActualMessage = (serverDate: string) => {
  const diff = differenceInMilliseconds(getTodayDate(), new Date(serverDate.replace(/-/g, '/')));

  return diff <= CONTROL_VIEW_TIME;
};

const Container = styled.div`
  border-radius: 12px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(18, 26, 123, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
`;
