import React from 'react';
import { Form as AntForm, FormItemProps } from 'antd';
import styled, { css } from 'styled-components';

export type TTextInputType = 'primary' | 'secondary';

interface IProps extends FormItemProps, IStyled {}

interface IStyled {
  $labelTop?: boolean;
}

export const FormItem = ({ $labelTop, ...rest }: IProps) => {
  return <StyledFormItem $labelTop={$labelTop} {...rest} />;
};

const StyledFormItem = styled(AntForm.Item)<IProps>`
  ${({ $labelTop }) => css`
    .ant-form-item-row {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }

    .ant-form-item-control {
      width: 100%;
    }

    .ant-form-item-label {
      font-family: 'Raleway', sans-serif;
      font-weight: 700;
      font-size: 16px;
    }

    ${$labelTop &&
    css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    `}
  `}
`;
