import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import { getAuthors, getAuthorsPage, getIsAuthorsLoading } from 'core/selectors';
import { getAuthorsRequestAction, getMoreAuthorsRequestAction } from 'core/actions';

import { Results } from './Results';

interface IResultsContainerProps {
  color?: string;
}

export const ResultsContainer = ({ color }: IResultsContainerProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { area } = useParams<{ area?: string }>();
  const screen = useBreakpoint();

  const { authors, total } = useSelector(getAuthors);
  const page = useSelector(getAuthorsPage);
  const authorsLoading = useSelector(getIsAuthorsLoading);

  const cardOnClick = useCallback(
    (id: number) => {
      history.push(area ? `/area/${area}/authors/${id}` : `/authors/${id}`);
    },
    [history, area],
  );

  const loadMoreAuthors = useCallback(() => {
    dispatch(getMoreAuthorsRequestAction({ alias: area }));
  }, [dispatch, area]);

  useEffect(() => {
    dispatch(getAuthorsRequestAction({ alias: area }));
  }, [dispatch, area, page]);

  return (
    <Results
      cardOnClick={cardOnClick}
      total={total}
      result={authors}
      loading={authorsLoading}
      screen={screen}
      loadMoreOnClick={loadMoreAuthors}
      color={color}
    />
  );
};
