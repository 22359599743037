import { IState } from 'core/types';

export const getCurrentSurveyStep = ({ surveys }: IState) => ({
  currentStep: surveys.currentStep,
});

export const getSurveyData = ({ surveys }: IState) => ({
  surveyData: surveys.currentSurvey,
});

export const getAllSurveys = ({ surveys }: IState) => ({
  allSurveys: surveys.allSurveys,
});

export const getSurveyAnswers = ({ surveys }: IState) => ({
  surveyAnswers: surveys.surveyAnswers,
});

export const getSurveyAnswerStatus = ({ surveys }: IState) => ({
  surveyAnswersStatus: surveys.answersStatus,
});
