import React, { FunctionComponent, memo } from 'react';
import { Tag as AntdTag } from 'antd';
import { CheckableTagProps } from 'antd/es/tag';
import styled from 'styled-components';

import { CloseIcon } from 'assets/icons';

interface ISearchTag extends ISearchTagStyles, CheckableTagProps {
  checked: boolean;
}

interface ISearchTagStyles {}

export const SearchTag: FunctionComponent<ISearchTag> = memo(({ children, ...props }) => {
  const { checked } = props;
  return (
    <StyledTag {...props}>
      {children}
      {checked && <CloseIcon />}
    </StyledTag>
  );
});

const StyledTag = styled(AntdTag.CheckableTag)`
  margin-top: 8px;
  &.ant-tag-checkable {
    color: #4853ec;
    border: 2px solid #4853ec;
    border-radius: 4px;
    &:not(.ant-tag-checkable-checked):hover {
      color: #4853ec;
    }
  }

  &.ant-tag-checkable-checked {
    background-color: #4853ec;
    color: #ffffff;
  }

  svg {
    margin-left: 10px;
  }
`;
