import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button } from 'components/atoms';
import { Cookies as CookiesImage } from 'assets/icons';
import { getCookies, setCookies } from 'helpers';

export const CookiesPopup = () => {
  const [isClosed, setClosed] = useState<boolean>(false);

  useEffect(() => {
    const isAgreeCookie = getCookies('AGR_DATE');
    setClosed(Boolean(isAgreeCookie));
  }, []);

  const onAgreeClick = () => {
    const dateNow = Date.now();
    setCookies('AGR_DATE', `${dateNow}`);
    setClosed(true);
  };

  if (isClosed) return null;

  return (
    <CookiesWrapper>
      <ContentWrapper>
        <LeftSideContent>
          <CookiesImage />
          <CookiesText>
            Мы используем cookie. Это позволяет нам анализировать взаимодействие посетителей с сайтом и делать его
            лучше. Продолжая пользоваться сайтом, вы соглашаетесь с использованием файлом cookie.
          </CookiesText>
        </LeftSideContent>
        <Button size="small" onClick={onAgreeClick}>
          Я согласен
        </Button>
      </ContentWrapper>
    </CookiesWrapper>
  );
};

const CookiesWrapper = styled.div`
  background-color: rgba(18, 26, 123, 0.9);
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 12px;

  @media (min-width: 1200px) {
    padding: 16px 0px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: column;

  & button:first-of-type {
    margin-top: 16px;
  }

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;

    & button:first-of-type {
      width: 143px;
    }
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
    flex-direction: row;

    & button:first-of-type {
      margin-top: 0px;
    }
  }

  @media (min-width: 1400px) {
    max-width: 1172px;
  }
`;

const CookiesText = styled.div`
  color: #ffffff;
  font-size: 14px;
  font-family: Open Sans;
  line-height: 20px;
  margin-top: 8px;

  @media (min-width: 768px) {
    margin-top: 0px;
    margin-left: 24px;
    max-width: calc(100% - 64px);
  }

  @media (min-width: 1200px) {
    max-width: 808px;
  }
`;

const LeftSideContent = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;
