import React from 'react';
import { Select as AntdSelect, SelectProps } from 'antd';
import styled, { CSSProperties } from 'styled-components';

import { DownIcon } from 'assets/icons';

export interface ISelect extends ISelectStyles, SelectProps<string | any> {}

interface ISelectStyles {
  arrowColor?: string;
}

export const Select = ({ arrowColor, ...props }: ISelect) => {
  return <StyledSelect {...props} dropdownStyle={DropDownStyles} $arrowColor={arrowColor} suffixIcon={<DownIcon />} />;
};

const StyledSelect = styled((p: SelectProps) => <AntdSelect {...p} />)<{ $arrowColor?: string }>`
  font-weight: 700;

  &.ant-select-open {
    .ant-select-selection-item {
      color: #2630ae;
    }
    .ant-select-arrow {
      color: #2630ae;
    }
  }

  .ant-select-arrow {
    color: ${({ $arrowColor }) => $arrowColor ?? '#000000'};
  }

  &.ant-select {
    .ant-select-selector {
      border: none;
      box-shadow: none;
      background-color: transparent;
    }
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
    border: none;
  }
`;

const DropDownStyles: CSSProperties = {
  background: '#FFFFFF',
  border: '1px solid #EBEFF2',
  borderRadius: 12,
};
