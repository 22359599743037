import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { getAuthorEvents } from 'core/selectors';
import { getAuthorEventsRequestAction } from 'core/actions';

import { ProgramsCarousel } from './ProgramsCarousel';

interface IContainerProps {
  color?: string;
}

export const Container = ({ color }: IContainerProps) => {
  const dispatch = useDispatch();
  const events = useSelector(getAuthorEvents);
  const history = useHistory();
  const { area, id } = useParams<{ id: string; area?: string }>();

  const itemClickHandler = (url: string = '') => {
    history.push(area ? `/area/${area}/${url}` : url);
  };

  const seeAllBtnHandler = () => {
    history.push(area ? `/area/${area}/catalog/all` : '/catalog/all');
  };

  useEffect(() => {
    dispatch(getAuthorEventsRequestAction({ id }));
  }, [dispatch, id, area]);

  if (!events.length) {
    return null;
  }

  return (
    <ProgramsCarousel
      items={events}
      itemClickHandler={itemClickHandler}
      seeAllBtnHandler={seeAllBtnHandler}
      disableTitle
      bordered
      color={color}
    />
  );
};
