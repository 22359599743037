import React, { ReactNode } from 'react';
import { Menu, Dropdown as AntdDropdown } from 'antd';
import styled, { css } from 'styled-components';

interface IOption {
  value: string;
  caption: string;
}

interface IProps {
  title?: ReactNode;
  options?: IOption[];
  onClick?(value: string): void;
}

export const Dropdown = ({ title = 'Dropdown', options = [], onClick, ...props }: IProps) => {
  const getMenu = () => {
    return (
      <MenuContainer>
        {options?.map(({ value, caption }) => (
          <Menu.Item key={value} onClick={() => onClick?.(value)}>
            {caption}
          </Menu.Item>
        ))}
      </MenuContainer>
    );
  };

  return (
    <DropdownContainer overlay={getMenu()} trigger={['click']} {...props}>
      <div className="ant-dropdown-link">{title}</div>
    </DropdownContainer>
  );
};

const MenuContainer = styled(Menu)`
  ${({
    theme: { dropDownMenuBgColor, dropDownMenuTextColor, dropDownMenuActiveBgColor, dropDownMenuActiveTextColor },
  }) => css`
    background-color: ${dropDownMenuBgColor};
    box-shadow: 0px 4px 8px rgba(136, 149, 158, 0.08);
    border-radius: 12px;
    overflow: hidden;
    padding: 16px 0;

    .ant-dropdown-menu-item {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: ${dropDownMenuTextColor};
      padding: 8px 30px 8px 16px;

      &-active {
        color: ${dropDownMenuActiveTextColor};
        background-color: ${dropDownMenuActiveBgColor};
      }
    }
  `}
`;

const DropdownContainer = styled(AntdDropdown)`
  width: 100%;
`;
