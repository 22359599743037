import { createAction } from 'typesafe-actions';

import { CatalogSearchASC, IEventCategory, IEventListItem, ITag } from 'types';

import { ACTIONS } from './constants';

export const putCurrentCatalogCategoryToStoreAction = createAction(ACTIONS.PUT_CURRENT_CATALOG_CATEGORY_TO_STORE)<{
  id?: number;
}>();
export const putSearchASCToStoreAction = createAction(ACTIONS.PUT_SEARCH_ASC_TO_STORE)<{ asc: CatalogSearchASC }>();

export const getCatalogCategoriesRequestAction = createAction(ACTIONS.GET_CATALOG_CATEGORIES_REQUEST)<{
  alias?: string;
}>();
export const getCatalogCategoriesSuccessAction = createAction(ACTIONS.GET_CATALOG_CATEGORIES_SUCCESS)();
export const getCatalogCategoriesFailureAction = createAction(ACTIONS.GET_CATALOG_CATEGORIES_FAILURE)();
export const putCatalogCategoriesToStoreAction = createAction(ACTIONS.PUT_CATALOG_CATEGORIES_TO_STORE)<{
  categories: IEventCategory[];
}>();

export const getTagsRequestAction = createAction(ACTIONS.GET_TAGS_REQUEST)();
export const getTagsSuccessAction = createAction(ACTIONS.GET_TAGS_SUCCESS)();
export const getTagsFailureAction = createAction(ACTIONS.GET_TAGS_FAILURE)();
export const putTagsToStoreAction = createAction(ACTIONS.PUT_TAGS_TO_STORE)<{ tags: ITag[] }>();

export const getCatalogEventsRequestAction = createAction(ACTIONS.GET_CATALOG_EVENTS_REQUEST)<{ alias?: string }>();
export const getCatalogEventsSuccessAction = createAction(ACTIONS.GET_CATALOG_EVENTS_SUCCESS)();
export const getCatalogEventsFailureAction = createAction(ACTIONS.GET_CATALOG_EVENTS_FAILURE)();
export const putCatalogEventsToStoreAction = createAction(ACTIONS.PUT_CATALOG_EVENTS_TO_STORE)<{
  events: IEventListItem[];
  eventsTotal: number;
}>();

export const getMoreCatalogEventsRequestAction = createAction(ACTIONS.GET_MORE_CATALOG_EVENTS_REQUEST)<{
  alias?: string;
}>();
export const getMoreCatalogEventsSuccessAction = createAction(ACTIONS.GET_MORE_CATALOG_EVENTS_SUCCESS)();
export const getMoreCatalogEventsFailureAction = createAction(ACTIONS.GET_MORE_CATALOG_EVENTS_FAILURE)();

export const resetCatalogState = createAction(ACTIONS.RESET_CATALOG_STATE)();

export interface ICatalogSearchFormValues {
  searchString: string;
  dateStart: Date;
  dateEnd: Date;
  checkedTags: string[];
}

// Catalog Search
export const setLastCatalogSearchAction = createAction(ACTIONS.SET_LAST_CATALOG_SEARCH)<
  Partial<ICatalogSearchFormValues>
>();
export const resetLastCatalogSearchAction = createAction(ACTIONS.RESET_LAST_CATALOG_SEARCH)();
