import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import md5 from 'md5';
import styled from 'styled-components';

import { getEvent } from 'core/selectors';
import { AuthService } from 'services';
import { IUser } from 'types';
import { convertUserToAppSchema } from 'services/apiMappers';
import { Button } from 'atoms';

declare global {
  interface Window {
    tolstoycomments: {
      t: number;
      push: (obj: { action: string; values: object }) => void;
      destroy: () => void;
      comment: { config: any };
      widget: {
        config: any;
        open: () => void;
        init: () => void;
        isopen: () => void;
        destroy: () => void;
        identity: string;
      };
    };
  }
}

export const Comments = () => {
  const { isViewed, id, title } = useSelector(getEvent);

  const [user, setUser] = useState<null | IUser>(null);

  useEffect(() => {
    if (isViewed) {
      AuthService.getUser()
        .then((apiUser) => setUser(convertUserToAppSchema(apiUser)))
        .catch(() => setUser(null));
    } else {
      setUser(null);
    }
  }, [isViewed]);

  useEffect(() => {
    if (id && isViewed && user) {
      window.tolstoycomments = window.tolstoycomments || [];

      const widget = window.tolstoycomments?.widget || {};

      const widgetConfig = widget?.config || {};
      widgetConfig.sso = widgetConfig?.sso || {};
      widgetConfig.comment_class = 'tolstoycomments-cc';
      widgetConfig.sso = createSignature(user);
      widgetConfig.lang = 'ru-Ru';
      widgetConfig.identity = `${id} ${title}`;
      widget?.init?.();

      return () => {
        widget?.destroy();
      };
    }

    return () => {};
  }, [user, isViewed, id, title]);

  if (!id || !isViewed || !user) {
    return null;
  }

  return (
    <CommentsBlock>
      {window.tolstoycomments?.widget?.open ? (
        <Btn onClick={() => window.tolstoycomments?.widget?.open()}>Открыть чат для вопросов</Btn>
      ) : null}
      <div className="tolstoycomments-feed" />
    </CommentsBlock>
  );
};

function createUserInfo(user: IUser): string {
  const id = (Math.random() + 1).toString(36).substring(7);

  const json = JSON.stringify({
    id,
    nick: user.fullName,
    email: user.email,
    avatar: 'https://static.tolstoycomments.com/ui/ac/b1/fa/acb1faad-2fad-441a-b789-da57f5317399.png',
  });
  return btoa(unescape(encodeURIComponent(json)));
}

function createSignature(user: IUser): string {
  const key = 'ee0e4683-31f7-4530-ab18-f8ec6433449b';
  const userdata = createUserInfo(user);
  const microtime = Date.now();
  const signtext = userdata + key + microtime.toString();

  const sign = md5(signtext);
  const sso = `${userdata} ${sign} ${microtime}`;

  return sso;
}

const Btn = styled(Button)`
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 20px;
`;

const CommentsBlock = styled.div`
  background: #ffffff;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  overflow: hidden;

  .hc__messages {
    max-height: 485px;
    overflow: auto;

    @media (min-width: 768px) {
      max-height: 420px;
      overflow: auto;
    }
  }

  .hc__menu__right {
    visibility: hidden;
    pointer-events: none;
  }
`;
