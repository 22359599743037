import React, { memo, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { InputDigit } from './InputDigit';

export interface IInputCode {
  code: string;
  setCode: (newCode: string) => void;
  wrongCode?: boolean;
  isLoading?: boolean;
  codeError?: string;
}

export const InputCode = memo(({ code, wrongCode, setCode, isLoading, codeError }: IInputCode) => {
  const [currentFocus, setCurrentFocus] = useState(0);

  const onFocus = useCallback(({ target, target: { name } }: React.FocusEvent<HTMLInputElement>) => {
    target.setSelectionRange(0, 1);
    setCurrentFocus(Number(name));
  }, []);

  const onKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      BACKSPACE_KEYS.includes(event.key) ||
      BACKSPACE_KEYS.includes(event.key) // for mobile devices
    )
      return setCurrentFocus(PREV_FOCUS);

    if (ARROW_LEFT_KEYS.includes(event.key)) return setCurrentFocus(PREV_FOCUS);

    if (ARROW_RIGHT_KEYS.includes(event.key)) return setCurrentFocus(NEXT_FOCUS);

    if (DELETE_KEYS.includes(event.key)) return setCurrentFocus(NEXT_FOCUS);

    return null;
  }, []);

  const afterOnChange = useCallback(({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.value) setCurrentFocus(NEXT_FOCUS);
    target.setSelectionRange(0, 1);
  }, []);

  const commonInputParams = useMemo(
    () => ({
      onFocus,
      onKeyDown,
      afterOnChange,
      hasError: wrongCode,
      isLoading,
    }),
    [onFocus, onKeyDown, afterOnChange, wrongCode, isLoading],
  );

  const setDigit = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    const index = Number(name);
    const newValue = value.trim() || ' ';
    const newCode = code.substr(0, index) + newValue + code.substr(index + newValue.length);
    setCode(newCode);
  };

  return (
    <>
      <InputCodeStyle>
        <InputDigit
          value={code[0] || ''}
          onChange={setDigit}
          onPaste={(e) => {
            e.persist();
            const value = e.clipboardData.getData('Text');
            setCode(value);
          }}
          name="0"
          focus={currentFocus === 0}
          {...commonInputParams}
        />
        <InputDigit
          value={code[1] || ''}
          onChange={setDigit}
          name="1"
          focus={currentFocus === 1}
          {...commonInputParams}
        />
        <InputDigit
          value={code[2] || ''}
          onChange={setDigit}
          name="2"
          focus={currentFocus === 2}
          {...commonInputParams}
        />
        <InputDigit
          value={code[3] || ''}
          onChange={setDigit}
          name="3"
          focus={currentFocus === 3}
          {...commonInputParams}
        />
      </InputCodeStyle>
      {codeError && <ErrorMessage>{codeError}</ErrorMessage>}
    </>
  );
});

const PREV_FOCUS = (currentFocus: number) => (currentFocus === 0 ? 0 : currentFocus - 1);

const NEXT_FOCUS = (currentFocus: number, maxLength = 5) =>
  currentFocus === maxLength - 1 ? maxLength - 1 : currentFocus + 1;

const InputCodeStyle = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & input {
    margin-right: 32px;

    @media (max-width: 768px) {
      margin-right: 14px;
    }
  }

  & input:last-of-type {
    margin-right: 0px;
  }
`;

const ARROW_LEFT_KEYS = ['ArrowLeft'];

const ARROW_RIGHT_KEYS = ['ArrowRight'];

const BACKSPACE_KEYS = ['Backspace'];

const DELETE_KEYS = ['Delete'];

const ErrorMessage = styled.p`
  margin-top: 12px;
  font-size: 12px;
  color: #eb5757;
`;
